<template>
  <div class="interactive-container">
    <img src="../../assets/images/session10/Blurred-BG.png" alt="background" class="session-background">
    <div class="text-box">
      <h1>Hjælpsomme / omsorgsfulde & venlige hænder</h1>
      <p>Hvilke positive ting kan vi kigge efter hos de mennesker vi ser op til?</p>
      <p>Hvilke ting kan vi li’ ved disse personer?</p>
      <p>Nogle eksempler kunne være, at de:</p>
      <p>&bull;&nbsp;&nbsp;holder af dyr, træer og levende væsener</p>
      <p>&bull;&nbsp;&nbsp;vælger ”grønne” tanker</p>
      <p>&bull;&nbsp;&nbsp;er omsorgsfulde</p>
      <p>&bull;&nbsp;&nbsp;er hjælpsomme</p>
      <p>&bull;&nbsp;&nbsp;smiler</p>
      <p>&bull;&nbsp;&nbsp;deler</p>
      <p>Kan du komme i tanke <br>om flere ting?</p>
    </div>
    <div class="hand-container" id="hand-1">
      <svg class="hand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538 691" width="538" height="691">
        <path id="&lt;Path&gt;" class="shp0-green" d="M497.35 172.88C465.98 172.88 452.19 202.82 442.94 228.49C430.88 261.99 420.32 296 407.94 329.41C417.42 270.59 429.28 212.19 440.38 153.67C447.47 116.26 466 36.09 400.19 44.04C356.33 49.34 357.62 110 351.21 142.5C339.73 200.74 327.38 259.01 317.28 317.51C321.02 234.47 317.35 150.49 317.35 67.37C317.35 55.76 317.35 44.16 317.35 32.56C317.35 -3.77 259.21 -8.36 239.72 12.45C227.04 26 227.83 47.44 227.94 64.7C228.1 89.93 228.53 115.17 228.75 140.4C229.26 196.76 230.5 253.13 229.22 309.48C216.92 245.89 202.38 182.51 191.49 118.66C185.25 82.06 168.63 20.31 116.72 49.9C94.72 62.44 95.17 91.83 97.81 113.7C102 148.45 109.28 182.96 113.96 217.71C123.54 288.88 133.76 359.97 142.44 431.26C128.02 403.15 116.36 373.61 102.6 345.15C92.85 324.97 83.74 294.83 59.33 288.16C38.18 282.38 6.79 294.47 1.51 317.61C-4.36 343.32 15.66 377.98 24.52 401.32C64.66 507.07 97.1 660.45 226.89 686.55C348.98 711.1 478.94 624.44 476.25 493.88C475.67 465.76 470.62 437.4 477.93 409.71C486.77 376.25 501.62 344.34 512.37 311.46C523.46 277.51 569.53 172.88 497.35 172.88Z" />
      </svg>
      <p>"Grønne" tanker</p>
    </div>
    <div class="hand-container" id="hand-2">
      <svg class="hand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538 691" width="538" height="691">
        <path id="&lt;Path&gt;" class="shp0-blue" d="M497.35 172.88C465.98 172.88 452.19 202.82 442.94 228.49C430.88 261.99 420.32 296 407.94 329.41C417.42 270.59 429.28 212.19 440.38 153.67C447.47 116.26 466 36.09 400.19 44.04C356.33 49.34 357.62 110 351.21 142.5C339.73 200.74 327.38 259.01 317.28 317.51C321.02 234.47 317.35 150.49 317.35 67.37C317.35 55.76 317.35 44.16 317.35 32.56C317.35 -3.77 259.21 -8.36 239.72 12.45C227.04 26 227.83 47.44 227.94 64.7C228.1 89.93 228.53 115.17 228.75 140.4C229.26 196.76 230.5 253.13 229.22 309.48C216.92 245.89 202.38 182.51 191.49 118.66C185.25 82.06 168.63 20.31 116.72 49.9C94.72 62.44 95.17 91.83 97.81 113.7C102 148.45 109.28 182.96 113.96 217.71C123.54 288.88 133.76 359.97 142.44 431.26C128.02 403.15 116.36 373.61 102.6 345.15C92.85 324.97 83.74 294.83 59.33 288.16C38.18 282.38 6.79 294.47 1.51 317.61C-4.36 343.32 15.66 377.98 24.52 401.32C64.66 507.07 97.1 660.45 226.89 686.55C348.98 711.1 478.94 624.44 476.25 493.88C475.67 465.76 470.62 437.4 477.93 409.71C486.77 376.25 501.62 344.34 512.37 311.46C523.46 277.51 569.53 172.88 497.35 172.88Z" />
      </svg>
      <p>Omsorgsfuld</p>
    </div>
    <div class="hand-container" id="hand-3">
      <svg class="hand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538 691" width="538" height="691">
        <path id="&lt;Path&gt;" class="shp0-red" d="M497.35 172.88C465.98 172.88 452.19 202.82 442.94 228.49C430.88 261.99 420.32 296 407.94 329.41C417.42 270.59 429.28 212.19 440.38 153.67C447.47 116.26 466 36.09 400.19 44.04C356.33 49.34 357.62 110 351.21 142.5C339.73 200.74 327.38 259.01 317.28 317.51C321.02 234.47 317.35 150.49 317.35 67.37C317.35 55.76 317.35 44.16 317.35 32.56C317.35 -3.77 259.21 -8.36 239.72 12.45C227.04 26 227.83 47.44 227.94 64.7C228.1 89.93 228.53 115.17 228.75 140.4C229.26 196.76 230.5 253.13 229.22 309.48C216.92 245.89 202.38 182.51 191.49 118.66C185.25 82.06 168.63 20.31 116.72 49.9C94.72 62.44 95.17 91.83 97.81 113.7C102 148.45 109.28 182.96 113.96 217.71C123.54 288.88 133.76 359.97 142.44 431.26C128.02 403.15 116.36 373.61 102.6 345.15C92.85 324.97 83.74 294.83 59.33 288.16C38.18 282.38 6.79 294.47 1.51 317.61C-4.36 343.32 15.66 377.98 24.52 401.32C64.66 507.07 97.1 660.45 226.89 686.55C348.98 711.1 478.94 624.44 476.25 493.88C475.67 465.76 470.62 437.4 477.93 409.71C486.77 376.25 501.62 344.34 512.37 311.46C523.46 277.51 569.53 172.88 497.35 172.88Z" />
      </svg>
      <p>Hjælpsom</p>
    </div>
    <div class="hand-container" id="hand-4">
      <svg class="hand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538 691" width="538" height="691">
        <path id="&lt;Path&gt;" class="shp0-yellow" d="M497.35 172.88C465.98 172.88 452.19 202.82 442.94 228.49C430.88 261.99 420.32 296 407.94 329.41C417.42 270.59 429.28 212.19 440.38 153.67C447.47 116.26 466 36.09 400.19 44.04C356.33 49.34 357.62 110 351.21 142.5C339.73 200.74 327.38 259.01 317.28 317.51C321.02 234.47 317.35 150.49 317.35 67.37C317.35 55.76 317.35 44.16 317.35 32.56C317.35 -3.77 259.21 -8.36 239.72 12.45C227.04 26 227.83 47.44 227.94 64.7C228.1 89.93 228.53 115.17 228.75 140.4C229.26 196.76 230.5 253.13 229.22 309.48C216.92 245.89 202.38 182.51 191.49 118.66C185.25 82.06 168.63 20.31 116.72 49.9C94.72 62.44 95.17 91.83 97.81 113.7C102 148.45 109.28 182.96 113.96 217.71C123.54 288.88 133.76 359.97 142.44 431.26C128.02 403.15 116.36 373.61 102.6 345.15C92.85 324.97 83.74 294.83 59.33 288.16C38.18 282.38 6.79 294.47 1.51 317.61C-4.36 343.32 15.66 377.98 24.52 401.32C64.66 507.07 97.1 660.45 226.89 686.55C348.98 711.1 478.94 624.44 476.25 493.88C475.67 465.76 470.62 437.4 477.93 409.71C486.77 376.25 501.62 344.34 512.37 311.46C523.46 277.51 569.53 172.88 497.35 172.88Z" />
      </svg>
      <p>Smiler</p>
    </div>
    <div class="hand-container" id="hand-5">
      <svg class="hand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538 691" width="538" height="691">
        <path id="&lt;Path&gt;" class="shp0-pink" d="M497.35 172.88C465.98 172.88 452.19 202.82 442.94 228.49C430.88 261.99 420.32 296 407.94 329.41C417.42 270.59 429.28 212.19 440.38 153.67C447.47 116.26 466 36.09 400.19 44.04C356.33 49.34 357.62 110 351.21 142.5C339.73 200.74 327.38 259.01 317.28 317.51C321.02 234.47 317.35 150.49 317.35 67.37C317.35 55.76 317.35 44.16 317.35 32.56C317.35 -3.77 259.21 -8.36 239.72 12.45C227.04 26 227.83 47.44 227.94 64.7C228.1 89.93 228.53 115.17 228.75 140.4C229.26 196.76 230.5 253.13 229.22 309.48C216.92 245.89 202.38 182.51 191.49 118.66C185.25 82.06 168.63 20.31 116.72 49.9C94.72 62.44 95.17 91.83 97.81 113.7C102 148.45 109.28 182.96 113.96 217.71C123.54 288.88 133.76 359.97 142.44 431.26C128.02 403.15 116.36 373.61 102.6 345.15C92.85 324.97 83.74 294.83 59.33 288.16C38.18 282.38 6.79 294.47 1.51 317.61C-4.36 343.32 15.66 377.98 24.52 401.32C64.66 507.07 97.1 660.45 226.89 686.55C348.98 711.1 478.94 624.44 476.25 493.88C475.67 465.76 470.62 437.4 477.93 409.71C486.77 376.25 501.62 344.34 512.37 311.46C523.46 277.51 569.53 172.88 497.35 172.88Z" />
      </svg>
      <p>Deler</p>
    </div>

    <audio src="../../assets/sounds/session10/Page197.mp3" ref="voice"/>
    <div class="page-number" id="page-light">197</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: 'Session10Page11',
  methods: {
    animateText() {
      let text = document.querySelector('.text-box').children;
      let hands = document.querySelectorAll('.hand-container');
      let animation = anime.timeline({easing: 'linear', duration: 500})
      animation
        .add({targets: text[1], opacity: 1}, 4600)
        .add({targets: text[2], opacity: 1}, 10600)
        .add({targets: text[3], opacity: 1}, 14100)
        .add({targets: text[4], opacity: 1}, 16400)
        .add({targets: hands[0], opacity: 1}, 20400)
        .add({targets: text[5], opacity: 1}, 20400)
        .add({targets: hands[1], opacity: 1}, 22800)
        .add({targets: text[6], opacity: 1}, 22800)
        .add({targets: hands[2], opacity: 1}, 25000)
        .add({targets: text[7], opacity: 1}, 25000)
        .add({targets: hands[3], opacity: 1}, 26700)
        .add({targets: text[8], opacity: 1}, 26700)
        .add({targets: hands[4], opacity: 1}, 28000)
        .add({targets: text[9], opacity: 1}, 28000)
        .add({targets: text[10], opacity: 1}, 29600)
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1)
    },
  },
  mounted() {
    this.animateText();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
#hand-1 {right: 32%;top: 10vh;}
#hand-2 {right: 4%;top: 10vh;}
#hand-3 {left: 25%;bottom: 10vh;}
#hand-4 {left: 50%;bottom: 10vh;}
#hand-5 {right: 6%;bottom: 10vh;}
#hand-2 p {font-size:2.5vh}
.hand-container {
  position: absolute;
  width: 20%;
  height: 34vh;
  opacity: 0;
}
.hand-container p {
  color: #ffffff;
  width: 100%;
  font-size: 3vh;
  font-weight: bold;
  position: absolute;
  left: 1vh;
  bottom: 2vh;
  text-align: center;
  text-transform: uppercase;
}
.hand {
  position: absolute;
  height: auto;
  width: 28vh;
}
.text-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  padding: 1vh;
}
.text-box h1 {
  font-size: 4vh;
}
.text-box p {
  font-size: 3vh;
  margin-bottom: 1vh;
  opacity: 0;
}
tspan { white-space:pre }
.shp0-red { fill: #C32C42 }
.shp0-blue { fill: #04ABDE }
.shp0-pink { fill: #F2ADC9 }
.shp0-yellow { fill: #FAB800 }
.shp0-green { fill: #4DB847 }
</style>
