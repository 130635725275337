<template>
  <div class="interactive-container">
    <div class="text-box">
      <h1>At vælge ”Røde” eller ”Grønne” tanker: At sove i sin egen seng</h1>
      <p>Mathilde skal til at sove i sin egen seng for første gang.</p>
      <p>Prøv at falde til ro, for eksempel ved at lukke dine
        øjne i. Forestil dig nu at du er Mathilde. Hvilke ”røde”
        tanker og ”grønne” tanker mon der kører igennem dit
        hoved?</p>
      <p>Husk at alle tanker er OK! Hvisk dine tanker til en ven(inde).</p>
      <p>Gå sammen og tal om hvilke tanker der ville kunne
        hjælpe Mathilde til bedre at kunne finde ro og falde i
        søvn.</p>
      <p>Vi er herre over vores egne tanker.
        <br>Vi kan vælge at tænke grønne tanker.</p>
    </div>
    <img src="../../assets/images/session5/86.jpg" alt="red" class="images" id="image-top">
    <img src="../../assets/images/session5/85693.jpg" alt="green" class="images" id="image-bottom">
    <svg class="traffic-light" id="top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1223 388" width="1223" height="388">
      <g id="Layer 1">
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp0-red" d="M1101.37 350.87C1082.27 350.87 1066.77 335.38 1066.77 316.19L1066.77 71.98C1066.77 52.86 1082.27 37.39 1101.37 37.39L1187.97 37.39C1207.14 37.39 1222.64 52.86 1222.64 71.98L1222.64 316.19C1222.64 335.38 1207.14 350.87 1187.97 350.87L1101.37 350.87Z" />
          <path id="&lt;Path&gt;" class="shp0-red" d="M35.59 350.87C16.49 350.87 0.98 335.38 0.98 316.19L0.98 71.98C0.98 52.86 16.49 37.39 35.59 37.39L122.18 37.39C141.36 37.39 156.86 52.86 156.86 71.98L156.86 316.19C156.86 335.38 141.36 350.87 122.18 350.87L35.59 350.87Z" />
          <path id="&lt;Path&gt;" class="shp0-red" d="M347.55 323.7C331.74 323.7 318.92 310.9 318.92 295.03L318.92 93.15C318.92 77.35 331.74 64.56 347.55 64.56L419.15 64.56C434.95 64.56 447.76 77.35 447.76 93.15L447.76 295.03C447.76 310.9 434.95 323.7 419.15 323.7L347.55 323.7Z" />
          <path id="&lt;Path&gt;" class="shp1-red" d="M50.79 387.42C47.78 387.42 45.33 384.97 45.33 381.96L45.33 6.3C45.33 3.28 47.78 0.84 50.79 0.84L1170.65 0.84C1173.67 0.84 1176.11 3.28 1176.11 6.3L1176.11 381.96C1176.11 384.97 1173.67 387.42 1170.65 387.42L50.79 387.42Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp2-red" d="M810.58 375.06C807.56 375.06 805.12 372.61 805.12 369.6L805.12 18.66C805.12 15.64 807.56 13.2 810.58 13.2L1161.52 13.2C1164.54 13.2 1166.98 15.64 1166.98 18.66L1166.98 369.6C1166.98 372.61 1164.54 375.06 1161.52 375.06L810.58 375.06Z" />
            <path id="&lt;Path&gt;" class="shp3-red" d="M986.05 364.57C892.35 364.57 816.36 288.57 816.36 194.9C816.36 101.17 892.35 25.22 986.05 25.22C1079.8 25.22 1155.74 101.17 1155.74 194.9C1155.74 288.57 1079.8 364.57 986.05 364.57Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M1146.08 45.79C1140.05 45.79 1135.17 40.9 1135.17 34.89C1135.17 28.86 1140.05 23.98 1146.08 23.98C1152.1 23.98 1156.98 28.86 1156.98 34.89C1156.98 40.9 1152.1 45.79 1146.08 45.79Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M827.26 45.79C821.24 45.79 816.36 40.9 816.36 34.89C816.36 28.86 821.24 23.98 827.26 23.98C833.28 23.98 838.16 28.86 838.16 34.89C838.16 40.9 833.28 45.79 827.26 45.79Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M1146.08 364.57C1140.05 364.57 1135.17 359.69 1135.17 353.67C1135.17 347.65 1140.05 342.77 1146.08 342.77C1152.1 342.77 1156.98 347.65 1156.98 353.67C1156.98 359.69 1152.1 364.57 1146.08 364.57Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M827.26 364.57C821.24 364.57 816.36 359.69 816.36 353.67C816.36 347.65 821.24 342.77 827.26 342.77C833.28 342.77 838.16 347.65 838.16 353.67C838.16 359.69 833.28 364.57 827.26 364.57Z" />
            <path id="&lt;Path&gt;" class="shp1-red" d="M986.05 340.55C905.61 340.55 840.38 275.31 840.38 194.9C840.38 114.43 905.61 49.24 986.05 49.24C1066.53 49.24 1131.72 114.43 1131.72 194.9C1131.72 275.31 1066.53 340.55 986.05 340.55Z" />
            <path id="&lt;Path&gt;" class="shp5-red" d="M986.05 325.97C913.66 325.97 854.96 267.26 854.96 194.9C854.96 122.49 913.66 63.82 986.05 63.82C1058.48 63.82 1117.14 122.49 1117.14 194.9C1117.14 267.26 1058.48 325.97 986.05 325.97Z" />
            <path id="&lt;Path&gt;" class="shp6-red" d="M986.05 314.23C920.13 314.23 866.72 260.84 866.72 194.9C866.72 128.96 920.13 75.62 986.05 75.62C1051.96 75.62 1105.35 128.96 1105.35 194.9C1105.35 260.84 1051.96 314.23 986.05 314.23Z" />
            <path id="&lt;Path&gt;" class="shp7-red" d="M889.52 211.49C883.32 211.49 878.3 204.06 878.3 194.89C878.3 185.73 883.32 178.29 889.52 178.29C895.73 178.29 900.76 185.73 900.76 194.89C900.76 204.06 895.73 211.49 889.52 211.49Z" />
            <path id="&lt;Path&gt;" class="shp7-red" d="M966.67 194.92C966.67 241.13 979.22 282.99 999.55 313.47C1059.1 306.77 1105.35 256.27 1105.35 194.9C1105.35 133.53 1059.1 83.07 999.54 76.38C979.21 106.88 966.67 148.75 966.67 194.92Z" />
            <path id="&lt;Path&gt;" class="shp8-red" d="M1046.87 256.54C1023.82 256.54 1005.16 228.96 1005.16 194.9C1005.16 160.87 1023.82 133.24 1046.87 133.24C1069.9 133.24 1088.61 160.87 1088.61 194.9C1088.61 228.96 1069.9 256.54 1046.87 256.54Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp2-red" d="M435.87 375.06C432.85 375.06 430.41 372.61 430.41 369.6L430.41 18.66C430.41 15.64 432.85 13.2 435.87 13.2L786.81 13.2C789.83 13.2 792.27 15.64 792.27 18.66L792.27 369.6C792.27 372.61 789.83 375.06 786.81 375.06L435.87 375.06Z" />
            <path id="&lt;Path&gt;" class="shp3-red" d="M611.34 364.57C517.64 364.57 441.65 288.57 441.65 194.9C441.65 101.17 517.64 25.22 611.34 25.22C705.1 25.22 781.03 101.17 781.03 194.9C781.03 288.57 705.1 364.57 611.34 364.57Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M771.37 45.79C765.35 45.79 760.46 40.9 760.46 34.89C760.46 28.86 765.35 23.98 771.37 23.98C777.39 23.98 782.27 28.86 782.27 34.89C782.27 40.9 777.39 45.79 771.37 45.79Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M452.55 45.79C446.53 45.79 441.65 40.9 441.65 34.89C441.65 28.86 446.53 23.98 452.55 23.98C458.58 23.98 463.45 28.86 463.45 34.89C463.45 40.9 458.58 45.79 452.55 45.79Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M771.37 364.57C765.35 364.57 760.46 359.69 760.46 353.67C760.46 347.65 765.35 342.77 771.37 342.77C777.39 342.77 782.27 347.65 782.27 353.67C782.27 359.69 777.39 364.57 771.37 364.57Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M452.55 364.57C446.53 364.57 441.65 359.69 441.65 353.67C441.65 347.65 446.53 342.77 452.55 342.77C458.58 342.77 463.45 347.65 463.45 353.67C463.45 359.69 458.58 364.57 452.55 364.57Z" />
            <path id="&lt;Path&gt;" class="shp1-red" d="M611.34 340.55C530.9 340.55 465.67 275.31 465.67 194.9C465.67 114.43 530.9 49.24 611.34 49.24C691.83 49.24 757.01 114.43 757.01 194.9C757.01 275.31 691.83 340.55 611.34 340.55Z" />
            <path id="&lt;Path&gt;" class="shp3-red" d="M611.34 325.97C538.95 325.97 480.25 267.26 480.25 194.9C480.25 122.49 538.95 63.82 611.34 63.82C683.77 63.82 742.43 122.49 742.43 194.9C742.43 267.26 683.77 325.97 611.34 325.97Z" />
            <path id="&lt;Path&gt;" class="shp0-red" d="M611.34 314.23C545.42 314.23 492.01 260.84 492.01 194.9C492.01 128.96 545.42 75.62 611.34 75.62C677.26 75.62 730.64 128.96 730.64 194.9C730.64 260.84 677.26 314.23 611.34 314.23Z" />
            <path id="&lt;Path&gt;" class="shp9-red" d="M515.09 211.49C508.89 211.49 503.86 204.06 503.86 194.89C503.86 185.73 508.89 178.29 515.09 178.29C521.29 178.29 526.33 185.73 526.33 194.89C526.33 204.06 521.29 211.49 515.09 211.49Z" />
            <path id="&lt;Path&gt;" class="shp10-red" d="M591.96 194.92C591.96 241.13 604.51 282.99 624.84 313.47C684.39 306.77 730.64 256.27 730.64 194.9C730.64 133.53 684.39 83.07 624.83 76.38C604.5 106.88 591.96 148.75 591.96 194.92Z" />
            <path id="&lt;Path&gt;" class="shp9-red" d="M672.16 256.54C649.11 256.54 630.45 228.96 630.45 194.9C630.45 160.87 649.11 133.24 672.16 133.24C695.2 133.24 713.91 160.87 713.91 194.9C713.91 228.96 695.2 256.54 672.16 256.54Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp2-red" d="M61.16 375.06C58.15 375.06 55.7 372.61 55.7 369.6L55.7 18.66C55.7 15.64 58.15 13.2 61.16 13.2L412.1 13.2C415.12 13.2 417.56 15.64 417.56 18.66L417.56 369.6C417.56 372.61 415.12 375.06 412.1 375.06L61.16 375.06Z" />
            <path id="&lt;Path&gt;" class="shp3-red" d="M236.63 364.57C142.93 364.57 66.94 288.57 66.94 194.9C66.94 101.17 142.93 25.22 236.63 25.22C330.39 25.22 406.33 101.17 406.33 194.9C406.33 288.57 330.39 364.57 236.63 364.57Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M396.66 45.79C390.64 45.79 385.75 40.9 385.75 34.89C385.75 28.86 390.64 23.98 396.66 23.98C402.68 23.98 407.56 28.86 407.56 34.89C407.56 40.9 402.68 45.79 396.66 45.79Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M77.84 45.79C71.82 45.79 66.94 40.9 66.94 34.89C66.94 28.86 71.82 23.98 77.84 23.98C83.87 23.98 88.75 28.86 88.75 34.89C88.75 40.9 83.87 45.79 77.84 45.79Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M396.66 364.57C390.64 364.57 385.75 359.69 385.75 353.67C385.75 347.65 390.64 342.77 396.66 342.77C402.68 342.77 407.56 347.65 407.56 353.67C407.56 359.69 402.68 364.57 396.66 364.57Z" />
            <path id="&lt;Path&gt;" class="shp4-red" d="M77.84 364.57C71.82 364.57 66.94 359.69 66.94 353.67C66.94 347.65 71.82 342.77 77.84 342.77C83.87 342.77 88.75 347.65 88.75 353.67C88.75 359.69 83.87 364.57 77.84 364.57Z" />
            <path id="&lt;Path&gt;" class="shp1-red" d="M236.63 340.55C156.19 340.55 90.96 275.31 90.96 194.9C90.96 114.43 156.19 49.24 236.63 49.24C317.12 49.24 382.31 114.43 382.3 194.9C382.3 275.31 317.12 340.55 236.63 340.55Z" />
            <path id="&lt;Path&gt;" class="shp3-red" d="M236.63 325.97C164.24 325.97 105.54 267.26 105.54 194.9C105.54 122.49 164.24 63.82 236.63 63.82C309.06 63.82 367.72 122.49 367.72 194.9C367.72 267.26 309.06 325.97 236.63 325.97Z" />
            <path id="&lt;Path&gt;" class="shp0-red" d="M236.63 314.23C170.71 314.23 117.3 260.84 117.3 194.9C117.3 128.96 170.71 75.62 236.63 75.62C302.55 75.62 355.93 128.96 355.93 194.9C355.93 260.84 302.55 314.23 236.63 314.23Z" />
            <path id="&lt;Path&gt;" class="shp10-red" d="M217.25 194.92C217.25 241.13 229.8 282.99 250.13 313.47C309.68 306.77 355.93 256.27 355.93 194.9C355.93 133.53 309.68 83.07 250.12 76.38C229.79 106.88 217.25 148.75 217.25 194.92Z" />
            <path id="&lt;Path&gt;" class="shp9-red" d="M297.45 256.54C274.4 256.54 255.74 228.96 255.74 194.9C255.74 160.87 274.4 133.24 297.45 133.24C320.49 133.24 339.2 160.87 339.2 194.9C339.2 228.96 320.49 256.54 297.45 256.54Z" />
            <path id="&lt;Path&gt;" class="shp9-red" d="M139.57 211.49C133.36 211.49 128.34 204.06 128.34 194.89C128.34 185.73 133.36 178.29 139.57 178.29C145.77 178.29 150.81 185.73 150.81 194.89C150.81 204.06 145.77 211.49 139.57 211.49Z" />
          </g>
        </g>
      </g>
    </svg>
    <svg class="traffic-light" id="bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1223 387" width="1223" height="387">
      <g id="Layer 1">
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp0-green" d="M1101.37 350.57C1082.27 350.57 1066.77 335.08 1066.77 315.89L1066.77 71.68C1066.77 52.56 1082.27 37.09 1101.37 37.09L1187.97 37.09C1207.14 37.09 1222.64 52.56 1222.64 71.68L1222.64 315.89C1222.64 335.08 1207.14 350.57 1187.97 350.57L1101.37 350.57Z" />
          <path id="&lt;Path&gt;" class="shp0-green" d="M35.59 350.57C16.49 350.57 0.98 335.08 0.98 315.89L0.98 71.68C0.98 52.56 16.49 37.09 35.59 37.09L122.18 37.09C141.36 37.09 156.86 52.56 156.86 71.68L156.86 315.89C156.86 335.08 141.36 350.57 122.18 350.57L35.59 350.57Z" />
          <path id="&lt;Path&gt;" class="shp0-green" d="M347.55 323.4C331.74 323.4 318.92 310.6 318.92 294.73L318.92 92.85C318.92 77.05 331.74 64.25 347.55 64.25L419.15 64.25C434.95 64.25 447.76 77.05 447.76 92.85L447.76 294.73C447.76 310.6 434.95 323.4 419.15 323.4L347.55 323.4Z" />
          <path id="&lt;Path&gt;" class="shp1-green" d="M50.79 387.12C47.78 387.12 45.33 384.67 45.33 381.66L45.33 6C45.33 2.98 47.78 0.54 50.79 0.54L1170.65 0.54C1173.67 0.54 1176.11 2.98 1176.11 6L1176.11 381.66C1176.11 384.67 1173.67 387.12 1170.65 387.12L50.79 387.12Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp2-green" d="M810.58 374.76C807.56 374.76 805.12 372.31 805.12 369.3L805.12 18.35C805.12 15.34 807.56 12.9 810.58 12.9L1161.52 12.9C1164.54 12.9 1166.98 15.34 1166.98 18.35L1166.98 369.3C1166.98 372.31 1164.54 374.76 1161.52 374.76L810.58 374.76Z" />
            <path id="&lt;Path&gt;" class="shp3-green" d="M986.05 364.27C892.35 364.27 816.36 288.27 816.36 194.6C816.36 100.87 892.35 24.92 986.05 24.92C1079.8 24.92 1155.74 100.87 1155.74 194.6C1155.74 288.27 1079.8 364.27 986.05 364.27Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M1146.08 45.49C1140.05 45.49 1135.17 40.6 1135.17 34.59C1135.17 28.56 1140.05 23.68 1146.08 23.68C1152.1 23.68 1156.98 28.56 1156.98 34.59C1156.98 40.6 1152.1 45.49 1146.08 45.49Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M827.26 45.49C821.24 45.49 816.36 40.6 816.36 34.59C816.36 28.56 821.24 23.68 827.26 23.68C833.28 23.68 838.16 28.56 838.16 34.59C838.16 40.6 833.28 45.49 827.26 45.49Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M1146.08 364.27C1140.05 364.27 1135.17 359.38 1135.17 353.37C1135.17 347.34 1140.05 342.46 1146.08 342.46C1152.1 342.46 1156.98 347.34 1156.98 353.37C1156.98 359.38 1152.1 364.27 1146.08 364.27Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M827.26 364.27C821.24 364.27 816.36 359.38 816.36 353.37C816.36 347.34 821.24 342.46 827.26 342.46C833.28 342.46 838.16 347.34 838.16 353.37C838.16 359.38 833.28 364.27 827.26 364.27Z" />
            <path id="&lt;Path&gt;" class="shp1-green" d="M986.05 340.25C905.61 340.25 840.38 275.01 840.38 194.6C840.38 114.13 905.61 48.94 986.05 48.94C1066.53 48.94 1131.72 114.13 1131.72 194.6C1131.72 275.01 1066.53 340.25 986.05 340.25Z" />
            <path id="&lt;Path&gt;" class="shp3-green" d="M986.05 325.67C913.66 325.67 854.96 266.96 854.96 194.6C854.96 122.19 913.66 63.52 986.05 63.52C1058.48 63.52 1117.14 122.19 1117.14 194.6C1117.14 266.96 1058.48 325.67 986.05 325.67Z" />
            <path id="&lt;Path&gt;" class="shp0-green" d="M986.05 313.93C920.13 313.93 866.72 260.54 866.72 194.6C866.72 128.66 920.13 75.32 986.05 75.32C1051.96 75.32 1105.35 128.66 1105.35 194.6C1105.35 260.54 1051.96 313.93 986.05 313.93Z" />
            <path id="&lt;Path&gt;" class="shp5-green" d="M889.52 211.19C883.32 211.19 878.3 203.76 878.3 194.59C878.3 185.43 883.32 177.99 889.52 177.99C895.73 177.99 900.76 185.43 900.76 194.59C900.76 203.76 895.73 211.19 889.52 211.19Z" />
            <path id="&lt;Path&gt;" class="shp6-green" d="M966.67 194.62C966.67 240.83 979.22 282.69 999.55 313.17C1059.1 306.47 1105.35 255.97 1105.35 194.6C1105.35 133.23 1059.1 82.77 999.54 76.08C979.21 106.58 966.67 148.45 966.67 194.62Z" />
            <path id="&lt;Path&gt;" class="shp5-green" d="M1046.87 256.24C1023.82 256.24 1005.16 228.66 1005.16 194.6C1005.16 160.57 1023.82 132.94 1046.87 132.94C1069.9 132.94 1088.61 160.57 1088.61 194.6C1088.61 228.66 1069.9 256.24 1046.87 256.24Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp2-green" d="M435.87 374.76C432.85 374.76 430.41 372.31 430.41 369.3L430.41 18.35C430.41 15.34 432.85 12.9 435.87 12.9L786.81 12.9C789.83 12.9 792.27 15.34 792.27 18.35L792.27 369.3C792.27 372.31 789.83 374.76 786.81 374.76L435.87 374.76Z" />
            <path id="&lt;Path&gt;" class="shp3-green" d="M611.34 364.27C517.64 364.27 441.65 288.27 441.65 194.6C441.65 100.87 517.64 24.92 611.34 24.92C705.1 24.92 781.03 100.87 781.03 194.6C781.03 288.27 705.1 364.27 611.34 364.27Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M771.37 45.49C765.35 45.49 760.46 40.6 760.46 34.59C760.46 28.56 765.35 23.68 771.37 23.68C777.39 23.68 782.27 28.56 782.27 34.59C782.27 40.6 777.39 45.49 771.37 45.49Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M452.55 45.49C446.53 45.49 441.65 40.6 441.65 34.59C441.65 28.56 446.53 23.68 452.55 23.68C458.58 23.68 463.45 28.56 463.45 34.59C463.45 40.6 458.58 45.49 452.55 45.49Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M771.37 364.27C765.35 364.27 760.46 359.38 760.46 353.37C760.46 347.34 765.35 342.46 771.37 342.46C777.39 342.46 782.27 347.34 782.27 353.37C782.27 359.38 777.39 364.27 771.37 364.27Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M452.55 364.27C446.53 364.27 441.65 359.38 441.65 353.37C441.65 347.34 446.53 342.46 452.55 342.46C458.58 342.46 463.45 347.34 463.45 353.37C463.45 359.38 458.58 364.27 452.55 364.27Z" />
            <path id="&lt;Path&gt;" class="shp1-green" d="M611.34 340.25C530.9 340.25 465.67 275.01 465.67 194.6C465.67 114.13 530.9 48.94 611.34 48.94C691.83 48.94 757.01 114.13 757.01 194.6C757.01 275.01 691.83 340.25 611.34 340.25Z" />
            <path id="&lt;Path&gt;" class="shp3-green" d="M611.34 325.67C538.95 325.67 480.25 266.96 480.25 194.6C480.25 122.19 538.95 63.52 611.34 63.52C683.77 63.52 742.43 122.19 742.43 194.6C742.43 266.96 683.77 325.67 611.34 325.67Z" />
            <path id="&lt;Path&gt;" class="shp0-green" d="M611.34 313.93C545.42 313.93 492.01 260.54 492.01 194.6C492.01 128.66 545.42 75.32 611.34 75.32C677.26 75.32 730.64 128.66 730.64 194.6C730.64 260.54 677.26 313.93 611.34 313.93Z" />
            <path id="&lt;Path&gt;" class="shp5-green" d="M515.09 211.19C508.89 211.19 503.86 203.76 503.86 194.59C503.86 185.43 508.89 177.99 515.09 177.99C521.29 177.99 526.33 185.43 526.33 194.59C526.33 203.76 521.29 211.19 515.09 211.19Z" />
            <path id="&lt;Path&gt;" class="shp6-green" d="M591.96 194.62C591.96 240.83 604.51 282.69 624.84 313.17C684.39 306.47 730.64 255.97 730.64 194.6C730.64 133.23 684.39 82.77 624.83 76.08C604.5 106.58 591.96 148.45 591.96 194.62Z" />
            <path id="&lt;Path&gt;" class="shp5-green" d="M672.16 256.24C649.11 256.24 630.45 228.66 630.45 194.6C630.45 160.57 649.11 132.94 672.16 132.94C695.2 132.94 713.91 160.57 713.91 194.6C713.91 228.66 695.2 256.24 672.16 256.24Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp2-green" d="M61.16 374.76C58.15 374.76 55.7 372.31 55.7 369.3L55.7 18.35C55.7 15.34 58.15 12.9 61.16 12.9L412.1 12.9C415.12 12.9 417.56 15.34 417.56 18.35L417.56 369.3C417.56 372.31 415.12 374.76 412.1 374.76L61.16 374.76Z" />
            <path id="&lt;Path&gt;" class="shp3-green" d="M236.63 364.27C142.93 364.27 66.94 288.27 66.94 194.6C66.94 100.87 142.93 24.92 236.63 24.92C330.39 24.92 406.33 100.87 406.33 194.6C406.33 288.27 330.39 364.27 236.63 364.27Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M396.66 45.49C390.64 45.49 385.75 40.6 385.75 34.59C385.75 28.56 390.64 23.68 396.66 23.68C402.68 23.68 407.56 28.56 407.56 34.59C407.56 40.6 402.68 45.49 396.66 45.49Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M77.84 45.49C71.82 45.49 66.94 40.6 66.94 34.59C66.94 28.56 71.82 23.68 77.84 23.68C83.87 23.68 88.75 28.56 88.75 34.59C88.75 40.6 83.87 45.49 77.84 45.49Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M396.66 364.27C390.64 364.27 385.75 359.38 385.75 353.37C385.75 347.34 390.64 342.46 396.66 342.46C402.68 342.46 407.56 347.34 407.56 353.37C407.56 359.38 402.68 364.27 396.66 364.27Z" />
            <path id="&lt;Path&gt;" class="shp4-green" d="M77.84 364.27C71.82 364.27 66.94 359.38 66.94 353.37C66.94 347.34 71.82 342.46 77.84 342.46C83.87 342.46 88.75 347.34 88.75 353.37C88.75 359.38 83.87 364.27 77.84 364.27Z" />
            <path id="&lt;Path&gt;" class="shp1-green" d="M236.63 340.25C156.19 340.25 90.96 275.01 90.96 194.6C90.96 114.13 156.19 48.94 236.63 48.94C317.12 48.94 382.31 114.13 382.3 194.6C382.3 275.01 317.12 340.25 236.63 340.25Z" />
            <path id="&lt;Path&gt;" class="shp7-green" d="M236.63 325.67C164.24 325.67 105.54 266.96 105.54 194.6C105.54 122.19 164.24 63.52 236.63 63.52C309.06 63.52 367.72 122.19 367.72 194.6C367.72 266.96 309.06 325.67 236.63 325.67Z" />
            <path id="&lt;Path&gt;" class="shp8-green" d="M236.63 313.93C170.71 313.93 117.3 260.54 117.3 194.6C117.3 128.66 170.71 75.32 236.63 75.32C302.55 75.32 355.93 128.66 355.93 194.6C355.93 260.54 302.55 313.93 236.63 313.93Z" />
            <path id="&lt;Path&gt;" class="shp9-green" d="M217.25 194.62C217.25 240.83 229.8 282.69 250.13 313.17C309.68 306.47 355.93 255.97 355.93 194.6C355.93 133.23 309.68 82.77 250.12 76.08C229.79 106.58 217.25 148.45 217.25 194.62Z" />
            <path id="&lt;Path&gt;" class="shp10-green" d="M297.45 256.24C274.4 256.24 255.74 228.66 255.74 194.6C255.74 160.57 274.4 132.94 297.45 132.94C320.49 132.94 339.2 160.57 339.2 194.6C339.2 228.66 320.49 256.24 297.45 256.24Z" />
            <path id="&lt;Path&gt;" class="shp9-green" d="M139.57 211.19C133.36 211.19 128.34 203.76 128.34 194.59C128.34 185.43 133.36 177.99 139.57 177.99C145.77 177.99 150.81 185.43 150.81 194.59C150.81 203.76 145.77 211.19 139.57 211.19Z" />
          </g>
        </g>
      </g>
    </svg>
    <audio src="../../assets/sounds/session6/Page130.mp3" ref="voice"/>
    <div class="page-number" id="page-light">130</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: 'Session6Page3',
  methods: {
    animateElements() {
      let text = document.getElementsByClassName('text-box')[0].children;
      let animation = anime.timeline({easing: 'linear', duration: 500})
      animation
        .add({targets: text[1], opacity: 1}, 5900)
        .add({targets: text[2], opacity: 1}, 11000)
        .add({targets: '#top', opacity: 1}, 20000)
        .add({targets: '#bottom', opacity: 1}, 21500)
        .add({targets: text[3], opacity: 1}, 25300)
        .add({targets: text[4], opacity: 1}, 31900)
        .add({targets: text[5], opacity: 1}, 40700)
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1)
    },
  },
  mounted() {
    this.animateElements();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
.traffic-light {
  position: absolute;
  width: auto;
  height: 10vh;
  right: 33%;
  transform: rotate(-90deg);
  opacity: 0;
}
#top {
  top: 15vh;
}
#bottom {
  top: 65vh;
}
.text-box {
  position: absolute;
  width: 48%;
  padding: 1vh;
}
.text-box h1 {
  font-size: 3.5vh;
  font-weight: bold;
  margin-bottom: 1vh;
}
.text-box p {
  font-size: 2.75vh;
  margin-bottom: 1vh;
  opacity: 0;
}
.text-box p:last-child {
  padding: 1vh 0;
  font-size: 3vh;
  margin-top: 16%;
  font-weight: bold;
  display: block;
  background-color: #00ce7c;
  color: #ffffff;
  text-align: center;
}
.images {
  position: absolute;
  width: 52%;
  height: 46vh;
  right: 0;
}
#image-bottom {
  bottom: 0;
}
.red {
  color: #ff0000;
}
.green {
  color: #00ce7c;
}
.shp0-red { fill: #2f3038 }
.shp1-red { fill: #27282b }
.shp2-red { fill: #37393e }
.shp3-red { fill: #44484e }
.shp4-red { fill: #626972 }
.shp5-red { fill: #c4261a }
.shp6-red { fill: #ff2f17 }
.shp7-red { fill: #ff4222 }
.shp8-red { fill: #ff715b }
.shp9-red { fill: #494e54 }
.shp10-red { fill: #363840 }

.shp0-green { fill: #2f3038 }
.shp1-green { fill: #27282b }
.shp2-green { fill: #37393e }
.shp3-green { fill: #44484e }
.shp4-green { fill: #626972 }
.shp5-green { fill: #494e54 }
.shp6-green { fill: #363840 }
.shp7-green { fill: #098541 }
.shp8-green { fill: #039f4b }
.shp9-green { fill: #3dae48 }
.shp10-green { fill: #79c864 }
</style>