<template>
  <div class="interactive-container cloud-background">
    <svg class="cloud1" ref="cloud1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223 103" width="223" height="103">
      <title>Cloud</title>
      <g id="Cloud">
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp-cloud" d="M222.91 51.51C222.91 44.61 217.31 39.01 210.41 39.01C208.83 39.01 207.32 39.31 205.93 39.85C206.06 38.9 206.16 37.95 206.16 36.96C206.16 25.79 197.1 16.72 185.92 16.72C178.34 16.72 171.75 20.89 168.28 27.05C167.41 12.12 155.03 0.27 139.87 0.27C129.25 0.27 120 6.09 115.11 14.71C107.7 9.48 98.67 6.4 88.91 6.4C73.54 6.4 59.95 14.04 51.71 25.71C47.91 23.85 43.62 22.79 39.1 22.79C24.67 22.79 12.73 33.44 10.69 47.3C4.64 49.91 0.41 55.92 0.41 62.92C0.41 72.31 8.02 79.92 17.41 79.92C20.74 79.92 23.84 78.95 26.46 77.29C30.27 79.17 34.56 80.22 39.1 80.22C43.46 80.22 47.59 79.24 51.29 77.5C59.49 89.52 73.27 97.4 88.91 97.4C93.13 97.4 97.21 96.82 101.09 95.74C104.76 100.13 110.27 102.92 116.43 102.92C125.03 102.92 132.34 97.48 135.17 89.87C140.82 94.57 148.08 97.4 156.01 97.4C161.99 97.4 167.59 95.79 172.41 92.97C175.04 95.7 178.73 97.4 182.82 97.4C187.33 97.4 191.35 95.35 194 92.13C195.27 92.37 196.57 92.51 197.91 92.51C209.23 92.51 218.41 83.33 218.41 72.01C218.41 68.62 217.57 65.43 216.12 62.62C220.15 60.54 222.91 56.35 222.91 51.51Z" />
        </g>
      </g>
    </svg>
    <svg class="sun" ref="sun" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269 265" width="150" height="150">
      <title>Sun</title>
      <g id="Sun">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp-sun" d="M199.23 134.28C199.23 170.2 170.1 199.33 134.18 199.33C98.25 199.33 69.13 170.2 69.13 134.28C69.13 98.35 98.25 69.23 134.18 69.23C170.1 69.23 199.23 98.35 199.23 134.28Z" />
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M141.57 63.77L126.79 63.77L120.94 0.31L147.42 0.31L141.57 63.77Z" />
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M113.88 66.63L106.25 69.41L88.04 28.75L101.69 23.79L113.88 66.63Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M94.52 75.51L83.19 85.02L37.93 40.16L58.21 23.14L94.52 75.51Z" />
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M75.14 95.51L71.09 102.54L31 83.1L38.27 70.52L75.14 95.51Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M66.03 114.75L63.46 129.32L-0.05 124.05L4.55 97.98L66.03 114.75Z" />
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M64.04 142.53L65.45 150.51L22.24 161.39L19.72 147.08L64.04 142.53Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M69.42 163.13L76.81 175.94L24.78 212.73L11.54 189.8L69.42 163.13Z" />
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M85.75 185.68L91.96 190.9L65.86 227L54.73 217.66L85.75 185.68Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M103.11 198.01L117.01 203.06L100.8 264.7L75.92 255.64L103.11 198.01Z" />
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M130.12 204.79L138.23 204.79L141.44 249.22L126.91 249.22L130.12 204.79Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M151.35 203.06L165.24 198.01L192.44 255.64L167.56 264.7L151.35 203.06Z" />
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M176.39 190.9L182.61 185.69L213.62 217.66L202.5 227L176.39 190.9Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M191.54 175.94L198.94 163.13L256.82 189.8L243.58 212.73L191.54 175.94Z" />
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M202.91 150.52L204.32 142.53L248.64 147.09L246.11 161.39L202.91 150.52Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M204.9 129.32L202.33 114.75L263.81 97.98L268.41 124.05L204.9 129.32Z" />
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M197.27 102.54L193.21 95.51L230.09 70.52L237.35 83.1L197.27 102.54Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M185.17 85.02L173.84 75.51L210.15 23.15L230.43 40.16L185.17 85.02Z" />
                <path id="&lt;Path&gt;" class="shp-sunlight" d="M162.11 69.41L154.49 66.64L166.67 23.79L180.32 28.76L162.11 69.41Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="cloud2" ref="cloud2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236 91" width="230" height="71">
      <title>Cloud</title>
      <g id="Layer 2">
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp-cloud" d="M218.3 37.31C218.3 37.24 218.31 37.16 218.31 37.09C218.31 21.3 205.51 8.5 189.72 8.5C183.28 8.5 177.34 10.63 172.57 14.22C167.62 10.63 161.54 8.5 154.96 8.5C150.34 8.5 145.97 9.55 142.07 11.41C136.83 4.51 128.53 0.05 119.2 0.05C110.8 0.05 103.24 3.66 97.99 9.41C94.39 7.88 90.44 7.04 86.28 7.04C79.16 7.04 72.62 9.52 67.47 13.67C62.18 10.4 55.95 8.5 49.27 8.5C32.94 8.5 19.26 19.8 15.58 34.99C6.63 38.98 0.38 47.96 0.38 58.39C0.38 72.54 11.85 84 25.99 84C28.6 84 31.12 83.61 33.49 82.88C37.48 87.6 43.43 90.6 50.09 90.6C56.47 90.6 62.22 87.84 66.19 83.46C68.64 85.02 71.55 85.94 74.68 85.94C78.69 85.94 82.34 84.43 85.13 81.97C89.13 87.51 95.63 91.12 102.98 91.12C109.24 91.12 114.89 88.5 118.89 84.3C120.79 85.01 122.83 85.42 124.97 85.42C131.51 85.42 137.21 81.79 140.16 76.44C142.54 82.39 148.35 86.61 155.15 86.61C161.41 86.61 166.82 83.05 169.5 77.85C173.47 83.29 179.9 86.83 187.15 86.83C195.33 86.83 202.45 82.34 206.19 75.69C208.93 77.06 212.03 77.84 215.31 77.84C226.56 77.84 235.68 68.72 235.68 57.47C235.68 47.23 228.13 38.76 218.3 37.31Z" />
        </g>
      </g>
    </svg>
    <svg class="long-cloud" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 517 87" width="800" height="120">
      <title>Cloud</title>
      <g id="Cloud">
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp-cloud" d="M513.11 86.13C515.46 82.84 516.86 78.83 516.86 74.48C516.86 63.38 507.86 54.38 496.75 54.38C494.02 54.38 491.42 54.93 489.05 55.91C485.98 52.6 481.69 50.45 476.89 50.18C477.2 48.54 477.37 46.85 477.37 45.12C477.37 30.23 465.29 18.16 450.4 18.16C439.95 18.16 430.89 24.11 426.41 32.8C422.68 29.88 417.97 28.12 412.86 28.12C403.73 28.12 395.9 33.68 392.57 41.6C389.68 41.96 387 43 384.7 44.58C383.57 35.31 375.68 28.12 366.1 28.12C364.96 28.12 363.85 28.24 362.77 28.44C360.1 20.7 352.77 15.12 344.12 15.12C333.65 15.12 325.1 23.3 324.45 33.61C321.06 30.22 316.37 28.12 311.2 28.12C304.13 28.12 297.98 32.04 294.78 37.82C292.79 37.06 290.63 36.62 288.37 36.62C284.6 36.62 281.09 37.78 278.2 39.75C275.52 27.94 264.97 19.12 252.36 19.12C240.18 19.12 229.93 27.34 226.83 38.53C225.48 37.85 224.04 37.33 222.52 37.01C216.25 31.74 208.15 28.56 199.32 28.56C182.94 28.56 169.12 39.46 164.69 54.39C163.95 55.52 163.32 56.72 162.81 57.99C162.83 57.66 162.86 57.33 162.86 57C162.86 48.4 155.89 41.43 147.29 41.43C143.06 41.43 139.22 43.14 136.41 45.88C136.7 44.45 136.86 42.96 136.86 41.43C136.86 29.11 126.87 19.12 114.54 19.12C108.31 19.12 102.67 21.69 98.62 25.81C99.41 23.73 99.86 21.48 99.86 19.12C99.86 8.63 91.35 0.12 80.86 0.12C70.36 0.12 61.86 8.63 61.86 19.12C61.86 19.14 61.86 19.14 61.86 19.15C58.33 16.63 54.02 15.12 49.36 15.12C37.48 15.12 27.86 24.75 27.86 36.62C27.86 37.17 27.9 37.71 27.94 38.24C16.66 39.39 7.86 48.92 7.86 60.5C7.86 62.79 8.2 64.99 8.84 67.07C3.76 69.48 0.23 74.63 0.23 80.63C0.23 82.57 0.62 84.43 1.29 86.13L513.11 86.13Z" />
        </g>
      </g>
    </svg>
    <svg class="small-cloud" ref="smallCloud" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 62" width="93" height="62">
      <title>Small Cloud</title>
      <g id="Small Cloud">
        <g id="&lt;Group&gt;">
          <path id="&lt;Compound Path&gt;" class="shp-cloud" d="M92.33 34.73C92.33 29.03 87.75 24.41 82.07 24.33C82.07 24.2 82.09 24.07 82.09 23.94C82.09 18.11 77.36 13.38 71.53 13.38C70.73 13.38 69.95 13.48 69.2 13.65C66.82 6.21 59.85 0.82 51.62 0.82C44.27 0.82 37.92 5.12 34.95 11.34C32.56 9.81 29.72 8.93 26.68 8.93C18.17 8.93 11.28 15.82 11.28 24.33C11.28 25.27 11.37 26.18 11.53 27.08C5.13 28.45 0.33 34.14 0.33 40.94C0.33 48.78 6.68 55.13 14.52 55.13C16.65 55.13 18.67 54.65 20.49 53.81C22.64 58.51 27.39 61.78 32.9 61.78C36.24 61.78 39.31 60.57 41.68 58.57C43.21 60.56 45.61 61.85 48.31 61.85C52.06 61.85 55.23 59.38 56.29 55.98C59.02 58.63 62.74 60.27 66.84 60.27C75.2 60.27 81.98 53.49 81.98 45.13L81.98 45.13C87.7 45.1 92.33 40.46 92.33 34.73Z" />
        </g>
      </g>
    </svg>
    <svg class="twitter-bird bird-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 -10 500 500"  xml:space="preserve">
      <g transform="rotate(30, 250, 250)">
        <path id="body" fill="#F3560A" d="M142.9,364.1c-1.6,1-3,1.7-4,2.3c-3,1.5-7.9,3.8-14.9,6.9c-7,3.1-14.7,5.7-23.1,7.9
  c-8.4,2.2-15.6,3.8-21.8,4.7c-6.2,0.9-12.2,1.5-18.1,1.8s-11.4,0.3-16.7,0c-5.2-0.3-8.5-0.5-9.6-0.6l-1.8-0.2l-0.4-0.1l-0.4-0.1v0.8
  h0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1H33l0.4,0.2l0.4,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l3.6,2.2c2.4,1.5,4.5,2.6,6.4,3.6
  s6.6,3.3,14.1,7.1c7.6,3.7,16.6,7.4,27.2,11.1s18.6,6.2,24,7.4c5.4,1.3,12.8,2.6,22.2,3.9s14.9,2.1,16.3,2.2
  c1.5,0.1,3.3,0.3,5.5,0.4l3.3,0.2v0.2h25.6v-0.2l14-1.3c9.3-0.9,17.6-2.1,25-3.3c7.3-1.3,14.9-3.1,22.8-5.5
  c7.9-2.4,15.3-4.9,22.4-7.7c7.1-2.8,13.7-5.7,19.7-9c6.1-3.2,11.3-6,15.6-8.5c4.3-2.5,9.1-5.6,14.2-9.3c5.2-3.7,10-7.5,14.6-11.2
  s7.1-5.9,7.7-6.4c0.6-0.6,4-4,10.2-10.2c6.2-6.3,11.3-11.9,15.4-16.8c4-5,8-10.3,12-15.9c3.9-5.6,8.3-12.5,13-20.6
  s9.2-17.3,13.5-27.5s8-20.7,11-31.5c3-10.7,5.2-20.4,6.7-28.9s2.4-16.5,3-23.8c0.5-7.3,0.8-13.9,0.9-19.7s2.5-10.8,7.4-14.8
  s9.9-8.5,15-13.7c5.1-5.1,7.9-8,8.3-8.7c0.5-0.7,0.9-1.1,1.1-1.2c0.3-0.1,2.1-2.3,5.3-6.7c3.3-4.4,5-6.6,5-6.7l0.1-0.1l0.2-0.4
  l0.2-0.4l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.2-0.1V115l-1,0.3l-0.8,0.1
  l-0.4,0.2l-0.4,0.2l-0.4,0.2l-0.4,0.2l-0.6,0.2l-1.2,0.4l-10.6,3.6c-6.7,2.2-13.7,4.1-21,5.7l-11,2.4h-1.9l0.1-0.1l0.1-0.1l0.1-0.1
  l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.4-0.2l0.4-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.4-0.2l0.4-0.2l1.9-1.3
  c1.3-0.9,2.4-1.8,3.3-2.8s2.8-2.7,5.6-5.1c2.8-2.4,6-6,9.5-10.7s6.5-9.4,8.8-14s3.6-7.2,3.7-7.9c0.1-0.6,0.3-1.2,0.4-1.6l0.2-0.6
  l0.2-0.4l0.2-0.4l0.2-0.6l0.2-0.6l0.1-0.6l0.1-0.6l-0.4,0.1l-0.4,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.4,0.2l-0.4,0.2
  l-0.4,0.2l-0.4,0.2l-0.1,0.1c-0.1,0.1-0.8,0.4-1.9,1.2c-1.2,0.7-4.7,2.4-10.5,5s-11.6,5-17.5,7.1s-11.4,3.7-16.5,4.9
  s-8.8,0.5-11.3-1.9c-2.4-2.4-5.2-4.7-8.3-6.9c-3.1-2.2-6.5-4.3-10.4-6.4c-3.8-2.1-7.7-3.9-11.8-5.5c-4-1.6-8.6-2.9-13.5-3.8
  l-7.4-1.5h-20.5v0.1c0,0.1-1.7,0.4-5.1,0.9s-7.6,1.6-12.6,3.3c-5,1.7-10.4,4.2-16.3,7.4c-5.9,3.3-11.1,7-15.7,11.2
  s-8.3,8.3-11.2,12.2c-2.9,3.9-5.2,7.4-7,10.5C221.5,163.3,231.3,307,142.9,364.1z"/>
        <path id="wing1" fill="#f98d5b" d="M233.2,181.5c-5-0.5-12.4-1.7-22.2-3.6c-9.8-1.8-16.8-3.3-20.8-4.5s-11.1-3.7-21.2-7.4
  c-10.1-3.8-19.5-8-28.3-12.8c-8.8-4.7-16.8-9.5-24-14.4s-13.1-9.1-17.5-12.8c-4.5-3.7-7.1-6-7.9-7s-1.5-1.6-1.9-1.8
  c-0.5-0.2-3.2-2.7-8-7.6s-9.1-9.2-12.6-13.2l-5.3-5.9l-0.1-0.1l-0.1-0.1L63.1,90l-0.2-0.4l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
  l-0.1-0.1L62.3,89l-0.1-0.1l0,0.1l-0.1,0.1L62,89.2l0,0.1l-0.1,0.1L61.7,90l-0.2,0.6L57.9,98c-2.2,5-3.9,9.7-5.1,14.1
  c-1.2,4.5-1.9,8.5-2.4,12c-0.4,3.5-0.5,7.8-0.4,12.8s0.7,10,1.8,15.1c1,5.1,2.6,10.2,4.6,15.2c2.1,5.1,4,9.2,5.8,12.5
  c1.8,3.2,3.9,6.4,6.3,9.2c2.4,2.9,4.7,5.6,7.1,8.3s4.6,4.7,6.7,6.4c2.2,1.6,3.3,2.5,3.4,2.5l0.1,0.1l0.4,0.2l0.4,0.2l0.1,0.1
  l0.1,0.1l0.1,0.1L87,207l0.4,0.2l0.4,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1H85l-1.8-0.2
  c-1.2-0.1-4.8-0.9-10.8-2.2s-11.1-2.9-15.1-4.7l-6.1-2.8l-0.4-0.2l-0.4-0.2l-0.4-0.2l-0.4-0.2l-0.4-0.1l-0.4-0.1l0.7,8.3
  c0.4,5.5,1.4,11,2.8,16.3c1.5,5.4,3.6,11,6.5,16.9s6.2,11.1,9.8,15.5c3.7,4.5,7,8.1,10.1,11c3.1,2.8,6.3,5.4,9.8,7.8
  c3.4,2.4,8,4.8,13.8,7.3s9.3,3.9,10.6,4.3c1.3,0.4,2.2,0.6,2.8,0.8l0.8,0.2l0.8,0.2l0.8,0.2l0.8,0.2l0.8,0.2l-0.1,0.1l-0.1,0.1
  l-0.1,0.1l-0.1,0.1l-1,0.2l-1,0.2l-0.8,0.2c-0.5,0.1-1.9,0.4-4.3,0.8s-6.6,0.6-12.8,0.8c-6.2,0.1-10.7,0-13.5-0.4l-4.3-0.6L81,288
  l-0.6-0.1l0.1,0.4l0.1,0.4l0.2,0.6l0.2,0.6l3.2,7.2c2.2,4.8,4.4,9,6.7,12.7c2.3,3.7,5.1,7.2,8.3,10.7c3.2,3.5,5.8,6.2,7.9,8
  c2.1,1.9,5.3,4.3,9.9,7.2c4.6,2.9,9.3,5.4,14.1,7.4c4.9,2.1,9.4,3.7,13.5,4.7c4.2,1,7.3,1.6,9.2,1.8c1.9,0.1,4,0.3,6.1,0.4l3.1,0.2
  c117.9-117.9,82.9-167.7,82.9-167.7l-2.8-0.1C241.6,182.3,238.2,181.9,233.2,181.5z"/>
        <path id="wing2" fill="#ffc2a6" d="M233.2,181.5c-5-0.5-12.4-1.7-22.2-3.6c-9.8-1.8-16.8-3.3-20.8-4.5s-11.1-3.7-21.2-7.4
  c-10.1-3.8-19.5-8-28.3-12.8c-8.8-4.7-16.8-9.5-24-14.4s-13.1-9.1-17.5-12.8c-4.5-3.7-7.1-6-7.9-7s-1.5-1.6-1.9-1.8
  c-0.5-0.2-3.2-2.7-8-7.6s-9.1-9.2-12.6-13.2l-5.3-5.9l-0.1-0.1l-0.1-0.1L63.1,90l-0.2-0.4l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
  l-0.1-0.1L62.3,89l-0.1-0.1l0,0.1l-0.1,0.1L62,89.2l0,0.1l-0.1,0.1L61.7,90l-0.2,0.6L57.9,98c-2.2,5-3.9,9.7-5.1,14.1
  c-1.2,4.5-1.9,8.5-2.4,12c-0.4,3.5-0.5,7.8-0.4,12.8s0.7,10,1.8,15.1c1,5.1,2.6,10.2,4.6,15.2c2.1,5.1,4,9.2,5.8,12.5
  c1.8,3.2,3.9,6.4,6.3,9.2c2.4,2.9,4.7,5.6,7.1,8.3s4.6,4.7,6.7,6.4c2.2,1.6,3.3,2.5,3.4,2.5l0.1,0.1l0.4,0.2l0.4,0.2l0.1,0.1
  l0.1,0.1l0.1,0.1L87,207l0.4,0.2l0.4,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1H85l-1.8-0.2
  c-1.2-0.1-4.8-0.9-10.8-2.2s-11.1-2.9-15.1-4.7l-6.1-2.8l-0.4-0.2l-0.4-0.2l-0.4-0.2l-0.4-0.2l-0.4-0.1l-0.4-0.1l0.7,8.3
  c0.4,5.5,1.4,11,2.8,16.3c1.5,5.4,3.6,11,6.5,16.9s6.2,11.1,9.8,15.5c3.7,4.5,7,8.1,10.1,11c3.1,2.8,6.3,5.4,9.8,7.8
  c3.4,2.4,8,4.8,13.8,7.3s9.3,3.9,10.6,4.3c1.3,0.4,2.2,0.6,2.8,0.8l0.8,0.2l0.8,0.2l0.8,0.2l0.8,0.2l0.8,0.2l-0.1,0.1l-0.1,0.1
  l-0.1,0.1l-0.1,0.1l-1,0.2l-1,0.2l-0.8,0.2c-0.5,0.1-1.9,0.4-4.3,0.8s-6.6,0.6-12.8,0.8c-6.2,0.1-10.7,0-13.5-0.4l-4.3-0.6L81,288
  l-0.6-0.1l0.1,0.4l0.1,0.4l0.2,0.6l0.2,0.6l3.2,7.2c2.2,4.8,4.4,9,6.7,12.7c2.3,3.7,5.1,7.2,8.3,10.7c3.2,3.5,5.8,6.2,7.9,8
  c2.1,1.9,5.3,4.3,9.9,7.2c4.6,2.9,9.3,5.4,14.1,7.4c4.9,2.1,9.4,3.7,13.5,4.7c4.2,1,7.3,1.6,9.2,1.8c1.9,0.1,4,0.3,6.1,0.4l3.1,0.2
  c117.9-117.9,82.9-167.7,82.9-167.7l-2.8-0.1C241.6,182.3,238.2,181.9,233.2,181.5z"/>
        <circle style="fill: rgb(236, 216, 0);" cx="339.879" cy="150.606" r="35.067"/>
        <circle style="fill: rgba(0, 0, 0, 0.76);" cx="339.879" cy="152.742" r="20.887"/>
      </g>

    </svg>
    <svg class="twitter-bird bird-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="-450 250 520 500" xml:space="preserve">
      <g transform="scale(-1,1) rotate(30, -250, 250)">
        <path id="body" fill="#0011ff" d="M142.9,364.1c-1.6,1-3,1.7-4,2.3c-3,1.5-7.9,3.8-14.9,6.9c-7,3.1-14.7,5.7-23.1,7.9
  c-8.4,2.2-15.6,3.8-21.8,4.7c-6.2,0.9-12.2,1.5-18.1,1.8s-11.4,0.3-16.7,0c-5.2-0.3-8.5-0.5-9.6-0.6l-1.8-0.2l-0.4-0.1l-0.4-0.1v0.8
  h0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1H33l0.4,0.2l0.4,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l3.6,2.2c2.4,1.5,4.5,2.6,6.4,3.6
  s6.6,3.3,14.1,7.1c7.6,3.7,16.6,7.4,27.2,11.1s18.6,6.2,24,7.4c5.4,1.3,12.8,2.6,22.2,3.9s14.9,2.1,16.3,2.2
  c1.5,0.1,3.3,0.3,5.5,0.4l3.3,0.2v0.2h25.6v-0.2l14-1.3c9.3-0.9,17.6-2.1,25-3.3c7.3-1.3,14.9-3.1,22.8-5.5
  c7.9-2.4,15.3-4.9,22.4-7.7c7.1-2.8,13.7-5.7,19.7-9c6.1-3.2,11.3-6,15.6-8.5c4.3-2.5,9.1-5.6,14.2-9.3c5.2-3.7,10-7.5,14.6-11.2
  s7.1-5.9,7.7-6.4c0.6-0.6,4-4,10.2-10.2c6.2-6.3,11.3-11.9,15.4-16.8c4-5,8-10.3,12-15.9c3.9-5.6,8.3-12.5,13-20.6
  s9.2-17.3,13.5-27.5s8-20.7,11-31.5c3-10.7,5.2-20.4,6.7-28.9s2.4-16.5,3-23.8c0.5-7.3,0.8-13.9,0.9-19.7s2.5-10.8,7.4-14.8
  s9.9-8.5,15-13.7c5.1-5.1,7.9-8,8.3-8.7c0.5-0.7,0.9-1.1,1.1-1.2c0.3-0.1,2.1-2.3,5.3-6.7c3.3-4.4,5-6.6,5-6.7l0.1-0.1l0.2-0.4
  l0.2-0.4l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.2-0.1l0.2-0.1V115l-1,0.3l-0.8,0.1
  l-0.4,0.2l-0.4,0.2l-0.4,0.2l-0.4,0.2l-0.6,0.2l-1.2,0.4l-10.6,3.6c-6.7,2.2-13.7,4.1-21,5.7l-11,2.4h-1.9l0.1-0.1l0.1-0.1l0.1-0.1
  l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.4-0.2l0.4-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.4-0.2l0.4-0.2l1.9-1.3
  c1.3-0.9,2.4-1.8,3.3-2.8s2.8-2.7,5.6-5.1c2.8-2.4,6-6,9.5-10.7s6.5-9.4,8.8-14s3.6-7.2,3.7-7.9c0.1-0.6,0.3-1.2,0.4-1.6l0.2-0.6
  l0.2-0.4l0.2-0.4l0.2-0.6l0.2-0.6l0.1-0.6l0.1-0.6l-0.4,0.1l-0.4,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.4,0.2l-0.4,0.2
  l-0.4,0.2l-0.4,0.2l-0.1,0.1c-0.1,0.1-0.8,0.4-1.9,1.2c-1.2,0.7-4.7,2.4-10.5,5s-11.6,5-17.5,7.1s-11.4,3.7-16.5,4.9
  s-8.8,0.5-11.3-1.9c-2.4-2.4-5.2-4.7-8.3-6.9c-3.1-2.2-6.5-4.3-10.4-6.4c-3.8-2.1-7.7-3.9-11.8-5.5c-4-1.6-8.6-2.9-13.5-3.8
  l-7.4-1.5h-20.5v0.1c0,0.1-1.7,0.4-5.1,0.9s-7.6,1.6-12.6,3.3c-5,1.7-10.4,4.2-16.3,7.4c-5.9,3.3-11.1,7-15.7,11.2
  s-8.3,8.3-11.2,12.2c-2.9,3.9-5.2,7.4-7,10.5C221.5,163.3,231.3,307,142.9,364.1z"/>
        <path id="wing1" fill="#7881ff" d="M233.2,181.5c-5-0.5-12.4-1.7-22.2-3.6c-9.8-1.8-16.8-3.3-20.8-4.5s-11.1-3.7-21.2-7.4
  c-10.1-3.8-19.5-8-28.3-12.8c-8.8-4.7-16.8-9.5-24-14.4s-13.1-9.1-17.5-12.8c-4.5-3.7-7.1-6-7.9-7s-1.5-1.6-1.9-1.8
  c-0.5-0.2-3.2-2.7-8-7.6s-9.1-9.2-12.6-13.2l-5.3-5.9l-0.1-0.1l-0.1-0.1L63.1,90l-0.2-0.4l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
  l-0.1-0.1L62.3,89l-0.1-0.1l0,0.1l-0.1,0.1L62,89.2l0,0.1l-0.1,0.1L61.7,90l-0.2,0.6L57.9,98c-2.2,5-3.9,9.7-5.1,14.1
  c-1.2,4.5-1.9,8.5-2.4,12c-0.4,3.5-0.5,7.8-0.4,12.8s0.7,10,1.8,15.1c1,5.1,2.6,10.2,4.6,15.2c2.1,5.1,4,9.2,5.8,12.5
  c1.8,3.2,3.9,6.4,6.3,9.2c2.4,2.9,4.7,5.6,7.1,8.3s4.6,4.7,6.7,6.4c2.2,1.6,3.3,2.5,3.4,2.5l0.1,0.1l0.4,0.2l0.4,0.2l0.1,0.1
  l0.1,0.1l0.1,0.1L87,207l0.4,0.2l0.4,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1H85l-1.8-0.2
  c-1.2-0.1-4.8-0.9-10.8-2.2s-11.1-2.9-15.1-4.7l-6.1-2.8l-0.4-0.2l-0.4-0.2l-0.4-0.2l-0.4-0.2l-0.4-0.1l-0.4-0.1l0.7,8.3
  c0.4,5.5,1.4,11,2.8,16.3c1.5,5.4,3.6,11,6.5,16.9s6.2,11.1,9.8,15.5c3.7,4.5,7,8.1,10.1,11c3.1,2.8,6.3,5.4,9.8,7.8
  c3.4,2.4,8,4.8,13.8,7.3s9.3,3.9,10.6,4.3c1.3,0.4,2.2,0.6,2.8,0.8l0.8,0.2l0.8,0.2l0.8,0.2l0.8,0.2l0.8,0.2l-0.1,0.1l-0.1,0.1
  l-0.1,0.1l-0.1,0.1l-1,0.2l-1,0.2l-0.8,0.2c-0.5,0.1-1.9,0.4-4.3,0.8s-6.6,0.6-12.8,0.8c-6.2,0.1-10.7,0-13.5-0.4l-4.3-0.6L81,288
  l-0.6-0.1l0.1,0.4l0.1,0.4l0.2,0.6l0.2,0.6l3.2,7.2c2.2,4.8,4.4,9,6.7,12.7c2.3,3.7,5.1,7.2,8.3,10.7c3.2,3.5,5.8,6.2,7.9,8
  c2.1,1.9,5.3,4.3,9.9,7.2c4.6,2.9,9.3,5.4,14.1,7.4c4.9,2.1,9.4,3.7,13.5,4.7c4.2,1,7.3,1.6,9.2,1.8c1.9,0.1,4,0.3,6.1,0.4l3.1,0.2
  c117.9-117.9,82.9-167.7,82.9-167.7l-2.8-0.1C241.6,182.3,238.2,181.9,233.2,181.5z"/>
        <path id="wing2" fill="#9ca2ff" d="M233.2,181.5c-5-0.5-12.4-1.7-22.2-3.6c-9.8-1.8-16.8-3.3-20.8-4.5s-11.1-3.7-21.2-7.4
  c-10.1-3.8-19.5-8-28.3-12.8c-8.8-4.7-16.8-9.5-24-14.4s-13.1-9.1-17.5-12.8c-4.5-3.7-7.1-6-7.9-7s-1.5-1.6-1.9-1.8
  c-0.5-0.2-3.2-2.7-8-7.6s-9.1-9.2-12.6-13.2l-5.3-5.9l-0.1-0.1l-0.1-0.1L63.1,90l-0.2-0.4l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
  l-0.1-0.1L62.3,89l-0.1-0.1l0,0.1l-0.1,0.1L62,89.2l0,0.1l-0.1,0.1L61.7,90l-0.2,0.6L57.9,98c-2.2,5-3.9,9.7-5.1,14.1
  c-1.2,4.5-1.9,8.5-2.4,12c-0.4,3.5-0.5,7.8-0.4,12.8s0.7,10,1.8,15.1c1,5.1,2.6,10.2,4.6,15.2c2.1,5.1,4,9.2,5.8,12.5
  c1.8,3.2,3.9,6.4,6.3,9.2c2.4,2.9,4.7,5.6,7.1,8.3s4.6,4.7,6.7,6.4c2.2,1.6,3.3,2.5,3.4,2.5l0.1,0.1l0.4,0.2l0.4,0.2l0.1,0.1
  l0.1,0.1l0.1,0.1L87,207l0.4,0.2l0.4,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1H85l-1.8-0.2
  c-1.2-0.1-4.8-0.9-10.8-2.2s-11.1-2.9-15.1-4.7l-6.1-2.8l-0.4-0.2l-0.4-0.2l-0.4-0.2l-0.4-0.2l-0.4-0.1l-0.4-0.1l0.7,8.3
  c0.4,5.5,1.4,11,2.8,16.3c1.5,5.4,3.6,11,6.5,16.9s6.2,11.1,9.8,15.5c3.7,4.5,7,8.1,10.1,11c3.1,2.8,6.3,5.4,9.8,7.8
  c3.4,2.4,8,4.8,13.8,7.3s9.3,3.9,10.6,4.3c1.3,0.4,2.2,0.6,2.8,0.8l0.8,0.2l0.8,0.2l0.8,0.2l0.8,0.2l0.8,0.2l-0.1,0.1l-0.1,0.1
  l-0.1,0.1l-0.1,0.1l-1,0.2l-1,0.2l-0.8,0.2c-0.5,0.1-1.9,0.4-4.3,0.8s-6.6,0.6-12.8,0.8c-6.2,0.1-10.7,0-13.5-0.4l-4.3-0.6L81,288
  l-0.6-0.1l0.1,0.4l0.1,0.4l0.2,0.6l0.2,0.6l3.2,7.2c2.2,4.8,4.4,9,6.7,12.7c2.3,3.7,5.1,7.2,8.3,10.7c3.2,3.5,5.8,6.2,7.9,8
  c2.1,1.9,5.3,4.3,9.9,7.2c4.6,2.9,9.3,5.4,14.1,7.4c4.9,2.1,9.4,3.7,13.5,4.7c4.2,1,7.3,1.6,9.2,1.8c1.9,0.1,4,0.3,6.1,0.4l3.1,0.2
  c117.9-117.9,82.9-167.7,82.9-167.7l-2.8-0.1C241.6,182.3,238.2,181.9,233.2,181.5z"/>
        <circle style="fill: rgb(236, 216, 0);" cx="339.879" cy="150.606" r="35.067"/>
        <circle style="fill: rgba(0, 0, 0, 0.76);" cx="339.879" cy="152.742" r="20.887"/>
      </g>
  </svg>
    <svg class="big-cloud" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236 91" width="800" height="350">
      <title>Cloud</title>
      <g id="Cloud">
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp-cloud" d="M218.3 37.31C218.3 37.24 218.31 37.16 218.31 37.09C218.31 21.3 205.51 8.5 189.72 8.5C183.28 8.5 177.34 10.63 172.57 14.22C167.62 10.63 161.54 8.5 154.96 8.5C150.34 8.5 145.97 9.55 142.07 11.41C136.83 4.51 128.53 0.05 119.2 0.05C110.8 0.05 103.24 3.66 97.99 9.41C94.39 7.88 90.44 7.04 86.28 7.04C79.16 7.04 72.62 9.52 67.47 13.67C62.18 10.4 55.95 8.5 49.27 8.5C32.94 8.5 19.26 19.8 15.58 34.99C6.63 38.98 0.38 47.96 0.38 58.39C0.38 72.54 11.85 84 25.99 84C28.6 84 31.12 83.61 33.49 82.88C37.48 87.6 43.43 90.6 50.09 90.6C56.47 90.6 62.22 87.84 66.19 83.46C68.64 85.02 71.55 85.94 74.68 85.94C78.69 85.94 82.34 84.43 85.13 81.97C89.13 87.51 95.63 91.12 102.98 91.12C109.24 91.12 114.89 88.5 118.89 84.3C120.79 85.01 122.83 85.42 124.97 85.42C131.51 85.42 137.21 81.79 140.16 76.44C142.54 82.39 148.35 86.61 155.15 86.61C161.41 86.61 166.82 83.05 169.5 77.85C173.47 83.29 179.9 86.83 187.15 86.83C195.33 86.83 202.45 82.34 206.19 75.69C208.93 77.06 212.03 77.84 215.31 77.84C226.56 77.84 235.68 68.72 235.68 57.47C235.68 47.23 228.13 38.76 218.3 37.31Z" />
        </g>
      </g>
    </svg>

    <p class="title"><span id="letter-f">E</span>xplore <br>(Vær Nysgerrig & Udforsk)</p>
    <p class="text" id="line-1">
      I dag kommer vi til at være nysgerrige og tænke over måder
      <br>hvorpå vi kan være omsorgsfulde overfor os selv og belønne
      <br>os selv for at have prøvet at gøre vores bedste.
    </p>

    <audio autoplay loop src="../../assets/sounds/all/387978__dcpoke__birds-singing-03.mp3"/>
    <audio src="../../assets/sounds/session9/Page173.mp3" ref="voice"/>
    <div class="page-number" id="page-light">173</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: "Session9Page1",
  methods: {
    animateSvg() {
      let vw = document.querySelector('.interactive-container').clientWidth;

      anime({
        targets: this.$refs.cloud1,
        translateX: vw + 0.4*vw,
        duration: 50000,
        loop: true,
        easing: 'linear'
      })
      anime({
        targets: this.$refs.smallCloud,
        translateX: -(vw + 0.4*vw),
        duration: 40000,
        direction: 'alternate',
        loop: true,
        easing: 'linear'
      })
      anime({
        targets: this.$refs.sun,
        rotate: '360deg',
        duration: 5000,
        easing: 'steps(10)',
        loop: true,
      })

      anime({
        targets: '.bird-1',
        translateX: vw + 0.2*vw,
        easing: 'linear',
        duration: 10000,
        loop: true
      });

      anime({
        targets: '.bird-2',
        translateX: -(vw + 0.2*vw),
        easing: 'linear',
        duration: 10000,
        delay: 2000,
        loop: true
      })
    },
    animateText() {
      let animation = anime.timeline({easing: 'linear', duration: 1000});
      animation
        .add({targets: ".title", opacity: 1}, 5600)
        .add({targets: "#line-1", color: '#000'}, 10400)
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1)
    },
  },
  mounted() {
    this.animateSvg();
    this.animateText();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap');
.title {
  font-family: 'Sigmar One', cursive;
  position: absolute;
  color: white;
  font-size: 7vh;
  line-height: 7vh;
  -webkit-text-stroke: 0.1vw black;
  text-align: center;
  width: 100%;
  top: 28vh;
  opacity: 0;
}
#letter-f {
  color: #FFE802;
}
.text {
  position: absolute;
  margin-bottom: 0;
  font-size: 4vh;
  color: #ffffff;
}
#line-1 {
  text-align: center;
  width: 100%;
  top: 44vh;
}
span {
  display: inline-block;
}
tspan { white-space:pre }
.shp-cloud { fill: #ffffff }
.shp-sun { fill: #f6921e }
.shp-sunlight { fill: #faaf3b }
.cloud-background {
  background-color: rgba(149, 213, 238, 1);
}
.twitter-bird {
  position: absolute;
  width: 11%;
  height: auto;
}
.bird-1 {
  top: 20vh;
  left: -5%;
  z-index: 20;
}
.bird-2 {
  bottom: 2vh;
  right: -11%;
  z-index: 70;
}
#wing1{
  transform-origin:center;
  animation: flap 0.4s ease-out infinite alternate;
}
#wing2{
  transform-origin:center;
  animation: flap 0.4s ease-in infinite alternate;
}
@keyframes flap{
  50%{
    transform:scaleX(-1) rotate(-45deg) translate(-40px,-40px);
  }
}
.cloud1 {
  position: absolute;
  width: 25%;
  height: auto;
  top: 10vh;
  left: -35%;
}
.cloud2 {
  position: absolute;
  width: 30vh;
  height: auto;
  top: 14vh;
  right: 1%;
}
.big-cloud {
  position: absolute;
  width: 110%;
  height: auto;
  top: 16vh;
  left: -5%;
}
.long-cloud {
  width: 130%;
  height: auto;
  position: absolute;
  bottom: -7vh;
  left: -12%;
  z-index: 50;
}
.small-cloud {
  position: absolute;
  width: 15vh;
  height: auto;
  top: 5vh;
  right: -25%;
}
.sun {
  position: absolute;
  top: 3vh;
  right: 3%;
  width: 15%;
  height: auto;
}
</style>