import { render, staticRenderFns } from "./Session10Page5.vue?vue&type=template&id=d667c60a&scoped=true&"
import script from "./Session10Page5.vue?vue&type=script&lang=js&"
export * from "./Session10Page5.vue?vue&type=script&lang=js&"
import style0 from "./Session10Page5.vue?vue&type=style&index=0&id=d667c60a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d667c60a",
  null
  
)

export default component.exports