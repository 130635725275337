<template>
  <svg class="emotes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 171" width="172" height="171">
    <title>Crying</title>
    <g id="Object">
      <g id="&lt;Group&gt;">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp0-crying" d="M0.98 85.4C0.87 38.33 38.95 0.08 86.03 -0.02C133.1 -0.12 171.35 37.96 171.45 85.03C171.55 132.11 133.47 170.35 86.4 170.45C39.32 170.56 1.08 132.48 0.98 85.4Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp1-crying" d="M130.65 12.37C139.17 25.61 144.12 41.35 144.16 58.26C144.26 105.33 106.18 143.57 59.11 143.68C42.76 143.71 27.48 139.14 14.48 131.18C29.68 154.8 56.23 170.42 86.4 170.35C133.47 170.25 171.55 132.01 171.45 84.93C171.38 54.21 155.06 27.32 130.65 12.37Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp2-crying" d="M12.58 94.37C12.49 50.66 47.84 15.16 91.55 15.06C117.09 15.01 139.83 27.07 154.34 45.82C140.63 22.13 114.98 6.21 85.64 6.28C41.93 6.37 6.58 41.88 6.67 85.58C6.71 103.74 12.87 120.46 23.18 133.79C16.47 122.19 12.61 108.73 12.58 94.37Z" />
            </g>
          </g>
        </g>
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp3-crying" d="M101.6 105.54C101.6 119.95 94.71 131.63 86.21 131.63C77.71 131.63 70.82 119.95 70.82 105.54C70.82 91.13 77.71 79.44 86.21 79.44C94.71 79.44 101.6 91.13 101.6 105.54Z" />
          <path id="&lt;Path&gt;" class="shp4-crying" d="M99.2 119.52C87.97 113.35 78.83 116.85 73.54 120.35C76.32 127.16 80.96 131.63 86.21 131.63C91.68 131.63 96.47 126.8 99.2 119.52Z" />
        </g>
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp5-crying" d="M59.86 68.27L37.43 82.55L37.98 155.5C44.65 160.09 52.01 163.75 59.86 166.3L59.86 68.27Z" />
            <path id="&lt;Path&gt;" class="shp5-crying" d="M112.6 68.27L135.03 82.55L134.48 155.5C127.81 160.09 120.45 163.75 112.6 166.3L112.6 68.27Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp6-crying" d="M40.24 85.09C39.16 85.09 38.1 84.61 37.38 83.69C36.16 82.12 36.44 79.85 38.01 78.62L50.31 69.04L37.6 61.57C35.88 60.56 35.3 58.34 36.32 56.62C37.33 54.9 39.54 54.33 41.26 55.34L58.55 65.51C59.59 66.12 60.25 67.2 60.33 68.39C60.41 69.59 59.89 70.74 58.94 71.48L42.45 84.32C41.79 84.84 41.01 85.09 40.24 85.09Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp6-crying" d="M132.19 85.09C131.41 85.09 130.63 84.84 129.97 84.32L113.48 71.48C112.54 70.74 112.02 69.59 112.1 68.39C112.18 67.2 112.84 66.12 113.87 65.51L131.16 55.34C132.88 54.33 135.09 54.9 136.11 56.62C137.12 58.34 136.55 60.56 134.83 61.57L122.12 69.04L134.41 78.62C135.99 79.85 136.27 82.12 135.04 83.69C134.33 84.61 133.26 85.09 132.19 85.09Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "EmoteCrying",
}
</script>

<style scoped>
.emotes {
  height: auto;
  width: 14vh;
}
tspan { white-space:pre }
.shp0-crying { fill: #ffd05d }
.shp1-crying { fill: #fdc453 }
.shp2-crying { fill: #ffd77f }
.shp3-crying { fill: #695342 }
.shp4-crying { fill: #f06669 }
.shp5-crying { fill: #7cc4e8 }
.shp6-crying { fill: #303030 }
</style>