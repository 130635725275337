<template>
  <div class="interactive-container">
    <img src="../../assets/images/appendix/appendix3-danish.jpg" alt="biography" class="session-background">
  </div>
</template>

<script>
export default {
  name: 'AppendixPage10'
}
</script>

<style scoped></style>