<template>
  <div class="traffic-light-container">
    <svg class="traffic-light-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 663 1223" width="663" height="1223">
      <g id="Layer 1">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp0" d="M200.82 107.7C200.59 104.48 197.88 101.98 194.62 101.98L6.57 101.98C4.32 101.98 2.25 103.18 1.15 105.12C0.05 107.05 0.09 109.43 1.26 111.32C21.92 144.84 36.82 188.93 51.23 231.57C76.73 307.01 84.21 378.27 137.63 378.27L213.03 378.27C214.75 378.27 216.39 377.56 217.57 376.31C218.74 375.07 219.34 373.4 219.22 371.7L200.82 107.7Z" />
            <path id="&lt;Path&gt;" class="shp0" d="M200.82 480.04C200.59 476.82 197.88 474.32 194.62 474.32L6.57 474.32C4.32 474.32 2.25 475.52 1.15 477.46C0.05 479.39 0.09 481.77 1.26 483.66C21.92 517.17 36.82 561.27 51.23 603.91C76.73 679.35 84.21 750.61 137.63 750.61L213.03 750.61C214.75 750.61 216.39 749.9 217.57 748.65C218.74 747.41 219.34 745.74 219.22 744.04L200.82 480.04Z" />
            <path id="&lt;Path&gt;" class="shp0" d="M200.82 852.38C200.59 849.16 197.88 846.66 194.62 846.66L6.57 846.66C4.32 846.66 2.25 847.86 1.15 849.79C0.05 851.73 0.09 854.11 1.26 856C21.92 889.51 36.82 933.6 51.23 976.25C76.73 1051.69 84.21 1122.95 137.63 1122.95L213.03 1122.95C214.75 1122.95 216.39 1122.24 217.57 1120.99C218.74 1119.75 219.34 1118.08 219.22 1116.38L200.82 852.38Z" />
            <path id="&lt;Path&gt;" class="shp0" d="M462.5 107.7C462.73 104.48 465.44 101.98 468.7 101.98L656.76 101.98C659 101.98 661.07 103.18 662.17 105.12C663.27 107.05 663.23 109.43 662.06 111.32C641.4 144.84 626.5 188.93 612.09 231.57C586.59 307.01 579.11 378.27 525.69 378.27L450.29 378.27C448.57 378.27 446.93 377.56 445.75 376.31C444.58 375.07 443.98 373.4 444.1 371.7L462.5 107.7Z" />
            <path id="&lt;Path&gt;" class="shp0" d="M462.5 480.04C462.73 476.82 465.44 474.32 468.7 474.32L656.76 474.32C659 474.32 661.07 475.52 662.17 477.46C663.27 479.39 663.23 481.77 662.06 483.66C641.4 517.17 626.5 561.27 612.09 603.91C586.59 679.35 579.11 750.61 525.69 750.61L450.29 750.61C448.57 750.61 446.93 749.9 445.75 748.65C444.58 747.41 443.98 745.74 444.1 744.04L462.5 480.04Z" />
            <path id="&lt;Path&gt;" class="shp0" d="M462.5 852.38C462.73 849.16 465.44 846.66 468.7 846.66L656.76 846.66C659 846.66 661.07 847.86 662.17 849.79C663.27 851.73 663.23 854.11 662.06 856C641.4 889.51 626.5 933.6 612.09 976.25C586.59 1051.69 579.11 1122.95 525.69 1122.95L450.29 1122.95C448.57 1122.95 446.93 1122.24 445.75 1120.99C444.58 1119.75 443.98 1118.08 444.1 1116.38L462.5 852.38Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M488.4 121.81C488.4 140.91 472.91 156.41 453.73 156.41L209.51 156.41C190.4 156.41 174.92 140.91 174.92 121.81L174.92 35.22C174.92 16.04 190.4 0.54 209.51 0.54L453.73 0.54C472.91 0.54 488.4 16.04 488.4 35.22L488.4 121.81Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M488.4 1187.6C488.4 1206.7 472.91 1222.2 453.73 1222.2L209.51 1222.2C190.4 1222.2 174.92 1206.7 174.92 1187.6L174.92 1101C174.92 1081.83 190.4 1066.33 209.51 1066.33L453.73 1066.33C472.91 1066.33 488.4 1081.83 488.4 1101L488.4 1187.6Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M461.23 875.63C461.23 891.44 448.43 904.26 432.57 904.26L230.69 904.26C214.88 904.26 202.09 891.44 202.09 875.63L202.09 804.03C202.09 788.24 214.88 775.42 230.69 775.42L432.57 775.42C448.43 775.42 461.23 788.24 461.23 804.03L461.23 875.63Z" />
            <path id="&lt;Path&gt;" class="shp2" d="M524.95 1172.39C524.95 1175.41 522.51 1177.85 519.49 1177.85L143.83 1177.85C140.82 1177.85 138.37 1175.41 138.37 1172.39L138.37 52.53C138.37 49.52 140.82 47.07 143.83 47.07L519.49 47.07C522.51 47.07 524.95 49.52 524.95 52.53L524.95 1172.39Z" />
            <g id="red-light">
              <path class="shp0" d="M512.59 412.61C512.59 415.62 510.15 418.06 507.13 418.06L156.19 418.06C153.17 418.06 150.73 415.62 150.73 412.61L150.73 61.66C150.73 58.65 153.17 56.2 156.19 56.2L507.13 56.2C510.15 56.2 512.59 58.65 512.59 61.66L512.59 412.61Z" />
              <path class="shp3" d="M502.1 237.14C502.1 330.84 426.11 406.83 332.44 406.83C238.7 406.83 162.75 330.84 162.75 237.14C162.75 143.38 238.7 67.44 332.44 67.44C426.11 67.44 502.1 143.38 502.1 237.14Z" />
              <path class="shp4" d="M183.32 77.11C183.32 83.13 178.44 88.01 172.42 88.01C166.4 88.01 161.52 83.13 161.52 77.11C161.52 71.09 166.4 66.21 172.42 66.21C178.44 66.21 183.32 71.09 183.32 77.11Z" />
              <path class="shp4" d="M183.32 395.92C183.32 401.94 178.44 406.83 172.42 406.83C166.4 406.83 161.52 401.94 161.52 395.92C161.52 389.9 166.4 385.02 172.42 385.02C178.44 385.02 183.32 389.9 183.32 395.92Z" />
              <path class="shp4" d="M502.1 77.11C502.1 83.13 497.22 88.01 491.2 88.01C485.18 88.01 480.3 83.13 480.3 77.11C480.3 71.09 485.18 66.21 491.2 66.21C497.22 66.21 502.1 71.09 502.1 77.11Z" />
              <path class="shp4" d="M502.1 395.92C502.1 401.94 497.22 406.83 491.2 406.83C485.18 406.83 480.3 401.94 480.3 395.92C480.3 389.9 485.18 385.02 491.2 385.02C497.22 385.02 502.1 389.9 502.1 395.92Z" />
              <path class="shp2" d="M478.08 237.14C478.08 317.57 412.85 382.81 332.44 382.81C251.97 382.81 186.77 317.57 186.77 237.14C186.77 156.65 251.97 91.46 332.44 91.46C412.85 91.46 478.08 156.65 478.08 237.14Z" />
              <path class="shp5 red" d="M463.5 237.14C463.5 309.52 404.8 368.23 332.44 368.23C260.02 368.23 201.35 309.52 201.35 237.14C201.35 164.71 260.02 106.04 332.44 106.04C404.8 106.04 463.5 164.71 463.5 237.14Z" />
              <path class="shp6 red" d="M451.77 237.14C451.77 303.06 398.37 356.47 332.43 356.47C266.5 356.47 213.15 303.06 213.15 237.14C213.15 171.22 266.5 117.84 332.43 117.84C398.37 117.84 451.77 171.22 451.77 237.14Z" />
              <path class="shp7 red" d="M349.02 333.66C349.02 339.87 341.6 344.89 332.43 344.89C323.27 344.89 315.83 339.87 315.83 333.66C315.83 327.46 323.27 322.42 332.43 322.42C341.6 322.42 349.02 327.46 349.02 333.66Z" />
              <path class="shp7 red" d="M332.45 256.51C378.67 256.51 420.52 243.97 451 223.63C444.3 164.08 393.81 117.84 332.43 117.84C271.06 117.84 220.61 164.09 213.92 223.65C244.42 243.97 286.28 256.51 332.45 256.51Z" />
              <path class="shp8 red" d="M394.08 176.32C394.08 199.37 366.49 218.02 332.43 218.02C298.4 218.02 270.77 199.37 270.77 176.32C270.77 153.28 298.4 134.57 332.43 134.57C366.49 134.57 394.08 153.28 394.08 176.32Z" />
            </g>
            <g id="yellow-light">
              <path class="shp0" d="M512.59 787.31C512.59 790.33 510.15 792.77 507.13 792.77L156.19 792.77C153.17 792.77 150.73 790.33 150.73 787.31L150.73 436.37C150.73 433.36 153.17 430.91 156.19 430.91L507.13 430.91C510.15 430.91 512.59 433.36 512.59 436.37L512.59 787.31Z" />
              <path class="shp3" d="M502.1 611.85C502.1 705.55 426.11 781.54 332.44 781.54C238.7 781.54 162.75 705.55 162.75 611.85C162.75 518.09 238.7 442.15 332.44 442.15C426.11 442.15 502.1 518.09 502.1 611.85Z" />
              <path class="shp4" d="M183.32 451.82C183.32 457.84 178.44 462.72 172.42 462.72C166.4 462.72 161.52 457.84 161.52 451.82C161.52 445.79 166.4 440.92 172.42 440.92C178.44 440.92 183.32 445.79 183.32 451.82Z" />
              <path class="shp4" d="M183.32 770.63C183.32 776.65 178.44 781.54 172.42 781.54C166.4 781.54 161.52 776.65 161.52 770.63C161.52 764.61 166.4 759.73 172.42 759.73C178.44 759.73 183.32 764.61 183.32 770.63Z" />
              <path class="shp4" d="M502.1 451.82C502.1 457.84 497.22 462.72 491.2 462.72C485.18 462.72 480.3 457.84 480.3 451.82C480.3 445.79 485.18 440.92 491.2 440.92C497.22 440.92 502.1 445.79 502.1 451.82Z" />
              <path class="shp4" d="M502.1 770.63C502.1 776.65 497.22 781.54 491.2 781.54C485.18 781.54 480.3 776.65 480.3 770.63C480.3 764.61 485.18 759.73 491.2 759.73C497.22 759.73 502.1 764.61 502.1 770.63Z" />
              <path class="shp2" d="M478.08 611.84C478.08 692.28 412.85 757.51 332.44 757.51C251.97 757.51 186.77 692.28 186.77 611.84C186.77 531.36 251.97 466.17 332.44 466.17C412.85 466.17 478.08 531.36 478.08 611.84Z" />
              <path class="shp9 yellow" d="M463.5 611.84C463.5 684.23 404.8 742.93 332.44 742.93C260.02 742.93 201.35 684.23 201.35 611.84C201.35 539.42 260.02 480.75 332.44 480.75C404.8 480.75 463.5 539.42 463.5 611.84Z" />
              <path class="shp10 yellow" d="M451.77 611.84C451.77 677.77 398.37 731.18 332.43 731.18C266.5 731.18 213.15 677.77 213.15 611.84C213.15 545.93 266.5 492.54 332.43 492.54C398.37 492.54 451.77 545.93 451.77 611.84Z" />
              <path class="shp11 yellow" d="M349.02 708.09C349.02 714.3 341.6 719.32 332.43 719.32C323.27 719.32 315.83 714.3 315.83 708.09C315.83 701.89 323.27 696.85 332.43 696.85C341.6 696.85 349.02 701.89 349.02 708.09Z" />
              <path class="shp11 yellow" d="M332.45 631.22C378.67 631.22 420.52 618.68 451 598.34C444.3 538.79 393.81 492.54 332.43 492.54C271.06 492.54 220.61 538.8 213.92 598.35C244.42 618.68 286.28 631.22 332.45 631.22Z" />
              <path class="shp12 yellow" d="M394.08 551.03C394.08 574.08 366.49 592.73 332.43 592.73C298.4 592.73 270.77 574.08 270.77 551.03C270.77 527.99 298.4 509.28 332.43 509.28C366.49 509.28 394.08 527.99 394.08 551.03Z" />
            </g>
            <g id="green-light">
              <path class="shp0" d="M512.59 1162.02C512.59 1165.04 510.15 1167.48 507.13 1167.48L156.19 1167.48C153.17 1167.48 150.73 1165.04 150.73 1162.02L150.73 811.08C150.73 808.07 153.17 805.62 156.19 805.62L507.13 805.62C510.15 805.62 512.59 808.07 512.59 811.08L512.59 1162.02Z" />
              <path class="shp3" d="M502.1 986.55C502.1 1080.26 426.11 1156.24 332.44 1156.24C238.7 1156.24 162.75 1080.26 162.75 986.55C162.75 892.8 238.7 816.86 332.44 816.86C426.11 816.86 502.1 892.8 502.1 986.55Z" />
              <path class="shp4" d="M183.32 826.53C183.32 832.55 178.44 837.43 172.42 837.43C166.4 837.43 161.52 832.55 161.52 826.53C161.52 820.5 166.4 815.62 172.42 815.62C178.44 815.62 183.32 820.5 183.32 826.53Z" />
              <path class="shp4" d="M183.32 1145.34C183.32 1151.36 178.44 1156.24 172.42 1156.24C166.4 1156.24 161.52 1151.36 161.52 1145.34C161.52 1139.32 166.4 1134.44 172.42 1134.44C178.44 1134.44 183.32 1139.32 183.32 1145.34Z" />
              <path class="shp4" d="M502.1 826.53C502.1 832.55 497.22 837.43 491.2 837.43C485.18 837.43 480.3 832.55 480.3 826.53C480.3 820.5 485.18 815.62 491.2 815.62C497.22 815.62 502.1 820.5 502.1 826.53Z" />
              <path class="shp4" d="M502.1 1145.34C502.1 1151.36 497.22 1156.24 491.2 1156.24C485.18 1156.24 480.3 1151.36 480.3 1145.34C480.3 1139.32 485.18 1134.44 491.2 1134.44C497.22 1134.44 502.1 1139.32 502.1 1145.34Z" />
              <path class="shp2" d="M478.08 986.55C478.08 1066.99 412.85 1132.22 332.44 1132.22C251.97 1132.22 186.77 1066.99 186.77 986.55C186.77 906.07 251.97 840.88 332.44 840.88C412.85 840.88 478.08 906.07 478.08 986.55Z" />
              <path class="shp13 green" d="M463.5 986.55C463.5 1058.94 404.8 1117.64 332.44 1117.64C260.02 1117.64 201.35 1058.94 201.35 986.55C201.35 914.12 260.02 855.46 332.44 855.46C404.8 855.46 463.5 914.12 463.5 986.55Z" />
              <path class="shp14 green" d="M451.77 986.55C451.77 1052.48 398.37 1105.89 332.43 1105.89C266.5 1105.89 213.15 1052.48 213.15 986.55C213.15 920.64 266.5 867.25 332.43 867.25C398.37 867.25 451.77 920.64 451.77 986.55Z" />
              <path class="shp15 green" d="M332.45 1005.93C378.67 1005.93 420.52 993.39 451 973.05C444.3 913.5 393.81 867.25 332.43 867.25C271.06 867.25 220.61 913.51 213.92 973.06C244.42 993.39 286.28 1005.93 332.45 1005.93Z" />
              <path class="shp16 green" d="M394.08 925.74C394.08 948.79 366.49 967.44 332.43 967.44C298.4 967.44 270.77 948.79 270.77 925.74C270.77 902.7 298.4 883.99 332.43 883.99C366.49 883.99 394.08 902.7 394.08 925.74Z" />
              <path class="shp15 green" d="M349.02 1083.62C349.02 1089.82 341.6 1094.84 332.43 1094.84C323.27 1094.84 315.83 1089.82 315.83 1083.62C315.83 1077.41 323.27 1072.38 332.43 1072.38C341.6 1072.38 349.02 1077.41 349.02 1083.62Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div class="traffic-text" id="traffic-green">Grøn</div>
    <div class="traffic-text" id="traffic-red">Rød</div>
    <div class="mask-container">
      <div class="mask" @click="handleClick" id="red"/>
      <div class="mask" @click="handleClick" id="yellow"/>
      <div class="mask" @click="handleClick" id="green"/>
    </div>
    <audio ref="wrong" src="../../assets/sounds/all/wrong-ans.mp3"/>
    <audio ref="correct" src="../../assets/sounds/all/correct-ans.mp3"/>
    <audio ref="celebrate" src="../../assets/sounds/all/kids-cheering.mp3"/>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: 'TrafficLightVertical',
  data() {
    return {
      audioSuccessPlayed: false,
      lightsArray: ['red','yellow','green'],
    }
  },
  props: {
    correctAns: String,
  },
  methods: {
    animateCorrectLight(light) {
      let lightComponents = document.querySelectorAll(`.${light}`);
      anime({
        targets: lightComponents,
        opacity: [
          {value: 0, duration: 200},
          {value: 1, duration: 200, delay: 100},
          {value: 0, duration: 200},
          {value: 1, duration: 200, delay: 100},
        ],
        easing: 'linear'
      })
    },
    animateIncorrectLight(light) {
      let lightComponents = document.querySelectorAll(`.${light}`);
      anime({
        targets: lightComponents,
        opacity: 0.1,
        easing: 'linear'
      });
      this.hideMask(light);
    },
    hideMask(lightMask) {
      document.querySelector(`#${lightMask}`).style.visibility = 'hidden'
    },
    hideIncorrectMask(correctLightMask) {
      let incorrectLightMasks = this.lightsArray.filter(light => light !== correctLightMask);
      incorrectLightMasks.forEach(lightMask => {this.animateIncorrectLight(lightMask);});
    },
    handleClick(e) {
      if (e.target.id === this.correctAns) {
        this.animateCorrectLight(e.target.id);
        if (!this.audioSuccessPlayed) {
          this.$emit('correctAnswer');
          Math.random() < 0.5 ? this.$refs.correct.play() : this.$refs.celebrate.play();
          this.audioSuccessPlayed = true;
          this.hideIncorrectMask(e.target.id);
        }
      } else {
        this.animateIncorrectLight(e.target.id);
        this.$emit('wrongAnswer');
        this.$refs.wrong.play();
      }
    }
  }
}
</script>

<style scoped>
.traffic-text {
  position: absolute;
  color: #ffffff;
  font-size: 4vh;
  z-index: 5;
  text-transform: capitalize;
}
#traffic-red {top: 8vh;left: 12vh}
#traffic-green {top: 42vh;left: 11vh;}
.traffic-light-svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 56vh;
  width: auto;
}
.mask {
  position: absolute;
  width: 100%;
  height: 17.33vh;
}
#red {top: 0}
#yellow {top: 17.33vh}
#green {bottom:0}
.mask-container {
  position: absolute;
  top: 2vh;
  left: 6.5vh;
  width: 17.5vh;
  height: 52vh;
  z-index: 100;
}
.traffic-light-container {position: relative}

.shp0 { fill: #37393e }
.shp1 { fill: #2f3038 }
.shp2 { fill: #27282b }
.shp3 { fill: #44484e }
.shp4 { fill: #626972 }
.shp5 { fill: #c4261a }
.shp6 { fill: #ff2f17 }
.shp7 { fill: #ff4222 }
.shp8 { fill: #ff715b }
.shp9 { fill: #ffa32b }
.shp10 { fill: #ffc032 }
.shp11 { fill: #ffd04e }
.shp12 { fill: #ffe871 }
.shp13 { fill: #098541 }
.shp14 { fill: #039f4b }
.shp15 { fill: #3dae48 }
.shp16 { fill: #79c864 }
</style>