import { render, staticRenderFns } from "./Session2Page2.vue?vue&type=template&id=58cf7bbb&scoped=true&"
import script from "./Session2Page2.vue?vue&type=script&lang=js&"
export * from "./Session2Page2.vue?vue&type=script&lang=js&"
import style0 from "./Session2Page2.vue?vue&type=style&index=0&id=58cf7bbb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58cf7bbb",
  null
  
)

export default component.exports