<template>
  <div class="interactive-container">
    <svg class="landscape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1750 1039" width="1750" height="1039">
      <defs>
        <linearGradient id="grd1" gradientUnits="userSpaceOnUse"  x1="1588" y1="0" x2="1588" y2="1000">
          <stop offset="0" stop-color="#69cfbc"  />
          <stop offset="1" stop-color="#ceebd1"  />
        </linearGradient>
      </defs>
      <g id="BACKGROUND">
        <g id="&lt;Clip Group&gt;">
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp0" d="M1584 1000L1584 0L84 0L84 1000L1584 1000Z" />
          </g>
        </g>
      </g>
      <g id="OBJECTS">
        <g id="&lt;Clip Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;" style="opacity: 0.239">
              <path id="&lt;Path&gt;" class="shp1" d="M41.97 420.22L1627.75 502L1627.75 477.11L38.42 366.89L38.42 302.89L1642.86 448.67L1642.86 430L0.19 240.67L13.53 414L41.97 420.22Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp2" d="M424.79 664.67C801.97 516.15 860.23 485.21 973.69 540.91C1087.15 596.6 1283.41 351.14 1476.6 433.65C1509.31 454.27 1559.4 441.9 1626.86 396.52L1626.86 621.35L424.79 664.67Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp3" d="M61.53 568.67C159.3 520.67 264.19 507.33 376.19 528.67C544.19 560.67 909.53 611.33 1021.53 587.33C1096.19 571.33 1202.86 580.22 1341.53 614L53.53 686L61.53 568.67Z" />
              <path id="&lt;Path&gt;" class="shp4" d="M678.42 622.89C429.03 626.11 58.8 619.13 169.95 533.43C126.14 542.95 90 554.7 61.53 568.67L53.53 686L1198.84 621.98C1114.62 587.91 951.54 619.36 678.42 622.89Z" />
              <path id="&lt;Path&gt;" class="shp2" d="M748.68 647.14L778.68 645.46C798.73 635.24 845.97 607.98 822.89 588.56C820.81 588.4 818.7 588.24 816.6 588.08C843.54 612.38 772.45 639.11 748.68 647.14Z" />
              <path id="&lt;Path&gt;" class="shp4" d="M869.97 591.33C878.56 587.19 886.42 586.44 893.53 589.11C902.42 580.22 911.6 578 921.08 582.44C926.12 578.89 930.42 578.3 933.97 580.67C954.42 576.81 969.08 581.41 977.97 594.44C924.34 596.22 888.34 595.19 869.97 591.33Z" />
              <path id="&lt;Path&gt;" class="shp4" d="M778.86 585.11C772.64 579.78 767.75 578.15 764.19 580.22C754.42 572.12 746.56 572.67 740.64 581.85L778.86 585.11Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp5" d="M65.97 654.89L361.08 637.11L361.19 639.22L63.42 657.89L65.97 654.89Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp5" d="M67.19 662.44L362.3 644.67L362.42 646.78L64.64 665.44L67.19 662.44Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp5" d="M84.27 649.93C82.3 649.93 80.71 651.52 80.71 653.48L80.71 678.07L87.82 678.07L87.82 653.48C87.82 651.52 86.23 649.93 84.27 649.93Z" />
                <path id="&lt;Path&gt;" class="shp5" d="M115.16 647.93C113.19 647.93 111.6 649.52 111.6 651.48L111.6 676.07L118.71 676.07L118.71 651.48C118.71 649.52 117.12 647.93 115.16 647.93Z" />
                <path id="&lt;Path&gt;" class="shp5" d="M146.05 645.93C144.08 645.93 142.49 647.52 142.49 649.48L142.49 674.07L149.6 674.07L149.6 649.48C149.6 647.52 148.01 645.93 146.05 645.93Z" />
                <path id="&lt;Path&gt;" class="shp5" d="M176.93 643.93C174.97 643.93 173.38 645.52 173.38 647.48L173.38 672.07L180.49 672.07L180.49 647.48C180.49 645.52 178.9 643.93 176.93 643.93Z" />
                <path id="&lt;Path&gt;" class="shp5" d="M207.82 641.93C205.86 641.93 204.27 643.52 204.27 645.48L204.27 670.07L211.38 670.07L211.38 645.48C211.38 643.52 209.79 641.93 207.82 641.93Z" />
                <path id="&lt;Path&gt;" class="shp5" d="M238.71 639.93C236.75 639.93 235.16 641.52 235.16 643.48L235.16 668.07L242.27 668.07L242.27 643.48C242.27 641.52 240.68 639.93 238.71 639.93Z" />
                <path id="&lt;Path&gt;" class="shp5" d="M269.6 637.93C267.64 637.93 266.05 639.52 266.05 641.48L266.05 666.07L273.16 666.07L273.16 641.48C273.16 639.52 271.56 637.93 269.6 637.93Z" />
                <path id="&lt;Path&gt;" class="shp5" d="M300.49 635.93C298.53 635.93 296.93 637.52 296.93 639.48L296.93 664.07L304.05 664.07L304.05 639.48C304.05 637.52 302.45 635.93 300.49 635.93Z" />
                <path id="&lt;Path&gt;" class="shp5" d="M331.38 633.93C329.42 633.93 327.82 635.52 327.82 637.48L327.82 662.07L334.93 662.07L334.93 637.48C334.93 635.52 333.34 633.93 331.38 633.93Z" />
                <path id="&lt;Path&gt;" class="shp5" d="M362.27 631.93C360.3 631.93 358.71 633.52 358.71 635.48L358.71 660.07L365.82 660.07L365.82 635.48C365.82 633.52 364.23 631.93 362.27 631.93Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp6" d="M64.19 672.67L1613.53 595.33L1613.53 1035.33L69.53 1035.33L64.19 672.67Z" />
              </g>
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp7" d="M42.92 687L42.8 685L1749.47 589L1749.58 591L42.92 687Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp7" d="M37.05 705.77L36.82 701.78L1743.49 605.78L1743.71 609.77L37.05 705.77Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp7" d="M37.1 731.66L36.77 725.67L1743.43 629.67L1743.77 635.66L37.1 731.66Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp7" d="M36.27 767.33L35.82 759.34L1742.49 663.34L1742.94 671.33L36.27 767.33Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp7" d="M38.99 810.1L38.43 800.12L1745.1 704.12L1745.66 714.1L38.99 810.1Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp7" d="M42.6 856.44L41.93 844.45L1748.6 748.45L1749.27 760.44L42.6 856.44Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp7" d="M40.88 900.1L40.1 886.12L1746.76 790.12L1747.55 804.1L40.88 900.1Z" />
                  </g>
                </g>
              </g>
            </g>
            <path id="&lt;Path&gt;" class="shp8" d="M829.97 716.67C897.23 720.81 953.68 721.85 999.3 719.78L968.64 715.33C958.86 695.78 944.19 692.22 924.64 704.67C904.19 676.52 883.6 676.67 862.86 705.11C847.16 695.33 836.19 699.19 829.97 716.67Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp9" d="M1126.55 567.91L1157.57 597.24L1157.57 698.74L1196.48 698.74L1196.48 591.6L1239.9 548.17L1229.75 538.02L1190.84 576.93L1194.79 553.81L1188.02 553.25L1180.13 593.85L1173.36 594.42L1150.24 531.26L1140.09 535.2L1158.13 582.01L1132.76 554.38L1126.55 567.91Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp10" d="M1165.5 572.96L1150.24 531.26L1140.09 535.2L1158.13 582.01L1132.76 554.38L1126.55 567.91L1148.35 588.51C1157.58 584.23 1162.8 577.6 1165.5 572.96Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M1235.84 544.11L1229.75 538.02L1190.84 576.93L1194.79 553.81L1188.02 553.25L1182.61 581.08L1185.77 583.98L1182.95 598.93L1181.25 628.81L1174.49 644.04L1177.2 698.74L1196.48 698.74L1196.48 591.6L1236.38 551.7L1235.84 544.11Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp11" d="M1243.79 410.59C1227.75 376.91 1193.41 353.62 1153.62 353.62C1098.5 353.62 1053.81 398.31 1053.81 453.44C1053.81 464.02 1055.47 474.22 1058.53 483.79C1044.82 494.88 1036.05 511.83 1036.05 530.83C1036.05 564.24 1063.12 591.31 1096.53 591.31C1122.01 591.31 1143.8 575.54 1152.71 553.23C1153.01 553.23 1153.32 553.25 1153.62 553.25C1158.6 553.25 1163.48 552.87 1168.26 552.17C1181.85 562.4 1198.74 568.48 1217.06 568.48C1261.91 568.48 1298.27 532.12 1298.27 487.27C1298.27 451.79 1275.5 421.64 1243.79 410.59Z" />
              <path id="&lt;Path&gt;" class="shp12" d="M1210.11 404.57C1198.62 398.27 1187.9 390.62 1178.94 381.06C1170.91 372.5 1159.51 367.16 1146.86 367.16C1125.71 367.16 1108.06 382.08 1103.83 401.97C1090.18 404.21 1079.75 416.03 1079.75 430.32C1079.75 430.35 1079.75 430.39 1079.75 430.43C1079.77 436.63 1077.05 442.58 1071.9 446.03C1055.05 457.32 1044.25 476.96 1045.44 499.03C1047.1 530.01 1072.85 554.85 1103.87 555.49C1126.79 555.96 1146.84 543.52 1157.27 524.95C1166.72 528.15 1177.11 529.3 1187.95 527.76C1217.78 523.51 1241.24 498.95 1244.1 468.95C1246.74 441.3 1232.31 416.74 1210.11 404.57Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp13" d="M1187.18 419.32C1187.18 416.2 1177.9 413.68 1166.45 413.68C1165.73 413.68 1165.01 413.69 1164.31 413.71C1165.04 412.91 1165.46 412.04 1165.46 411.14C1165.46 406.94 1156.75 403.53 1146.01 403.53C1135.26 403.53 1126.55 406.94 1126.55 411.14C1126.55 415.32 1135.17 418.71 1145.83 418.75C1145.76 418.94 1145.73 419.13 1145.73 419.32C1145.73 419.72 1145.88 420.11 1146.17 420.48C1127.3 420.84 1112.17 430.14 1112.17 441.59C1112.17 453.27 1127.89 462.74 1147.28 462.74C1166.67 462.74 1182.38 453.27 1182.38 441.59C1182.38 434.81 1177.06 428.79 1168.82 424.92C1179.15 424.6 1187.18 422.22 1187.18 419.32Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1097.94 436.8C1100.97 436.8 1103.43 435.54 1103.43 433.98C1103.43 432.42 1100.97 431.16 1097.94 431.16C1094.9 431.16 1092.44 432.42 1092.44 433.98C1092.44 435.54 1094.9 436.8 1097.94 436.8Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1093.42 463.87C1081.51 463.87 1071.85 468.67 1071.85 474.58C1071.85 480.5 1081.51 485.3 1093.42 485.3C1105.34 485.3 1114.99 480.5 1114.99 474.58C1114.99 468.67 1105.34 463.87 1093.42 463.87Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1096.67 453.86C1096.67 455.49 1099.95 456.82 1104 456.82C1108.05 456.82 1111.33 455.49 1111.33 453.86C1111.33 452.22 1108.05 450.9 1104 450.9C1099.95 450.9 1096.67 452.22 1096.67 453.86Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp14" d="M1243.79 410.59C1239.68 401.97 1234.37 394.03 1228.08 386.99C1224.66 391.51 1222.15 396.75 1220.84 402.47C1219.18 405.17 1217.64 407.95 1216.22 410.8C1205.45 418.54 1198.42 431.13 1198.42 445.41C1198.42 453.74 1200.84 461.49 1204.97 468.05C1207.72 505.74 1229.41 538.16 1260.56 555.83C1283.23 541.43 1298.27 516.11 1298.27 487.27C1298.27 451.79 1275.5 421.64 1243.79 410.59Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp5" d="M624.26 644.71L588.39 646.5C567.54 666.47 503.26 753.69 961.08 761.56L1105.08 717.11C709.28 729.62 549.01 705.49 624.26 644.71Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp15" d="M979.31 718.7C971.11 732.32 944 745.65 890.56 759.58C912.57 760.46 936.04 761.13 961.08 761.56L1105.08 717.11C1069.27 718.24 1027.34 718.77 979.31 718.7Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M594.86 684.67C604.05 706.89 643.75 722.89 713.97 732.67C784.19 742.44 856.34 749.11 930.42 752.67C837.38 744.37 770.12 737.26 728.64 731.33C666.42 722.44 624.64 713.56 594.86 684.67Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M601.53 663.33C593.53 688.22 654.12 708.07 783.3 722.89C660.64 714.3 600.05 694.44 601.53 663.33Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M977.53 728.67L825.53 719.78L976.19 731.78L977.53 728.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp16" d="M1634.86 630C1210.56 630 774.42 766.3 326.42 1038.89L1638.42 1038.89L1634.86 630Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1330.2 1038.89L1638.42 1038.89L1634.86 630C1477.88 630 1318.74 654.89 1168.05 692.37C1519.88 677 1626.81 834.46 1330.2 1038.89Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp5" d="M682.4 1038.89L958.66 1038.89C1035.3 965.74 1173.42 803.8 955.81 754.44C918.09 767.06 881.36 780.16 845.82 793.51C971.41 818.51 780.87 968.49 682.4 1038.89Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M1067.85 886.83C996.54 916.59 898.92 928.93 822.06 926.02C778.84 967.62 722.85 1009.97 682.4 1038.89L958.66 1038.89C997.19 1002.11 1051.25 942.9 1067.85 886.83Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp15" d="M959.3 918.74C959.3 924.06 949.75 928.37 937.97 928.37C926.19 928.37 916.64 924.06 916.64 918.74C916.64 913.42 926.19 909.11 937.97 909.11C949.75 909.11 959.3 913.42 959.3 918.74Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M994.86 915.93C994.86 919.03 988.16 921.56 979.9 921.56C971.63 921.56 964.93 919.03 964.93 915.93C964.93 912.82 971.63 910.3 979.9 910.3C988.16 910.3 994.86 912.82 994.86 915.93Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M967.9 906.59C967.9 907.82 966.37 908.81 964.49 908.81C962.61 908.81 961.08 907.82 961.08 906.59C961.08 905.37 962.61 904.37 964.49 904.37C966.37 904.37 967.9 905.37 967.9 906.59Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M932.93 925.56C932.93 931.04 921 935.48 906.27 935.48C891.54 935.48 879.6 931.04 879.6 925.56C879.6 920.07 891.54 915.63 906.27 915.63C921 915.63 932.93 920.07 932.93 925.56Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M918.12 909.26C918.12 910.81 916.06 912.07 913.53 912.07C910.99 912.07 908.93 910.81 908.93 909.26C908.93 907.7 910.99 906.44 913.53 906.44C916.06 906.44 918.12 907.7 918.12 909.26Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1001.08 896.96C1001.08 898.11 998.69 899.04 995.75 899.04C992.8 899.04 990.42 898.11 990.42 896.96C990.42 895.82 992.8 894.89 995.75 894.89C998.69 894.89 1001.08 895.82 1001.08 896.96Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1012.64 909.7C1012.64 911.99 1008.06 913.85 1002.42 913.85C996.77 913.85 992.19 911.99 992.19 909.7C992.19 907.41 996.77 905.56 1002.42 905.56C1008.06 905.56 1012.64 907.41 1012.64 909.7Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1053.23 900.37C1053.23 905.69 1046.07 910 1037.23 910C1028.39 910 1021.23 905.69 1021.23 900.37C1021.23 895.05 1028.39 890.74 1037.23 890.74C1046.07 890.74 1053.23 895.05 1053.23 900.37Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1018.27 894.15C1018.27 895.38 1016.34 896.37 1013.97 896.37C1011.6 896.37 1009.68 895.38 1009.68 894.15C1009.68 892.92 1011.6 891.93 1013.97 891.93C1016.34 891.93 1018.27 892.92 1018.27 894.15Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1034.56 905.85C1034.56 909.94 1027.8 913.26 1019.45 913.26C1011.11 913.26 1004.34 909.94 1004.34 905.85C1004.34 901.76 1011.11 898.44 1019.45 898.44C1027.8 898.44 1034.56 901.76 1034.56 905.85Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M901.23 923.78C901.23 929.91 887.77 934.89 871.16 934.89C854.55 934.89 841.08 929.91 841.08 923.78C841.08 917.64 854.55 912.67 871.16 912.67C887.77 912.67 901.23 917.64 901.23 923.78Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M897.08 906.74C897.08 908.38 893.9 909.7 889.97 909.7C886.04 909.7 882.86 908.38 882.86 906.74C882.86 905.1 886.04 903.78 889.97 903.78C893.9 903.78 897.08 905.1 897.08 906.74Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M946.86 896.07C946.86 897.55 942.55 898.74 937.23 898.74C931.91 898.74 927.6 897.55 927.6 896.07C927.6 894.6 931.91 893.41 937.23 893.41C942.55 893.41 946.86 894.6 946.86 896.07Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1062.71 885.11C1062.71 890.84 1055.68 895.48 1047.01 895.48C1038.34 895.48 1031.3 890.84 1031.3 885.11C1031.3 879.38 1038.34 874.74 1047.01 874.74C1055.68 874.74 1062.71 879.38 1062.71 885.11Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1022.42 878.59C1022.42 879.74 1020.43 880.67 1017.97 880.67C1015.52 880.67 1013.53 879.74 1013.53 878.59C1013.53 877.45 1015.52 876.52 1017.97 876.52C1020.43 876.52 1022.42 877.45 1022.42 878.59Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1056.79 864.37C1056.79 865.02 1055.66 865.56 1054.27 865.56C1052.88 865.56 1051.75 865.02 1051.75 864.37C1051.75 863.72 1052.88 863.19 1054.27 863.19C1055.66 863.19 1056.79 863.72 1056.79 864.37Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M1106.86 721.56C1200.49 699.63 1276.05 687.19 1333.53 684.22L1352.19 694C1223.6 704.07 1141.82 713.26 1106.86 721.56Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M1154.27 785.56C1298.27 747.04 1417.38 724.81 1511.6 718.89L1545.38 744.67C1393.08 753.56 1262.71 767.19 1154.27 785.56Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M1113.97 900.81C1355.75 845.11 1505.08 815.78 1561.97 812.81L1583.3 860.81C1269.82 886.89 1113.38 900.22 1113.97 900.81Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M1011.75 1027.33L1516.64 928.67L1537.97 1011.33L1011.75 1027.33Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp17" d="M1401.08 711.89L1596.64 705.93C1579.94 657.44 1560.87 640.35 1539.4 654.66C1507.6 641.14 1483.36 644.72 1466.67 665.39C1445.2 643.13 1423.34 658.63 1401.08 711.89Z" />
            <path id="&lt;Path&gt;" class="shp18" d="M1479.39 721.44L1620.85 717.13C1608.77 682.05 1594.97 669.69 1579.45 680.04C1556.44 670.27 1538.91 672.85 1526.83 687.8C1511.31 671.7 1495.49 682.92 1479.39 721.44Z" />
            <path id="&lt;Path&gt;" class="shp17" d="M1255.52 681L1365.61 677.64C1356.21 650.34 1345.47 640.72 1333.39 648.78C1315.49 641.17 1301.84 643.18 1292.44 654.82C1280.35 642.29 1268.05 651.01 1255.52 681Z" />
            <path id="&lt;Path&gt;" class="shp18" d="M1440.19 870.72L1684.93 863.26C1664.04 802.57 1640.16 781.18 1613.3 799.09C1573.51 782.18 1543.16 786.65 1522.27 812.52C1495.41 784.66 1468.05 804.06 1440.19 870.72Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M72.19 1008.67L72.19 712.67C241.08 691.33 402.86 728.67 557.53 824.67C712.19 920.67 894.42 982 1104.19 1008.67C411.75 1004.22 67.75 1004.22 72.19 1008.67Z" />
              <path id="&lt;Path&gt;" class="shp12" d="M201.46 1005.97C386.12 1005.09 687.03 1005.99 1104.19 1008.67C894.42 982 712.19 920.67 557.53 824.67C497.63 787.49 436.33 762.07 378.32 744.77C445.55 850.28 306.51 949.58 201.46 1005.97Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M413.53 890.74C413.53 894.34 410.61 897.26 407.01 897.26C403.41 897.26 400.49 894.34 400.49 890.74C400.49 887.14 403.41 884.22 407.01 884.22C410.61 884.22 413.53 887.14 413.53 890.74Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M457.97 834.44C457.97 839.68 453.73 843.93 448.49 843.93C443.25 843.93 439.01 839.68 439.01 834.44C439.01 829.21 443.25 824.96 448.49 824.96C453.73 824.96 457.97 829.21 457.97 834.44Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M487.6 874.15C487.6 877.75 484.68 880.67 481.08 880.67C477.48 880.67 474.56 877.75 474.56 874.15C474.56 870.55 477.48 867.63 481.08 867.63C484.68 867.63 487.6 870.55 487.6 874.15Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M456.19 927.48C456.19 930.1 454.07 932.22 451.45 932.22C448.83 932.22 446.71 930.1 446.71 927.48C446.71 924.86 448.83 922.74 451.45 922.74C454.07 922.74 456.19 924.86 456.19 927.48Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M378.56 944.07C378.56 950.29 373.52 955.33 367.3 955.33C361.09 955.33 356.05 950.29 356.05 944.07C356.05 937.86 361.09 932.81 367.3 932.81C373.52 932.81 378.56 937.86 378.56 944.07Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M469.82 977.85C469.82 984.07 464.78 989.11 458.56 989.11C452.35 989.11 447.3 984.07 447.3 977.85C447.3 971.63 452.35 966.59 458.56 966.59C464.78 966.59 469.82 971.63 469.82 977.85Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M555.75 847.48C555.75 854.35 550.18 859.93 543.3 859.93C536.43 859.93 530.86 854.35 530.86 847.48C530.86 840.61 536.43 835.04 543.3 835.04C550.18 835.04 555.75 840.61 555.75 847.48Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M568.19 892.52C568.19 894.81 566.34 896.67 564.05 896.67C561.75 896.67 559.9 894.81 559.9 892.52C559.9 890.23 561.75 888.37 564.05 888.37C566.34 888.37 568.19 890.23 568.19 892.52Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M423.01 783.48C423.01 787.74 419.56 791.19 415.3 791.19C411.05 791.19 407.6 787.74 407.6 783.48C407.6 779.23 411.05 775.78 415.3 775.78C419.56 775.78 423.01 779.23 423.01 783.48Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M506.56 826.74C506.56 828.38 505.24 829.7 503.6 829.7C501.96 829.7 500.64 828.38 500.64 826.74C500.64 825.1 501.96 823.78 503.6 823.78C505.24 823.78 506.56 825.1 506.56 826.74Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M560.49 955.93C560.49 961.49 555.98 966 550.42 966C544.85 966 540.34 961.49 540.34 955.93C540.34 950.36 544.85 945.85 550.42 945.85C555.98 945.85 560.49 950.36 560.49 955.93Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M652.93 908.52C652.93 911.46 650.55 913.85 647.6 913.85C644.66 913.85 642.27 911.46 642.27 908.52C642.27 905.57 644.66 903.19 647.6 903.19C650.55 903.19 652.93 905.57 652.93 908.52Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M686.12 948.81C686.12 954.71 681.34 959.48 675.45 959.48C669.56 959.48 664.79 954.71 664.79 948.81C664.79 942.92 669.56 938.15 675.45 938.15C681.34 938.15 686.12 942.92 686.12 948.81Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M631.6 968.37C631.6 972.95 627.89 976.67 623.3 976.67C618.72 976.67 615.01 972.95 615.01 968.37C615.01 963.79 618.72 960.07 623.3 960.07C627.89 960.07 631.6 963.79 631.6 968.37Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M616.79 921.56C616.79 928.1 611.48 933.41 604.93 933.41C598.39 933.41 593.08 928.1 593.08 921.56C593.08 915.01 598.39 909.7 604.93 909.7C611.48 909.7 616.79 915.01 616.79 921.56Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M743.6 952.96C743.6 955.25 741.74 957.11 739.45 957.11C737.16 957.11 735.3 955.25 735.3 952.96C735.3 950.67 737.16 948.81 739.45 948.81C741.74 948.81 743.6 950.67 743.6 952.96Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M714.56 984.96C714.56 987.58 712.44 989.7 709.82 989.7C707.21 989.7 705.08 987.58 705.08 984.96C705.08 982.34 707.21 980.22 709.82 980.22C712.44 980.22 714.56 982.34 714.56 984.96Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M714.56 918C714.56 920.29 712.71 922.15 710.42 922.15C708.12 922.15 706.27 920.29 706.27 918C706.27 915.71 708.12 913.85 710.42 913.85C712.71 913.85 714.56 915.71 714.56 918Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M796.93 968.37C796.93 971.64 794.28 974.3 791.01 974.3C787.74 974.3 785.08 971.64 785.08 968.37C785.08 965.1 787.74 962.44 791.01 962.44C794.28 962.44 796.93 965.1 796.93 968.37Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M775.6 997.41C775.6 999.7 773.74 1001.56 771.45 1001.56C769.16 1001.56 767.3 999.7 767.3 997.41C767.3 995.12 769.16 993.26 771.45 993.26C773.74 993.26 775.6 995.12 775.6 997.41Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M863.9 982.59C863.9 986.52 860.71 989.7 856.79 989.7C852.86 989.7 849.68 986.52 849.68 982.59C849.68 978.67 852.86 975.48 856.79 975.48C860.71 975.48 863.9 978.67 863.9 982.59Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M830.71 993.85C830.71 997.12 828.06 999.78 824.79 999.78C821.51 999.78 818.86 997.12 818.86 993.85C818.86 990.58 821.51 987.93 824.79 987.93C828.06 987.93 830.71 990.58 830.71 993.85Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M833.08 961.26C833.08 963.88 830.96 966 828.34 966C825.72 966 823.6 963.88 823.6 961.26C823.6 958.64 825.72 956.52 828.34 956.52C830.96 956.52 833.08 958.64 833.08 961.26Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M223.3 810.74C223.3 815.32 219.59 819.04 215.01 819.04C210.43 819.04 206.71 815.32 206.71 810.74C206.71 806.16 210.43 802.44 215.01 802.44C219.59 802.44 223.3 806.16 223.3 810.74Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M253.53 845.7C253.53 849.3 250.61 852.22 247.01 852.22C243.41 852.22 240.49 849.3 240.49 845.7C240.49 842.1 243.41 839.19 247.01 839.19C250.61 839.19 253.53 842.1 253.53 845.7Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M275.45 772.22C275.45 776.8 271.74 780.52 267.16 780.52C262.57 780.52 258.86 776.8 258.86 772.22C258.86 767.64 262.57 763.93 267.16 763.93C271.74 763.93 275.45 767.64 275.45 772.22Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M309.82 814.3C309.82 818.55 306.37 822 302.12 822C297.86 822 294.42 818.55 294.42 814.3C294.42 810.04 297.86 806.59 302.12 806.59C306.37 806.59 309.82 810.04 309.82 814.3Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M345.38 852.22C345.38 855.17 342.99 857.56 340.05 857.56C337.1 857.56 334.71 855.17 334.71 852.22C334.71 849.28 337.1 846.89 340.05 846.89C342.99 846.89 345.38 849.28 345.38 852.22Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M356.64 784.67C356.64 786.63 355.05 788.22 353.08 788.22C351.12 788.22 349.53 786.63 349.53 784.67C349.53 782.7 351.12 781.11 353.08 781.11C355.05 781.11 356.64 782.7 356.64 784.67Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M183.01 918.59C183.01 923.5 179.03 927.48 174.12 927.48C169.21 927.48 165.23 923.5 165.23 918.59C165.23 913.68 169.21 909.7 174.12 909.7C179.03 909.7 183.01 913.68 183.01 918.59Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M242.86 899.04C242.86 901.66 240.74 903.78 238.12 903.78C235.5 903.78 233.38 901.66 233.38 899.04C233.38 896.42 235.5 894.3 238.12 894.3C240.74 894.3 242.86 896.42 242.86 899.04Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M198.42 955.93C198.42 959.85 195.23 963.04 191.3 963.04C187.38 963.04 184.19 959.85 184.19 955.93C184.19 952 187.38 948.81 191.3 948.81C195.23 948.81 198.42 952 198.42 955.93Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M112.49 847.48C112.49 851.41 109.31 854.59 105.38 854.59C101.45 854.59 98.27 851.41 98.27 847.48C98.27 843.55 101.45 840.37 105.38 840.37C109.31 840.37 112.49 843.55 112.49 847.48Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M111.3 913.26C111.3 916.86 108.39 919.78 104.79 919.78C101.19 919.78 98.27 916.86 98.27 913.26C98.27 909.66 101.19 906.74 104.79 906.74C108.39 906.74 111.3 909.66 111.3 913.26Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M123.75 953.56C123.75 957.81 120.3 961.26 116.05 961.26C111.79 961.26 108.34 957.81 108.34 953.56C108.34 949.3 111.79 945.85 116.05 945.85C120.3 945.85 123.75 949.3 123.75 953.56Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M170.56 851.04C170.56 855.95 166.58 859.93 161.68 859.93C156.77 859.93 152.79 855.95 152.79 851.04C152.79 846.13 156.77 842.15 161.68 842.15C166.58 842.15 170.56 846.13 170.56 851.04Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M113.68 758C113.68 760.29 111.82 762.15 109.53 762.15C107.24 762.15 105.38 760.29 105.38 758C105.38 755.71 107.24 753.85 109.53 753.85C111.82 753.85 113.68 755.71 113.68 758Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M149.82 787.04C149.82 790.31 147.17 792.96 143.9 792.96C140.62 792.96 137.97 790.31 137.97 787.04C137.97 783.76 140.62 781.11 143.9 781.11C147.17 781.11 149.82 783.76 149.82 787.04Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M184.79 736.67C184.79 738.63 183.19 740.22 181.23 740.22C179.27 740.22 177.68 738.63 177.68 736.67C177.68 734.7 179.27 733.11 181.23 733.11C183.19 733.11 184.79 734.7 184.79 736.67Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M299.75 897.26C299.75 901.84 296.03 905.56 291.45 905.56C286.87 905.56 283.16 901.84 283.16 897.26C283.16 892.68 286.87 888.96 291.45 888.96C296.03 888.96 299.75 892.68 299.75 897.26Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1053.08 779.11C1070.19 799.44 1083.53 794.56 1084.3 786.78C1095.49 785.89 1099.75 782.3 1097.08 776L1053.08 779.11Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp5" d="M1053.08 779.11C1065.53 780.3 1074.12 780.81 1078.86 780.67C1083.6 780.52 1089.68 778.96 1097.08 776C1090.27 766.37 1085.6 763.33 1083.08 766.89C1080.27 757.26 1075.38 752.89 1068.42 753.78C1061.45 754.67 1056.34 763.11 1053.08 779.11Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M1078.86 780.67C1083.6 780.52 1089.68 778.96 1097.08 776C1094.72 772.66 1092.3 769.98 1089.84 767.96C1087.15 773.22 1083.94 773.68 1080.19 769.33C1073.09 775.6 1064.76 776.27 1055.22 771.34C1054.26 774.23 1053.55 776.82 1053.08 779.11C1065.53 780.3 1074.12 780.81 1078.86 780.67Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M769.36 897.28C790.92 922.9 807.71 916.74 808.69 906.94C822.79 905.82 828.15 901.3 824.79 893.36L769.36 897.28Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp5" d="M769.36 897.28C785.04 898.78 795.86 899.43 801.84 899.24C807.81 899.06 815.46 897.1 824.79 893.36C816.21 881.23 810.33 877.41 807.15 881.89C803.61 869.75 797.45 864.25 788.68 865.37C779.91 866.49 773.47 877.13 769.36 897.28Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M801.84 899.24C807.81 899.06 815.46 897.1 824.79 893.36C821.81 889.15 818.77 885.78 815.67 883.23C812.28 889.86 808.23 890.44 803.52 884.97C794.56 892.85 784.08 893.7 772.05 887.49C770.85 891.14 769.95 894.4 769.36 897.28C785.04 898.78 795.86 899.43 801.84 899.24Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1084.93 792.67C1087.3 800.57 1099.85 803.33 1122.56 800.96C1145.28 798.59 1145.48 794.54 1123.16 788.81L1084.93 792.67Z" />
              <path id="&lt;Path&gt;" class="shp5" d="M1084.93 792.67C1099.16 797.8 1111.9 796.52 1123.16 788.81C1122.17 784.27 1116.14 781.11 1105.08 779.33C1094.02 777.56 1087.3 782 1084.93 792.67Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1123.16 788.81C1122.6 786.33 1119.95 784.09 1115.2 782.07C1108.19 790.12 1093.67 793.34 1085.47 790.94C1085.22 791.63 1085.04 792.21 1084.93 792.67C1099.16 797.8 1111.9 796.52 1123.16 788.81Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp20" d="M985.98 847.59C987.26 851.87 994.04 853.36 1006.33 852.08C1018.61 850.8 1018.72 848.61 1006.65 845.51L985.98 847.59Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M985.98 847.59C993.67 850.37 1000.56 849.68 1006.65 845.51C1006.11 843.05 1002.85 841.35 996.87 840.38C990.89 839.42 987.26 841.83 985.98 847.59Z" />
              <path id="&lt;Path&gt;" class="shp21" d="M1006.65 845.51C1006.35 844.17 1004.91 842.95 1002.34 841.87C998.55 846.21 990.7 847.96 986.27 846.66C986.13 847.03 986.04 847.35 985.98 847.59C993.67 850.37 1000.56 849.68 1006.65 845.51Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp20" d="M969.93 852.2C970.69 854.76 974.76 855.66 982.13 854.89C989.5 854.12 989.56 852.81 982.32 850.95L969.93 852.2Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M969.93 852.2C974.54 853.87 978.67 853.45 982.32 850.95C982 849.48 980.05 848.45 976.46 847.87C972.87 847.3 970.69 848.74 969.93 852.2Z" />
              <path id="&lt;Path&gt;" class="shp21" d="M982.32 850.95C982.14 850.15 981.28 849.42 979.74 848.76C977.47 851.37 972.76 852.42 970.1 851.64C970.02 851.86 969.96 852.05 969.93 852.2C974.54 853.87 978.67 853.45 982.32 850.95Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp20" d="M988.59 855.16C989.36 857.73 993.43 858.62 1000.8 857.85C1008.17 857.08 1008.23 855.77 1000.99 853.91L988.59 855.16Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M988.59 855.16C993.21 856.83 997.34 856.41 1000.99 853.91C1000.67 852.44 998.72 851.41 995.13 850.84C991.54 850.26 989.36 851.7 988.59 855.16Z" />
              <path id="&lt;Path&gt;" class="shp21" d="M1000.99 853.91C1000.81 853.11 999.95 852.38 998.41 851.73C996.14 854.34 991.43 855.38 988.77 854.6C988.69 854.83 988.63 855.01 988.59 855.16C993.21 856.83 997.34 856.41 1000.99 853.91Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp8" d="M590.42 709.56C599.9 719.63 628.79 730 677.08 740.67L653.53 730.89C646.12 718.44 639.3 713.85 633.08 717.11C626.56 703.78 620.05 701.11 613.53 709.11C603.75 694.3 596.05 694.44 590.42 709.56Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M374.71 713.26L495.01 706.15C484.54 696.86 476.84 694.1 471.9 697.85C451.75 681.06 434.07 678.89 418.86 691.33C408.19 680.47 397.53 683.43 386.86 700.22C381.72 699.23 377.68 703.58 374.71 713.26Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M949.53 664.67L1077.97 658.44C1066.12 652.52 1057.97 651.04 1053.53 654C1039.6 638 1025.97 634.3 1012.64 642.89C998.71 633.41 984.93 636.67 971.3 652.67C959.75 650.59 952.49 654.59 949.53 664.67Z" />
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp9" d="M1288.49 542L1321.38 585.11L1321.82 690.89C1335.16 696.52 1350.42 696.52 1367.6 690.89L1367.6 606L1410.71 553.56L1404.49 548.22L1382.27 573.56L1385.82 554L1380.93 553.11L1373.38 576.22L1360.05 589.11L1373.82 534.89L1366.27 533.11L1348.49 589.56L1341.82 534.89L1333.82 534.44L1341.38 587.33L1336.49 587.33L1317.38 524.67L1310.27 528.67L1324.93 571.78L1298.27 538.44L1288.49 542Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp10" d="M1341.82 534.89L1333.82 534.44L1340.94 584.26C1343.33 583.81 1345.57 583.27 1347.65 582.69L1341.82 534.89Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1404.49 548.22L1382.27 573.56L1385.82 554L1380.93 553.11L1373.38 576.22L1360.05 589.11L1373.82 534.89L1366.27 533.11L1350.97 581.68C1351.35 581.56 1351.72 581.44 1352.08 581.31C1353.54 598.67 1355.16 618.15 1355.16 618.89C1355.16 619.85 1355.39 667.23 1355.52 693.78C1359.35 693.19 1363.4 692.27 1367.6 690.89L1367.6 606L1410.71 553.56L1404.49 548.22Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1335.8 585.08L1317.38 524.67L1310.27 528.67L1324.93 571.78L1298.27 538.44L1288.49 542L1321.38 585.11L1321.38 585.61C1326.55 585.87 1331.38 585.63 1335.8 585.08Z" />
                </g>
              </g>
              <path id="&lt;Path&gt;" class="shp9" d="M1288.49 542L1321.38 585.11L1321.82 690.89C1335.16 696.52 1350.42 696.52 1367.6 690.89L1367.6 606L1410.71 553.56L1404.49 548.22L1382.27 573.56L1385.82 554L1380.93 553.11L1373.38 576.22L1360.05 589.11L1373.82 534.89L1366.27 533.11L1348.49 589.56L1341.82 534.89L1333.82 534.44L1341.38 587.33L1336.49 587.33L1317.38 524.67L1310.27 528.67L1324.93 571.78L1298.27 538.44L1288.49 542Z" />
              <path id="&lt;Path&gt;" class="shp10" d="M1341.82 534.89L1333.82 534.44L1340.94 584.26C1343.33 583.81 1345.57 583.27 1347.65 582.69L1341.82 534.89Z" />
              <path id="&lt;Path&gt;" class="shp10" d="M1404.49 548.22L1382.27 573.56L1385.82 554L1380.93 553.11L1373.38 576.22L1360.05 589.11L1373.82 534.89L1366.27 533.11L1350.97 581.68C1351.35 581.56 1351.72 581.44 1352.08 581.31C1353.54 598.67 1355.16 618.15 1355.16 618.89C1355.16 619.85 1355.39 667.23 1355.52 693.78C1359.35 693.19 1363.4 692.27 1367.6 690.89L1367.6 606L1410.71 553.56L1404.49 548.22Z" />
              <path id="&lt;Path&gt;" class="shp10" d="M1335.8 585.08L1317.38 524.67L1310.27 528.67L1324.93 571.78L1298.27 538.44L1288.49 542L1321.38 585.11L1321.38 585.61C1326.55 585.87 1331.38 585.63 1335.8 585.08Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp11" d="M1495.01 459.33C1495.01 434.07 1482.85 411.65 1464.06 397.6C1464.91 393.02 1465.38 388.31 1465.38 383.48C1465.38 340.93 1430.89 306.44 1388.34 306.44C1379.38 306.44 1370.78 307.99 1362.78 310.8C1351.47 304.27 1338.35 300.52 1324.34 300.52C1281.8 300.52 1247.3 335.01 1247.3 377.56C1247.3 378.54 1247.34 379.51 1247.38 380.49C1228.27 400.31 1216.49 427.25 1216.49 456.96C1216.49 517.84 1265.84 567.18 1326.71 567.18C1340.99 567.18 1354.63 564.44 1367.15 559.49C1376.59 565.85 1387.96 569.56 1400.19 569.56C1427.68 569.56 1450.78 550.84 1457.49 525.47C1479.96 512.01 1495.01 487.43 1495.01 459.33Z" />
                <path id="&lt;Path&gt;" class="shp12" d="M1427.14 402.24C1426.63 385.71 1414.12 371.56 1397.77 369.07C1397.46 369.02 1397.14 368.98 1396.83 368.94C1391.95 368.33 1388.83 363.76 1389.61 358.91C1389.76 357.98 1389.83 357.03 1389.82 356.05C1389.73 346.98 1382.1 339.39 1373.04 339.33C1370.09 339.32 1367.32 340.05 1364.9 341.36C1364.56 328.9 1354.37 318.89 1341.82 318.89C1333.7 318.89 1326.57 323.09 1322.45 329.43C1314.98 321.69 1304.23 317.15 1292.41 318.13C1274.68 319.61 1260.22 333.87 1258.46 351.58C1258.44 351.72 1258.43 351.86 1258.42 352C1257.52 362.12 1252.85 371.42 1245.27 378.19C1237.64 385 1232.58 394.63 1231.73 405.45C1218.92 412.81 1210.27 426.6 1210.27 442.44C1210.27 466.01 1229.37 485.11 1252.93 485.11C1254.66 485.11 1256.35 484.98 1258.03 484.78C1266.93 496.84 1281.24 504.67 1297.38 504.67C1307.64 504.67 1317.16 501.5 1325.02 496.1C1335.84 509.25 1352.62 517.3 1371.23 516.11C1398.97 514.33 1421.35 491.53 1422.65 463.76C1422.79 460.79 1422.69 457.88 1422.38 455.03C1420.79 440.62 1422.33 426.16 1426.02 412.14C1426.85 408.99 1427.25 405.67 1427.14 402.24Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp13" d="M1358.46 379.36C1359.48 377.87 1360.05 376.29 1360.05 374.67C1360.05 369.63 1354.77 365.09 1346.33 361.89C1352.4 360.61 1356.49 358.26 1356.49 355.56C1356.49 351.51 1347.34 348.22 1336.05 348.22C1335.74 348.22 1335.44 348.23 1335.13 348.24C1334.86 342.08 1322.75 337.11 1307.82 337.11C1292.73 337.11 1280.49 342.19 1280.49 348.44C1280.49 350.97 1282.5 353.29 1285.86 355.17C1280.6 356.46 1276.93 359.33 1276.93 362.67C1276.93 363.92 1277.46 365.1 1278.38 366.16C1274.92 368.68 1272.93 371.58 1272.93 374.67C1272.93 378.24 1275.59 381.57 1280.15 384.34C1263.95 387.68 1252.93 394.23 1252.93 401.78C1252.93 412.7 1276.02 421.56 1304.49 421.56C1316.04 421.56 1326.71 420.1 1335.3 417.64C1341.63 419.83 1349.22 421.11 1357.38 421.11C1379.47 421.11 1397.38 411.76 1397.38 400.22C1397.38 388.88 1380.05 379.66 1358.46 379.36Z" />
                  <path id="&lt;Path&gt;" class="shp13" d="M1376.27 373.56C1378.11 373.56 1379.6 372.86 1379.6 372C1379.6 371.14 1378.11 370.44 1376.27 370.44C1374.43 370.44 1372.93 371.14 1372.93 372C1372.93 372.86 1374.43 373.56 1376.27 373.56Z" />
                  <path id="&lt;Path&gt;" class="shp13" d="M1281.16 434.44C1269.74 434.44 1260.49 438.23 1260.49 442.89C1260.49 447.55 1269.74 451.33 1281.16 451.33C1292.57 451.33 1301.82 447.55 1301.82 442.89C1301.82 438.23 1292.57 434.44 1281.16 434.44Z" />
                  <path id="&lt;Path&gt;" class="shp13" d="M1335.16 441.11C1324.85 441.11 1316.49 445.09 1316.49 450C1316.49 454.91 1324.85 458.89 1335.16 458.89C1345.47 458.89 1353.82 454.91 1353.82 450C1353.82 445.09 1345.47 441.11 1335.16 441.11Z" />
                </g>
              </g>
              <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp14" d="M1495.01 459.33C1495.01 487.43 1479.96 512.01 1457.49 525.47C1450.78 550.84 1427.68 569.56 1400.19 569.56C1399.33 569.56 1398.49 569.5 1397.64 569.46L1395.44 572.14L1382.49 587.88C1378.62 584.83 1374.92 581.57 1371.4 578.13L1373.38 576.22L1377.1 564.84C1373.74 563.42 1370.52 561.71 1367.51 559.72L1364.63 571.06C1344.9 548.89 1332.15 520.37 1329.83 488.93C1324.59 480.62 1321.55 470.78 1321.55 460.23C1321.55 442.21 1330.44 426.3 1344.03 416.52C1345.81 412.97 1347.72 409.51 1349.78 406.15C1352.19 395.49 1357.89 386.09 1365.76 379.07C1366.67 378.25 1367.53 377.38 1368.37 376.5C1368.86 349.17 1380.63 324.6 1399.2 307.22C1436.6 312.5 1465.38 344.62 1465.38 383.48C1465.38 388.31 1464.91 393.02 1464.06 397.6C1482.85 411.65 1495.01 434.07 1495.01 459.33ZM1387.06 568.09C1385.84 567.81 1384.63 567.48 1383.44 567.13L1382.27 573.56L1387.06 568.09Z" />
              <path id="&lt;Path&gt;" class="shp22" d="M1387.17 567.97L1382.27 573.56L1383.47 566.95C1381.41 566.31 1379.3 565.54 1377.17 564.64L1377.1 564.84L1373.38 576.22L1371.4 578.13C1374.92 581.57 1378.62 584.83 1382.49 587.88L1392.01 576.3L1395.44 572.14L1396.09 571.34L1397.6 569.5C1394.26 569.34 1390.77 568.85 1387.17 567.97Z" />
              <path id="&lt;Path&gt;" class="shp22" d="M1364.63 571.06L1367.51 559.72L1357.75 562.66C1359.94 565.54 1362.23 568.36 1364.63 571.06Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp9" d="M1446.13 584.21L1487.67 638.66L1488.23 772.27C1505.08 779.38 1524.35 779.38 1546.06 772.27L1546.06 665.05L1600.51 598.8L1592.65 592.07L1564.58 624.06L1569.07 599.36L1562.9 598.24L1553.35 627.43L1536.51 643.71L1553.92 575.22L1544.37 572.98L1521.92 644.27L1513.5 575.22L1503.39 574.66L1512.93 641.47L1506.76 641.47L1482.62 562.31L1473.64 567.36L1492.16 621.82L1458.48 579.72L1446.13 584.21Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp10" d="M1513.5 575.22L1503.39 574.66L1512.38 637.59C1515.39 637.02 1518.23 636.34 1520.86 635.6L1513.5 575.22Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1592.65 592.07L1564.58 624.06L1569.07 599.36L1562.9 598.24L1553.35 627.43L1536.51 643.71L1553.92 575.22L1544.37 572.98L1525.05 634.33C1525.52 634.17 1526 634.02 1526.46 633.86C1528.29 655.78 1530.34 680.4 1530.34 681.33C1530.34 682.54 1530.63 742.39 1530.79 775.92C1535.64 775.18 1540.75 774.01 1546.06 772.27L1546.06 665.05L1600.51 598.8L1592.65 592.07Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1505.89 638.62L1482.62 562.31L1473.64 567.36L1492.16 621.82L1458.48 579.72L1446.13 584.21L1487.67 638.66L1487.67 639.29C1494.2 639.62 1500.3 639.32 1505.89 638.62Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp11" d="M1706.99 479.79C1706.99 447.88 1691.63 419.56 1667.9 401.81C1668.97 396.03 1669.56 390.07 1669.56 383.98C1669.56 330.24 1626 286.67 1572.25 286.67C1560.93 286.67 1550.07 288.62 1539.97 292.18C1525.68 283.93 1509.1 279.19 1491.41 279.19C1437.67 279.19 1394.11 322.75 1394.11 376.49C1394.11 377.73 1394.15 378.96 1394.2 380.19C1370.06 405.23 1355.18 439.27 1355.18 476.79C1355.18 553.69 1417.52 616.02 1494.41 616.02C1512.45 616.02 1529.67 612.55 1545.49 606.3C1557.41 614.33 1571.77 619.01 1587.23 619.01C1621.94 619.01 1651.13 595.38 1659.59 563.32C1687.98 546.32 1706.99 515.28 1706.99 479.79Z" />
                <path id="&lt;Path&gt;" class="shp12" d="M1621.26 407.67C1620.62 386.8 1604.81 368.92 1584.17 365.77C1583.77 365.71 1583.37 365.66 1582.97 365.61C1576.82 364.84 1572.87 359.07 1573.86 352.95C1574.04 351.77 1574.14 350.56 1574.13 349.33C1574.01 337.88 1564.38 328.28 1552.92 328.21C1549.2 328.19 1545.7 329.12 1542.65 330.78C1542.22 315.03 1529.35 302.39 1513.5 302.39C1503.24 302.39 1494.23 307.69 1489.02 315.7C1479.59 305.93 1466 300.19 1451.09 301.43C1428.68 303.3 1410.42 321.31 1408.19 343.68C1408.17 343.86 1408.16 344.03 1408.14 344.21C1407.01 357 1401.11 368.74 1391.53 377.29C1381.89 385.9 1375.51 398.06 1374.44 411.73C1358.25 421.03 1347.33 438.45 1347.33 458.46C1347.33 488.22 1371.45 512.35 1401.22 512.35C1403.4 512.35 1405.54 512.18 1407.65 511.93C1418.89 527.16 1436.97 537.05 1457.36 537.05C1470.32 537.05 1482.34 533.05 1492.27 526.23C1505.93 542.83 1527.13 553 1550.64 551.5C1585.68 549.25 1613.95 520.45 1615.59 485.38C1615.77 481.63 1615.64 477.95 1615.25 474.35C1613.24 456.15 1615.19 437.88 1619.85 420.18C1620.9 416.2 1621.4 412.01 1621.26 407.67Z" />
                <path id="&lt;Path&gt;" class="shp12" d="M1667.45 464.07C1667.45 480.44 1648.75 493.7 1625.68 493.7C1602.6 493.7 1583.9 480.44 1583.9 464.07C1583.9 447.71 1602.6 434.44 1625.68 434.44C1648.75 434.44 1667.45 447.71 1667.45 464.07Z" />
                <path id="&lt;Path&gt;" class="shp12" d="M1649.68 431.78C1649.68 444.71 1638 455.19 1623.6 455.19C1609.2 455.19 1597.53 444.71 1597.53 431.78C1597.53 418.85 1609.2 408.37 1623.6 408.37C1638 408.37 1649.68 418.85 1649.68 431.78Z" />
                <path id="&lt;Path&gt;" class="shp12" d="M1607.6 352.96C1607.6 356.73 1603.22 359.78 1597.82 359.78C1592.42 359.78 1588.05 356.73 1588.05 352.96C1588.05 349.2 1592.42 346.15 1597.82 346.15C1603.22 346.15 1607.6 349.2 1607.6 352.96Z" />
                <path id="&lt;Path&gt;" class="shp12" d="M1605.82 331.63C1605.82 338.99 1599.85 344.96 1592.49 344.96C1585.13 344.96 1579.16 338.99 1579.16 331.63C1579.16 324.27 1585.13 318.3 1592.49 318.3C1599.85 318.3 1605.82 324.27 1605.82 331.63Z" />
                <path id="&lt;Path&gt;" class="shp12" d="M1643.75 407.78C1643.75 416.61 1636.59 423.78 1627.75 423.78C1618.91 423.78 1611.75 416.61 1611.75 407.78C1611.75 398.94 1618.91 391.78 1627.75 391.78C1636.59 391.78 1643.75 398.94 1643.75 407.78Z" />
                <path id="&lt;Path&gt;" class="shp12" d="M1650.27 519.19C1650.27 528.02 1643.1 535.19 1634.27 535.19C1625.43 535.19 1618.27 528.02 1618.27 519.19C1618.27 510.35 1625.43 503.19 1634.27 503.19C1643.1 503.19 1650.27 510.35 1650.27 519.19Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1523.45 459.33C1523.45 475.37 1495.2 488.37 1460.34 488.37C1425.49 488.37 1397.23 475.37 1397.23 459.33C1397.23 443.3 1425.49 430.3 1460.34 430.3C1495.2 430.3 1523.45 443.3 1523.45 459.33Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1560.19 464.07C1560.19 473.89 1545.73 481.85 1527.9 481.85C1510.06 481.85 1495.6 473.89 1495.6 464.07C1495.6 454.26 1510.06 446.3 1527.9 446.3C1545.73 446.3 1560.19 454.26 1560.19 464.07Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1630.12 452.22C1630.12 457.79 1621.36 462.3 1610.56 462.3C1599.76 462.3 1591.01 457.79 1591.01 452.22C1591.01 446.66 1599.76 442.15 1610.56 442.15C1621.36 442.15 1630.12 446.66 1630.12 452.22Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1604.64 424.07C1604.64 427.51 1600.26 430.3 1594.86 430.3C1589.46 430.3 1585.08 427.51 1585.08 424.07C1585.08 420.64 1589.46 417.85 1594.86 417.85C1600.26 417.85 1604.64 420.64 1604.64 424.07Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1573.23 398.89C1573.23 413.94 1546.43 426.15 1513.38 426.15C1480.32 426.15 1453.53 413.94 1453.53 398.89C1453.53 383.83 1480.32 371.63 1513.38 371.63C1546.43 371.63 1573.23 383.83 1573.23 398.89Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1593.38 435.04C1593.38 445.51 1570.16 454 1541.53 454C1512.89 454 1489.68 445.51 1489.68 435.04C1489.68 424.56 1512.89 416.07 1541.53 416.07C1570.16 416.07 1593.38 424.56 1593.38 435.04Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1417.97 423.19C1417.97 426.46 1410.68 429.11 1401.68 429.11C1392.67 429.11 1385.38 426.46 1385.38 423.19C1385.38 419.91 1392.67 417.26 1401.68 417.26C1410.68 417.26 1417.97 419.91 1417.97 423.19Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1534.12 432.96C1534.12 443.27 1515.28 451.63 1492.05 451.63C1468.81 451.63 1449.97 443.27 1449.97 432.96C1449.97 422.65 1468.81 414.3 1492.05 414.3C1515.28 414.3 1534.12 422.65 1534.12 432.96Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1445.23 396.52C1445.23 400.45 1439.39 403.63 1432.19 403.63C1424.99 403.63 1419.16 400.45 1419.16 396.52C1419.16 392.59 1424.99 389.41 1432.19 389.41C1439.39 389.41 1445.23 392.59 1445.23 396.52Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1508.05 351.78C1508.05 355.54 1498.76 358.59 1487.3 358.59C1475.85 358.59 1466.56 355.54 1466.56 351.78C1466.56 348.01 1475.85 344.96 1487.3 344.96C1498.76 344.96 1508.05 348.01 1508.05 351.78Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1599.3 395.33C1599.3 401.55 1590.55 406.59 1579.75 406.59C1568.95 406.59 1560.19 401.55 1560.19 395.33C1560.19 389.11 1568.95 384.07 1579.75 384.07C1590.55 384.07 1599.3 389.11 1599.3 395.33Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1480.19 419.33C1480.19 424.08 1472.5 427.93 1463.01 427.93C1453.52 427.93 1445.82 424.08 1445.82 419.33C1445.82 414.59 1453.52 410.74 1463.01 410.74C1472.5 410.74 1480.19 414.59 1480.19 419.33Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1464.79 360.96C1464.79 364.89 1459.75 368.07 1453.53 368.07C1447.31 368.07 1442.27 364.89 1442.27 360.96C1442.27 357.04 1447.31 353.85 1453.53 353.85C1459.75 353.85 1464.79 357.04 1464.79 360.96Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1556.64 345.56C1556.64 352.1 1551.33 357.41 1544.79 357.41C1538.24 357.41 1532.93 352.1 1532.93 345.56C1532.93 339.01 1538.24 333.7 1544.79 333.7C1551.33 333.7 1556.64 339.01 1556.64 345.56Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1524.05 370.15C1524.05 373.26 1518.47 375.78 1511.6 375.78C1504.73 375.78 1499.16 373.26 1499.16 370.15C1499.16 367.04 1504.73 364.52 1511.6 364.52C1518.47 364.52 1524.05 367.04 1524.05 370.15Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1560.79 512.67C1560.79 517.25 1553.22 520.96 1543.9 520.96C1534.57 520.96 1527.01 517.25 1527.01 512.67C1527.01 508.08 1534.57 504.37 1543.9 504.37C1553.22 504.37 1560.79 508.08 1560.79 512.67Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1386.56 456.67C1386.56 460.1 1382.45 462.89 1377.38 462.89C1372.31 462.89 1368.19 460.1 1368.19 456.67C1368.19 453.23 1372.31 450.44 1377.38 450.44C1382.45 450.44 1386.56 453.23 1386.56 456.67Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp23" d="M426.88 769.16C426.88 770.73 422.95 772.01 418.11 772.01C413.26 772.01 409.33 770.73 409.33 769.16C409.33 767.58 413.26 766.31 418.11 766.31C422.95 766.31 426.88 767.58 426.88 769.16Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M396.4 769.16C396.4 770.73 392.47 772.01 387.63 772.01C382.78 772.01 378.85 770.73 378.85 769.16C378.85 767.58 382.78 766.31 387.63 766.31C392.47 766.31 396.4 767.58 396.4 769.16Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M419.69 764.67C419.69 767.77 411.97 770.28 402.44 770.28C392.91 770.28 385.18 767.77 385.18 764.67C385.18 761.57 392.91 759.06 402.44 759.06C411.97 759.06 419.69 761.57 419.69 764.67Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M397.03 752.87C392.54 748.86 386.83 747.59 379.9 749.07C379.22 756.11 381.13 761.64 385.62 765.65C390.11 769.66 395.82 770.93 402.75 769.45C403.43 762.41 401.53 756.88 397.03 752.87Z" />
                  <path id="&lt;Path&gt;" class="shp24" d="M425.01 748.24C418.04 747.02 412.38 748.49 408.03 752.67C403.69 756.84 401.98 762.43 402.92 769.45C409.9 770.68 415.56 769.2 419.9 765.03C424.25 760.86 425.95 755.26 425.01 748.24Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M425.06 752.7C414.98 770.09 408.03 760.98 405.42 756.04C403.1 760.08 402.27 764.55 402.92 769.45C409.9 770.68 415.56 769.2 419.9 765.03C423.69 761.39 424.8 756.32 425.06 752.7Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M379.95 753.52C390.66 770.53 397.28 761.18 399.71 756.14C402.17 760.1 403.16 764.54 402.69 769.46C395.77 770.93 390.06 769.67 385.56 765.65C381.65 762.16 380.35 757.13 379.95 753.52Z" />
                  <path id="&lt;Path&gt;" class="shp26" d="M402.26 737.63C396.26 742.18 393.33 747.67 393.44 754.07C393.56 760.47 396.69 765.85 402.85 770.18C408.84 765.62 411.78 760.14 411.66 753.74C411.55 747.33 408.41 741.96 402.26 737.63Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M402.85 770.18C408.84 765.62 411.78 760.14 411.66 753.74C411.64 752.79 411.54 751.88 411.36 751C410.47 754.16 407.09 765.03 402.86 765.11C397.92 765.2 393.77 750.71 393.76 750.66C393.54 751.75 393.42 752.89 393.44 754.07C393.56 760.47 396.69 765.85 402.85 770.18Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp23" d="M419.43 774.3C419.43 777.4 411.7 779.91 402.18 779.91C392.65 779.91 384.92 777.4 384.92 774.3C384.92 771.21 392.65 768.7 402.18 768.7C411.7 768.7 419.43 771.21 419.43 774.3Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp23" d="M681.1 892.71C681.1 894.29 677.18 895.57 672.33 895.57C667.48 895.57 663.55 894.29 663.55 892.71C663.55 891.14 667.48 889.86 672.33 889.86C677.18 889.86 681.1 891.14 681.1 892.71Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M650.62 892.71C650.62 894.29 646.7 895.57 641.85 895.57C637 895.57 633.07 894.29 633.07 892.71C633.07 891.14 637 889.86 641.85 889.86C646.7 889.86 650.62 891.14 650.62 892.71Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M673.92 888.23C673.92 891.32 666.19 893.83 656.66 893.83C647.13 893.83 639.41 891.32 639.41 888.23C639.41 885.13 647.13 882.62 656.66 882.62C666.19 882.62 673.92 885.13 673.92 888.23Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M651.25 876.42C646.76 872.41 641.05 871.14 634.13 872.62C633.44 879.67 635.35 885.2 639.84 889.21C644.34 893.22 650.05 894.49 656.97 893.01C657.65 885.96 655.75 880.43 651.25 876.42Z" />
                  <path id="&lt;Path&gt;" class="shp24" d="M679.23 871.8C672.26 870.58 666.6 872.05 662.25 876.22C657.91 880.4 656.21 885.99 657.15 893.01C664.12 894.23 669.78 892.76 674.12 888.58C678.47 884.41 680.17 878.82 679.23 871.8Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M679.29 876.26C669.21 893.65 662.25 884.54 659.64 879.59C657.32 883.64 656.49 888.11 657.15 893.01C664.12 894.23 669.78 892.76 674.12 888.58C677.91 884.95 679.02 879.87 679.29 876.26Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M634.18 877.08C644.88 894.09 651.51 884.73 653.93 879.7C656.39 883.66 657.39 888.09 656.91 893.01C649.99 894.49 644.28 893.22 639.79 889.21C635.87 885.71 634.57 880.68 634.18 877.08Z" />
                  <path id="&lt;Path&gt;" class="shp26" d="M656.48 861.18C650.49 865.74 647.55 871.22 647.66 877.63C647.78 884.03 650.92 889.4 657.07 893.74C663.06 889.18 666 883.7 665.88 877.29C665.77 870.89 662.63 865.52 656.48 861.18Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M657.07 893.74C663.06 889.18 666 883.7 665.88 877.29C665.87 876.35 665.76 875.44 665.59 874.56C664.69 877.71 661.31 888.59 657.08 888.67C652.15 888.76 647.99 874.26 647.98 874.22C647.76 875.31 647.64 876.45 647.66 877.63C647.78 884.03 650.92 889.4 657.07 893.74Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp23" d="M673.65 897.86C673.65 900.96 665.93 903.47 656.4 903.47C646.87 903.47 639.14 900.96 639.14 897.86C639.14 894.76 646.87 892.25 656.4 892.25C665.93 892.25 673.65 894.76 673.65 897.86Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp23" d="M170.3 730.86C170.3 732.44 166.37 733.72 161.52 733.72C156.68 733.72 152.75 732.44 152.75 730.86C152.75 729.29 156.68 728.01 161.52 728.01C166.37 728.01 170.3 729.29 170.3 730.86Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M139.82 730.86C139.82 732.44 135.89 733.72 131.04 733.72C126.2 733.72 122.27 732.44 122.27 730.86C122.27 729.29 126.2 728.01 131.04 728.01C135.89 728.01 139.82 729.29 139.82 730.86Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M163.11 726.38C163.11 729.48 155.39 731.99 145.86 731.99C136.33 731.99 128.6 729.48 128.6 726.38C128.6 723.28 136.33 720.77 145.86 720.77C155.39 720.77 163.11 723.28 163.11 726.38Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M140.45 714.57C135.95 710.56 130.24 709.3 123.32 710.77C122.64 717.82 124.54 723.35 129.04 727.36C133.53 731.37 139.24 732.64 146.16 731.16C146.85 724.11 144.94 718.59 140.45 714.57Z" />
                  <path id="&lt;Path&gt;" class="shp24" d="M168.43 709.95C161.45 708.73 155.79 710.2 151.45 714.37C147.1 718.55 145.4 724.14 146.34 731.16C153.31 732.38 158.97 730.91 163.32 726.73C167.66 722.56 169.37 716.97 168.43 709.95Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M168.48 714.41C158.4 731.8 151.44 722.69 148.84 717.75C146.52 721.79 145.69 726.26 146.34 731.16C153.31 732.38 158.97 730.91 163.32 726.73C167.1 723.1 168.21 718.02 168.48 714.41Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M123.37 715.23C134.08 732.24 140.7 722.88 143.12 717.85C145.59 721.81 146.58 726.24 146.11 731.16C139.18 732.64 133.47 731.37 128.98 727.36C125.06 723.86 123.77 718.83 123.37 715.23Z" />
                  <path id="&lt;Path&gt;" class="shp26" d="M145.67 699.33C139.68 703.89 136.74 709.37 136.86 715.78C136.98 722.18 140.11 727.55 146.27 731.89C152.26 727.33 155.2 721.85 155.08 715.44C154.96 709.04 151.83 703.67 145.67 699.33Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M146.27 731.89C152.26 727.33 155.2 721.85 155.08 715.44C155.06 714.5 154.95 713.59 154.78 712.71C153.89 715.86 150.5 726.74 146.28 726.82C141.34 726.91 137.19 712.41 137.18 712.37C136.96 713.46 136.84 714.6 136.86 715.78C136.98 722.18 140.11 727.55 146.27 731.89Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp23" d="M162.85 736.01C162.85 739.11 155.12 741.62 145.59 741.62C136.06 741.62 128.34 739.11 128.34 736.01C128.34 732.91 136.06 730.4 145.59 730.4C155.12 730.4 162.85 732.91 162.85 736.01Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp23" d="M375.5 834.36C375.5 837.29 368.2 839.66 359.19 839.66C350.19 839.66 342.89 837.29 342.89 834.36C342.89 831.44 350.19 829.07 359.19 829.07C368.2 829.07 375.5 831.44 375.5 834.36Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M318.86 834.36C318.86 837.29 311.56 839.66 302.56 839.66C293.55 839.66 286.25 837.29 286.25 834.36C286.25 831.44 293.55 829.07 302.56 829.07C311.56 829.07 318.86 831.44 318.86 834.36Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M362.14 826.03C362.14 831.78 347.79 836.45 330.08 836.45C312.37 836.45 298.02 831.78 298.02 826.03C298.02 820.27 312.37 815.61 330.08 815.61C347.79 815.61 362.14 820.27 362.14 826.03Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M320.03 804.1C311.68 796.64 301.07 794.29 288.21 797.03C286.94 810.13 290.48 820.4 298.83 827.85C307.18 835.31 317.79 837.66 330.65 834.92C331.92 821.82 328.38 811.55 320.03 804.1Z" />
                  <path id="&lt;Path&gt;" class="shp24" d="M372.02 795.51C359.06 793.23 348.55 795.97 340.47 803.72C332.4 811.48 329.23 821.87 330.98 834.91C343.94 837.18 354.45 834.44 362.53 826.69C370.6 818.94 373.76 808.54 372.02 795.51Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M372.12 803.79C353.39 836.1 340.46 819.17 335.62 809.99C331.31 817.5 329.77 825.81 330.98 834.91C343.94 837.18 354.45 834.44 362.53 826.69C369.56 819.94 371.62 810.51 372.12 803.79Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M288.3 805.32C308.2 836.92 320.5 819.53 325 810.18C329.58 817.54 331.43 825.78 330.55 834.92C317.68 837.66 307.07 835.31 298.72 827.85C291.44 821.36 289.04 812.01 288.3 805.32Z" />
                  <path id="&lt;Path&gt;" class="shp26" d="M329.74 775.78C318.6 784.25 313.15 794.43 313.36 806.33C313.58 818.23 319.41 828.21 330.84 836.27C341.98 827.8 347.43 817.61 347.22 805.71C347 793.81 341.17 783.83 329.74 775.78Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M330.84 836.27C341.98 827.8 347.43 817.61 347.22 805.71C347.19 803.96 346.99 802.27 346.66 800.63C345 806.49 338.72 826.7 330.86 826.85C321.69 827.01 313.97 800.08 313.95 800C313.54 802.03 313.32 804.14 313.36 806.33C313.58 818.23 319.41 828.21 330.84 836.27Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp23" d="M361.65 843.93C361.65 849.68 347.3 854.35 329.59 854.35C311.88 854.35 297.53 849.68 297.53 843.93C297.53 838.17 311.88 833.51 329.59 833.51C347.3 833.51 361.65 838.17 361.65 843.93Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp23" d="M579.05 858.36C579.05 861.29 571.75 863.66 562.75 863.66C553.74 863.66 546.44 861.29 546.44 858.36C546.44 855.44 553.74 853.07 562.75 853.07C571.75 853.07 579.05 855.44 579.05 858.36Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M522.42 858.36C522.42 861.29 515.12 863.66 506.11 863.66C497.11 863.66 489.81 861.29 489.81 858.36C489.81 855.44 497.11 853.07 506.11 853.07C515.12 853.07 522.42 855.44 522.42 858.36Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M565.7 850.03C565.7 855.78 551.34 860.45 533.64 860.45C515.93 860.45 501.58 855.78 501.58 850.03C501.58 844.27 515.93 839.61 533.64 839.61C551.34 839.61 565.7 844.27 565.7 850.03Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M523.59 828.1C515.24 820.64 504.63 818.29 491.76 821.03C490.49 834.13 494.03 844.4 502.38 851.85C510.74 859.31 521.34 861.66 534.21 858.92C535.48 845.82 531.94 835.55 523.59 828.1Z" />
                  <path id="&lt;Path&gt;" class="shp24" d="M575.57 819.51C562.62 817.23 552.1 819.97 544.03 827.72C535.95 835.48 532.79 845.87 534.54 858.91C547.49 861.18 558.01 858.44 566.08 850.69C574.15 842.94 577.32 832.54 575.57 819.51Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M575.67 827.79C556.94 860.1 544.02 843.17 539.17 833.99C534.87 841.5 533.32 849.81 534.54 858.91C547.49 861.18 558.01 858.44 566.08 850.69C573.12 843.94 575.18 834.51 575.67 827.79Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M491.86 829.32C511.75 860.92 524.06 843.53 528.56 834.18C533.14 841.54 534.98 849.78 534.1 858.92C521.24 861.66 510.63 859.31 502.28 851.85C495 845.36 492.6 836.01 491.86 829.32Z" />
                  <path id="&lt;Path&gt;" class="shp26" d="M533.29 799.78C522.16 808.25 516.7 818.43 516.92 830.33C517.14 842.23 522.96 852.21 534.4 860.27C545.53 851.8 550.99 841.61 550.77 829.71C550.56 817.81 544.73 807.83 533.29 799.78Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M534.4 860.27C545.53 851.8 550.99 841.61 550.77 829.71C550.74 827.96 550.54 826.27 550.22 824.63C548.56 830.49 542.27 850.7 534.42 850.85C525.25 851.01 517.53 824.08 517.51 824C517.1 826.03 516.88 828.14 516.92 830.33C517.14 842.23 522.96 852.21 534.4 860.27Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp23" d="M565.21 867.93C565.21 873.68 550.85 878.35 533.15 878.35C515.44 878.35 501.09 873.68 501.09 867.93C501.09 862.17 515.44 857.51 533.15 857.51C550.85 857.51 565.21 862.17 565.21 867.93Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp23" d="M146.02 821.8C146.02 824.48 139.32 826.66 131.07 826.66C122.81 826.66 116.11 824.48 116.11 821.8C116.11 819.12 122.81 816.94 131.07 816.94C139.32 816.94 146.02 819.12 146.02 821.8Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M94.08 821.8C94.08 824.48 87.39 826.66 79.13 826.66C70.87 826.66 64.18 824.48 64.18 821.8C64.18 819.12 70.87 816.94 79.13 816.94C87.39 816.94 94.08 819.12 94.08 821.8Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M133.77 814.16C133.77 819.43 120.61 823.71 104.37 823.71C88.13 823.71 74.97 819.43 74.97 814.16C74.97 808.88 88.13 804.6 104.37 804.6C120.61 804.6 133.77 808.88 133.77 814.16Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M95.15 794.04C87.5 787.21 77.77 785.05 65.97 787.57C64.8 799.57 68.05 808.99 75.71 815.83C83.37 822.66 93.1 824.82 104.89 822.31C106.06 810.3 102.81 800.88 95.15 794.04Z" />
                  <path id="&lt;Path&gt;" class="shp24" d="M142.83 786.17C130.94 784.08 121.3 786.59 113.9 793.7C106.49 800.81 103.59 810.34 105.19 822.3C117.07 824.39 126.72 821.87 134.12 814.76C141.53 807.66 144.43 798.12 142.83 786.17Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M142.92 793.76C125.74 823.39 113.89 807.87 109.45 799.45C105.5 806.34 104.08 813.96 105.19 822.3C117.07 824.39 126.72 821.87 134.12 814.76C140.57 808.57 142.46 799.92 142.92 793.76Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M66.06 795.16C84.3 824.15 95.58 808.2 99.71 799.62C103.91 806.37 105.61 813.93 104.8 822.31C93 824.82 83.27 822.67 75.61 815.83C68.94 809.87 66.73 801.3 66.06 795.16Z" />
                  <path id="&lt;Path&gt;" class="shp26" d="M104.06 768.07C93.84 775.84 88.84 785.18 89.04 796.09C89.24 807.01 94.58 816.16 105.07 823.54C115.28 815.78 120.28 806.44 120.08 795.53C119.88 784.61 114.54 775.46 104.06 768.07Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M105.07 823.54C115.28 815.78 120.28 806.44 120.08 795.53C120.05 793.92 119.87 792.36 119.57 790.86C118.05 796.24 112.29 814.77 105.09 814.91C96.68 815.06 89.6 790.36 89.58 790.29C89.2 792.14 89 794.08 89.04 796.09C89.24 807.01 94.58 816.16 105.07 823.54Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp23" d="M133.32 830.57C133.32 835.85 120.16 840.12 103.92 840.12C87.68 840.12 74.52 835.85 74.52 830.57C74.52 825.29 87.68 821.01 103.92 821.01C120.16 821.01 133.32 825.29 133.32 830.57Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp23" d="M258.83 775.65C258.83 777.75 253.6 779.45 247.15 779.45C240.7 779.45 235.48 777.75 235.48 775.65C235.48 773.56 240.7 771.86 247.15 771.86C253.6 771.86 258.83 773.56 258.83 775.65Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M218.27 775.65C218.27 777.75 213.05 779.45 206.6 779.45C200.15 779.45 194.92 777.75 194.92 775.65C194.92 773.56 200.15 771.86 206.6 771.86C213.05 771.86 218.27 773.56 218.27 775.65Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M249.26 769.69C249.26 773.81 238.98 777.15 226.31 777.15C213.63 777.15 203.35 773.81 203.35 769.69C203.35 765.57 213.63 762.22 226.31 762.22C238.98 762.22 249.26 765.57 249.26 769.69Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M219.11 753.98C213.13 748.64 205.54 746.96 196.32 748.92C195.41 758.3 197.95 765.65 203.93 770.99C209.91 776.33 217.5 778.01 226.72 776.05C227.63 766.67 225.09 759.32 219.11 753.98Z" />
                  <path id="&lt;Path&gt;" class="shp24" d="M256.33 747.83C247.06 746.2 239.53 748.16 233.75 753.71C227.97 759.27 225.7 766.71 226.95 776.04C236.23 777.67 243.76 775.71 249.54 770.16C255.32 764.61 257.58 757.17 256.33 747.83Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M256.41 753.76C243 776.9 233.74 764.77 230.27 758.2C227.19 763.58 226.08 769.53 226.95 776.04C236.23 777.67 243.76 775.71 249.54 770.16C254.58 765.32 256.05 758.57 256.41 753.76Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M196.39 754.85C210.64 777.49 219.45 765.03 222.67 758.34C225.95 763.6 227.27 769.51 226.64 776.05C217.43 778.02 209.83 776.33 203.85 770.99C198.64 766.34 196.92 759.65 196.39 754.85Z" />
                  <path id="&lt;Path&gt;" class="shp26" d="M226.06 733.7C218.09 739.77 214.18 747.06 214.34 755.58C214.49 764.1 218.66 771.25 226.85 777.02C234.82 770.95 238.73 763.66 238.58 755.14C238.42 746.62 234.25 739.47 226.06 733.7Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M226.85 777.02C234.82 770.95 238.73 763.66 238.58 755.14C238.55 753.88 238.41 752.67 238.18 751.5C236.99 755.7 232.49 770.17 226.87 770.27C220.3 770.39 214.77 751.1 214.76 751.05C214.46 752.5 214.31 754.01 214.34 755.58C214.49 764.1 218.66 771.25 226.85 777.02Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp23" d="M248.91 782.5C248.91 786.62 238.63 789.96 225.96 789.96C213.28 789.96 203 786.62 203 782.5C203 778.38 213.28 775.04 225.96 775.04C238.63 775.04 248.91 778.38 248.91 782.5Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp23" d="M500.04 927.33C500.04 930.91 491.13 933.81 480.13 933.81C469.13 933.81 460.21 930.91 460.21 927.33C460.21 923.76 469.13 920.86 480.13 920.86C491.13 920.86 500.04 923.76 500.04 927.33Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M430.87 927.33C430.87 930.91 421.96 933.81 410.96 933.81C399.96 933.81 391.04 930.91 391.04 927.33C391.04 923.76 399.96 920.86 410.96 920.86C421.96 920.86 430.87 923.76 430.87 927.33Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M483.73 917.15C483.73 924.18 466.2 929.88 444.57 929.88C422.95 929.88 405.41 924.18 405.41 917.15C405.41 910.13 422.95 904.43 444.57 904.43C466.2 904.43 483.73 910.13 483.73 917.15Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M432.3 890.37C422.1 881.26 409.14 878.39 393.43 881.74C391.88 897.73 396.2 910.28 406.4 919.38C416.6 928.48 429.56 931.36 445.27 928.01C446.82 912.02 442.5 899.47 432.3 890.37Z" />
                  <path id="&lt;Path&gt;" class="shp24" d="M495.79 879.87C479.97 877.1 467.12 880.44 457.26 889.91C447.4 899.38 443.54 912.08 445.67 928C461.49 930.78 474.34 927.43 484.2 917.96C494.06 908.5 497.92 895.8 495.79 879.87Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M495.91 889.99C473.04 929.45 457.25 908.78 451.33 897.56C446.07 906.74 444.19 916.89 445.67 928C461.49 930.78 474.34 927.43 484.2 917.96C492.79 909.71 495.31 898.2 495.91 889.99Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M393.54 891.86C417.84 930.46 432.87 909.22 438.37 897.8C443.96 906.78 446.22 916.85 445.14 928.01C429.43 931.36 416.47 928.49 406.27 919.38C397.38 911.45 394.45 900.03 393.54 891.86Z" />
                  <path id="&lt;Path&gt;" class="shp26" d="M444.15 855.78C430.55 866.12 423.89 878.56 424.15 893.09C424.42 907.63 431.53 919.82 445.5 929.66C459.1 919.32 465.77 906.88 465.5 892.34C465.24 877.81 458.12 865.62 444.15 855.78Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M445.5 929.66C459.1 919.32 465.77 906.88 465.5 892.34C465.46 890.2 465.22 888.13 464.82 886.13C462.8 893.29 455.12 917.98 445.53 918.15C434.32 918.36 424.9 885.46 424.87 885.37C424.37 887.84 424.1 890.42 424.15 893.09C424.42 907.63 431.53 919.82 445.5 929.66Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp23" d="M483.13 939.01C483.13 946.04 465.6 951.74 443.97 951.74C422.35 951.74 404.82 946.04 404.82 939.01C404.82 931.98 422.35 926.29 443.97 926.29C465.6 926.29 483.13 931.98 483.13 939.01Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp23" d="M278.37 917.53C278.37 921.27 269.05 924.3 257.55 924.3C246.06 924.3 236.74 921.27 236.74 917.53C236.74 913.8 246.06 910.77 257.55 910.77C269.05 910.77 278.37 913.8 278.37 917.53Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M206.07 917.53C206.07 921.27 196.75 924.3 185.25 924.3C173.76 924.3 164.44 921.27 164.44 917.53C164.44 913.8 173.76 910.77 185.25 910.77C196.75 910.77 206.07 913.8 206.07 917.53Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M261.32 906.89C261.32 914.24 242.99 920.19 220.39 920.19C197.79 920.19 179.46 914.24 179.46 906.89C179.46 899.55 197.79 893.59 220.39 893.59C242.99 893.59 261.32 899.55 261.32 906.89Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M207.56 878.89C196.9 869.38 183.36 866.37 166.93 869.88C165.31 886.59 169.83 899.71 180.49 909.22C191.15 918.74 204.7 921.74 221.12 918.24C222.74 901.52 218.22 888.41 207.56 878.89Z" />
                  <path id="&lt;Path&gt;" class="shp24" d="M273.93 867.93C257.39 865.02 243.96 868.52 233.66 878.42C223.35 888.31 219.31 901.59 221.54 918.23C238.08 921.13 251.5 917.64 261.81 907.74C272.12 897.84 276.16 884.57 273.93 867.93Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M274.05 878.5C250.14 919.75 233.64 898.14 227.46 886.42C221.96 896.01 219.99 906.62 221.54 918.23C238.08 921.13 251.5 917.64 261.81 907.74C270.79 899.11 273.42 887.08 274.05 878.5Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M167.06 880.45C192.45 920.8 208.16 898.6 213.91 886.66C219.75 896.05 222.11 906.58 220.98 918.24C204.56 921.74 191.02 918.74 180.36 909.22C171.07 900.93 168 889 167.06 880.45Z" />
                  <path id="&lt;Path&gt;" class="shp26" d="M219.95 842.74C205.74 853.55 198.77 866.55 199.05 881.74C199.32 896.94 206.76 909.68 221.36 919.96C235.57 909.15 242.54 896.15 242.27 880.96C241.99 865.76 234.55 853.03 219.95 842.74Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M221.36 919.96C235.57 909.15 242.54 896.15 242.27 880.96C242.23 878.72 241.97 876.56 241.56 874.47C239.44 881.95 231.41 907.75 221.39 907.94C209.68 908.15 199.83 873.77 199.8 873.67C199.28 876.25 199 878.95 199.05 881.74C199.32 896.94 206.76 909.68 221.36 919.96Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp23" d="M260.69 929.74C260.69 937.09 242.37 943.04 219.76 943.04C197.16 943.04 178.84 937.09 178.84 929.74C178.84 922.39 197.16 916.44 219.76 916.44C242.37 916.44 260.69 922.39 260.69 929.74Z" />
              </g>
            </g>
            <path id="&lt;Path&gt;" class="shp18" d="M1499.3 776.22C1505.53 765.56 1511.16 762.3 1516.19 766.44C1521.82 754.59 1530.12 752.07 1541.08 758.89C1547.6 751.48 1557.38 757.11 1570.42 775.78C1552.64 787.63 1528.93 787.78 1499.3 776.22Z" />
            <path id="&lt;Path&gt;" class="shp17" d="M1332.93 694.3C1340.05 688.57 1346.47 686.99 1352.19 689.56C1359.9 677.7 1369.97 675.33 1382.42 682.44C1388.14 680.67 1392.29 682.84 1394.86 688.96C1379.45 697.85 1358.81 699.63 1332.93 694.3Z" />
            <path id="&lt;Path&gt;" class="shp17" d="M1276.93 978.44C1329.08 941.7 1373.53 935.48 1410.27 959.78C1435.75 916.52 1471.6 904.96 1517.82 925.11C1520.79 916.81 1527.01 915.33 1536.49 920.67C1591.01 896.37 1639.6 913.56 1682.27 972.22C1703.01 971.04 1721.68 982.59 1738.27 1006.89L1152.49 1006.89C1205.82 970.15 1247.3 960.67 1276.93 978.44Z" />
          </g>
        </g>
      </g>
    </svg>
    <div class="text-box">
      <h1>Husk at:</h1>
      <p>&bull; være omsorgsfuld overfor alle levende væsener</p>
      <p>&bull; kom ud og leg</p>
      <p>&bull; få rigeligt med søvn</p>
      <p>&bull; drikke og spise sunde madvarer</p>
      <p>&bull; øve dig på at afspænde / slappe af uden skærme</p>
      <p>&bull; udvise opmærksomhed overfor oplevelser der gør dig glad,
        og det du så, hørte, lugtede / duftede, smagte og følte / mærkede i
        de enkelte situationer</p>
      <p>&bull; sige tak for de glade stunder.</p>
    </div>
    <div class="image-container">
      <img class="action-image" src="../../assets/images/introduction/Koala_Child_Hugging.jpg" alt="kind-to-animals">
      <img class="action-image" src="../../assets/images/session1/811-resized.jpg" alt="play-outside">
      <img class="action-image" src="../../assets/images/session5/85693.jpg" alt="sleep-early">
      <img class="action-image" src="../../assets/images/session1/6796-resized.jpg" alt="eat-healthy">
      <img class="action-image" src="../../assets/images/session1/4082-resized.jpg" alt="relaxing">
      <img class="action-image" src="../../assets/images/session1/2749-resized.jpg" alt="happy-things">
      <img class="action-image" src="../../assets/images/session1/thank-you.jpg" alt="thank-you">
    </div>
    <audio autoplay loop src="../../assets/sounds/session1/Relaxing-Forest-Sound-Effect.mp3"/>
    <audio src="../../assets/sounds/session7/Page155.mp3" ref="voice"/>
    <div class="page-number" id="page-light">155</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: 'Session7Page17',
  methods: {
    animateText() {
      let texts = document.querySelector('.text-box').children;
      let images = document.querySelector('.image-container').children;
      let animation = anime.timeline({duration: 500, easing: 'linear'});
      animation
        .add({targets: ".text-box", opacity: 1}, 500)
        .add({targets: texts[1], opacity: 1}, 1200)
        .add({targets: images[0], opacity: 1}, 1200)
        .add({targets: texts[2], opacity: 1}, 5000)
        .add({targets: images[1], opacity: 1}, 5000)
        .add({targets: texts[3], opacity: 1}, 6900)
        .add({targets: images[2], opacity: 1}, 6900)
        .add({targets: texts[4], opacity: 1}, 9500)
        .add({targets: images[3], opacity: 1}, 9500)
        .add({targets: texts[5], opacity: 1}, 13000)
        .add({targets: images[4], opacity: 1}, 13000)
        .add({targets: texts[6], opacity: 1}, 17500)
        .add({targets: images[5], opacity: 1}, 17500)
        .add({targets: texts[7], opacity: 1}, 31000)
        .add({targets: images[6], opacity: 1}, 31000)
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1)
    },
  },
  mounted() {
    this.animateText();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
.action-image {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.image-container {
  position: absolute;
  top: 7vh;
  right: 2%;
  width: 44%;
  height: 40vh;
}
.text-box {
  position: absolute;
  background-color: rgba(255,255,255,0.8);
  opacity: 0;
  padding: 1vh;
  top: 1%;
  left: 1%;
  width: 50%;
}
.text-box h1 {
  margin-bottom: 1vh;
  font-size: 4vh;
}
.text-box p {
  font-size: 3vh;
  padding-left: 0;
  margin-bottom: 1vh;
  opacity: 0;
}
.text-box p:last-child {
  margin-bottom: 0;
}
.landscape {
  position: absolute;
  height: auto;
  width: 125%;
  left: -8%;
}
tspan { white-space:pre }
.shp0 { fill: url(#grd1) }
.shp1 { fill: #ffffff }
.shp2 { fill: #96b1d9 }
.shp3 { fill: #849cbf }
.shp4 { fill: #6f89aa }
.shp5 { fill: #fae798 }
.shp6 { fill: #01c68e }
.shp7 { fill: #14a699 }
.shp8 { fill: #0e8971 }
.shp9 { fill: #e0ba5c }
.shp10 { fill: #c18f36 }
.shp11 { fill: #448432 }
.shp12 { fill: #659944 }
.shp13 { fill: #79aa4f }
.shp14 { fill: #3b7229 }
.shp15 { fill: #e8cf8e }
.shp16 { fill: #a4cf4e }
.shp17 { fill: #567f3b }
.shp18 { fill: #609140 }
.shp19 { fill: #91c95b }
.shp20 { fill: #c9ae79 }
.shp21 { fill: #d8bc7d }
.shp22 { fill: #845e21 }
.shp23 { fill: #4c873c }
.shp24 { fill: #e5a495 }
.shp25 { fill: #db9191 }
.shp26 { fill: #f3d5ad }
.shp27 { fill: #e5c2a5 }
</style>