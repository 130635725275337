<template>
  <div class="interactive-container">
    <img src="../../assets/images/session10/1034.jpg" alt="Annie" class="session-background">
    <div class="text-box">Mia lærer af én som hun ser op til.</div>
    <div class="text-box-green">Min mor hjælper mig med at lære at svømme.</div>
    <audio src="../../assets/sounds/session10/Page192.mp3" ref="voice"/>
    <div class="page-number" id="page-light">192</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: 'Session10Page6',
  methods: {
    animateText() {
      let animation = anime.timeline({duration: 500, easing: 'linear'});
      animation
        .add({targets: '.text-box',opacity: 1}, 500)
        .add({targets: '.text-box-green',opacity: 1}, 4400);
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1)
    },
  },
  mounted() {
    this.animateText();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
.text-box {
  position: absolute;
  top: 3vh;
  left: 3%;
  background-color: rgba(255,255,255,0.9);
  font-weight: bold;
  font-size: 4vh;
  padding: 2vh;
  opacity: 0;
}
.text-box-green {
  position: absolute;
  bottom: 11.5vh;
  left: 3%;
  padding: 1vh 2.8vh;
  background-color: #00CE7C;
  color: #ffffff;
  font-size: 3vh;
  font-weight: bold;
  opacity: 0;
}
</style>
