<template>
  <svg class="emotes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 171" width="172" height="171">
    <title>Tear</title>
    <g id="Object">
      <g id="&lt;Group&gt;">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp0-tear" d="M0.55 85.22C0.55 38.14 38.71 -0.02 85.79 -0.02C132.86 -0.02 171.02 38.14 171.02 85.22C171.02 132.29 132.86 170.45 85.79 170.45C38.71 170.45 0.55 132.29 0.55 85.22Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp1-tear" d="M130.38 12.47C138.87 25.72 143.79 41.48 143.79 58.38C143.79 105.46 105.63 143.62 58.56 143.62C42.21 143.62 26.94 139.01 13.96 131.03C29.11 154.68 55.62 170.35 85.79 170.35C132.86 170.35 171.02 132.19 171.02 85.12C171.02 54.39 154.76 27.47 130.38 12.47Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp2-tear" d="M12.14 94.21C12.14 50.5 47.57 15.07 91.27 15.07C116.82 15.07 139.53 27.18 154 45.97C140.34 22.25 114.73 6.28 85.38 6.28C41.68 6.28 6.25 41.71 6.25 85.41C6.25 103.57 12.37 120.3 22.65 133.65C15.96 122.04 12.14 108.57 12.14 94.21Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp3-tear" d="M60.67 64.54C60.67 70.39 57.85 75.12 54.39 75.12C50.92 75.12 48.11 70.39 48.11 64.54C48.11 58.69 50.92 53.95 54.39 53.95C57.85 53.95 60.67 58.69 60.67 64.54Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp3-tear" d="M123.47 64.54C123.47 70.39 120.66 75.12 117.19 75.12C113.72 75.12 110.91 70.39 110.91 64.54C110.91 58.69 113.72 53.95 117.19 53.95C120.66 53.95 123.47 58.69 123.47 64.54Z" />
              </g>
            </g>
          </g>
        </g>
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp4-tear" d="M136.14 46.67C135.22 46.4 134.33 46.15 133.45 45.91C131.54 45.37 129.73 44.87 127.98 44.26C125.37 43.38 123.02 42.38 120.8 41.21C118.46 39.97 116.34 38.59 114.31 37C112.09 35.27 110.03 33.26 108.13 31.33C108.02 31.23 107.86 31.19 107.72 31.26C107.58 31.32 107.49 31.46 107.5 31.61C107.64 34.52 108.79 37.53 110.83 40.31C112.69 42.83 115.18 44.95 118.04 46.45C120.73 47.86 123.73 48.72 126.75 48.95C126.87 48.96 126.99 48.97 127.1 48.97C130.49 49.15 133.55 48.61 136.19 47.36C136.33 47.29 136.41 47.15 136.4 47C136.39 46.84 136.28 46.71 136.14 46.67Z" />
          <path id="&lt;Path&gt;" class="shp4-tear" d="M35.44 46.67C36.36 46.4 37.25 46.15 38.12 45.91C40.04 45.37 41.84 44.87 43.59 44.26C46.2 43.38 48.55 42.38 50.77 41.21C53.11 39.97 55.23 38.59 57.27 37C59.49 35.27 61.55 33.26 63.44 31.33C63.55 31.23 63.72 31.19 63.86 31.26C64 31.32 64.08 31.46 64.08 31.61C63.93 34.52 62.78 37.53 60.75 40.31C58.88 42.83 56.39 44.95 53.54 46.45C50.85 47.86 47.85 48.72 44.82 48.95C44.71 48.96 44.59 48.97 44.47 48.97C41.09 49.15 38.03 48.61 35.39 47.36C35.25 47.29 35.16 47.15 35.17 47C35.19 46.84 35.29 46.71 35.44 46.67Z" />
        </g>
        <path id="&lt;Path&gt;" class="shp5-tear" d="M103.78 98.14C98.93 93.94 92.36 91.57 85.78 91.7C79.32 91.72 72.81 94.08 67.91 98.18C65.43 100.27 63.37 102.82 61.93 105.58C60.41 108.57 59.62 111.59 59.59 114.54C59.58 115.25 60.02 115.88 60.69 116.11C61.36 116.34 62.1 116.12 62.53 115.56C64.3 113.28 65.97 111.47 67.64 110.03C69.44 108.5 71.36 107.22 73.33 106.23C77.25 104.27 81.59 103.22 85.87 103.2C90.26 103.17 94.42 104.16 98.26 106.15C102.21 108.18 105.84 111.34 109.03 115.55C109.35 115.97 109.84 116.2 110.34 116.2C110.52 116.2 110.7 116.17 110.88 116.11C111.55 115.88 112 115.24 111.99 114.53C111.9 108.59 108.83 102.46 103.78 98.14Z" />
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp6-tear" d="M124.7 80.62C107.87 99.87 100.39 115.01 102.26 126.05C102.26 138.45 112.31 148.5 124.7 148.5C137.1 148.5 147.15 138.45 147.15 126.05C149.02 115.01 141.54 99.87 124.7 80.62Z" />
          <path id="&lt;Path&gt;" class="shp7-tear" d="M108.02 141.06C112.12 145.63 118.08 148.5 124.7 148.5C137.1 148.5 147.15 138.45 147.15 126.05C147.93 121.41 146.3 114.84 142.27 106.33C137.8 135.43 119.53 140.72 108.02 141.06Z" />
        </g>
      </g>
    </g>
    </svg>
</template>

<script>
export default {
  name: "EmoteTear",
}
</script>

<style scoped>
.emotes {
  height: auto;
  width: 14vh;
}
tspan { white-space:pre }
.shp0-tear { fill: #ffd05d }
.shp1-tear { fill: #fdc453 }
.shp2-tear { fill: #ffd77f }
.shp3-tear { fill: #303030 }
.shp4-tear { fill: #414042 }
.shp5-tear { fill: #5e4939 }
.shp6-tear { fill: #7cc4e8 }
.shp7-tear { fill: #62bbe8 }
</style>