<template>
  <svg class="emotes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 171" width="172" height="171">
    <title>Cool</title>
    <g id="Object">
      <g id="&lt;Group&gt;">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp0-cool" d="M0.7 85.18C0.7 38.11 38.86 -0.05 85.94 -0.05C133.01 -0.05 171.18 38.11 171.18 85.18C171.18 132.26 133.01 170.42 85.94 170.42C38.86 170.42 0.7 132.26 0.7 85.18Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp1-cool" d="M130.53 12.43C139.02 25.68 143.94 41.44 143.94 58.34C143.94 105.42 105.78 143.58 58.71 143.58C42.36 143.58 27.09 138.97 14.11 130.99C29.26 154.64 55.77 170.32 85.94 170.32C133.01 170.32 171.18 132.16 171.18 85.08C171.18 54.36 154.92 27.43 130.53 12.43Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp2-cool" d="M12.29 94.17C12.29 50.47 47.72 15.04 91.42 15.04C116.97 15.04 139.69 27.15 154.15 45.93C140.49 22.21 114.88 6.24 85.53 6.24C41.83 6.24 6.4 41.67 6.4 85.38C6.4 103.54 12.52 120.26 22.8 133.62C16.11 122.01 12.29 108.54 12.29 94.17Z" />
            </g>
          </g>
          <path id="&lt;Path&gt;" class="shp3-cool" d="M51 103.08C48.81 100.94 45.29 103.3 46.43 106.15C52.72 121.88 68.11 133 86.09 133C103.78 133 118.95 122.25 125.44 106.93C126.62 104.13 123.08 101.72 120.9 103.83C108.42 115.92 81.94 133.13 51 103.08Z" />
        </g>
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp4-cool" d="M146.1 52.84L111.49 55.71C111.08 55.71 110.67 55.73 110.26 55.78C109.95 55.81 98.8 57.14 86.38 57.87C86.09 57.89 85.79 57.89 85.5 57.87C73.08 57.14 61.92 55.81 61.6 55.77C61.2 55.73 60.8 55.71 60.39 55.71L25.78 52.84C18.97 52.71 15.21 56.06 15.57 61.98C15.57 62.14 16.39 66.7 18.01 75.69C18.8 80.31 19.67 84.11 22.5 88.29C23.97 90.47 26.16 92.1 28.5 93.3C29.69 93.91 30.95 94.38 32.25 94.69C33.35 94.95 34.55 94.94 35.72 95.01C36.75 95.06 38.44 95.14 39.47 95.14L47.37 95.14C49.59 95.14 50.4 95.14 50.66 95.14C54.31 95.14 58.04 94.28 61.31 92.65C66.45 90.08 69.43 85.84 71.57 80.68C73.58 75.81 77.61 72.12 81.91 69.31C84.36 67.7 87.52 67.7 89.97 69.31C92.29 70.83 94.44 72.62 96.3 74.67C97.67 76.18 98.78 78.08 99.9 79.83C101.16 81.8 101.97 84.14 103.28 86.14C104.61 88.16 106.07 89.81 108.1 91.21C111.5 93.54 115.62 94.81 119.72 95.09C120.22 95.13 120.72 95.14 121.22 95.14C121.48 95.14 122.29 95.14 124.5 95.14L132.41 95.14C133.44 95.14 135.13 95.06 136.16 95.01C137.33 94.94 138.53 94.95 139.62 94.69C140.93 94.38 142.19 93.91 143.38 93.3C145.72 92.1 147.91 90.47 149.38 88.29C152.21 84.11 153.07 80.31 153.87 75.69C155.49 66.7 156.3 62.14 156.31 61.98C156.67 56.06 152.91 52.71 146.1 52.84Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp5-cool" d="M121.22 95.14C121.48 95.14 122.29 95.14 124.5 95.14L132.41 95.14C133.44 95.14 135.13 95.06 136.16 95.01C137.33 94.94 138.53 94.95 139.62 94.69C140.93 94.38 142.19 93.91 143.38 93.3C145.72 92.1 147.91 90.47 149.38 88.29C152.21 84.11 153.07 80.31 153.87 75.69C155.49 66.7 156.3 62.14 156.31 61.98C156.36 61.16 156.32 60.39 156.22 59.66C140.27 84.35 120.94 90.31 108.52 91.47C111.84 93.63 115.78 94.82 119.72 95.09C120.22 95.13 120.72 95.14 121.22 95.14Z" />
            <path id="&lt;Path&gt;" class="shp5-cool" d="M28.5 93.3C29.69 93.91 30.95 94.38 32.25 94.69C33.35 94.95 34.55 94.94 35.72 95.01C36.75 95.06 38.44 95.14 39.47 95.14L47.37 95.14C49.59 95.14 50.4 95.14 50.66 95.14C54.31 95.14 58.04 94.28 61.31 92.65C66.45 90.08 69.43 85.84 71.57 80.68C72.64 78.09 74.29 75.85 76.23 73.88C53.18 89.16 33.99 91.56 26.05 91.83C26.83 92.38 27.66 92.87 28.5 93.3Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "EmoteCool",
}
</script>

<style scoped>
.emotes {
  height: auto;
  width: 14vh;
}
tspan { white-space:pre }
.shp0-cool { fill: #ffd05d }
.shp1-cool { fill: #fdc453 }
.shp2-cool { fill: #ffd77f }
.shp3-cool { fill: #5e4939 }
.shp4-cool { fill: #2d292a }
.shp5-cool { fill: #414042 }
</style>