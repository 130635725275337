<template>
  <div class="interactive-container">
    <svg class="landscape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1000" width="1500" height="1000">
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
          <path d="M421.12 1000L29.58 1000L29.58 678.76L421.12 678.76L421.12 1000Z" />
        </clipPath>
      </defs>
      <g id="OBJECT">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp0" d="M0 360.89C167.8 384.85 336.62 407.64 507.95 412.56C623.2 415.87 738.85 411.07 853.88 416.84C941.69 421.25 1030.56 432.36 1110.23 456.5C1238.36 437.17 1368.86 424.8 1500 419.57L1500 271.73C1000 270.21 500 268.68 0 267.15L0 360.89Z" />
              <path id="&lt;Path&gt;" class="shp1" d="M1500 0L0 0L0 193.63C500 195.16 1000 196.69 1500 198.22L1500 0Z" />
              <path id="&lt;Path&gt;" class="shp2" d="M0 193.63L0 267.15C500 268.68 1000 270.21 1500 271.73L1500 198.22C1000 196.69 500 195.16 0 193.63Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp2" d="M955 310C815.27 302.68 588.73 302.68 449 310C588.73 317.32 815.27 317.32 955 310Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp2" d="M1329 340.67C1189.82 333.38 964.18 333.38 825 340.67C964.18 347.95 1189.82 347.95 1329 340.67Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp2" d="M1465 311.33C1345.15 305.06 1150.85 305.06 1031 311.33C1150.85 317.61 1345.15 317.61 1465 311.33Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp2" d="M997 380.67C927.41 377.02 814.59 377.02 745 380.67C814.59 384.31 927.41 384.31 997 380.67Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp3" d="M783 98C617.31 89.32 348.68 89.32 183 98C348.68 106.68 617.31 106.68 783 98Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp3" d="M1050 118C953.9 112.97 798.1 112.97 702 118C798.1 123.03 953.9 123.03 1050 118Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp3" d="M1368 66C1271.07 60.92 1113.93 60.92 1017 66C1113.93 71.08 1271.07 71.08 1368 66Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp3" d="M1257 166C1060.66 155.72 742.34 155.72 546 166C742.34 176.28 1060.66 176.28 1257 166Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp3" d="M324 160C236.31 155.41 110.93 154 0 155.76L0 164.24C110.93 166 236.31 164.59 324 160Z" />
              <path id="&lt;Path&gt;" class="shp3" d="M1231 110C1303.8 113.81 1407.98 114.98 1500 113.5L1500 106.5C1407.98 105.02 1303.8 106.19 1231 110Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp4" d="M883.73 463.47L1058.83 319.38L1102.61 319.38L1269.5 220.88L1326.96 160.69L1500 271.73L1500 546.46L883.73 546.46L883.73 463.47Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M0 261.77L194.36 185.65L249.08 141.54L451.54 270.13L506.26 240.03L828.9 463.47L0 463.47L0 261.77Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M249.08 141.54L243.05 175.74L216.88 193.52L270.41 193.52L219.34 220.42L187.52 220.42L168.68 237.3L219.54 237.3L158.01 261.92L258.54 261.92L99.21 287.91L105.41 259.18L0 283.81L0 261.77L194.36 185.65L249.08 141.54Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M506.26 240.03L451.54 270.13L473.22 270.13L399.85 302.5L466.27 287.91L455.68 302.5L509.09 292.93L528.29 276.97L498.35 276.97L506.26 240.03Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M1326.96 160.69L1305.04 240.03L1263.88 271.27L1315.97 267.39L1207.38 302.5L1261.42 325.76L1190.29 325.76L1213.91 353.58L1120.15 353.58L1060.01 412.03L1070.12 374.66L1012.53 395.07L1027.82 367.11L973.41 389.67L1058.83 319.38L1102.61 319.38L1269.5 220.88L1326.96 160.69Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp7" d="M833.13 416.44C838.84 406.33 849.12 398.91 860.53 396.67C871.93 394.44 884.25 397.43 893.36 404.65C899.53 397.26 912.35 396.94 918.89 404.02C927.56 395.1 940.22 390.2 952.64 390.97C965.05 391.74 977.01 398.16 984.52 408.08C994.37 397.93 1009.01 393.81 1023.05 395.59C1037.08 397.36 1050.07 406.04 1057.09 418.33C1068.33 410.31 1083.43 407.96 1096.58 412.21C1109.72 416.45 1120.6 427.17 1125.04 440.26C1137.92 428.76 1159.96 429.71 1173.61 440.28C1177.81 428.47 1189.38 419.65 1201.88 418.72C1214.38 417.8 1227.11 424.82 1233 435.89C1237.97 428.12 1247.38 422.04 1256.56 421.1C1265.73 420.17 1275.26 423.65 1281.68 430.26C1291.18 421.29 1304.99 417.1 1317.87 419.28C1330.76 421.46 1342.42 429.96 1348.44 441.55L1073.12 475.68L833.13 416.44Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M0 285.1C24.09 280.04 51.25 282.45 73.11 293.76C94.97 305.07 112.74 324.11 122.53 346.7C133.31 336.91 153.28 341.16 159.15 354.48C170.07 345.46 187.84 340.39 201.82 342.69C215.79 345 228.61 353.59 236.05 365.64C242.14 354.51 254.33 346.99 267 346.55C279.68 346.1 292.37 352.75 299.22 363.42C318.77 345.3 351.14 348.03 373.39 362.71C395.64 377.4 409.97 401.19 422.79 424.57L0 424.57L0 285.1Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M847.61 409.37C836.48 397.73 816.62 395.81 803.48 405.12C793.11 393.18 774.26 389.66 760.29 397.04C745.74 384.54 726.22 378 707.08 379.2C687.93 380.41 669.39 389.35 656.52 403.57C646.41 394.96 628.49 394.08 617.71 401.84C610.72 391.94 598.39 386.06 586.3 386.86C574.2 387.65 562.75 395.09 557.11 405.82C539.39 400.01 518.6 404.88 505.3 417.97L860.8 432.67L847.61 409.37Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M1500 353.58C1493.36 352.59 1486.87 354.58 1482.43 359.62C1477.99 364.66 1476.32 372 1478.14 378.46C1474.25 376.7 1468.12 377.61 1464.73 380.21C1461.34 382.8 1459.54 387.33 1460.22 391.54C1452.2 385.2 1440.4 384.36 1431.2 388.82C1422 393.29 1415.76 403.28 1415.78 413.5C1406.77 411.22 1396.69 412.74 1388.59 417.3C1380.48 421.86 1374.24 429.61 1371.5 438.5L1500 438.5L1500 353.58Z" />
          </g>
          <g id="cloud">
            <path id="&lt;Path&gt;" class="shp6" d="M812.02 221.33L588 221.33C600.76 212.77 619.42 210.57 633.64 216.39C637.36 203.25 648.3 192.41 661.71 189.8C675.12 187.19 689.79 192.7 698.17 203.5C700.97 198.19 706.46 194.4 712.41 193.66C718.36 192.92 724.61 195.25 728.63 199.7C742.15 189.77 759.59 186.03 775.96 189.67C792.32 193.31 803.98 206.62 812.02 221.33Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M1153.33 203.33L938 203.33C952.6 188.85 977.29 182.23 995.88 191.01C1006.84 174.14 1026.74 163.47 1046.85 163.67C1066.97 163.88 1086.65 174.96 1097.26 192.05C1105.68 186.62 1115.45 186.21 1125.42 187.27C1135.38 188.33 1146.24 196.25 1153.33 203.33Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M451.68 129L300.67 129C308.22 115.31 324.55 105.38 340.16 104.49C355.77 103.59 371.61 111.19 380.69 123.93C390.57 116.54 403 112.62 415.33 113.01C427.67 113.39 442.28 121.01 451.68 129Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M797.39 80.87L602.63 80.87C617.27 62.54 645.89 57.5 664.93 71.22C673.21 53.22 692.84 41.08 712.64 41.7C732.44 42.32 751.26 55.67 758.4 74.16C770.85 67.49 785.63 73.04 797.39 80.87Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M1154.2 82.46L992.84 82.46C1007.42 68.34 1030.38 65.18 1048.32 74.67C1055.73 63.7 1068.91 56.87 1082.15 57.16C1095.38 57.45 1108.25 64.84 1115.17 76.13C1126.7 68.74 1143.71 73.65 1154.2 82.46Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp8" d="M725.21 465.97L636.04 465.97L833.56 0L887.77 0L725.21 465.97Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M368.81 465.97L279.64 465.97L477.16 0L531.37 0L368.81 465.97Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M947.32 465.97L829.65 465.97L1027.17 0L1109.88 0L947.32 465.97Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M1055.91 465.97L993.12 465.97L1190.64 0L1218.48 0L1055.91 465.97Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M1337.44 465.97L1274.65 465.97L1472.17 0L1500 0L1337.44 465.97Z" />
            <path id="&lt;Path&gt;" class="shp9" d="M545.87 465.97L483.08 465.97L680.6 0L708.43 0L545.87 465.97Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M242.13 465.97L152.96 465.97L350.48 0L404.69 0L242.13 465.97Z" />
            <path id="&lt;Path&gt;" class="shp9" d="M62.79 465.97L0 465.97L197.52 0L225.35 0L62.79 465.97Z" />
            <path id="&lt;Path&gt;" class="shp9" d="M1230.04 465.97L1167.25 465.97L1364.77 0L1392.6 0L1230.04 465.97Z" />
            <path id="&lt;Path&gt;" class="shp9" d="M961.54 465.97L898.75 465.97L1096.27 0L1124.1 0L961.54 465.97Z" />
            <path id="&lt;Path&gt;" class="shp9" d="M319.76 465.97L256.97 465.97L454.49 0L482.32 0L319.76 465.97Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp10" d="M585.04 533.29C535.69 513.17 472.32 509.5 418.14 522.78C447.21 531.06 475.94 539.95 504.89 548.38C567.96 546.51 630.71 539.8 693.23 530.59C679.12 523.78 661.87 519.86 644.45 519.65C623.72 519.41 600.76 524.29 585.04 533.29Z" />
            <path id="&lt;Path&gt;" class="shp11" d="M273.92 470.78C240.59 461.92 203.41 462.94 171.05 473.27C145.91 457.22 112.71 446.89 78.11 444.34C51.7 442.39 24.6 445.01 0 451.68L0 477.12C111.56 470.57 225.04 478.84 331.87 501.26C317.35 487.74 297.1 476.93 273.92 470.78Z" />
            <path id="&lt;Path&gt;" class="shp12" d="M853.88 416.84C738.85 411.07 623.2 415.87 507.95 412.56C336.62 407.64 167.8 384.85 0 360.89L0 400.22C112.98 409.03 221.3 435.33 332.29 452.32C475.27 474.2 623.56 480.56 769.56 471.1C858.8 465.31 952.64 454.17 1037.76 468.21C1061.82 464.06 1085.98 460.16 1110.23 456.5C1030.56 432.36 941.69 421.25 853.88 416.84Z" />
            <path id="&lt;Path&gt;" class="shp13" d="M769.56 471.1C623.56 480.56 475.27 474.2 332.29 452.32C221.3 435.33 112.98 409.03 0 400.22L0 451.68C24.6 445.01 51.7 442.39 78.11 444.34C112.71 446.89 145.91 457.22 171.05 473.27C203.41 462.94 240.59 461.92 273.92 470.78C297.1 476.93 317.35 487.74 331.87 501.26C348.42 504.73 364.83 508.53 381.03 512.68C393.5 515.88 405.85 519.28 418.14 522.78C472.32 509.5 535.69 513.17 585.04 533.29C600.76 524.29 623.72 519.41 644.45 519.65C661.87 519.86 679.12 523.78 693.23 530.59C721.15 526.47 749.03 521.85 776.86 516.94C783.48 507.37 794.49 499.14 808.18 493.88C827.02 486.63 850.59 485.1 871.17 489.78C906.93 467.44 962.83 461.27 1008.02 473.46C1016.88 471.86 1025.74 470.28 1034.6 468.74C1035.65 468.56 1036.71 468.39 1037.76 468.21C952.64 454.17 858.8 465.31 769.56 471.1Z" />
            <path id="&lt;Path&gt;" class="shp11" d="M871.17 489.78C850.59 485.1 827.02 486.63 808.18 493.88C794.49 499.14 783.48 507.37 776.86 516.94C854.18 503.28 931.18 487.33 1008.02 473.46C962.83 461.27 906.93 467.44 871.17 489.78Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp13" d="M1024.75 571.65C1024.66 571.39 1024.61 571.13 1024.49 570.87C1018.49 558.11 988.57 558.06 978.71 546.41C973.45 540.19 975.71 532.04 981.96 526.23C988.21 520.43 997.78 516.6 1007.54 513.65C1042.23 503.17 1081.32 501.94 1119.18 499.2C1134.42 498.1 1151.84 495.77 1159.25 486.82C1165.41 479.37 1161.69 469.45 1152.65 463.45C1144.32 457.93 1132.45 455.39 1120.66 454.96C1117.18 455.48 1113.7 455.98 1110.23 456.5C1084.92 460.32 1059.71 464.38 1034.6 468.74C859.37 499.16 683.39 543.08 504.89 548.38C545.28 560.15 586.1 570.99 628.9 577.89C633.27 578.59 637.65 579.25 642.04 579.88C732.24 580.01 822.43 585.91 910.94 597.51C945.75 588.71 984.17 579.92 1024.75 571.65Z" />
            <path id="&lt;Path&gt;" class="shp14" d="M1018.69 586.35C1023.75 582.29 1026.63 576.74 1024.75 571.65C984.17 579.92 945.75 588.71 910.94 597.51C822.43 585.91 732.24 580.01 642.04 579.88C707.66 589.19 775.52 589.94 841.98 596.5C864.36 598.72 886.91 601.73 908.88 605.88C916.84 604.85 924.8 603.78 932.74 602.65C959.96 598.76 986.98 594.27 1013.92 589.54C1015.63 588.56 1017.24 587.51 1018.69 586.35Z" />
            <path id="&lt;Path&gt;" class="shp15" d="M1159.25 486.82C1151.84 495.77 1134.42 498.1 1119.18 499.2C1081.32 501.94 1042.23 503.17 1007.54 513.65C997.78 516.6 988.21 520.43 981.96 526.23C975.71 532.04 973.45 540.19 978.71 546.41C988.57 558.06 1018.49 558.11 1024.49 570.87C1024.61 571.13 1024.66 571.39 1024.75 571.65C1036.29 569.29 1047.99 566.99 1059.85 564.73C1056.82 559.25 1051.4 554.49 1043.65 551.8C1035.32 548.91 1025.47 548.67 1016.8 546.26C1008.13 543.86 1000.25 537.59 1003.71 531.78C1005.6 528.59 1010.31 526.59 1014.9 525.04C1046.83 514.26 1084.34 515.16 1119.85 512.16C1135.49 510.85 1151.47 508.6 1164.42 502.62C1177.38 496.64 1186.71 486.14 1184.05 475.79C1181.81 467.05 1171.56 459.97 1159.73 455.94C1154.3 454.09 1148.54 452.79 1142.64 451.79C1135.3 452.82 1127.98 453.88 1120.66 454.96C1132.45 455.39 1144.32 457.93 1152.65 463.45C1161.69 469.45 1165.41 479.37 1159.25 486.82Z" />
            <path id="&lt;Path&gt;" class="shp15" d="M1059.85 564.73C1047.99 566.99 1036.29 569.29 1024.75 571.65C1026.63 576.74 1023.75 582.29 1018.69 586.35C1017.24 587.51 1015.63 588.56 1013.92 589.54C1029.95 586.73 1045.95 583.82 1061.95 580.91C1062.07 580.45 1062.24 580 1062.33 579.53C1063.3 574.61 1062.44 569.41 1059.85 564.73Z" />
            <path id="&lt;Path&gt;" class="shp13" d="M1184.05 475.79C1186.71 486.14 1177.38 496.64 1164.42 502.62C1151.47 508.6 1135.49 510.85 1119.85 512.16C1084.34 515.16 1046.83 514.26 1014.9 525.04C1010.31 526.59 1005.6 528.59 1003.71 531.78C1000.25 537.59 1008.13 543.86 1016.8 546.26C1025.47 548.67 1035.32 548.91 1043.65 551.8C1051.4 554.49 1056.82 559.25 1059.85 564.73C1204.07 537.31 1369.77 517.96 1500 528.21L1500 419.57C1379.9 424.36 1260.34 435.16 1142.64 451.79C1148.54 452.79 1154.3 454.09 1159.73 455.94C1171.56 459.97 1181.81 467.05 1184.05 475.79Z" />
            <path id="&lt;Path&gt;" class="shp14" d="M1059.85 564.73C1062.44 569.41 1063.3 574.61 1062.33 579.53C1062.24 580 1062.07 580.45 1061.95 580.91C1127.12 569.06 1192.19 557.03 1258.84 549.94C1338.58 541.46 1419.23 540.2 1500 542.21L1500 528.21C1369.77 517.96 1204.07 537.31 1059.85 564.73Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp16" d="M784.4 779.09C703.63 781.99 622.9 785.48 542.1 783.81C488.04 782.7 433.37 779.04 380.29 771.56C436.69 786.61 494.58 797.96 555.54 802.03C641.88 807.78 728.45 798.35 815.13 794.08C808.42 791.07 801.79 787.98 795.3 784.78C791.64 782.97 787.99 781.08 784.4 779.09Z" />
            <path id="&lt;Path&gt;" class="shp17" d="M784.4 779.09C761.21 766.27 740.8 749.7 742.76 730.18C743.75 720.21 750.67 710.84 751.84 700.87C753.84 683.84 738.32 667.51 717.61 657.43C696.91 647.35 671.63 642.48 646.69 638.53C630.6 635.97 614.28 633.68 598.54 630.35C542.89 633.45 487.25 636.65 431.9 641.24C328.67 649.79 224.19 663.87 128.13 689.69C212.42 717.28 294.64 748.7 380.29 771.56C433.37 779.04 488.04 782.7 542.1 783.81C622.9 785.48 703.63 781.99 784.4 779.09Z" />
            <path id="&lt;Path&gt;" class="shp18" d="M717.61 657.43C738.32 667.51 753.84 683.84 751.84 700.87C750.67 710.84 743.75 720.21 742.76 730.18C740.8 749.7 761.21 766.27 784.4 779.09C803.59 778.4 822.78 777.76 841.97 777.22C834.21 773.04 826.67 768.67 819.19 764.31C800.51 753.41 780.39 740.6 779.35 724.07C778.67 713.4 786.31 703.2 786.93 692.54C787.84 677.17 773.73 662.72 755.07 653.68C736.41 644.64 713.69 640.2 691.2 636.81C673.24 634.11 655 631.92 637.53 628.18C624.53 628.91 611.54 629.63 598.54 630.35C614.28 633.68 630.6 635.97 646.69 638.53C671.63 642.48 696.91 647.35 717.61 657.43Z" />
            <path id="&lt;Path&gt;" class="shp19" d="M784.4 779.09C787.99 781.08 791.64 782.97 795.3 784.78C801.79 787.98 808.42 791.07 815.13 794.08C828.31 793.43 841.49 792.89 854.67 792.55C861.29 792.38 867.9 792.27 874.51 792.19C863 787.95 852.29 782.77 841.97 777.22C822.78 777.76 803.59 778.4 784.4 779.09Z" />
            <path id="&lt;Path&gt;" class="shp20" d="M1334.55 559.27C1304.89 561.12 1274.2 565.6 1251.37 578.35C1239.42 585.03 1230.04 593.76 1216.87 599.32C1198.28 607.18 1175.02 607.63 1153.02 608.21C1085.86 609.98 1016.52 615.68 958.88 638.73C935.04 648.26 911.5 664.56 917.22 682.7C922.2 698.51 947.95 707.8 972.08 709.33C1036.05 713.41 1092.21 679.26 1156.19 675.19C1207.06 671.96 1256.48 688.08 1307.56 688.93C1351.35 689.67 1393.54 679.21 1436.12 672.37C1456.82 669.05 1478.66 666.7 1500 666.81L1500 572.06C1446.98 560.21 1390.23 555.79 1334.55 559.27Z" />
            <path id="&lt;Path&gt;" class="shp17" d="M1307.56 688.93C1256.48 688.08 1207.06 671.96 1156.19 675.19C1092.21 679.26 1036.05 713.41 972.08 709.33C947.95 707.8 922.2 698.51 917.22 682.7C911.5 664.56 935.04 648.26 958.88 638.73C1016.52 615.68 1085.86 609.98 1153.02 608.21C1175.02 607.63 1198.28 607.18 1216.87 599.32C1230.04 593.76 1239.42 585.03 1251.37 578.35C1274.2 565.6 1304.89 561.12 1334.55 559.27C1390.23 555.79 1446.98 560.21 1500 572.06L1500 542.21C1419.23 540.2 1338.58 541.46 1258.84 549.94C1148.34 561.69 1042.15 587.02 932.74 602.65C924.8 603.78 916.84 604.85 908.88 605.88C819.41 617.5 728.54 623.09 637.53 628.18C655 631.92 673.24 634.11 691.2 636.81C713.69 640.2 736.41 644.64 755.07 653.68C773.73 662.72 787.84 677.17 786.93 692.54C786.31 703.2 778.67 713.4 779.35 724.07C780.39 740.6 800.51 753.41 819.19 764.31C826.67 768.67 834.21 773.04 841.97 777.22C869.24 776.44 896.52 775.88 923.8 775.78C1119.71 775.05 1315.74 797.62 1500 841.99L1500 666.81C1478.66 666.7 1456.82 669.05 1436.12 672.37C1393.54 679.21 1351.35 689.67 1307.56 688.93Z" />
            <path id="&lt;Path&gt;" class="shp16" d="M841.97 777.22C852.29 782.77 863 787.95 874.51 792.19C1092.05 789.71 1302.07 838.2 1500 902.63L1500 841.99C1315.74 797.62 1119.71 775.05 923.8 775.78C896.52 775.88 869.24 776.44 841.97 777.22Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp20" d="M110.19 640.81C112.51 631.04 122.97 622.72 135.7 617.62C148.43 612.53 163.26 610.27 177.98 608.95C226.6 604.59 275.87 609.65 324.9 610.72C333.81 610.91 343.02 610.93 351.22 608.61C359.42 606.29 366.39 601.01 365.99 595.08C365.3 584.78 345.22 579.99 339.8 570.33C335.92 563.41 340.8 555.34 349.04 550.4C357.27 545.46 368.22 543.08 379 541.36C412.31 536.07 447.45 536.13 480.8 541.26C447.74 531.4 414.77 521.33 381.03 512.68C260.28 481.75 128.85 469.56 0 477.12L0 652.89C43.68 663.18 86.17 675.95 128.13 689.69C135.09 687.82 142.11 686.03 149.16 684.28C142.39 681.3 136.07 677.87 130.39 674.01C117.38 665.16 107.28 653.05 110.19 640.81Z" />
            <path id="&lt;Path&gt;" class="shp20" d="M908.88 605.88C886.91 601.73 864.36 598.72 841.98 596.5C771.07 589.5 698.58 589.12 628.9 577.89C596.83 572.72 565.86 565.33 535.35 557.01C490.06 550.02 443.8 543.94 397.89 548.02C386.34 549.05 367.98 550.88 365.56 560.27C364.12 565.86 370.86 571.82 376.11 575.82C381.37 579.83 387.75 583.13 393.06 587.12C400.78 592.91 406.13 600.29 406.77 608.02C407.4 615.76 402.95 623.81 394.02 628.77C385.36 633.58 373.69 635.1 362.49 635.91C311.1 639.61 258.61 631.78 207.69 637.72C202.58 638.32 197.04 639.25 194.01 642.05C190.28 645.5 192.06 650.6 196.38 653.73C200.69 656.86 206.89 658.47 212.92 659.87C221.7 661.9 230.61 663.66 239.62 665.2C302.63 654.06 367.51 646.58 431.9 641.24C590.71 628.08 751.94 626.27 908.88 605.88Z" />
            <path id="&lt;Path&gt;" class="shp18" d="M196.38 653.73C192.06 650.6 190.28 645.5 194.01 642.05C197.04 639.25 202.58 638.32 207.69 637.72C258.61 631.78 311.1 639.61 362.49 635.91C373.69 635.1 385.36 633.58 394.02 628.77C402.95 623.81 407.4 615.76 406.77 608.02C406.13 600.29 400.78 592.91 393.06 587.12C387.75 583.13 381.37 579.83 376.11 575.82C370.86 571.82 364.12 565.86 365.56 560.27C367.98 550.88 386.34 549.05 397.89 548.02C443.8 543.94 490.06 550.02 535.35 557.01C525.15 554.22 515.01 551.33 504.89 548.38C496.84 546.04 488.82 543.66 480.8 541.26C447.45 536.13 412.31 536.07 379 541.36C368.22 543.08 357.27 545.46 349.04 550.4C340.8 555.34 335.92 563.41 339.8 570.33C345.22 579.99 365.3 584.78 365.99 595.08C366.39 601.01 359.42 606.29 351.22 608.61C343.02 610.93 333.81 610.91 324.9 610.72C275.87 609.65 226.6 604.59 177.98 608.95C163.26 610.27 148.43 612.53 135.7 617.62C122.97 622.72 112.51 631.04 110.19 640.81C107.28 653.05 117.38 665.16 130.39 674.01C136.07 677.87 142.39 681.3 149.16 684.28C178.69 676.94 208.93 670.63 239.62 665.2C230.61 663.66 221.7 661.9 212.92 659.87C206.89 658.47 200.69 656.86 196.38 653.73Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp7" d="M616.91 915.46C533.17 918.17 448.52 941.21 367.78 926.17C319.32 917.14 278.92 895.39 235.08 878.92C163.99 852.21 81.49 839.27 0 841.68L0 1000L1018.61 1000C995.43 993.66 972.57 986.85 950.13 979.96C843.97 947.37 733.65 911.68 616.91 915.46Z" />
            <path id="&lt;Path&gt;" class="shp21" d="M634.13 903C551.72 905.22 468.78 920.56 387.83 910.02C317.46 900.86 256.38 873.11 193.02 850.73C133.56 829.72 65.22 812.84 0 821.59L0 841.68C81.49 839.27 163.99 852.21 235.08 878.92C278.92 895.39 319.32 917.14 367.78 926.17C448.52 941.21 533.17 918.17 616.91 915.46C733.65 911.68 843.97 947.37 950.13 979.96C972.57 986.85 995.43 993.66 1018.61 1000L1108.79 1000C959.37 947.87 801.54 898.51 634.13 903Z" />
            <path id="&lt;Path&gt;" class="shp22" d="M854.67 792.55C754.78 795.12 655.01 808.66 555.54 802.03C401.99 791.79 267.93 735.44 128.13 689.69C86.17 675.95 43.68 663.18 0 652.89L0 734.21L0 821.59C65.22 812.84 133.56 829.72 193.02 850.73C256.38 873.11 317.46 900.86 387.83 910.02C468.78 920.56 551.72 905.22 634.13 903C801.54 898.51 959.37 947.87 1108.79 1000L1245.71 1000C1135.88 922.72 986.16 870.66 827.79 856.26C938.73 854.15 1059.81 863.41 1160.36 894.72C1244.48 920.92 1318.19 958.92 1388.15 1000L1500 1000L1500 902.63C1296.06 836.24 1079.28 786.77 854.67 792.55Z" />
            <path id="&lt;Path&gt;" class="shp23" d="M827.79 856.26C986.16 870.66 1135.88 922.72 1245.71 1000L1388.15 1000C1318.19 958.92 1244.48 920.92 1160.36 894.72C1059.81 863.41 938.73 854.15 827.79 856.26Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M1394.87 925.81C1396.14 935.58 1396.06 945.52 1394.62 955.27C1399 958.7 1405.54 959.42 1410.32 956.59C1412.14 946.87 1411.87 936.48 1409.67 926.85C1405.97 926.75 1398.57 925.91 1394.87 925.81Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp25" d="M1385.61 879.32C1399.88 875.64 1410.94 863.89 1417.53 850.72C1424.73 836.35 1427.41 819.16 1422.06 804.01C1418.23 793.14 1409.76 783.75 1399.35 779.16C1386.63 779.83 1374.11 783.99 1363.3 790.82C1353.61 796.95 1345.48 805.62 1340.81 816.16C1335.28 828.64 1334.68 842.86 1336.92 856.34C1338.67 859.51 1340.72 862.52 1343.13 865.24C1353.54 876.99 1370.41 883.23 1385.61 879.32Z" />
              <path id="&lt;Path&gt;" class="shp26" d="M1413.44 908.12C1437.01 894.5 1452.2 867.74 1452.45 840.53C1452.64 820.89 1445.16 801.5 1432.54 786.47C1428.01 783.88 1423.02 781.89 1417.5 780.65C1411.52 779.3 1405.41 778.84 1399.35 779.16C1409.76 783.75 1418.23 793.14 1422.06 804.01C1427.41 819.16 1424.73 836.35 1417.53 850.72C1410.94 863.89 1399.88 875.64 1385.61 879.32C1370.41 883.23 1353.54 876.99 1343.13 865.24C1340.72 862.52 1338.67 859.51 1336.92 856.34C1336.99 856.78 1337.04 857.22 1337.12 857.65C1339.62 871.52 1344.95 884.7 1350.58 897.62C1353.37 904.02 1356.43 910.54 1360.13 916.51C1378.12 920.15 1397.51 917.34 1413.44 908.12Z" />
              <path id="&lt;Path&gt;" class="shp10" d="M1459.1 818.96C1453.52 804.97 1444.79 793.46 1432.54 786.47C1445.16 801.5 1452.64 820.89 1452.45 840.53C1452.2 867.74 1437.01 894.5 1413.44 908.12C1397.51 917.34 1378.12 920.15 1360.13 916.51C1364.6 923.73 1370.04 930.14 1377.3 934.46C1391.97 943.19 1411.43 941.13 1425.96 932.16C1440.48 923.19 1450.53 908.28 1456.92 892.45C1466.31 869.16 1468.41 842.29 1459.1 818.96Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M1270.75 887.58C1267.05 889.21 1262.44 888.26 1258.75 886.6C1259.93 872.38 1260.1 858.07 1259.26 843.82C1263.4 843.84 1267.85 844.6 1271.98 844.62C1272.39 858.05 1270.35 874.15 1270.75 887.58Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp25" d="M1228.59 800.64C1238.07 796.97 1245.58 788.48 1247.87 778.58C1250.3 768.03 1247.01 756.89 1248.7 746.2C1249.96 738.19 1253.94 730.87 1255.86 723C1257.79 715.13 1257.08 705.64 1250.76 700.57C1246.54 697.19 1240.39 697.04 1235.6 699.4C1232.91 704.55 1231.08 709.97 1230.35 714.72C1228.81 724.72 1230.37 735.04 1228.46 744.97C1226.69 754.23 1222 762.62 1217.83 771.07C1213.08 780.66 1208.89 790.7 1206.65 801.12C1213.76 803.39 1221.63 803.33 1228.59 800.64Z" />
              <path id="&lt;Path&gt;" class="shp10" d="M1321.63 811.11C1320.55 797.8 1315.32 785.12 1308.61 773.57C1301.62 761.56 1292.84 750.21 1289.52 736.71C1285.07 718.65 1290.56 688.94 1268.07 680.87C1261.94 678.67 1256.24 679.51 1251.21 682.2C1251.99 682.4 1252.78 682.61 1253.55 682.88C1261.67 685.72 1268.62 692.23 1271.17 700.45C1276.47 717.51 1262.68 736.57 1269.05 753.27C1272.23 761.61 1279.82 767.39 1284.61 774.93C1293.09 788.24 1291.96 805.55 1287.8 820.78C1285.12 830.55 1280.72 840.75 1271.91 845.75C1265.22 849.54 1257.1 849.67 1249.44 849.07C1239.02 848.25 1228.54 846.19 1219.29 841.32C1214.82 838.97 1210.68 835.89 1207.2 832.23C1209.32 839.65 1212.99 846.55 1218.49 851.96C1230.25 863.52 1247.99 866.66 1264.48 866.12C1281.35 865.56 1299.23 861.1 1310.42 848.46C1319.27 838.46 1322.7 824.43 1321.63 811.11Z" />
              <path id="&lt;Path&gt;" class="shp26" d="M1249.44 849.07C1257.1 849.67 1265.22 849.54 1271.91 845.75C1280.72 840.75 1285.12 830.55 1287.8 820.78C1291.96 805.55 1293.09 788.24 1284.61 774.93C1279.82 767.39 1272.23 761.61 1269.05 753.27C1262.68 736.57 1276.47 717.51 1271.17 700.45C1268.62 692.23 1261.67 685.72 1253.55 682.88C1252.78 682.61 1251.99 682.4 1251.21 682.2C1244.68 685.67 1239.31 692.26 1235.6 699.4C1240.39 697.04 1246.54 697.19 1250.76 700.57C1257.08 705.64 1257.79 715.13 1255.86 723C1253.94 730.87 1249.96 738.19 1248.7 746.2C1247.01 756.89 1250.3 768.03 1247.87 778.58C1245.58 788.48 1238.07 796.97 1228.59 800.64C1221.63 803.33 1213.76 803.39 1206.65 801.12C1205.87 804.76 1205.32 808.44 1205.08 812.16C1204.64 818.88 1205.34 825.75 1207.2 832.23C1210.68 835.89 1214.82 838.97 1219.29 841.32C1228.54 846.19 1239.02 848.25 1249.44 849.07Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M498 681.25C498.53 687.29 498.4 693.38 497.63 699.39C499.72 701.14 502.5 702.56 505.23 702.56C507.96 702.55 510.68 701.56 512.77 699.8C511.76 694.95 511.25 689.57 511.46 684.63C507.36 683.35 502.23 681.97 498 681.25Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M483.98 569.17C485.86 561.48 488.81 553.44 495.76 551.62C503.79 549.51 511.77 556.99 516.18 562.74C523.35 572.1 525.09 584.01 527.94 595.16C531.39 608.68 534.84 622.21 538.29 635.73C536.92 640.61 534.51 645.15 531.01 648.85C521.84 658.55 505.48 660.99 494.57 653.31C485.75 647.1 481.66 635.89 481.03 625.12C480.39 614.36 482.67 603.66 484.03 592.96C485.03 585.08 485.49 576.91 483.98 569.17ZM511.38 602.57C511.29 604.38 511.48 606.26 512.36 607.84C513.25 609.42 514.92 610.64 516.73 610.59C518.68 610.54 520.37 609.04 521.12 607.24C521.87 605.44 521.83 603.41 521.54 601.49C521.14 598.8 519.53 594.97 516.2 595.14C512.73 595.33 511.52 599.77 511.38 602.57ZM499.12 585.69C501.13 588.67 503.88 591.72 507.48 591.83C511.13 591.94 514.24 588.75 515.25 585.24C516.26 581.72 515.64 577.96 514.93 574.37C513.85 568.95 511.78 560.17 505.69 558.19C499.35 556.12 494.26 564.2 493.81 569.52C493.33 575.28 495.9 580.89 499.12 585.69Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M538.5 636.58C538.43 636.3 538.36 636.01 538.29 635.73C536.92 640.61 534.51 645.15 531.01 648.85C521.84 658.55 505.48 660.99 494.57 653.31C485.75 647.1 481.66 635.89 481.03 625.12C480.39 614.36 482.67 603.66 484.03 592.96C485.03 585.08 485.49 576.91 483.98 569.17C483.03 573.08 482.35 576.89 481.58 579.76C477.76 593.88 475.85 611.96 469.34 625C465.02 640.93 461.11 659.38 470.73 672.79C477.76 682.59 490.29 686.81 502.25 688.35C513.67 689.82 526.6 688.87 534.63 680.61C540.01 675.08 542.24 667.08 542.29 659.36C542.33 651.64 540.41 644.06 538.5 636.58Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M507.48 591.83C511.13 591.94 514.24 588.75 515.25 585.24C516.26 581.72 515.64 577.96 514.93 574.37C513.85 568.95 511.78 560.17 505.69 558.19C499.35 556.12 494.26 564.2 493.81 569.52C493.33 575.28 495.9 580.89 499.12 585.69C501.13 588.67 503.88 591.72 507.48 591.83Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M511.38 602.57C511.29 604.38 511.48 606.26 512.36 607.84C513.25 609.42 514.92 610.64 516.73 610.59C518.68 610.54 520.37 609.04 521.12 607.24C521.87 605.44 521.83 603.41 521.54 601.49C521.14 598.8 519.53 594.97 516.2 595.14C512.73 595.33 511.52 599.77 511.38 602.57Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M335.69 664.96C336.04 668.95 335.96 672.98 335.45 676.95C336.83 678.1 338.67 679.04 340.47 679.04C342.28 679.04 344.07 678.38 345.45 677.22C344.79 674.01 344.45 670.46 344.59 667.19C341.88 666.35 338.49 665.44 335.69 664.96Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M326.43 590.9C327.67 585.81 329.62 580.5 334.21 579.3C339.52 577.91 344.79 582.85 347.71 586.65C352.45 592.83 353.6 600.7 355.48 608.07C357.76 617.01 360.04 625.94 362.32 634.88C361.42 638.1 359.82 641.11 357.51 643.55C351.45 649.96 340.64 651.57 333.43 646.5C327.6 642.39 324.9 634.99 324.48 627.87C324.06 620.76 325.56 613.69 326.46 606.62C327.12 601.41 327.43 596.01 326.43 590.9ZM344.53 612.97C344.47 614.16 344.6 615.4 345.19 616.45C345.77 617.49 346.88 618.3 348.07 618.27C349.36 618.24 350.48 617.24 350.97 616.05C351.47 614.87 351.44 613.52 351.25 612.25C350.99 610.47 349.92 607.94 347.72 608.06C345.42 608.18 344.63 611.12 344.53 612.97ZM336.44 601.81C337.76 603.78 339.58 605.8 341.96 605.87C344.37 605.94 346.43 603.84 347.09 601.51C347.76 599.19 347.35 596.7 346.88 594.33C346.16 590.75 344.8 584.95 340.77 583.64C336.59 582.27 333.22 587.61 332.93 591.12C332.61 594.93 334.3 598.64 336.44 601.81Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M362.46 635.44C362.41 635.25 362.36 635.07 362.32 634.88C361.42 638.1 359.82 641.11 357.51 643.55C351.45 649.96 340.64 651.57 333.43 646.5C327.6 642.39 324.9 634.99 324.48 627.87C324.06 620.76 325.56 613.69 326.46 606.62C327.12 601.41 327.43 596.01 326.43 590.9C325.8 593.48 325.35 596 324.84 597.9C322.32 607.22 321.06 619.17 316.75 627.79C313.9 638.32 311.32 650.51 317.67 659.37C322.32 665.85 330.6 668.63 338.51 669.65C346.05 670.62 354.59 669.99 359.9 664.54C363.46 660.88 364.93 655.6 364.96 650.49C364.99 645.39 363.72 640.38 362.46 635.44Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M341.96 605.87C344.37 605.94 346.43 603.84 347.09 601.51C347.76 599.19 347.35 596.7 346.88 594.33C346.16 590.75 344.8 584.95 340.77 583.64C336.59 582.27 333.22 587.61 332.93 591.12C332.61 594.93 334.3 598.64 336.44 601.81C337.76 603.78 339.58 605.8 341.96 605.87Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M344.53 612.97C344.47 614.16 344.6 615.4 345.19 616.45C345.77 617.49 346.88 618.3 348.07 618.27C349.36 618.24 350.48 617.24 350.97 616.05C351.47 614.87 351.44 613.52 351.25 612.25C350.99 610.47 349.92 607.94 347.72 608.06C345.42 608.18 344.63 611.12 344.53 612.97Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M288.79 699.67C289.18 704.07 289.09 708.51 288.52 712.88C290.05 714.16 292.07 715.19 294.06 715.19C296.05 715.18 298.03 714.46 299.55 713.18C298.81 709.65 298.44 705.73 298.59 702.13C295.61 701.2 291.87 700.2 288.79 699.67Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M278.58 618.07C279.95 612.46 282.1 606.61 287.16 605.28C293.01 603.75 298.82 609.2 302.03 613.38C307.25 620.2 308.52 628.87 310.59 636.99C313.1 646.83 315.61 656.68 318.13 666.53C317.13 670.08 315.37 673.39 312.83 676.08C306.15 683.14 294.24 684.92 286.3 679.33C279.87 674.81 276.9 666.65 276.43 658.81C275.97 650.97 277.63 643.18 278.62 635.39C279.35 629.65 279.68 623.7 278.58 618.07ZM298.53 642.39C298.47 643.7 298.61 645.07 299.25 646.22C299.89 647.37 301.11 648.26 302.43 648.23C303.85 648.19 305.08 647.09 305.63 645.78C306.17 644.48 306.14 643 305.93 641.6C305.64 639.64 304.47 636.85 302.04 636.98C299.51 637.11 298.64 640.35 298.53 642.39ZM289.61 630.09C291.07 632.27 293.08 634.48 295.69 634.56C298.36 634.64 300.62 632.32 301.35 629.76C302.09 627.2 301.64 624.46 301.12 621.85C300.33 617.91 298.82 611.51 294.39 610.07C289.78 608.57 286.07 614.44 285.74 618.32C285.39 622.51 287.26 626.6 289.61 630.09Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M318.28 667.15C318.23 666.94 318.18 666.74 318.13 666.53C317.13 670.08 315.37 673.39 312.83 676.08C306.15 683.14 294.24 684.92 286.3 679.33C279.87 674.81 276.9 666.65 276.43 658.81C275.97 650.97 277.63 643.18 278.62 635.39C279.35 629.65 279.68 623.7 278.58 618.07C277.89 620.91 277.4 623.69 276.83 625.78C274.05 636.05 272.67 649.22 267.92 658.72C264.78 670.32 261.93 683.75 268.94 693.52C274.06 700.65 283.18 703.72 291.89 704.84C300.2 705.91 309.62 705.22 315.46 699.21C319.38 695.18 321.01 689.35 321.04 683.73C321.07 678.11 319.67 672.59 318.28 667.15Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M295.69 634.56C298.36 634.64 300.62 632.32 301.35 629.76C302.09 627.2 301.64 624.46 301.12 621.85C300.33 617.91 298.82 611.51 294.39 610.07C289.78 608.57 286.07 614.44 285.74 618.32C285.39 622.51 287.26 626.6 289.61 630.09C291.07 632.27 293.08 634.48 295.69 634.56Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M298.53 642.39C298.47 643.7 298.61 645.07 299.25 646.22C299.89 647.37 301.11 648.26 302.43 648.23C303.85 648.19 305.08 647.09 305.63 645.78C306.17 644.48 306.14 643 305.93 641.6C305.64 639.64 304.47 636.85 302.04 636.98C299.51 637.11 298.64 640.35 298.53 642.39Z" />
            </g>
          </g>
          <path id="&lt;Path&gt;" class="shp20" d="M671.23 870.51L614.98 870.51C611.71 855.6 613.04 838.87 617.2 824.18C619.83 833 622.04 842.61 622.9 851.77C624.08 834.41 629.78 817.37 639.12 802.69C641.2 815.65 641.47 828.98 640 842.01C645.85 823.57 656.46 806.84 670.48 793.51C672.01 813.85 669.41 834.49 662.62 853.72C670.31 842.98 680.75 835.54 692.91 830.35C687.3 845.91 678.46 858.81 671.23 870.51Z" />
          <path id="&lt;Path&gt;" class="shp23" d="M762.01 966.5L724 966.5C719 948.67 719.78 928.76 726.5 911.5C728.81 923.93 730.33 937.03 730.46 949.67C731.23 929.88 736.27 906.26 744.65 888.33C749.95 910.1 752 932.66 750.35 955C752.54 944.56 757.03 934.94 763.46 926.43C764.72 939.11 764.16 953.94 762.01 966.5Z" />
          <path id="&lt;Path&gt;" class="shp30" d="M0 662.66C3.44 656.46 20.61 662.79 24.88 668.45C29.15 674.11 29.63 681.63 31.29 688.53C32.95 695.42 36.99 702.86 43.98 704.04C49.97 705.04 56.53 701.04 61.94 703.8C68.6 707.2 67.49 717.28 71.98 723.25C77.48 730.57 88.52 729.13 97.58 730.44C108.66 732.04 118.8 739.18 124.04 749.07C129.28 758.96 129.5 771.36 124.61 781.43C122.88 781.26 81.34 781.74 0 782.87L0 662.66Z" />
          <path id="&lt;Path&gt;" class="shp20" d="M491.96 775.02C472.74 767.57 452.81 761.93 432.54 758.19C427.47 749.12 426.35 737.5 427.34 727.16C430.97 728.89 433.83 731.74 436.18 735C438.53 738.26 440.32 741.88 442.1 745.49C443.76 731.69 448.73 719.15 455.31 706.91C456.44 719.81 456.7 733.06 455.14 745.91C461.18 728.67 471.56 713.24 485.21 701.1C486.29 718.67 481.88 736.59 472.72 751.63C477.72 742.49 486.09 735.28 494.48 729.12C493.54 741.12 489.54 752.88 482.4 762.57C490.01 752.45 500.45 744.99 512.28 740.46C509.56 748.82 504.61 757.1 498.74 763.63C503.21 762.97 507.98 763.04 512.5 762.92C507.27 768.5 499.28 772.8 491.96 775.02Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp20" d="M870.89 823L843.67 823C840.83 812.03 840.94 800.31 844.27 789.48C846.9 796.38 849.08 804.02 849.61 811.39C849.76 798.2 852.69 785.04 858.17 773.04C859.52 782.12 860.41 791.67 859.5 800.81C861.48 789.67 864.94 778.39 869.4 768C870.45 782.4 869.85 796.91 867.89 811.22C869.43 806.02 872.59 801.48 876.3 797.53C876.75 806.03 874.11 815.12 870.89 823Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp17" d="M1227.5 954.32L1186.72 951.33C1183.43 937.15 1182.29 923.55 1183.54 908.82C1189.64 916.32 1194.64 926.18 1195.82 936.2C1195.25 918.28 1195.39 900.08 1195.49 880.39C1202.95 891.83 1208.45 905.76 1209.13 919.65C1209.63 902.59 1209.18 884.35 1207.24 865.88C1219.67 886.45 1225.18 911.21 1223.24 934.72C1225.17 926.14 1228.92 918.08 1233.15 910.22C1234.83 917.52 1234.93 925.39 1233.82 932.98C1232.71 940.58 1230.39 947.88 1227.5 954.32Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M1371.74 561.1C1372.17 565.13 1372.03 569.22 1371.32 573.22C1374.2 575.46 1378.62 575.93 1381.61 573.82C1380.46 570.13 1380.43 565.96 1380.99 562.14C1378.29 561.94 1374.45 561.29 1371.74 561.1Z" />
            <path id="&lt;Path&gt;" class="shp29" d="M1361.86 528.51C1363.55 529.22 1365.54 529.54 1367.17 528.71C1368.92 527.82 1369.84 525.82 1370.17 523.88C1370.49 521.93 1370.35 519.94 1370.67 517.99C1371.17 514.84 1372.83 512.01 1374.5 509.29C1376.18 506.57 1377.94 503.83 1378.69 500.72C1379.45 497.62 1379.03 494.03 1376.8 491.76C1375.3 490.23 1372.88 489.63 1370.88 490.17C1367.86 494.7 1366.04 499.96 1363.21 504.64C1360.81 508.63 1357.68 512.13 1355.07 515.98C1354.71 516.51 1354.37 517.04 1354.05 517.59C1354.11 518.53 1354.27 519.47 1354.54 520.37C1355.62 523.99 1358.38 527.06 1361.86 528.51Z" />
            <path id="&lt;Path&gt;" class="shp28" d="M1407.27 529.21C1403.74 522.55 1399.5 516.22 1397.03 509.1C1394.83 502.74 1394.05 495.79 1390.55 490.05C1388.63 486.91 1385.54 484.13 1382.03 483.54C1384.66 485.87 1386.68 488.9 1387.68 492.27C1389.91 499.8 1387.3 507.84 1387.54 515.69C1387.84 525.75 1392.83 535.71 1390.65 545.54C1389.78 549.49 1387.62 553.29 1384.18 555.39C1380.82 557.44 1376.67 557.66 1372.75 557.42C1363.69 556.88 1354.42 553.92 1348.1 547.41C1346.37 545.62 1344.92 543.52 1343.81 541.26C1343.35 542.81 1343 544.39 1342.81 546.02C1342.19 551.56 1343.86 557.7 1348.38 560.97C1351.61 563.3 1355.76 563.85 1359.73 564.23C1372.59 565.43 1385.56 565.47 1398.4 564.14C1401.68 563.81 1405.1 563.32 1407.78 561.4C1411.11 559.02 1412.68 554.81 1413.03 550.73C1413.67 543.23 1410.79 535.87 1407.27 529.21Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M1372.75 557.42C1376.67 557.66 1380.82 557.44 1384.18 555.39C1387.62 553.29 1389.78 549.49 1390.65 545.54C1392.83 535.71 1387.84 525.75 1387.54 515.69C1387.3 507.84 1389.91 499.8 1387.68 492.27C1386.68 488.9 1384.66 485.87 1382.03 483.54C1381.29 483.41 1380.53 483.38 1379.76 483.47C1376.44 483.84 1373.69 486.27 1371.7 488.96C1371.41 489.35 1371.15 489.76 1370.88 490.17C1372.88 489.63 1375.3 490.23 1376.8 491.76C1379.03 494.03 1379.45 497.62 1378.69 500.72C1377.94 503.83 1376.18 506.57 1374.5 509.29C1372.83 512.01 1371.17 514.84 1370.67 517.99C1370.35 519.94 1370.49 521.93 1370.17 523.88C1369.84 525.82 1368.92 527.82 1367.17 528.71C1365.54 529.54 1363.55 529.22 1361.86 528.51C1358.38 527.06 1355.62 523.99 1354.54 520.37C1354.27 519.47 1354.11 518.53 1354.05 517.59C1351.67 521.59 1350.13 526.03 1348.26 530.29C1346.69 533.89 1344.93 537.48 1343.81 541.26C1344.92 543.52 1346.37 545.62 1348.1 547.41C1354.42 553.92 1363.69 556.88 1372.75 557.42Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M1325.17 589.3C1325.6 593.34 1325.46 597.43 1324.74 601.42C1327.63 603.67 1332.05 604.13 1335.03 602.03C1333.89 598.33 1333.85 594.17 1334.42 590.34C1331.71 590.15 1327.87 589.5 1325.17 589.3Z" />
            <path id="&lt;Path&gt;" class="shp29" d="M1315.29 556.72C1316.97 557.42 1318.96 557.74 1320.59 556.92C1322.35 556.03 1323.27 554.02 1323.59 552.08C1323.91 550.14 1323.78 548.14 1324.09 546.2C1324.6 543.04 1326.25 540.21 1327.93 537.49C1329.6 534.78 1331.36 532.03 1332.12 528.93C1332.88 525.83 1332.46 522.24 1330.22 519.96C1328.72 518.44 1326.31 517.84 1324.3 518.37C1321.28 522.9 1319.46 528.17 1316.64 532.84C1314.23 536.83 1311.11 540.33 1308.49 544.18C1308.14 544.71 1307.8 545.25 1307.48 545.79C1307.53 546.73 1307.7 547.67 1307.97 548.58C1309.04 552.19 1311.81 555.26 1315.29 556.72Z" />
            <path id="&lt;Path&gt;" class="shp28" d="M1360.69 557.41C1357.17 550.76 1352.92 544.42 1350.46 537.3C1348.25 530.95 1347.48 524 1343.97 518.25C1342.06 515.12 1338.96 512.33 1335.46 511.74C1338.09 514.07 1340.1 517.1 1341.1 520.48C1343.34 528 1340.73 536.05 1340.96 543.89C1341.27 553.96 1346.26 563.92 1344.08 573.75C1343.21 577.69 1341.05 581.49 1337.6 583.59C1334.24 585.64 1330.1 585.87 1326.17 585.63C1317.11 585.08 1307.85 582.12 1301.53 575.61C1299.79 573.82 1298.35 571.72 1297.24 569.46C1296.78 571.02 1296.42 572.6 1296.24 574.22C1295.61 579.76 1297.29 585.91 1301.81 589.17C1305.04 591.5 1309.19 592.06 1313.15 592.43C1326.01 593.64 1338.99 593.68 1351.83 592.35C1355.11 592.01 1358.53 591.52 1361.2 589.61C1364.53 587.22 1366.11 583.01 1366.46 578.94C1367.1 571.43 1364.22 564.07 1360.69 557.41Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M1326.17 585.63C1330.1 585.87 1334.24 585.64 1337.6 583.59C1341.05 581.49 1343.21 577.69 1344.08 573.75C1346.26 563.92 1341.27 553.96 1340.96 543.89C1340.73 536.05 1343.34 528 1341.1 520.48C1340.1 517.1 1338.09 514.07 1335.46 511.74C1334.72 511.62 1333.96 511.58 1333.19 511.67C1329.86 512.05 1327.12 514.48 1325.12 517.17C1324.83 517.56 1324.57 517.97 1324.3 518.37C1326.31 517.84 1328.72 518.44 1330.22 519.96C1332.46 522.24 1332.88 525.83 1332.12 528.93C1331.36 532.03 1329.6 534.78 1327.93 537.49C1326.25 540.21 1324.6 543.04 1324.09 546.2C1323.78 548.14 1323.91 550.14 1323.59 552.08C1323.27 554.02 1322.35 556.03 1320.59 556.92C1318.96 557.74 1316.97 557.42 1315.29 556.72C1311.81 555.26 1309.04 552.19 1307.97 548.58C1307.7 547.67 1307.53 546.73 1307.48 545.79C1305.1 549.8 1303.56 554.23 1301.69 558.5C1300.12 562.09 1298.35 565.69 1297.24 569.46C1298.35 571.72 1299.79 573.82 1301.53 575.61C1307.85 582.12 1317.11 585.08 1326.17 585.63Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M145.52 513.3C145.8 515.89 145.7 518.52 145.24 521.08C147.1 522.53 149.94 522.83 151.86 521.47C151.12 519.1 151.1 516.42 151.46 513.96C149.73 513.84 147.26 513.42 145.52 513.3Z" />
            <path id="&lt;Path&gt;" class="shp29" d="M139.17 492.35C140.25 492.81 141.53 493.01 142.58 492.48C143.71 491.91 144.3 490.62 144.51 489.37C144.71 488.12 144.63 486.84 144.83 485.59C145.15 483.56 146.22 481.74 147.29 480C148.37 478.25 149.5 476.48 149.99 474.49C150.48 472.5 150.21 470.19 148.77 468.73C147.8 467.75 146.25 467.36 144.96 467.71C143.02 470.62 141.85 474 140.04 477.01C138.49 479.57 136.48 481.82 134.8 484.3C134.57 484.64 134.36 484.98 134.15 485.33C134.19 485.94 134.29 486.54 134.46 487.12C135.16 489.44 136.93 491.42 139.17 492.35Z" />
            <path id="&lt;Path&gt;" class="shp28" d="M168.35 492.8C166.09 488.52 163.36 484.45 161.77 479.88C160.36 475.79 159.86 471.32 157.6 467.63C156.37 465.61 154.39 463.83 152.13 463.45C153.82 464.94 155.12 466.89 155.76 469.06C157.2 473.9 155.52 479.07 155.67 484.11C155.86 490.58 159.07 496.98 157.67 503.3C157.11 505.83 155.73 508.28 153.51 509.63C151.35 510.94 148.69 511.09 146.16 510.93C140.34 510.58 134.39 508.68 130.33 504.49C129.21 503.34 128.28 502 127.57 500.54C127.27 501.54 127.04 502.56 126.92 503.6C126.52 507.16 127.6 511.11 130.5 513.21C132.58 514.71 135.25 515.07 137.8 515.31C146.06 516.08 154.4 516.11 162.65 515.25C164.76 515.04 166.96 514.72 168.68 513.49C170.82 511.96 171.83 509.25 172.06 506.63C172.47 501.81 170.62 497.08 168.35 492.8Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M146.16 510.93C148.69 511.09 151.35 510.94 153.51 509.63C155.73 508.28 157.11 505.83 157.67 503.3C159.07 496.98 155.86 490.58 155.67 484.11C155.52 479.07 157.2 473.9 155.76 469.06C155.12 466.89 153.82 464.94 152.13 463.45C151.66 463.37 151.17 463.34 150.67 463.4C148.53 463.64 146.77 465.2 145.49 466.93C145.3 467.18 145.13 467.45 144.96 467.71C146.25 467.36 147.8 467.75 148.77 468.73C150.21 470.19 150.48 472.5 149.99 474.49C149.5 476.48 148.37 478.25 147.29 480C146.22 481.74 145.15 483.56 144.83 485.59C144.63 486.84 144.71 488.12 144.51 489.37C144.3 490.62 143.71 491.91 142.58 492.48C141.53 493.01 140.25 492.81 139.17 492.35C136.93 491.42 135.16 489.44 134.46 487.12C134.29 486.54 134.19 485.94 134.15 485.33C132.62 487.9 131.63 490.76 130.43 493.5C129.42 495.81 128.29 498.12 127.57 500.54C128.28 502 129.21 503.34 130.33 504.49C134.39 508.68 140.34 510.58 146.16 510.93Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M115.58 531.42C115.86 534.02 115.77 536.65 115.31 539.21C117.16 540.65 120.01 540.96 121.92 539.6C121.19 537.23 121.17 534.55 121.53 532.09C119.79 531.97 117.32 531.55 115.58 531.42Z" />
            <path id="&lt;Path&gt;" class="shp29" d="M109.23 510.48C110.32 510.93 111.59 511.14 112.64 510.61C113.77 510.04 114.36 508.75 114.57 507.5C114.78 506.25 114.69 504.97 114.89 503.72C115.22 501.69 116.28 499.87 117.36 498.12C118.43 496.38 119.56 494.61 120.05 492.62C120.54 490.63 120.27 488.32 118.83 486.86C117.87 485.88 116.32 485.49 115.03 485.83C113.09 488.75 111.92 492.13 110.1 495.14C108.56 497.7 106.55 499.95 104.87 502.42C104.64 502.76 104.42 503.11 104.21 503.46C104.25 504.06 104.36 504.67 104.53 505.25C105.22 507.57 107 509.55 109.23 510.48Z" />
            <path id="&lt;Path&gt;" class="shp28" d="M138.42 510.93C136.15 506.65 133.42 502.58 131.84 498C130.42 493.92 129.92 489.45 127.67 485.76C126.44 483.74 124.45 481.95 122.2 481.57C123.89 483.07 125.18 485.02 125.83 487.19C127.26 492.02 125.59 497.19 125.74 502.24C125.93 508.71 129.14 515.11 127.74 521.43C127.18 523.96 125.79 526.4 123.58 527.75C121.42 529.07 118.75 529.21 116.23 529.06C110.41 528.71 104.45 526.81 100.39 522.62C99.27 521.47 98.35 520.12 97.63 518.67C97.34 519.67 97.11 520.69 96.99 521.73C96.59 525.29 97.66 529.24 100.57 531.34C102.65 532.84 105.31 533.19 107.86 533.43C116.13 534.21 124.46 534.24 132.72 533.38C134.83 533.16 137.02 532.85 138.75 531.62C140.88 530.09 141.9 527.38 142.12 524.76C142.53 519.94 140.68 515.21 138.42 510.93Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M116.23 529.06C118.75 529.21 121.42 529.07 123.58 527.75C125.79 526.4 127.18 523.96 127.74 521.43C129.14 515.11 125.93 508.71 125.74 502.24C125.59 497.19 127.26 492.02 125.83 487.19C125.18 485.02 123.89 483.07 122.2 481.57C121.72 481.49 121.23 481.47 120.74 481.53C118.6 481.77 116.84 483.33 115.56 485.06C115.37 485.31 115.2 485.57 115.03 485.83C116.32 485.49 117.87 485.88 118.83 486.86C120.27 488.32 120.54 490.63 120.05 492.62C119.56 494.61 118.43 496.38 117.36 498.12C116.28 499.87 115.22 501.69 114.89 503.72C114.69 504.97 114.78 506.25 114.57 507.5C114.36 508.75 113.77 510.04 112.64 510.61C111.59 511.14 110.32 510.93 109.23 510.48C107 509.55 105.22 507.57 104.53 505.25C104.36 504.67 104.25 504.06 104.21 503.46C102.68 506.03 101.69 508.88 100.49 511.62C99.48 513.93 98.35 516.25 97.63 518.67C98.35 520.12 99.27 521.47 100.39 522.62C104.45 526.81 110.41 528.71 116.23 529.06Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M1437.16 625.15C1437.59 629.18 1437.44 633.27 1436.73 637.26C1439.61 639.51 1444.03 639.97 1447.02 637.87C1445.88 634.17 1445.84 630.01 1446.41 626.18C1443.7 625.99 1439.86 625.34 1437.16 625.15Z" />
            <path id="&lt;Path&gt;" class="shp29" d="M1427.27 592.56C1428.96 593.27 1430.95 593.58 1432.58 592.76C1434.34 591.87 1435.25 589.86 1435.58 587.92C1435.9 585.98 1435.77 583.98 1436.08 582.04C1436.59 578.89 1438.24 576.05 1439.91 573.33C1441.59 570.62 1443.35 567.87 1444.11 564.77C1444.87 561.67 1444.45 558.08 1442.21 555.8C1440.71 554.28 1438.29 553.68 1436.29 554.21C1433.27 558.74 1431.45 564.01 1428.63 568.69C1426.22 572.67 1423.1 576.17 1420.48 580.03C1420.12 580.55 1419.79 581.09 1419.46 581.63C1419.52 582.58 1419.68 583.51 1419.95 584.42C1421.03 588.03 1423.79 591.1 1427.27 592.56Z" />
            <path id="&lt;Path&gt;" class="shp28" d="M1472.68 593.25C1469.16 586.6 1464.91 580.26 1462.44 573.15C1460.24 566.79 1459.46 559.84 1455.96 554.09C1454.04 550.96 1450.95 548.18 1447.44 547.58C1450.07 549.91 1452.09 552.95 1453.09 556.32C1455.32 563.84 1452.72 571.89 1452.95 579.73C1453.25 589.8 1458.24 599.76 1456.07 609.59C1455.19 613.53 1453.04 617.33 1449.59 619.43C1446.23 621.48 1442.08 621.71 1438.16 621.47C1429.1 620.92 1419.84 617.96 1413.52 611.45C1411.78 609.66 1410.33 607.56 1409.22 605.3C1408.76 606.86 1408.41 608.44 1408.22 610.06C1407.6 615.6 1409.27 621.75 1413.79 625.01C1417.02 627.34 1421.17 627.9 1425.14 628.27C1438 629.48 1450.97 629.52 1463.82 628.19C1467.09 627.85 1470.51 627.37 1473.19 625.45C1476.52 623.07 1478.09 618.85 1478.44 614.78C1479.09 607.27 1476.21 599.91 1472.68 593.25Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M1438.16 621.47C1442.08 621.71 1446.23 621.48 1449.59 619.43C1453.04 617.33 1455.19 613.53 1456.07 609.59C1458.24 599.76 1453.25 589.8 1452.95 579.73C1452.72 571.89 1455.32 563.84 1453.09 556.32C1452.09 552.95 1450.07 549.91 1447.44 547.58C1446.7 547.46 1445.94 547.42 1445.17 547.51C1441.85 547.89 1439.1 550.32 1437.11 553.01C1436.82 553.4 1436.56 553.81 1436.29 554.21C1438.29 553.68 1440.71 554.28 1442.21 555.8C1444.45 558.08 1444.87 561.67 1444.11 564.77C1443.35 567.87 1441.59 570.62 1439.91 573.33C1438.24 576.05 1436.59 578.89 1436.08 582.04C1435.77 583.98 1435.9 585.98 1435.58 587.92C1435.25 589.86 1434.34 591.87 1432.58 592.76C1430.95 593.58 1428.96 593.27 1427.27 592.56C1423.79 591.1 1421.03 588.03 1419.95 584.42C1419.68 583.51 1419.52 582.58 1419.46 581.63C1417.08 585.64 1415.54 590.07 1413.68 594.34C1412.1 597.93 1410.34 601.53 1409.22 605.3C1410.33 607.56 1411.78 609.66 1413.52 611.45C1419.84 617.96 1429.1 620.92 1438.16 621.47Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1014.78 589.25L1004.39 590.88C1002.65 586.86 1001.98 582.38 1002.61 578.04C1004.03 580.52 1005.32 583.31 1005.96 586.09C1005.23 581.04 1005.56 575.84 1006.93 570.93C1007.99 574.32 1008.9 577.91 1009.11 581.46C1009.19 577.08 1009.84 572.57 1010.92 568.33C1012.19 573.77 1012.83 579.35 1012.93 584.93C1013.21 582.85 1014.15 580.93 1015.33 579.2C1016.01 582.42 1015.54 586.05 1014.78 589.25Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp16" d="M1047.66 615.41L1034.13 616.67C1032.21 611.35 1031.72 605.52 1032.87 599.98C1034.5 603.29 1035.94 606.99 1036.55 610.63C1036.01 604.06 1036.85 597.38 1039.02 591.16C1040.11 595.62 1040.99 600.32 1040.97 604.9C1041.44 599.28 1042.63 593.51 1044.37 588.13C1045.56 595.24 1045.94 602.49 1045.62 609.69C1046.15 607.04 1047.51 604.63 1049.17 602.49C1049.79 606.7 1048.9 611.34 1047.66 615.41Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp22" d="M920.14 661.93L902.65 660.94C901.18 654.46 900.94 648.01 902.1 641.42C904.38 645.15 906.21 649.66 906.6 654.13C906.58 646.14 907.35 638.09 908.82 629.77C911.13 635.17 912.74 641.27 912.63 647.2C913.23 641.31 913.99 633.7 914.91 624.62C918.34 633.91 919.47 644.09 918.36 653.82C919.29 650.29 921.11 647.05 923.2 644C924.12 650.02 922.52 656.63 920.14 661.93Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp22" d="M1243.64 624.61L1226.15 623.62C1224.68 617.14 1224.44 610.68 1225.6 604.1C1227.88 607.82 1229.71 612.33 1230.1 616.8C1230.08 608.82 1230.85 600.77 1232.33 592.45C1234.63 597.84 1236.24 603.94 1236.14 609.88C1236.73 603.99 1237.49 596.38 1238.41 587.3C1241.84 596.59 1242.97 606.77 1241.86 616.5C1242.8 612.96 1244.61 609.73 1246.7 606.68C1247.62 612.69 1246.03 619.31 1243.64 624.61Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp16" d="M974.53 683.51L957.4 686.9C954.04 679.49 953.59 670.46 956.35 662.87C957.66 667.95 959.09 673.22 959.96 678.23C959.1 669.15 961.66 659.88 966.74 653.04C966.37 658.97 966.36 665.01 965.88 670.84C967.01 663.69 970.18 656.83 974.46 651.64C973.04 660 972.04 668.34 971.62 676.95C972.3 673.76 974.26 671.01 976.66 668.86C976.88 673.54 975.67 678.6 974.53 683.51Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp16" d="M1133.02 657.51L1115.89 660.91C1112.53 653.49 1112.07 644.47 1114.83 636.88C1116.14 641.95 1117.57 647.22 1118.45 652.23C1117.59 643.15 1120.15 633.88 1125.23 627.04C1124.85 632.98 1124.85 639.01 1124.37 644.84C1125.5 637.7 1128.66 630.84 1132.94 625.65C1131.52 634 1130.52 642.34 1130.1 650.95C1130.78 647.76 1132.74 645.02 1135.14 642.87C1135.37 647.54 1134.15 652.6 1133.02 657.51Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1104.24 709.09L1090.14 713.08C1087.06 707.82 1085.4 701.74 1085.53 695.64C1087.91 698.83 1090.16 702.46 1091.51 706.2C1089.66 699.35 1089.24 692.11 1090.32 685.09C1092.35 689.59 1094.21 694.41 1095.08 699.27C1094.48 693.21 1094.61 686.86 1095.4 680.83C1098.05 688.13 1099.87 695.73 1100.95 703.43C1100.99 700.51 1101.96 697.7 1103.3 695.11C1104.78 699.45 1104.75 704.54 1104.24 709.09Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1397.35 651.35L1383.26 655.35C1380.17 650.08 1378.51 644 1378.65 637.9C1381.02 641.09 1383.27 644.73 1384.63 648.46C1382.77 641.61 1382.36 634.37 1383.43 627.35C1385.46 631.86 1387.32 636.67 1388.2 641.53C1387.59 635.48 1387.72 629.13 1388.51 623.09C1391.17 630.4 1392.98 638 1394.07 645.69C1394.1 642.78 1395.08 639.96 1396.42 637.37C1397.89 641.71 1397.86 646.8 1397.35 651.35Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp22" d="M1185.53 708.57L1166.39 710.66C1163.44 702.36 1163.57 692.68 1166.93 684.59C1168.15 690.15 1169.4 695.97 1169.97 701.49C1169.75 691.55 1172.97 681.65 1178.71 674.23C1178.17 680.76 1177.9 687.42 1177.01 693.82C1178.7 686.02 1182.43 678.58 1187.25 672.82C1185.52 682.15 1184.01 691.43 1182.89 700.98C1183.88 697.49 1186.21 694.56 1188.99 692.26C1188.96 697.56 1187.24 703.2 1185.53 708.57Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp22" d="M1403.58 732.7L1384.45 734.8C1381.49 726.5 1381.62 716.82 1384.99 708.73C1386.2 714.29 1387.46 720.11 1388.02 725.63C1387.8 715.69 1391.02 705.79 1396.77 698.37C1396.22 704.9 1395.95 711.56 1395.06 717.96C1396.75 710.16 1400.48 702.72 1405.3 696.96C1403.57 706.29 1402.06 715.57 1400.94 725.12C1401.94 721.63 1404.27 718.7 1407.04 716.4C1407.01 721.7 1405.29 727.34 1403.58 732.7Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1216.79 690.34L1200.93 693.95C1197.82 687.94 1196.32 681.1 1196.83 674.34C1199.28 678.01 1201.56 682.18 1202.85 686.4C1201.19 678.7 1201.15 670.64 1202.75 662.92C1204.74 668.03 1206.52 673.48 1207.21 678.92C1206.88 672.17 1207.4 665.14 1208.62 658.49C1211.14 666.74 1212.72 675.28 1213.47 683.87C1213.69 680.64 1214.92 677.58 1216.56 674.78C1217.95 679.68 1217.62 685.32 1216.79 690.34Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1300.64 648.01L1284.79 651.62C1281.67 645.61 1280.18 638.77 1280.69 632.01C1283.13 635.69 1285.42 639.85 1286.71 644.07C1285.04 636.37 1285 628.31 1286.6 620.59C1288.59 625.7 1290.38 631.15 1291.06 636.59C1290.74 629.84 1291.25 622.81 1292.48 616.16C1295 624.41 1296.58 632.95 1297.33 641.54C1297.54 638.31 1298.78 635.25 1300.42 632.45C1301.81 637.35 1301.48 642.99 1300.64 648.01Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp22" d="M853.94 737.96L833.76 738.06C831.6 731.18 830.53 724.49 830.62 717.17C833.89 720.66 836.71 725.34 837.66 730.24C836.71 721.42 836.13 712.45 835.52 702.74C839.57 708.13 842.77 714.79 843.6 721.6C843.23 713.19 842.38 704.23 840.83 695.21C847.61 704.91 851.18 716.87 851.1 728.49C851.73 724.2 853.28 720.11 855.09 716.1C857.3 723.17 856.32 731.45 853.94 737.96Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp22" d="M1171.9 593.52L1151.72 593.62C1149.56 586.74 1148.49 580.05 1148.58 572.73C1151.85 576.23 1154.67 580.91 1155.63 585.81C1154.68 576.99 1154.09 568.01 1153.49 558.31C1157.54 563.7 1160.73 570.36 1161.57 577.17C1161.2 568.75 1160.34 559.79 1158.8 550.78C1165.58 560.48 1169.15 572.44 1169.07 584.05C1169.7 579.77 1171.25 575.68 1173.05 571.66C1175.26 578.73 1174.29 587.01 1171.9 593.52Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp22" d="M1042.73 742.63L1022.55 742.73C1020.38 735.85 1019.31 729.16 1019.4 721.84C1022.67 725.33 1025.49 730.01 1026.45 734.92C1025.5 726.09 1024.91 717.12 1024.31 707.41C1028.36 712.8 1031.55 719.46 1032.39 726.27C1032.02 717.86 1031.17 708.9 1029.62 699.88C1036.4 709.58 1039.97 721.54 1039.89 733.16C1040.52 728.87 1042.07 724.78 1043.87 720.77C1046.08 727.84 1045.11 736.12 1042.73 742.63Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M628.51 737.01L610.5 737.75C608.24 729.53 609.4 720.08 613.69 712.82C614.03 718.28 614.51 723.94 614.54 729.24C615.22 719.76 619.63 710.72 626.21 704.87C624.59 710.79 623.41 716.9 621.87 722.74C624.31 715.68 628.89 709.36 634.29 705.1C631.12 713.14 628.52 721.32 626.63 729.93C627.86 726.83 630.34 724.42 633.17 722.72C632.55 727.41 630.45 732.25 628.51 737.01Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp22" d="M603.57 697.2L584.77 703.12C580.41 696.15 577.94 688.03 577.89 679.82C581.2 684.02 584.37 688.82 586.34 693.8C583.58 684.65 582.74 674.92 583.92 665.44C586.83 671.42 589.52 677.82 590.88 684.33C589.83 676.21 589.76 667.66 590.58 659.51C594.44 669.23 597.19 679.39 598.94 689.71C598.88 685.78 600.07 681.96 601.78 678.42C603.93 684.2 604.09 691.05 603.57 697.2Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp20" d="M442.16 693.12L429.5 693.61C427.99 689.56 427.07 685.69 426.67 681.28C428.91 683.11 430.94 685.81 431.72 688.77C430.79 683.42 429.87 677.97 428.54 672.02C431.59 674.95 434.12 678.9 435.03 683.12C434.32 677.88 433.05 672.31 431.03 666.79C436.41 672.2 439.63 679.69 440.07 687.12C440.28 684.36 441.02 681.66 441.87 678.97C443.75 683.41 443.52 688.87 442.16 693.12Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M578.91 654.55L563.4 654.85C561.66 648.63 561.59 641.95 563.38 635.74C564.95 639.64 566.27 643.97 566.66 648.16C566.6 640.65 568.12 633.12 571.11 626.22C571.98 631.38 572.59 636.81 572.18 642.03C573.18 635.66 575.03 629.2 577.46 623.23C578.21 631.42 578.03 639.7 577.07 647.87C577.89 644.89 579.64 642.27 581.71 639.98C582.06 644.82 580.66 650.02 578.91 654.55Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp22" d="M395.21 698.57L381.31 703.03C378.05 697.89 376.19 691.88 376.12 685.8C378.59 688.9 380.96 692.44 382.44 696.12C380.35 689.36 379.69 682.15 380.53 675.13C382.71 679.54 384.72 684.28 385.76 689.09C384.94 683.08 384.86 676.75 385.44 670.71C388.34 677.89 390.41 685.41 391.75 693.04C391.69 690.13 392.56 687.29 393.81 684.67C395.43 688.94 395.57 694.01 395.21 698.57Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp20" d="M713.26 751.19L691.82 751.52C689.45 742.91 689.39 733.68 691.88 725.1C694.04 730.51 695.85 736.5 696.36 742.3C696.31 731.91 698.46 721.5 702.63 711.99C703.81 719.12 704.62 726.63 704.02 733.84C705.44 725.05 708.03 716.12 711.42 707.87C712.42 719.21 712.13 730.65 710.75 741.94C711.91 737.83 714.34 734.22 717.22 731.06C717.67 737.75 715.71 744.94 713.26 751.19Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp20" d="M678.37 688.57L661.55 687.07C660.4 680.13 661.11 672.89 663.76 666.38C665.01 670.79 665.93 675.64 665.86 680.22C666.68 672.08 669.21 664.1 673.26 656.99C673.59 662.68 673.62 668.62 672.56 674.22C674.39 667.45 677.15 660.66 680.48 654.49C680.34 663.45 679.17 672.38 677.17 681.12C678.41 677.99 680.61 675.36 683.12 673.12C682.93 678.4 680.8 683.87 678.37 688.57Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M394.45 494.89C394.8 497.11 394.89 499.37 394.75 501.61C395.57 502.21 396.63 502.67 397.64 502.6C398.65 502.54 399.64 502.1 400.37 501.4C399.88 499.63 399.56 497.65 399.52 495.82C397.97 495.44 396.04 495.05 394.45 494.89Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M386.58 453.72C387.09 450.83 387.99 447.78 390.52 446.94C393.44 445.97 396.58 448.55 398.35 450.57C401.23 453.86 402.16 458.23 403.48 462.29C405.08 467.22 406.68 472.14 408.28 477.07C407.89 478.91 407.11 480.65 405.9 482.1C402.74 485.91 396.74 487.21 392.52 484.62C389.1 482.54 387.32 478.48 386.83 474.51C386.33 470.54 386.92 466.53 387.17 462.53C387.35 459.59 387.32 456.55 386.58 453.72ZM397.52 465.43C397.53 466.11 397.65 466.8 398.02 467.36C398.38 467.92 399.03 468.34 399.7 468.28C400.42 468.21 401.01 467.61 401.24 466.93C401.48 466.25 401.41 465.5 401.26 464.79C401.05 463.8 400.36 462.42 399.13 462.57C397.85 462.72 397.51 464.39 397.52 465.43ZM392.58 459.48C393.4 460.53 394.49 461.6 395.82 461.55C397.18 461.5 398.25 460.25 398.54 458.92C398.83 457.6 398.51 456.22 398.16 454.91C397.63 452.93 396.66 449.72 394.35 449.13C391.96 448.52 390.27 451.63 390.23 453.61C390.19 455.76 391.27 457.78 392.58 459.48Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M408.38 477.38C408.35 477.27 408.31 477.17 408.28 477.07C407.89 478.91 407.11 480.65 405.9 482.1C402.74 485.91 396.74 487.21 392.52 484.62C389.1 482.54 387.32 478.48 386.83 474.51C386.33 470.54 386.92 466.53 387.17 462.53C387.35 459.59 387.32 456.55 386.58 453.72C386.32 455.19 386.16 456.62 385.94 457.7C384.87 463.02 384.6 469.76 382.49 474.75C381.28 480.75 380.27 487.67 384.16 492.41C386.99 495.87 391.73 497.13 396.2 497.41C400.46 497.69 405.23 497.02 408 493.78C409.86 491.6 410.5 488.58 410.33 485.72C410.16 482.86 409.27 480.1 408.38 477.38Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M395.82 461.55C397.18 461.5 398.25 460.25 398.54 458.92C398.83 457.6 398.51 456.22 398.16 454.91C397.63 452.93 396.66 449.72 394.35 449.13C391.96 448.52 390.27 451.63 390.23 453.61C390.19 455.76 391.27 457.78 392.58 459.48C393.4 460.53 394.49 461.6 395.82 461.55Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M397.52 465.43C397.53 466.11 397.65 466.8 398.02 467.36C398.38 467.92 399.03 468.34 399.7 468.28C400.42 468.21 401.01 467.61 401.24 466.93C401.48 466.25 401.41 465.5 401.26 464.79C401.05 463.8 400.36 462.42 399.13 462.57C397.85 462.72 397.51 464.39 397.52 465.43Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M360.88 523.32C359.83 523.67 358.6 523.28 357.63 522.73C358.33 518.92 358.6 515.02 358.48 511.14C359.61 511.17 360.83 511.42 361.96 511.45C361.97 515.19 361.14 519.61 360.88 523.32Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M350.01 499.75C352.57 498.7 354.51 496.34 354.93 493.65C355.4 490.78 354.17 487.91 354.29 485.02C354.34 482.86 355.15 480.75 355.31 478.56C355.49 476.38 354.82 473.86 352.84 472.88C351.53 472.22 349.88 472.53 348.71 473.4C348.28 474.87 348.03 476.35 348.06 477.59C348.11 480.21 348.92 482.79 348.75 485.41C348.57 487.85 347.53 490.14 346.59 492.37C345.49 494.92 344.53 497.5 344.02 500.11C345.98 500.6 348.13 500.52 350.01 499.75Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M375.56 501.82C375.15 497.85 373.52 494.13 371.4 490.92C369.18 487.56 366.46 484.59 365 480.92C363.14 476.03 362.88 467.38 356.33 466.63C354.56 466.42 353.1 467.02 351.91 468.03C352.14 468.04 352.36 468.05 352.58 468.07C354.91 468.31 357.18 469.63 358.31 471.73C360.69 476.14 357.79 481.93 360.04 486.25C361.18 488.43 363.4 489.83 364.88 491.85C367.52 495.42 367.47 500.47 366.35 504.8C365.65 507.59 364.39 510.46 361.93 511.77C360.07 512.76 357.85 512.7 355.76 512.47C352.91 512.15 350.06 511.5 347.56 510.2C346.35 509.58 345.24 508.78 344.29 507.86C344.86 509.72 345.81 511.47 347.26 512.9C350.35 515.97 355.12 517.15 359.64 517.28C364.26 517.42 369.23 516.5 372.44 512.88C374.96 510.03 375.96 505.8 375.56 501.82Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M355.76 512.47C357.85 512.7 360.07 512.76 361.93 511.77C364.39 510.46 365.65 507.59 366.35 504.8C367.47 500.47 367.52 495.42 364.88 491.85C363.4 489.83 361.18 488.43 360.04 486.25C357.79 481.93 360.69 476.14 358.31 471.73C357.18 469.63 354.91 468.31 352.58 468.07C352.36 468.05 352.14 468.04 351.91 468.03C350.38 469.36 349.35 471.37 348.71 473.4C349.88 472.53 351.53 472.22 352.84 472.88C354.82 473.86 355.49 476.38 355.31 478.56C355.15 480.75 354.34 482.86 354.29 485.02C354.17 487.91 355.4 490.78 354.93 493.65C354.51 496.34 352.57 498.7 350.01 499.75C348.13 500.52 345.98 500.6 344.02 500.11C343.83 501.03 343.73 501.95 343.67 502.87C343.59 504.54 343.8 506.24 344.29 507.86C345.24 508.78 346.35 509.58 347.56 510.2C350.06 511.5 352.91 512.15 355.76 512.47Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M432.62 540.58C432.86 543.39 432.71 546.24 432.17 549.01C433.38 550.05 435.25 550.34 436.65 549.59C437.3 546.83 437.35 543.85 436.84 541.06C435.78 540.99 433.68 540.65 432.62 540.58Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M430.56 527.15C434.69 526.28 438 523.06 440.06 519.37C442.3 515.35 443.29 510.46 441.95 506.06C440.99 502.9 438.68 500.1 435.76 498.65C432.11 498.69 428.48 499.72 425.29 501.54C422.44 503.17 420 505.55 418.53 508.5C416.79 512.01 416.44 516.07 416.91 519.96C417.37 520.89 417.92 521.77 418.58 522.58C421.41 526.08 426.16 528.08 430.56 527.15Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M438.16 535.75C445.08 532.15 449.77 524.68 450.18 516.89C450.49 511.28 448.59 505.63 445.17 501.17C443.91 500.37 442.5 499.74 440.94 499.31C439.24 498.85 437.5 498.64 435.76 498.65C438.68 500.1 440.99 502.9 441.95 506.06C443.29 510.46 442.3 515.35 440.06 519.37C438 523.06 434.69 526.28 430.56 527.15C426.16 528.08 421.41 526.08 418.58 522.58C417.92 521.77 417.37 520.89 416.91 519.96C416.93 520.08 416.93 520.21 416.95 520.33C417.49 524.34 418.85 528.18 420.3 531.95C421.01 533.82 421.81 535.72 422.79 537.47C427.89 538.75 433.48 538.19 438.16 535.75Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M452.36 510.81C450.94 506.73 448.59 503.33 445.17 501.17C448.59 505.63 450.49 511.28 450.18 516.89C449.77 524.68 445.08 532.15 438.16 535.75C433.48 538.19 427.89 538.75 422.79 537.47C423.98 539.6 425.45 541.5 427.48 542.83C431.57 545.52 437.16 545.18 441.44 542.79C445.71 540.41 448.77 536.27 450.8 531.82C453.79 525.27 454.73 517.6 452.36 510.81Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M59.98 580.64C60.32 582.86 60.42 585.12 60.27 587.37C61.09 587.97 62.15 588.43 63.16 588.36C64.18 588.29 65.16 587.86 65.89 587.16C65.4 585.39 65.08 583.41 65.04 581.57C63.49 581.19 61.56 580.81 59.98 580.64Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M52.1 539.48C52.61 536.58 53.51 533.54 56.04 532.69C58.96 531.72 62.1 534.3 63.87 536.33C66.75 539.62 67.68 543.99 69 548.05C70.6 552.97 72.2 557.9 73.8 562.82C73.42 564.66 72.63 566.4 71.42 567.86C68.26 571.67 62.26 572.96 58.04 570.38C54.62 568.29 52.84 564.24 52.35 560.27C51.85 556.29 52.44 552.28 52.69 548.29C52.87 545.34 52.85 542.3 52.1 539.48ZM63.05 551.19C63.06 551.86 63.17 552.55 63.54 553.11C63.9 553.68 64.55 554.09 65.22 554.03C65.94 553.97 66.53 553.37 66.77 552.68C67 552 66.94 551.25 66.78 550.54C66.57 549.56 65.88 548.18 64.65 548.32C63.37 548.47 63.03 550.15 63.05 551.19ZM58.1 545.23C58.92 546.29 60.01 547.35 61.34 547.3C62.7 547.26 63.78 546 64.06 544.68C64.35 543.35 64.04 541.97 63.68 540.66C63.15 538.68 62.18 535.48 59.88 534.89C57.48 534.28 55.79 537.39 55.75 539.37C55.71 541.52 56.79 543.53 58.1 545.23Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M73.9 563.13C73.87 563.03 73.84 562.93 73.8 562.82C73.42 564.66 72.63 566.4 71.42 567.86C68.26 571.67 62.26 572.96 58.04 570.38C54.62 568.29 52.84 564.24 52.35 560.27C51.85 556.29 52.44 552.28 52.69 548.29C52.87 545.34 52.85 542.3 52.1 539.48C51.84 540.95 51.68 542.38 51.46 543.46C50.39 548.77 50.12 555.52 48.02 560.5C46.8 566.5 45.79 573.43 49.68 578.16C52.51 581.62 57.26 582.88 61.72 583.17C65.99 583.44 70.75 582.78 73.52 579.53C75.39 577.35 76.02 574.33 75.85 571.48C75.68 568.62 74.79 565.85 73.9 563.13Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M61.34 547.3C62.7 547.26 63.78 546 64.06 544.68C64.35 543.35 64.04 541.97 63.68 540.66C63.15 538.68 62.18 535.48 59.88 534.89C57.48 534.28 55.79 537.39 55.75 539.37C55.71 541.52 56.79 543.53 58.1 545.23C58.92 546.29 60.01 547.35 61.34 547.3Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M63.05 551.19C63.06 551.86 63.17 552.55 63.54 553.11C63.9 553.68 64.55 554.09 65.22 554.03C65.94 553.97 66.53 553.37 66.77 552.68C67 552 66.94 551.25 66.78 550.54C66.57 549.56 65.88 548.18 64.65 548.32C63.37 548.47 63.03 550.15 63.05 551.19Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M26.4 609.07C25.36 609.42 24.12 609.03 23.16 608.49C23.86 604.67 24.12 600.77 24 596.89C25.13 596.93 26.35 597.17 27.48 597.21C27.49 600.94 26.66 605.36 26.4 609.07Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M15.53 585.5C18.1 584.45 20.04 582.09 20.46 579.4C20.92 576.53 19.69 573.66 19.81 570.77C19.87 568.61 20.67 566.51 20.83 564.32C21.01 562.13 20.34 559.61 18.36 558.63C17.05 557.97 15.4 558.29 14.24 559.16C13.8 560.62 13.56 562.1 13.58 563.35C13.63 565.97 14.45 568.55 14.28 571.16C14.09 573.6 13.05 575.89 12.11 578.13C11.02 580.67 10.05 583.25 9.54 585.87C11.5 586.36 13.65 586.28 15.53 585.5Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M41.09 587.58C40.68 583.6 39.04 579.89 36.92 576.67C34.7 573.32 31.98 570.34 30.52 566.67C28.66 561.79 28.4 553.13 21.85 552.38C20.08 552.18 18.62 552.77 17.44 553.79C17.66 553.79 17.88 553.8 18.1 553.82C20.43 554.06 22.71 555.39 23.83 557.49C26.22 561.9 23.32 567.69 25.56 572C26.7 574.19 28.92 575.59 30.4 577.61C33.04 581.17 32.99 586.22 31.87 590.55C31.17 593.34 29.91 596.21 27.45 597.52C25.59 598.51 23.37 598.46 21.28 598.22C18.44 597.9 15.58 597.26 13.08 595.96C11.88 595.33 10.77 594.54 9.81 593.62C10.38 595.47 11.33 597.22 12.78 598.66C15.87 601.72 20.64 602.9 25.16 603.04C29.78 603.18 34.75 602.25 37.97 598.64C40.48 595.78 41.48 591.55 41.09 587.58Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M21.28 598.22C23.37 598.46 25.59 598.51 27.45 597.52C29.91 596.21 31.17 593.34 31.87 590.55C32.99 586.22 33.04 581.17 30.4 577.61C28.92 575.59 26.7 574.19 25.56 572C23.32 567.69 26.22 561.9 23.83 557.49C22.71 555.39 20.43 554.06 18.1 553.82C17.88 553.8 17.66 553.79 17.44 553.79C15.9 555.11 14.87 557.12 14.24 559.16C15.4 558.29 17.05 557.97 18.36 558.63C20.34 559.61 21.01 562.13 20.83 564.32C20.67 566.51 19.87 568.61 19.81 570.77C19.69 573.66 20.92 576.53 20.46 579.4C20.04 582.09 18.1 584.45 15.53 585.5C13.65 586.28 11.5 586.36 9.54 585.87C9.35 586.78 9.25 587.7 9.2 588.63C9.11 590.3 9.32 592 9.81 593.62C10.77 594.54 11.88 595.33 13.08 595.96C15.58 597.26 18.44 597.9 21.28 598.22Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M98.14 626.33C98.38 629.14 98.23 631.99 97.69 634.76C98.9 635.8 100.77 636.09 102.17 635.34C102.82 632.58 102.87 629.6 102.36 626.82C101.31 626.74 99.2 626.41 98.14 626.33Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M96.08 612.91C100.21 612.04 103.52 608.81 105.58 605.12C107.82 601.1 108.81 596.22 107.47 591.81C106.51 588.65 104.2 585.86 101.28 584.41C97.63 584.44 94 585.47 90.82 587.29C87.96 588.92 85.53 591.3 84.06 594.26C82.32 597.76 81.96 601.82 82.43 605.71C82.89 606.64 83.44 607.53 84.1 608.34C86.93 611.83 91.68 613.83 96.08 612.91Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M103.68 621.51C110.6 617.9 115.29 610.44 115.71 602.65C116.01 597.03 114.11 591.39 110.69 586.92C109.43 586.12 108.02 585.49 106.46 585.07C104.76 584.6 103.02 584.39 101.28 584.41C104.2 585.86 106.51 588.65 107.47 591.81C108.81 596.22 107.82 601.1 105.58 605.12C103.52 608.81 100.21 612.04 96.08 612.91C91.68 613.83 86.93 611.83 84.1 608.34C83.44 607.53 82.89 606.64 82.43 605.71C82.45 605.84 82.46 605.96 82.47 606.09C83.01 610.09 84.37 613.93 85.82 617.7C86.54 619.57 87.33 621.47 88.31 623.23C93.42 624.5 99 623.94 103.68 621.51Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M117.88 596.56C116.46 592.48 114.11 589.08 110.69 586.92C114.11 591.39 116.01 597.03 115.71 602.65C115.29 610.44 110.6 617.9 103.68 621.51C99 623.94 93.42 624.5 88.31 623.23C89.5 625.35 90.98 627.26 93 628.59C97.09 631.27 102.69 630.93 106.96 628.55C111.23 626.16 114.3 622.02 116.33 617.57C119.31 611.02 120.25 603.36 117.88 596.56Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M278.41 546.46C278.5 549.88 278.05 553.34 277 556.6C278.33 558.02 280.56 558.68 282.38 557.94C283.57 554.6 283.97 550.85 283.55 547.34C282.26 547.17 279.7 546.62 278.41 546.46Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M275.98 530.31C280.99 529.1 284.89 524.88 286.92 519.94C289.17 514.59 289.46 508.02 286.74 502.79C284.81 499.03 281.21 496.24 277.32 495.41C272.98 496.55 268.95 498.84 265.62 501.69C262.61 504.26 260.24 507.39 259.02 510.82C257.58 514.88 257.75 519.14 258.71 523.23C259.37 524.17 260.1 525.07 260.98 525.89C264.72 529.42 270.62 531.61 275.98 530.31Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M285.41 540.73C289.73 538.55 293.27 534.91 295.7 530.41C298.09 525.93 299.28 520.59 298.92 515.3C298.43 507.65 294.7 500.44 289.27 495.75C287.52 495.1 285.66 494.72 283.67 494.66C281.52 494.59 279.38 494.87 277.32 495.41C281.21 496.24 284.81 499.03 286.74 502.79C289.46 508.02 289.17 514.59 286.92 519.94C284.89 524.88 280.99 529.1 275.98 530.31C270.62 531.61 264.72 529.42 260.98 525.89C260.1 525.07 259.37 524.17 258.71 523.23L258.8 523.62C259.85 527.82 261.71 531.89 263.52 536.05C264.44 538.11 265.36 540.24 266.51 542.26C272.7 543.94 279.63 543.71 285.41 540.73Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M300.42 506.67C297.81 501.42 294 497.51 289.27 495.75C294.7 500.44 298.43 507.65 298.92 515.3C299.28 520.59 298.09 525.93 295.7 530.41C293.27 534.91 289.73 538.55 285.41 540.73C279.63 543.71 272.7 543.94 266.51 542.26C267.9 544.71 269.57 546.99 271.95 548.73C276.7 552.23 283.58 552.62 289.03 549.87C294.51 547.16 298.53 541.7 300.96 535.54C304.57 526.53 304.83 515.41 300.42 506.67Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M253.98 579.41C254.21 582.76 253.93 586.16 253.06 589.4C254.44 590.72 256.64 591.25 258.38 590.45C259.38 587.14 259.6 583.47 259.04 580.06C257.77 579.95 255.25 579.52 253.98 579.41Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M251.04 563.67C255.91 562.33 259.61 558.12 261.5 553.27C263.59 548.02 263.79 541.66 261.11 536.6C259.2 532.96 255.69 530.25 251.88 529.44C247.64 530.54 243.69 532.78 240.45 535.6C237.51 538.14 235.22 541.26 234.06 544.69C232.71 548.75 232.95 553.02 233.98 557.08C234.65 558.01 235.39 558.89 236.27 559.69C240.01 563.12 245.85 565.1 251.04 563.67Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M260.6 573.56C264.74 571.27 268.08 567.63 270.32 563.22C272.54 558.81 273.58 553.65 273.15 548.56C272.54 541.19 268.87 534.29 263.57 529.75C261.86 529.12 260.04 528.76 258.1 528.7C255.99 528.64 253.9 528.91 251.88 529.44C255.69 530.25 259.2 532.96 261.11 536.6C263.79 541.66 263.59 548.02 261.5 553.27C259.61 558.12 255.91 562.33 251.04 563.67C245.85 565.1 240.01 563.12 236.27 559.69C235.39 558.89 234.65 558.01 233.98 557.08C234.01 557.22 234.04 557.34 234.08 557.48C235.2 561.65 237.14 565.65 239.05 569.72C240.01 571.73 240.99 573.82 242.19 575.77C248.3 577.19 255.05 576.67 260.6 573.56Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M274.51 540.21C271.91 535.19 268.19 531.45 263.57 529.75C268.87 534.29 272.54 541.19 273.15 548.56C273.58 553.65 272.54 558.81 270.32 563.22C268.08 567.63 264.74 571.27 260.6 573.56C255.05 576.67 248.3 577.19 242.19 575.77C243.64 578.14 245.37 580.32 247.76 581.92C252.56 585.16 259.3 585.19 264.51 582.3C269.73 579.43 273.44 574.01 275.62 568.01C278.85 559.22 278.89 548.57 274.51 540.21Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M302.73 582.74C301.81 583.17 300.65 582.96 299.71 582.56C299.92 578.98 299.88 575.38 299.59 571.8C300.63 571.79 301.76 571.95 302.8 571.93C302.98 575.31 302.55 579.37 302.73 582.74Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M291.63 561.12C293.99 560.15 295.84 557.97 296.35 555.46C296.91 552.8 296.01 550.02 296.38 547.32C296.65 545.3 297.61 543.43 298.05 541.44C298.49 539.45 298.25 537.07 296.63 535.83C295.55 535 294.01 535 292.82 535.62C292.17 536.93 291.74 538.31 291.58 539.51C291.25 542.03 291.71 544.62 291.28 547.12C290.89 549.46 289.76 551.6 288.76 553.75C287.62 556.19 286.62 558.74 286.12 561.37C287.92 561.9 289.9 561.84 291.63 561.12Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M315.09 563.22C314.74 559.88 313.35 556.72 311.6 553.86C309.77 550.88 307.5 548.07 306.59 544.7C305.36 540.18 306.58 532.68 300.87 530.78C299.32 530.26 297.89 530.51 296.64 531.21C296.84 531.26 297.04 531.3 297.24 531.37C299.29 532.03 301.08 533.63 301.77 535.68C303.2 539.94 299.84 544.82 301.54 548.98C302.38 551.06 304.32 552.47 305.57 554.34C307.78 557.63 307.6 561.99 306.64 565.85C306.02 568.32 304.97 570.91 302.78 572.22C301.12 573.21 299.08 573.29 297.15 573.18C294.53 573.03 291.88 572.57 289.53 571.4C288.39 570.84 287.33 570.09 286.43 569.19C287.01 571.04 287.97 572.75 289.39 574.08C292.41 576.93 296.89 577.61 301.03 577.38C305.27 577.14 309.74 575.92 312.48 572.68C314.65 570.11 315.43 566.56 315.09 563.22Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M297.15 573.18C299.08 573.29 301.12 573.21 302.78 572.22C304.97 570.91 306.02 568.32 306.64 565.85C307.6 561.99 307.78 557.63 305.57 554.34C304.32 552.47 302.38 551.06 301.54 548.98C299.84 544.82 303.2 539.94 301.77 535.68C301.08 533.63 299.29 532.03 297.24 531.37C297.04 531.3 296.84 531.26 296.64 531.21C295.02 532.12 293.71 533.81 292.82 535.62C294.01 535 295.55 535 296.63 535.83C298.25 537.07 298.49 539.45 298.05 541.44C297.61 543.43 296.65 545.3 296.38 547.32C296.01 550.02 296.91 552.8 296.35 555.46C295.84 557.97 293.99 560.15 291.63 561.12C289.9 561.84 287.92 561.9 286.12 561.37C285.94 562.29 285.83 563.22 285.79 564.15C285.72 565.84 285.93 567.57 286.43 569.19C287.33 570.09 288.39 570.84 289.53 571.4C291.88 572.57 294.53 573.03 297.15 573.18Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M1190.78 439.04C1191.18 441.23 1191.34 443.47 1191.26 445.69C1192.08 446.26 1193.15 446.69 1194.15 446.59C1195.14 446.5 1196.11 446.04 1196.81 445.33C1196.28 443.59 1195.91 441.64 1195.82 439.82C1194.28 439.49 1192.35 439.16 1190.78 439.04Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M1181.85 398.51C1182.28 395.64 1183.09 392.59 1185.57 391.69C1188.43 390.65 1191.61 393.12 1193.42 395.07C1196.36 398.25 1197.4 402.55 1198.81 406.53C1200.53 411.36 1202.25 416.19 1203.98 421.03C1203.64 422.86 1202.91 424.6 1201.76 426.07C1198.73 429.93 1192.83 431.38 1188.58 428.94C1185.14 426.96 1183.27 423 1182.67 419.08C1182.07 415.17 1182.54 411.18 1182.68 407.22C1182.78 404.3 1182.67 401.29 1181.85 398.51ZM1193.01 409.81C1193.04 410.47 1193.17 411.15 1193.55 411.7C1193.92 412.25 1194.58 412.64 1195.24 412.56C1195.95 412.47 1196.52 411.87 1196.73 411.18C1196.94 410.5 1196.86 409.76 1196.69 409.06C1196.45 408.09 1195.73 406.75 1194.52 406.92C1193.26 407.11 1192.96 408.78 1193.01 409.81ZM1187.95 404.04C1188.79 405.07 1189.9 406.09 1191.22 406.01C1192.56 405.92 1193.59 404.65 1193.84 403.33C1194.09 402.01 1193.73 400.66 1193.35 399.37C1192.77 397.42 1191.72 394.28 1189.42 393.76C1187.04 393.22 1185.45 396.35 1185.46 398.31C1185.48 400.43 1186.61 402.4 1187.95 404.04Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M1204.08 421.33C1204.05 421.23 1204.01 421.13 1203.98 421.03C1203.64 422.86 1202.91 424.6 1201.76 426.07C1198.73 429.93 1192.83 431.38 1188.58 428.94C1185.14 426.96 1183.27 423 1182.67 419.08C1182.07 415.17 1182.54 411.18 1182.68 407.22C1182.78 404.3 1182.67 401.29 1181.85 398.51C1181.64 399.97 1181.52 401.39 1181.33 402.47C1180.41 407.76 1180.33 414.44 1178.39 419.43C1177.35 425.41 1176.54 432.29 1180.52 436.87C1183.42 440.22 1188.15 441.34 1192.57 441.49C1196.8 441.65 1201.5 440.86 1204.16 437.57C1205.94 435.36 1206.48 432.36 1206.24 429.53C1205.99 426.71 1205.03 424 1204.08 421.33Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M1191.22 406.01C1192.56 405.92 1193.59 404.65 1193.84 403.33C1194.09 402.01 1193.73 400.66 1193.35 399.37C1192.77 397.42 1191.72 394.28 1189.42 393.76C1187.04 393.22 1185.45 396.35 1185.46 398.31C1185.48 400.43 1186.61 402.4 1187.95 404.04C1188.79 405.07 1189.9 406.09 1191.22 406.01Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M1193.01 409.81C1193.04 410.47 1193.17 411.15 1193.55 411.7C1193.92 412.25 1194.58 412.64 1195.24 412.56C1195.95 412.47 1196.52 411.87 1196.73 411.18C1196.94 410.5 1196.86 409.76 1196.69 409.06C1196.45 408.09 1195.73 406.75 1194.52 406.92C1193.26 407.11 1192.96 408.78 1193.01 409.81Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M1168.97 460.48C1169.31 462.25 1169.45 464.05 1169.41 465.85C1170.08 466.3 1170.94 466.64 1171.75 466.56C1172.55 466.47 1173.33 466.1 1173.89 465.51C1173.44 464.11 1173.13 462.54 1173.04 461.07C1171.8 460.82 1170.24 460.57 1168.97 460.48Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M1161.43 427.82C1161.75 425.49 1162.38 423.02 1164.38 422.27C1166.69 421.41 1169.27 423.38 1170.75 424.94C1173.15 427.49 1174.02 430.95 1175.2 434.16C1176.63 438.05 1178.06 441.93 1179.48 445.82C1179.23 447.31 1178.65 448.72 1177.73 449.92C1175.32 453.06 1170.56 454.27 1167.11 452.34C1164.32 450.77 1162.77 447.59 1162.26 444.43C1161.74 441.27 1162.09 438.04 1162.17 434.84C1162.23 432.48 1162.11 430.05 1161.43 427.82ZM1170.53 436.85C1170.56 437.38 1170.68 437.93 1170.99 438.37C1171.29 438.81 1171.83 439.12 1172.36 439.05C1172.93 438.98 1173.39 438.48 1173.55 437.93C1173.72 437.38 1173.65 436.78 1173.5 436.22C1173.3 435.44 1172.71 434.35 1171.73 434.51C1170.71 434.67 1170.49 436.02 1170.53 436.85ZM1166.41 432.23C1167.09 433.05 1167.99 433.87 1169.06 433.79C1170.14 433.72 1170.96 432.68 1171.15 431.61C1171.34 430.54 1171.05 429.45 1170.73 428.41C1170.24 426.85 1169.37 424.31 1167.51 423.91C1165.58 423.5 1164.32 426.03 1164.35 427.62C1164.38 429.34 1165.31 430.91 1166.41 432.23Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M1179.57 446.07C1179.54 445.99 1179.51 445.9 1179.48 445.82C1179.23 447.31 1178.65 448.72 1177.73 449.92C1175.32 453.06 1170.56 454.27 1167.11 452.34C1164.32 450.77 1162.77 447.59 1162.26 444.43C1161.74 441.27 1162.09 438.04 1162.17 434.84C1162.23 432.48 1162.11 430.05 1161.43 427.82C1161.27 429 1161.19 430.14 1161.04 431.01C1160.34 435.3 1160.33 440.7 1158.8 444.74C1158.01 449.58 1157.41 455.14 1160.66 458.81C1163.03 461.49 1166.86 462.36 1170.44 462.45C1173.85 462.54 1177.64 461.87 1179.76 459.19C1181.19 457.39 1181.6 454.96 1181.38 452.68C1181.16 450.4 1180.36 448.22 1179.57 446.07Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M1169.06 433.79C1170.14 433.72 1170.96 432.68 1171.15 431.61C1171.34 430.54 1171.05 429.45 1170.73 428.41C1170.24 426.85 1169.37 424.31 1167.51 423.91C1165.58 423.5 1164.32 426.03 1164.35 427.62C1164.38 429.34 1165.31 430.91 1166.41 432.23C1167.09 433.05 1167.99 433.87 1169.06 433.79Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M1170.53 436.85C1170.56 437.38 1170.68 437.93 1170.99 438.37C1171.29 438.81 1171.83 439.12 1172.36 439.05C1172.93 438.98 1173.39 438.48 1173.55 437.93C1173.72 437.38 1173.65 436.78 1173.5 436.22C1173.3 435.44 1172.71 434.35 1171.73 434.51C1170.71 434.67 1170.49 436.02 1170.53 436.85Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M1206.53 486.06C1207.14 488.42 1207.42 490.86 1207.37 493.3C1208.55 494 1210.18 493.98 1211.27 493.14C1211.41 490.7 1211.03 488.16 1210.19 485.86C1209.28 485.95 1207.44 485.97 1206.53 486.06Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp31" d="M1202.84 474.93C1206.23 473.59 1208.58 470.37 1209.8 466.93C1211.12 463.19 1211.26 458.89 1209.48 455.33C1208.21 452.78 1205.84 450.74 1203.15 449.93C1200.04 450.48 1197.1 451.88 1194.65 453.89C1192.46 455.69 1190.73 458.07 1189.91 460.8C1188.93 464.03 1189.22 467.54 1190.18 470.78C1190.71 471.51 1191.3 472.18 1191.98 472.78C1194.89 475.34 1199.23 476.36 1202.84 474.93Z" />
              <path id="&lt;Path&gt;" class="shp32" d="M1210.55 481.15C1215.91 477.08 1218.82 470.05 1218.06 463.36C1217.5 458.54 1215.07 454.01 1211.51 450.71C1210.32 450.21 1209.04 449.87 1207.65 449.74C1206.13 449.59 1204.62 449.66 1203.15 449.93C1205.84 450.74 1208.21 452.78 1209.48 455.33C1211.26 458.89 1211.12 463.19 1209.8 466.93C1208.58 470.37 1206.23 473.59 1202.84 474.93C1199.23 476.36 1194.89 475.34 1191.98 472.78C1191.3 472.18 1190.71 471.51 1190.18 470.78C1190.21 470.89 1190.24 470.99 1190.27 471.09C1191.31 474.42 1193.02 477.5 1194.8 480.49C1195.68 481.98 1196.63 483.48 1197.72 484.84C1202.25 485.18 1206.92 483.9 1210.55 481.15Z" />
              <path id="&lt;Path&gt;" class="shp33" d="M1219.03 457.87C1217.23 454.6 1214.73 452.05 1211.51 450.71C1215.07 454.01 1217.5 458.54 1218.06 463.36C1218.82 470.05 1215.91 477.08 1210.55 481.15C1206.92 483.9 1202.25 485.18 1197.72 484.84C1199.04 486.47 1200.57 487.88 1202.48 488.72C1206.35 490.41 1211.06 489.31 1214.35 486.67C1217.65 484.02 1219.66 480.05 1220.74 475.97C1222.33 469.97 1222.02 463.31 1219.03 457.87Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M1230.72 499.23C1231.39 501.8 1231.7 504.46 1231.64 507.11C1232.92 507.89 1234.69 507.86 1235.88 506.94C1236.04 504.29 1235.62 501.52 1234.71 499.02C1233.72 499.11 1231.72 499.14 1230.72 499.23Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp31" d="M1226.7 487.11C1230.39 485.66 1232.96 482.15 1234.28 478.41C1235.73 474.33 1235.87 469.64 1233.93 465.77C1232.55 462.99 1229.97 460.77 1227.04 459.89C1223.66 460.49 1220.45 462.02 1217.79 464.2C1215.4 466.16 1213.52 468.75 1212.62 471.73C1211.56 475.25 1211.87 479.07 1212.92 482.6C1213.49 483.38 1214.14 484.12 1214.88 484.77C1218.05 487.56 1222.77 488.67 1226.7 487.11Z" />
              <path id="&lt;Path&gt;" class="shp32" d="M1235.1 493.89C1240.94 489.46 1244.11 481.8 1243.27 474.52C1242.67 469.26 1240.02 464.33 1236.15 460.73C1234.85 460.19 1233.45 459.83 1231.94 459.68C1230.29 459.52 1228.65 459.6 1227.04 459.89C1229.97 460.77 1232.55 462.99 1233.93 465.77C1235.87 469.64 1235.73 474.33 1234.28 478.41C1232.96 482.15 1230.39 485.66 1226.7 487.11C1222.77 488.67 1218.05 487.56 1214.88 484.77C1214.14 484.12 1213.49 483.38 1212.92 482.6C1212.95 482.71 1212.98 482.83 1213.02 482.94C1214.15 486.56 1216.01 489.91 1217.95 493.17C1218.91 494.79 1219.94 496.43 1221.13 497.9C1226.06 498.28 1231.15 496.88 1235.1 493.89Z" />
              <path id="&lt;Path&gt;" class="shp33" d="M1244.33 468.53C1242.38 464.98 1239.66 462.2 1236.15 460.73C1240.02 464.33 1242.67 469.26 1243.27 474.52C1244.11 481.8 1240.94 489.46 1235.1 493.89C1231.15 496.88 1226.06 498.28 1221.13 497.9C1222.57 499.68 1224.23 501.22 1226.31 502.13C1230.53 503.97 1235.66 502.78 1239.24 499.9C1242.83 497.01 1245.02 492.69 1246.2 488.25C1247.93 481.71 1247.6 474.46 1244.33 468.53Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M1061.27 456.54C1061.88 459.17 1062.11 461.89 1061.96 464.59C1064.05 465.84 1067.02 465.8 1068.82 464.16C1067.77 461.81 1067.41 459.05 1067.47 456.47C1065.67 456.57 1063.07 456.44 1061.27 456.54Z" />
            <path id="&lt;Path&gt;" class="shp29" d="M1052.1 435.76C1053.27 436.09 1054.61 436.14 1055.63 435.47C1056.72 434.74 1057.16 433.33 1057.22 432.02C1057.28 430.71 1057.03 429.4 1057.08 428.09C1057.16 425.96 1058.02 423.95 1058.91 422.02C1059.8 420.08 1060.75 418.12 1061 416.01C1061.25 413.9 1060.68 411.55 1059.02 410.23C1057.9 409.34 1056.26 409.14 1054.97 409.65C1053.34 412.89 1052.56 416.53 1051.07 419.85C1049.8 422.68 1048.01 425.25 1046.59 428.01C1046.4 428.39 1046.22 428.77 1046.05 429.16C1046.16 429.78 1046.34 430.39 1046.6 430.96C1047.6 433.27 1049.68 435.08 1052.1 435.76Z" />
            <path id="&lt;Path&gt;" class="shp28" d="M1082.21 432.56C1079.34 428.44 1076.02 424.59 1073.81 420.07C1071.84 416.04 1070.77 411.51 1067.98 407.99C1066.46 406.06 1064.19 404.47 1061.82 404.36C1063.75 405.69 1065.33 407.54 1066.26 409.69C1068.35 414.49 1067.27 420.03 1068.06 425.2C1069.07 431.84 1073.18 438.03 1072.53 444.71C1072.27 447.39 1071.15 450.08 1069.04 451.75C1066.98 453.38 1064.25 453.86 1061.64 454.02C1055.6 454.39 1049.22 453.18 1044.51 449.38C1043.22 448.33 1042.09 447.06 1041.18 445.65C1041 446.72 1040.89 447.8 1040.9 448.88C1040.93 452.6 1042.54 456.54 1045.79 458.33C1048.12 459.62 1050.91 459.65 1053.57 459.57C1062.17 459.34 1070.77 458.32 1079.16 456.4C1081.3 455.91 1083.53 455.31 1085.15 453.83C1087.16 451.98 1087.86 449.07 1087.76 446.34C1087.58 441.32 1085.08 436.68 1082.21 432.56Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M1061.64 454.02C1064.25 453.86 1066.98 453.38 1069.04 451.75C1071.15 450.08 1072.27 447.39 1072.53 444.71C1073.18 438.03 1069.07 431.84 1068.06 425.2C1067.27 420.03 1068.35 414.49 1066.26 409.69C1065.33 407.54 1063.75 405.69 1061.82 404.36C1061.32 404.34 1060.82 404.38 1060.31 404.5C1058.14 405.02 1056.52 406.85 1055.42 408.79C1055.26 409.07 1055.12 409.36 1054.97 409.65C1056.26 409.14 1057.9 409.34 1059.02 410.23C1060.68 411.55 1061.25 413.9 1061 416.01C1060.75 418.12 1059.8 420.08 1058.91 422.02C1058.02 423.95 1057.16 425.96 1057.08 428.09C1057.03 429.4 1057.28 430.71 1057.22 432.02C1057.16 433.33 1056.72 434.74 1055.63 435.47C1054.61 436.14 1053.27 436.09 1052.1 435.76C1049.68 435.08 1047.6 433.27 1046.6 430.96C1046.34 430.39 1046.16 429.78 1046.05 429.16C1044.79 432 1044.13 435.06 1043.24 438.04C1042.49 440.54 1041.61 443.07 1041.18 445.65C1042.09 447.06 1043.22 448.33 1044.51 449.38C1049.22 453.18 1055.6 454.39 1061.64 454.02Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M970.39 475.01C970.78 477.2 970.94 479.44 970.85 481.66C971.68 482.23 972.74 482.66 973.74 482.57C974.74 482.48 975.7 482.02 976.41 481.31C975.88 479.57 975.51 477.62 975.43 475.8C973.89 475.47 971.96 475.14 970.39 475.01Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M961.55 434.47C961.99 431.59 962.8 428.55 965.28 427.65C968.15 426.62 971.32 429.09 973.12 431.05C976.06 434.24 977.09 438.53 978.5 442.52C980.21 447.35 981.92 452.19 983.63 457.02C983.29 458.85 982.56 460.6 981.4 462.07C978.37 465.92 972.46 467.35 968.21 464.9C964.78 462.92 962.91 458.96 962.32 455.04C961.74 451.12 962.22 447.13 962.36 443.17C962.47 440.25 962.36 437.25 961.55 434.47ZM972.68 445.78C972.71 446.45 972.84 447.13 973.22 447.68C973.59 448.22 974.25 448.62 974.91 448.54C975.62 448.46 976.19 447.85 976.4 447.17C976.62 446.48 976.54 445.74 976.37 445.05C976.13 444.08 975.41 442.73 974.2 442.9C972.94 443.08 972.64 444.75 972.68 445.78ZM967.64 440.01C968.47 441.03 969.58 442.06 970.9 441.98C972.24 441.9 973.27 440.63 973.53 439.31C973.78 437.99 973.43 436.63 973.05 435.34C972.47 433.4 971.43 430.25 969.13 429.73C966.75 429.18 965.15 432.31 965.16 434.27C965.18 436.39 966.3 438.36 967.64 440.01Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M983.73 457.33C983.7 457.23 983.66 457.13 983.63 457.02C983.29 458.85 982.56 460.6 981.4 462.07C978.37 465.92 972.46 467.35 968.21 464.9C964.78 462.92 962.91 458.96 962.32 455.04C961.74 451.12 962.22 447.13 962.36 443.17C962.47 440.25 962.36 437.25 961.55 434.47C961.33 435.93 961.21 437.34 961.02 438.42C960.09 443.71 960 450.39 958.04 455.38C956.99 461.35 956.17 468.23 960.13 472.82C963.03 476.17 967.76 477.3 972.18 477.47C976.41 477.63 981.11 476.85 983.77 473.57C985.56 471.37 986.11 468.36 985.87 465.54C985.63 462.71 984.68 460 983.73 457.33Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M970.9 441.98C972.24 441.9 973.27 440.63 973.53 439.31C973.78 437.99 973.43 436.63 973.05 435.34C972.47 433.4 971.43 430.25 969.13 429.73C966.75 429.18 965.15 432.31 965.16 434.27C965.18 436.39 966.3 438.36 967.64 440.01C968.47 441.03 969.58 442.06 970.9 441.98Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M972.68 445.78C972.71 446.45 972.84 447.13 973.22 447.68C973.59 448.22 974.25 448.62 974.91 448.54C975.62 448.46 976.19 447.85 976.4 447.17C976.62 446.48 976.54 445.74 976.37 445.05C976.13 444.08 975.41 442.73 974.2 442.9C972.94 443.08 972.64 444.75 972.68 445.78Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M951.49 487.93C952.07 490.27 952.38 492.67 952.44 495.07C953.37 495.63 954.54 496.02 955.62 495.85C956.69 495.69 957.69 495.13 958.41 494.32C957.72 492.48 957.19 490.4 956.98 488.45C955.29 488.19 953.2 487.96 951.49 487.93Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M939.24 444.8C939.51 441.67 940.19 438.33 942.81 437.2C945.83 435.89 949.41 438.35 951.49 440.34C954.87 443.58 956.27 448.14 958.05 452.35C960.22 457.45 962.39 462.54 964.56 467.64C964.32 469.64 963.64 471.57 962.49 473.23C959.48 477.59 953.21 479.53 948.47 477.17C944.63 475.27 942.35 471.12 941.45 466.93C940.56 462.74 940.81 458.41 940.69 454.13C940.61 450.98 940.3 447.74 939.24 444.8ZM952 456.25C952.08 456.97 952.27 457.69 952.71 458.26C953.15 458.83 953.88 459.21 954.59 459.08C955.35 458.94 955.92 458.25 956.11 457.5C956.29 456.75 956.15 455.95 955.93 455.22C955.61 454.18 954.74 452.78 953.45 453.05C952.1 453.33 951.89 455.15 952 456.25ZM946.18 450.37C947.15 451.42 948.41 452.45 949.83 452.27C951.27 452.1 952.3 450.66 952.48 449.22C952.66 447.78 952.19 446.34 951.7 444.97C950.95 442.91 949.61 439.59 947.1 439.18C944.49 438.75 942.98 442.23 943.12 444.34C943.28 446.63 944.62 448.68 946.18 450.37Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M964.69 467.96C964.65 467.86 964.6 467.75 964.56 467.64C964.32 469.64 963.64 471.57 962.49 473.23C959.48 477.59 953.21 479.53 948.47 477.17C944.63 475.27 942.35 471.12 941.45 466.93C940.56 462.74 940.81 458.41 940.69 454.13C940.61 450.98 940.3 447.74 939.24 444.8C939.1 446.39 939.07 447.93 938.94 449.1C938.29 454.87 938.63 462.08 936.86 467.59C936.13 474.1 935.7 481.57 940.29 486.25C943.63 489.68 948.8 490.57 953.59 490.46C958.16 490.35 963.17 489.2 965.83 485.47C967.61 482.98 968 479.7 967.55 476.67C967.1 473.64 965.89 470.78 964.69 467.96Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M949.83 452.27C951.27 452.1 952.3 450.66 952.48 449.22C952.66 447.78 952.19 446.34 951.7 444.97C950.95 442.91 949.61 439.59 947.1 439.18C944.49 438.75 942.98 442.23 943.12 444.34C943.28 446.63 944.62 448.68 946.18 450.37C947.15 451.42 948.41 452.45 949.83 452.27Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M952 456.25C952.08 456.97 952.27 457.69 952.71 458.26C953.15 458.83 953.88 459.21 954.59 459.08C955.35 458.94 955.92 458.25 956.11 457.5C956.29 456.75 956.15 455.95 955.93 455.22C955.61 454.18 954.74 452.78 953.45 453.05C952.1 453.33 951.89 455.15 952 456.25Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M1456.25 470.86C1456.64 473.05 1456.8 475.29 1456.71 477.51C1457.54 478.08 1458.6 478.51 1459.6 478.42C1460.6 478.33 1461.56 477.87 1462.26 477.16C1461.73 475.42 1461.37 473.47 1461.28 471.65C1459.74 471.32 1457.82 470.99 1456.25 470.86Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M1447.41 430.32C1447.84 427.44 1448.66 424.4 1451.14 423.5C1454.01 422.47 1457.18 424.94 1458.98 426.9C1461.91 430.08 1462.94 434.38 1464.35 438.37C1466.06 443.2 1467.77 448.04 1469.48 452.87C1469.15 454.7 1468.41 456.45 1467.25 457.92C1464.22 461.77 1458.31 463.2 1454.07 460.75C1450.64 458.77 1448.77 454.8 1448.18 450.89C1447.59 446.97 1448.07 442.98 1448.22 439.02C1448.32 436.1 1448.22 433.09 1447.41 430.32ZM1458.54 441.63C1458.57 442.29 1458.7 442.98 1459.07 443.52C1459.45 444.07 1460.1 444.47 1460.76 444.39C1461.47 444.31 1462.04 443.7 1462.26 443.02C1462.47 442.33 1462.39 441.59 1462.22 440.9C1461.99 439.93 1461.27 438.58 1460.06 438.75C1458.79 438.93 1458.5 440.6 1458.54 441.63ZM1453.5 435.86C1454.33 436.88 1455.44 437.91 1456.76 437.83C1458.1 437.75 1459.13 436.48 1459.38 435.16C1459.64 433.84 1459.29 432.48 1458.91 431.19C1458.33 429.25 1457.28 426.1 1454.99 425.58C1452.6 425.03 1451.01 428.15 1451.02 430.12C1451.03 432.24 1452.16 434.21 1453.5 435.86Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M1469.59 453.18C1469.55 453.08 1469.52 452.97 1469.48 452.87C1469.15 454.7 1468.41 456.45 1467.25 457.92C1464.22 461.77 1458.31 463.2 1454.07 460.75C1450.64 458.77 1448.77 454.8 1448.18 450.89C1447.59 446.97 1448.07 442.98 1448.22 439.02C1448.32 436.1 1448.22 433.09 1447.41 430.32C1447.19 431.78 1447.07 433.19 1446.88 434.27C1445.95 439.56 1445.85 446.24 1443.9 451.23C1442.85 457.2 1442.03 464.08 1445.99 468.67C1448.89 472.02 1453.61 473.15 1458.04 473.32C1462.27 473.48 1466.96 472.7 1469.63 469.42C1471.42 467.22 1471.97 464.21 1471.73 461.39C1471.49 458.56 1470.53 455.85 1469.59 453.18Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M1456.76 437.83C1458.1 437.75 1459.13 436.48 1459.38 435.16C1459.64 433.84 1459.29 432.48 1458.91 431.19C1458.33 429.25 1457.28 426.1 1454.99 425.58C1452.6 425.03 1451.01 428.15 1451.02 430.12C1451.03 432.24 1452.16 434.21 1453.5 435.86C1454.33 436.88 1455.44 437.91 1456.76 437.83Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M1458.54 441.63C1458.57 442.29 1458.7 442.98 1459.07 443.52C1459.45 444.07 1460.1 444.47 1460.76 444.39C1461.47 444.31 1462.04 443.7 1462.26 443.02C1462.47 442.33 1462.39 441.59 1462.22 440.9C1461.99 439.93 1461.27 438.58 1460.06 438.75C1458.79 438.93 1458.5 440.6 1458.54 441.63Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M1437.35 483.78C1437.92 486.12 1438.24 488.51 1438.3 490.92C1439.22 491.48 1440.4 491.87 1441.47 491.7C1442.54 491.54 1443.55 490.98 1444.26 490.17C1443.57 488.33 1443.05 486.25 1442.83 484.29C1441.15 484.04 1439.05 483.81 1437.35 483.78Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp27" d="M1425.1 440.65C1425.37 437.52 1426.05 434.18 1428.66 433.05C1431.69 431.74 1435.27 434.19 1437.34 436.19C1440.72 439.43 1442.12 443.99 1443.91 448.2C1446.08 453.29 1448.25 458.39 1450.42 463.49C1450.17 465.49 1449.5 467.42 1448.35 469.08C1445.34 473.44 1439.06 475.38 1434.32 473.02C1430.49 471.12 1428.21 466.97 1427.31 462.78C1426.41 458.59 1426.66 454.26 1426.55 449.98C1426.47 446.83 1426.16 443.59 1425.1 440.65ZM1437.86 452.1C1437.93 452.82 1438.12 453.54 1438.56 454.11C1439.01 454.68 1439.74 455.05 1440.44 454.93C1441.2 454.79 1441.78 454.1 1441.96 453.35C1442.15 452.6 1442.01 451.8 1441.78 451.07C1441.46 450.03 1440.6 448.63 1439.3 448.9C1437.95 449.18 1437.75 451 1437.86 452.1ZM1432.03 446.22C1433 447.27 1434.27 448.3 1435.68 448.12C1437.12 447.95 1438.15 446.51 1438.33 445.07C1438.52 443.63 1438.05 442.19 1437.55 440.82C1436.8 438.76 1435.46 435.44 1432.95 435.03C1430.34 434.6 1428.83 438.08 1428.98 440.19C1429.13 442.48 1430.48 444.53 1432.03 446.22Z" />
                <path id="&lt;Path&gt;" class="shp28" d="M1450.55 463.81C1450.51 463.71 1450.46 463.6 1450.42 463.49C1450.17 465.49 1449.5 467.42 1448.35 469.08C1445.34 473.44 1439.06 475.38 1434.32 473.02C1430.49 471.12 1428.21 466.97 1427.31 462.78C1426.41 458.59 1426.66 454.26 1426.55 449.98C1426.47 446.83 1426.16 443.59 1425.1 440.65C1424.96 442.24 1424.92 443.77 1424.79 444.95C1424.14 450.72 1424.49 457.93 1422.71 463.43C1421.98 469.95 1421.56 477.42 1426.14 482.1C1429.49 485.53 1434.66 486.42 1439.45 486.31C1444.02 486.2 1449.03 485.04 1451.68 481.32C1453.46 478.83 1453.86 475.55 1453.41 472.52C1452.96 469.49 1451.75 466.63 1450.55 463.81Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M1435.68 448.12C1437.12 447.95 1438.15 446.51 1438.33 445.07C1438.52 443.63 1438.05 442.19 1437.55 440.82C1436.8 438.76 1435.46 435.44 1432.95 435.03C1430.34 434.6 1428.83 438.08 1428.98 440.19C1429.13 442.48 1430.48 444.53 1432.03 446.22C1433 447.27 1434.27 448.3 1435.68 448.12Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M1437.86 452.1C1437.93 452.82 1438.12 453.54 1438.56 454.11C1439.01 454.68 1439.74 455.05 1440.44 454.93C1441.2 454.79 1441.78 454.1 1441.96 453.35C1442.15 452.6 1442.01 451.8 1441.78 451.07C1441.46 450.03 1440.6 448.63 1439.3 448.9C1437.95 449.18 1437.75 451 1437.86 452.1Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M855.39 493.29C855.98 495.01 856.33 496.82 856.43 498.64C857.35 499.1 858.56 498.99 859.32 498.3C859.29 496.47 858.86 494.6 858.11 492.94C857.44 493.05 856.06 493.17 855.39 493.29Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M852 485.21C854.45 484.02 856.03 481.48 856.74 478.85C857.51 475.98 857.37 472.77 855.84 470.22C854.74 468.4 852.87 467.01 850.81 466.56C848.53 467.15 846.42 468.36 844.71 470C843.18 471.46 842.02 473.33 841.57 475.41C841.02 477.88 841.44 480.48 842.34 482.84C842.77 483.35 843.25 483.81 843.79 484.22C846.11 485.97 849.39 486.48 852 485.21Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M858.11 489.4C861.87 486.07 863.64 480.66 862.69 475.72C862 472.16 859.93 468.92 857.09 466.66C856.18 466.36 855.2 466.18 854.15 466.16C853.02 466.13 851.9 466.28 850.81 466.56C852.87 467.01 854.74 468.4 855.84 470.22C857.37 472.77 857.51 475.98 856.74 478.85C856.03 481.48 854.45 484.02 852 485.21C849.39 486.48 846.11 485.97 843.79 484.22C843.25 483.81 842.77 483.35 842.34 482.84C842.37 482.91 842.39 482.99 842.42 483.07C843.38 485.49 844.84 487.68 846.33 489.81C847.07 490.87 847.86 491.94 848.75 492.88C852.15 492.88 855.56 491.66 858.11 489.4Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M863.1 471.57C861.57 469.24 859.57 467.48 857.09 466.66C859.93 468.92 862 472.16 862.69 475.72C863.64 480.66 861.87 486.07 858.11 489.4C855.56 491.66 852.15 492.88 848.75 492.88C849.83 494.03 851.05 494.99 852.52 495.5C855.5 496.55 858.95 495.46 861.26 493.3C863.56 491.14 864.83 488.07 865.41 484.97C866.25 480.4 865.64 475.46 863.1 471.57Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M876.91 488.44C877.26 489.8 877.41 491.22 877.36 492.63C878.04 493.05 878.98 493.04 879.62 492.56C879.72 491.15 879.51 489.67 879.04 488.34C878.51 488.39 877.44 488.39 876.91 488.44Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M874.83 481.97C876.8 481.22 878.18 479.36 878.9 477.38C879.69 475.22 879.79 472.73 878.78 470.66C878.05 469.18 876.69 467.98 875.14 467.5C873.34 467.8 871.63 468.6 870.2 469.75C868.92 470.78 867.91 472.15 867.42 473.72C866.84 475.59 866.99 477.62 867.53 479.51C867.83 479.93 868.17 480.32 868.56 480.67C870.23 482.17 872.73 482.78 874.83 481.97Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M879.27 485.61C882.39 483.29 884.11 479.23 883.7 475.35C883.41 472.56 882.02 469.92 879.98 467.99C879.29 467.7 878.55 467.5 877.75 467.41C876.87 467.32 876 467.35 875.14 467.5C876.69 467.98 878.05 469.18 878.78 470.66C879.79 472.73 879.69 475.22 878.9 477.38C878.18 479.36 876.8 481.22 874.83 481.97C872.73 482.78 870.23 482.17 868.56 480.67C868.17 480.32 867.83 479.93 867.53 479.51C867.54 479.57 867.56 479.63 867.58 479.69C868.16 481.62 869.14 483.41 870.15 485.15C870.65 486.02 871.19 486.89 871.82 487.68C874.44 487.91 877.15 487.19 879.27 485.61Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M884.29 472.18C883.27 470.28 881.84 468.79 879.98 467.99C882.02 469.92 883.41 472.56 883.7 475.35C884.11 479.23 882.39 483.29 879.27 485.61C877.15 487.19 874.44 487.91 871.82 487.68C872.57 488.64 873.45 489.46 874.56 489.96C876.79 490.96 879.52 490.34 881.44 488.83C883.36 487.31 884.54 485.03 885.19 482.67C886.15 479.2 886 475.34 884.29 472.18Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M767.18 532.56C767.72 535.09 767.9 537.7 767.73 540.29C768.95 541.1 770.68 541.15 771.88 540.31C772.15 537.72 771.86 535 771.08 532.52C770.11 532.57 768.15 532.51 767.18 532.56Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M763.78 520.56C767.45 519.3 770.1 515.98 771.56 512.39C773.14 508.47 773.49 503.91 771.77 500.04C770.53 497.27 768.11 494.99 765.29 494C761.97 494.45 758.77 495.8 756.08 497.82C753.67 499.62 751.71 502.07 750.71 504.93C749.52 508.32 749.66 512.06 750.53 515.55C751.06 516.35 751.66 517.09 752.35 517.76C755.32 520.62 759.88 521.9 763.78 520.56Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M771.68 527.53C777.58 523.47 781 516.13 780.5 508.99C780.14 503.83 777.77 498.9 774.15 495.23C772.9 494.64 771.55 494.22 770.08 494.01C768.48 493.78 766.87 493.79 765.29 494C768.11 494.99 770.53 497.27 771.77 500.04C773.49 503.91 773.14 508.47 771.56 512.39C770.1 515.98 767.45 519.3 763.78 520.56C759.88 521.9 755.32 520.62 752.35 517.76C751.66 517.09 751.06 516.35 750.53 515.55C750.56 515.66 750.58 515.78 750.61 515.89C751.55 519.47 753.23 522.82 754.98 526.09C755.84 527.71 756.78 529.36 757.88 530.85C762.67 531.43 767.7 530.28 771.68 527.53Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M781.79 503.19C780.04 499.64 777.51 496.8 774.15 495.23C777.77 498.9 780.14 503.83 780.5 508.99C781 516.13 777.58 523.47 771.68 527.53C767.7 530.28 762.67 531.43 757.88 530.85C759.2 532.65 760.76 534.21 762.75 535.19C766.78 537.18 771.85 536.23 775.47 533.57C779.09 530.92 781.41 526.8 782.76 522.51C784.73 516.21 784.72 509.12 781.79 503.19Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M752.45 555.86C752.93 558.15 753.08 560.51 752.92 562.85C754.02 563.58 755.58 563.64 756.67 562.88C756.92 560.54 756.67 558.08 755.98 555.84C755.1 555.88 753.33 555.81 752.45 555.86Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M749.42 545C752.74 543.87 755.15 540.88 756.48 537.64C757.93 534.1 758.25 529.98 756.71 526.48C755.61 523.97 753.43 521.9 750.88 520.99C747.87 521.38 744.98 522.59 742.54 524.41C740.35 526.03 738.58 528.24 737.66 530.82C736.57 533.88 736.68 537.26 737.46 540.42C737.93 541.14 738.47 541.82 739.09 542.42C741.77 545.02 745.88 546.2 749.42 545Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M756.54 551.33C761.88 547.68 765 541.06 764.58 534.6C764.27 529.94 762.14 525.47 758.88 522.13C757.76 521.6 756.54 521.22 755.21 521.02C753.77 520.81 752.31 520.81 750.88 520.99C753.43 521.9 755.61 523.97 756.71 526.48C758.25 529.98 757.93 534.1 756.48 537.64C755.15 540.88 752.74 543.87 749.42 545C745.88 546.2 741.77 545.02 739.09 542.42C738.47 541.82 737.93 541.14 737.46 540.42C737.48 540.52 737.5 540.63 737.53 540.73C738.37 543.97 739.87 547 741.44 549.97C742.21 551.44 743.06 552.92 744.04 554.27C748.37 554.82 752.92 553.8 756.54 551.33Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M765.77 529.36C764.19 526.14 761.91 523.57 758.88 522.13C762.14 525.47 764.27 529.94 764.58 534.6C765 541.06 761.88 547.68 756.54 551.33C752.92 553.8 748.37 554.82 744.04 554.27C745.23 555.91 746.64 557.33 748.44 558.22C752.07 560.03 756.65 559.19 759.93 556.81C763.22 554.42 765.34 550.7 766.57 546.83C768.38 541.14 768.39 534.73 765.77 529.36Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M725.53 538.08C726.07 539.87 726.36 541.73 726.39 543.59C727.31 544.1 728.56 544.03 729.37 543.36C729.41 541.49 729.04 539.55 728.33 537.82C727.64 537.92 726.23 537.98 725.53 538.08Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M722.38 529.68C724.93 528.55 726.64 526.02 727.47 523.35C728.37 520.45 728.34 517.15 726.88 514.49C725.83 512.57 723.96 511.08 721.87 510.54C719.52 511.06 717.3 512.22 715.49 513.83C713.87 515.27 712.62 517.14 712.07 519.25C711.42 521.75 711.74 524.43 712.57 526.88C713 527.42 713.47 527.91 714.01 528.35C716.31 530.23 719.66 530.87 722.38 529.68Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M728.46 534.21C732.45 530.94 734.46 525.47 733.68 520.38C733.11 516.7 731.12 513.31 728.3 510.89C727.37 510.54 726.38 510.32 725.31 510.26C724.15 510.19 722.99 510.29 721.87 510.54C723.96 511.08 725.83 512.57 726.88 514.49C728.34 517.15 728.37 520.45 727.47 523.35C726.64 526.02 724.93 528.55 722.38 529.68C719.66 530.87 716.31 530.23 714.01 528.35C713.47 527.91 713 527.42 712.57 526.88C712.6 526.96 712.62 527.04 712.65 527.12C713.54 529.63 714.95 531.93 716.39 534.17C717.11 535.28 717.88 536.41 718.76 537.41C722.23 537.54 725.77 536.42 728.46 534.21Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M734.26 516.14C732.79 513.7 730.8 511.82 728.3 510.89C731.12 513.31 733.11 516.7 733.68 520.38C734.46 525.47 732.45 530.94 728.46 534.21C725.77 536.42 722.23 537.54 718.76 537.41C719.82 538.62 721.03 539.65 722.52 540.24C725.53 541.42 729.1 540.44 731.54 538.31C733.98 536.19 735.4 533.1 736.11 529.94C737.15 525.3 736.71 520.22 734.26 516.14Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M891.8 559.04C892.04 560.6 892.02 562.2 891.76 563.76C892.91 564.61 894.66 564.8 895.83 563.94C895.36 562.48 895.3 560.83 895.47 559.3C894.39 559.27 892.87 559.07 891.8 559.04Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M886.92 546.85C887.62 547.04 888.41 547.07 889.01 546.68C889.66 546.26 889.93 545.44 889.96 544.68C889.99 543.92 889.83 543.16 889.86 542.39C889.87 541.15 890.36 539.96 890.84 538.81C891.33 537.65 891.82 536.46 891.9 535.2C891.97 533.94 891.54 532.57 890.49 531.87C889.79 531.4 888.81 531.36 888.08 531.73C887.27 533.69 886.95 535.82 886.15 537.77C885.47 539.43 884.46 540.93 883.65 542.51C883.54 542.74 883.43 542.95 883.34 543.16C883.41 543.51 883.52 543.84 883.67 544.16C884.28 545.44 885.49 546.46 886.92 546.85Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M904.77 545.06C903.06 542.43 901.04 540.05 899.61 537.31C898.33 534.86 897.51 532.11 895.68 530.1C894.68 529.01 893.24 528.18 891.84 528.25C893.05 528.93 894.1 529.96 894.75 531.21C896.22 534.03 895.77 537.43 896.32 540.54C897.03 544.52 899.43 548.34 898.89 552.42C898.66 554.04 897.91 555.64 896.59 556.55C895.32 557.44 893.68 557.6 892.13 557.59C888.54 557.55 884.85 556.6 882.18 554.4C881.45 553.79 880.82 553.07 880.31 552.29C880.18 552.87 880.09 553.45 880.06 554.05C879.96 556.07 880.75 558.24 882.58 559.37C883.89 560.18 885.53 560.33 887.1 560.42C892.19 560.7 897.32 560.59 902.37 559.86C903.66 559.68 905 559.43 906.02 558.58C907.3 557.52 907.83 555.69 907.86 553.94C907.9 550.73 906.47 547.68 904.77 545.06Z" />
              <path id="&lt;Path&gt;" class="shp27" d="M892.13 557.59C893.68 557.6 895.32 557.44 896.59 556.55C897.91 555.64 898.66 554.04 898.89 552.42C899.43 548.34 897.03 544.52 896.32 540.54C895.77 537.43 896.22 534.03 894.75 531.21C894.1 529.96 893.05 528.93 891.84 528.25C891.54 528.26 891.25 528.32 890.96 528.42C889.71 528.85 888.85 530.02 888.3 531.2C888.22 531.39 888.15 531.56 888.08 531.73C888.81 531.36 889.79 531.4 890.49 531.87C891.54 532.57 891.97 533.94 891.9 535.2C891.82 536.46 891.33 537.65 890.84 538.81C890.36 539.96 889.87 541.15 889.86 542.39C889.83 543.16 889.99 543.92 889.96 544.68C889.93 545.44 889.66 546.26 889.01 546.68C888.41 547.07 887.62 547.04 886.92 546.85C885.49 546.46 884.28 545.44 883.67 544.16C883.52 543.84 883.41 543.51 883.34 543.16C882.61 544.77 882.22 546.48 881.66 548.12C881.19 549.51 880.63 550.88 880.31 552.29C880.82 553.07 881.45 553.79 882.18 554.4C884.85 556.6 888.54 557.55 892.13 557.59Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M843.32 545.42C843.73 546.79 843.95 548.23 843.95 549.67C845.1 550.27 846.68 550.15 847.58 549.22C846.95 548 846.67 546.55 846.62 545.18C845.66 545.29 844.28 545.31 843.32 545.42Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M837.78 534.68C838.41 534.82 839.12 534.8 839.64 534.41C840.2 533.99 840.39 533.23 840.37 532.53C840.36 531.83 840.19 531.14 840.17 530.44C840.14 529.31 840.54 528.22 840.95 527.16C841.36 526.1 841.79 525.03 841.86 523.9C841.92 522.77 841.55 521.54 840.62 520.89C840 520.46 839.12 520.41 838.45 520.72C837.69 522.5 837.39 524.45 836.71 526.26C836.13 527.81 835.26 529.23 834.6 530.75C834.51 530.95 834.42 531.16 834.35 531.37C834.43 531.7 834.54 532.01 834.7 532.31C835.31 533.5 836.47 534.4 837.78 534.68Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M853.66 532C852.01 529.91 850.12 527.97 848.8 525.64C847.62 523.57 846.9 521.19 845.31 519.41C844.44 518.44 843.18 517.67 841.92 517.69C842.99 518.33 843.88 519.26 844.45 520.37C845.71 522.85 845.32 525.83 845.91 528.55C846.66 532.05 849.05 535.2 848.92 538.77C848.87 540.2 848.36 541.67 847.29 542.62C846.25 543.55 844.82 543.9 843.44 544.07C840.24 544.46 836.82 544.02 834.19 542.16C833.47 541.65 832.83 541.01 832.3 540.29C832.24 540.86 832.22 541.44 832.26 542.02C832.39 543.99 833.37 546.03 835.16 546.87C836.44 547.48 837.92 547.41 839.33 547.28C843.89 546.87 848.42 546.05 852.82 544.76C853.94 544.43 855.1 544.04 855.92 543.2C856.92 542.15 857.2 540.58 857.06 539.14C856.8 536.48 855.32 534.1 853.66 532Z" />
              <path id="&lt;Path&gt;" class="shp27" d="M843.44 544.07C844.82 543.9 846.25 543.55 847.29 542.62C848.36 541.67 848.87 540.2 848.92 538.77C849.05 535.2 846.66 532.05 845.91 528.55C845.32 525.83 845.71 522.85 844.45 520.37C843.88 519.26 842.99 518.33 841.92 517.69C841.65 517.69 841.38 517.73 841.12 517.81C839.98 518.16 839.18 519.18 838.66 520.25C838.58 520.4 838.52 520.56 838.45 520.72C839.12 520.41 840 520.46 840.62 520.89C841.55 521.54 841.92 522.77 841.86 523.9C841.79 525.03 841.36 526.1 840.95 527.16C840.54 528.22 840.14 529.31 840.17 530.44C840.19 531.14 840.36 531.83 840.37 532.53C840.39 533.23 840.2 533.99 839.64 534.41C839.12 534.8 838.41 534.82 837.78 534.68C836.47 534.4 835.31 533.5 834.7 532.31C834.54 532.01 834.43 531.7 834.35 531.37C833.77 532.92 833.52 534.57 833.15 536.18C832.83 537.53 832.45 538.9 832.3 540.29C832.83 541.01 833.47 541.65 834.19 542.16C836.82 544.02 840.24 544.46 843.44 544.07Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M867.59 572.2C867.7 574.23 867.47 576.27 866.94 578.23C868.3 579.47 870.52 579.94 872.12 579C871.71 577.06 871.86 574.94 872.26 573C870.89 572.82 868.96 572.38 867.59 572.2Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M862.84 556.03C863.7 556.35 864.72 556.49 865.54 556.06C866.42 555.6 866.85 554.59 866.99 553.61C867.11 552.64 866.99 551.65 867.11 550.67C867.28 549.09 868.04 547.62 868.78 546.19C869.53 544.76 870.29 543.3 870.54 541.68C870.76 540.08 870.36 538.27 869.09 537.26C868.24 536.58 866.98 536.43 866 536.82C864.75 539.26 864.12 541.94 862.87 544.35C861.82 546.39 860.36 548.2 859.13 550.12C858.97 550.38 858.81 550.65 858.66 550.91C858.72 551.36 858.82 551.8 858.98 552.23C859.61 553.93 861.04 555.36 862.84 556.03Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M885.93 555.83C884.04 552.23 881.72 548.93 880.19 545.24C878.82 541.95 878.07 538.32 875.92 535.54C874.75 534.02 872.99 532.81 871.19 532.75C872.67 533.75 873.91 535.18 874.61 536.87C876.2 540.65 875.25 544.97 875.61 549.02C876.07 554.22 878.69 559.42 877.5 564.6C877.02 566.66 875.86 568.61 874.05 569.62C872.3 570.6 870.19 570.6 868.2 570.39C863.61 569.89 859 568.21 855.86 565.08C855 564.22 854.26 563.23 853.72 562.18C853.47 562.9 853.28 563.63 853.18 564.38C852.81 566.93 853.55 569.79 855.74 571.46C857.32 572.65 859.41 573.05 861.41 573.36C867.89 574.37 874.46 574.89 881.03 574.61C882.71 574.53 884.45 574.39 885.87 573.42C887.64 572.22 888.56 569.92 888.8 567.67C889.26 563.52 887.8 559.41 885.93 555.83Z" />
              <path id="&lt;Path&gt;" class="shp27" d="M868.2 570.39C870.19 570.6 872.3 570.6 874.05 569.62C875.86 568.61 877.02 566.66 877.5 564.6C878.69 559.42 876.07 554.22 875.61 549.02C875.25 544.97 876.2 540.65 874.61 536.87C873.91 535.18 872.67 533.75 871.19 532.75C870.81 532.74 870.42 532.78 870.04 532.88C868.39 533.31 867.17 534.72 866.35 536.18C866.23 536.4 866.12 536.61 866 536.82C866.98 536.43 868.24 536.58 869.09 537.26C870.36 538.27 870.76 540.08 870.54 541.68C870.29 543.3 869.53 544.76 868.78 546.19C868.04 547.62 867.28 549.09 867.11 550.67C866.99 551.65 867.11 552.64 866.99 553.61C866.85 554.59 866.42 555.6 865.54 556.06C864.72 556.49 863.7 556.35 862.84 556.03C861.04 555.36 859.61 553.93 858.98 552.23C858.82 551.8 858.72 551.36 858.66 550.91C857.55 552.89 856.85 555.01 855.94 557.04C855.18 558.74 854.29 560.43 853.72 562.18C854.26 563.23 855 564.22 855.86 565.08C859 568.21 863.61 569.89 868.2 570.39Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M1346.49 457.37C1346.73 458.93 1346.72 460.53 1346.45 462.09C1347.61 462.94 1349.36 463.13 1350.52 462.27C1350.05 460.81 1350 459.16 1350.16 457.63C1349.09 457.6 1347.56 457.4 1346.49 457.37Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M1341.62 445.18C1342.31 445.37 1343.11 445.4 1343.71 445.01C1344.36 444.59 1344.62 443.77 1344.66 443.01C1344.69 442.25 1344.53 441.49 1344.55 440.72C1344.57 439.48 1345.06 438.29 1345.54 437.14C1346.02 435.98 1346.52 434.79 1346.6 433.53C1346.67 432.27 1346.24 430.9 1345.19 430.2C1344.49 429.73 1343.51 429.69 1342.77 430.06C1341.96 432.02 1341.64 434.15 1340.84 436.1C1340.16 437.76 1339.15 439.26 1338.34 440.84C1338.23 441.07 1338.13 441.28 1338.03 441.49C1338.1 441.84 1338.21 442.17 1338.36 442.49C1338.97 443.77 1340.18 444.79 1341.62 445.18Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M1359.47 443.39C1357.76 440.76 1355.74 438.38 1354.31 435.64C1353.03 433.19 1352.21 430.44 1350.37 428.43C1349.37 427.34 1347.93 426.51 1346.53 426.58C1347.74 427.26 1348.79 428.29 1349.44 429.54C1350.91 432.36 1350.46 435.76 1351.01 438.87C1351.73 442.85 1354.12 446.67 1353.58 450.75C1353.36 452.37 1352.61 453.97 1351.29 454.88C1350.01 455.77 1348.38 455.93 1346.83 455.92C1343.23 455.88 1339.54 454.93 1336.88 452.73C1336.15 452.12 1335.51 451.4 1335.01 450.62C1334.87 451.2 1334.78 451.78 1334.76 452.38C1334.66 454.4 1335.45 456.57 1337.27 457.7C1338.58 458.51 1340.23 458.66 1341.8 458.75C1346.89 459.03 1352.01 458.92 1357.06 458.19C1358.36 458.01 1359.7 457.76 1360.72 456.91C1361.99 455.85 1362.53 454.02 1362.55 452.27C1362.6 449.06 1361.17 446.01 1359.47 443.39Z" />
              <path id="&lt;Path&gt;" class="shp27" d="M1346.83 455.92C1348.38 455.93 1350.01 455.77 1351.29 454.88C1352.61 453.97 1353.36 452.37 1353.58 450.75C1354.12 446.67 1351.73 442.85 1351.01 438.87C1350.46 435.76 1350.91 432.36 1349.44 429.54C1348.79 428.29 1347.74 427.26 1346.53 426.58C1346.24 426.59 1345.94 426.65 1345.65 426.75C1344.4 427.18 1343.55 428.35 1343 429.53C1342.92 429.72 1342.85 429.89 1342.77 430.06C1343.51 429.69 1344.49 429.73 1345.19 430.2C1346.24 430.9 1346.67 432.27 1346.6 433.53C1346.52 434.79 1346.02 435.98 1345.54 437.14C1345.06 438.29 1344.57 439.48 1344.55 440.72C1344.53 441.49 1344.69 442.25 1344.66 443.01C1344.62 443.77 1344.36 444.59 1343.71 445.01C1343.11 445.4 1342.31 445.37 1341.62 445.18C1340.18 444.79 1338.97 443.77 1338.36 442.49C1338.21 442.17 1338.1 441.84 1338.03 441.49C1337.31 443.1 1336.92 444.81 1336.36 446.45C1335.89 447.84 1335.33 449.21 1335.01 450.62C1335.51 451.4 1336.15 452.12 1336.88 452.73C1339.54 454.93 1343.23 455.88 1346.83 455.92Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M1298.02 443.75C1298.43 445.12 1298.64 446.56 1298.65 448C1299.8 448.6 1301.37 448.48 1302.28 447.55C1301.64 446.33 1301.36 444.88 1301.31 443.51C1300.36 443.62 1298.97 443.64 1298.02 443.75Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M1292.47 433.01C1293.1 433.15 1293.82 433.13 1294.33 432.74C1294.89 432.32 1295.08 431.56 1295.07 430.86C1295.06 430.16 1294.88 429.47 1294.87 428.77C1294.84 427.64 1295.23 426.55 1295.64 425.49C1296.05 424.43 1296.49 423.36 1296.55 422.23C1296.62 421.1 1296.24 419.87 1295.31 419.22C1294.69 418.79 1293.81 418.74 1293.15 419.05C1292.39 420.83 1292.09 422.78 1291.41 424.59C1290.82 426.14 1289.96 427.56 1289.29 429.08C1289.2 429.28 1289.12 429.49 1289.04 429.7C1289.12 430.03 1289.24 430.34 1289.39 430.64C1290 431.83 1291.16 432.73 1292.47 433.01Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M1308.36 430.33C1306.7 428.24 1304.81 426.3 1303.49 423.97C1302.31 421.9 1301.6 419.52 1300 417.74C1299.13 416.77 1297.87 416 1296.61 416.02C1297.68 416.66 1298.58 417.59 1299.14 418.7C1300.41 421.18 1300.02 424.16 1300.6 426.88C1301.36 430.38 1303.74 433.53 1303.61 437.1C1303.56 438.53 1303.06 440 1301.99 440.95C1300.95 441.88 1299.52 442.23 1298.13 442.4C1294.94 442.79 1291.51 442.35 1288.89 440.49C1288.17 439.98 1287.53 439.34 1286.99 438.62C1286.93 439.19 1286.91 439.77 1286.95 440.35C1287.09 442.32 1288.07 444.36 1289.86 445.2C1291.13 445.81 1292.62 445.74 1294.03 445.61C1298.59 445.2 1303.12 444.38 1307.52 443.09C1308.64 442.76 1309.8 442.37 1310.61 441.53C1311.62 440.48 1311.9 438.91 1311.76 437.47C1311.5 434.81 1310.02 432.43 1308.36 430.33Z" />
              <path id="&lt;Path&gt;" class="shp27" d="M1298.13 442.4C1299.52 442.23 1300.95 441.88 1301.99 440.95C1303.06 440 1303.56 438.53 1303.61 437.1C1303.74 433.53 1301.36 430.38 1300.6 426.88C1300.02 424.16 1300.41 421.18 1299.14 418.7C1298.58 417.59 1297.68 416.66 1296.61 416.02C1296.35 416.02 1296.08 416.06 1295.82 416.14C1294.68 416.49 1293.88 417.51 1293.36 418.58C1293.28 418.73 1293.21 418.89 1293.15 419.05C1293.81 418.74 1294.69 418.79 1295.31 419.22C1296.24 419.87 1296.62 421.1 1296.55 422.23C1296.49 423.36 1296.05 424.43 1295.64 425.49C1295.23 426.55 1294.84 427.64 1294.87 428.77C1294.88 429.47 1295.06 430.16 1295.07 430.86C1295.08 431.56 1294.89 432.32 1294.33 432.74C1293.82 433.13 1293.1 433.15 1292.47 433.01C1291.16 432.73 1290 431.83 1289.39 430.64C1289.24 430.34 1289.12 430.03 1289.04 429.7C1288.47 431.25 1288.22 432.9 1287.84 434.51C1287.52 435.86 1287.14 437.23 1286.99 438.62C1287.53 439.34 1288.17 439.98 1288.89 440.49C1291.51 442.35 1294.94 442.79 1298.13 442.4Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M1322.29 470.53C1322.4 472.56 1322.17 474.6 1321.63 476.56C1323 477.8 1325.21 478.27 1326.82 477.33C1326.41 475.39 1326.55 473.27 1326.95 471.33C1325.59 471.15 1323.66 470.71 1322.29 470.53Z" />
              <path id="&lt;Path&gt;" class="shp29" d="M1317.53 454.36C1318.4 454.68 1319.41 454.82 1320.23 454.39C1321.11 453.93 1321.55 452.92 1321.68 451.94C1321.81 450.97 1321.69 449.98 1321.81 449C1321.97 447.42 1322.73 445.95 1323.47 444.52C1324.23 443.09 1324.99 441.63 1325.23 440.01C1325.46 438.41 1325.06 436.6 1323.78 435.59C1322.93 434.91 1321.68 434.76 1320.7 435.15C1319.45 437.59 1318.81 440.27 1317.57 442.68C1316.52 444.72 1315.05 446.53 1313.83 448.45C1313.66 448.71 1313.51 448.98 1313.36 449.24C1313.41 449.69 1313.51 450.13 1313.67 450.56C1314.31 452.26 1315.74 453.69 1317.53 454.36Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M1340.62 454.16C1338.74 450.56 1336.42 447.26 1334.89 443.57C1333.52 440.28 1332.76 436.65 1330.62 433.87C1329.45 432.35 1327.69 431.14 1325.88 431.08C1327.36 432.08 1328.6 433.51 1329.31 435.2C1330.9 438.98 1329.95 443.3 1330.3 447.35C1330.77 452.55 1333.39 457.75 1332.2 462.93C1331.71 464.99 1330.55 466.94 1328.75 467.95C1327 468.93 1324.89 468.93 1322.9 468.72C1318.3 468.22 1313.69 466.54 1310.55 463.41C1309.69 462.55 1308.96 461.56 1308.41 460.51C1308.17 461.23 1307.98 461.96 1307.88 462.71C1307.5 465.26 1308.24 468.12 1310.43 469.79C1312.01 470.98 1314.1 471.38 1316.1 471.69C1322.58 472.7 1329.16 473.22 1335.72 472.94C1337.4 472.86 1339.15 472.72 1340.57 471.75C1342.34 470.55 1343.25 468.25 1343.5 466C1343.96 461.85 1342.5 457.74 1340.62 454.16Z" />
              <path id="&lt;Path&gt;" class="shp27" d="M1322.9 468.72C1324.89 468.93 1327 468.93 1328.75 467.95C1330.55 466.94 1331.71 464.99 1332.2 462.93C1333.39 457.75 1330.77 452.55 1330.3 447.35C1329.95 443.3 1330.9 438.98 1329.31 435.2C1328.6 433.51 1327.36 432.08 1325.88 431.08C1325.5 431.07 1325.12 431.11 1324.74 431.21C1323.09 431.64 1321.87 433.05 1321.04 434.51C1320.92 434.73 1320.81 434.94 1320.7 435.15C1321.68 434.76 1322.93 434.91 1323.78 435.59C1325.06 436.6 1325.46 438.41 1325.23 440.01C1324.99 441.63 1324.23 443.09 1323.47 444.52C1322.73 445.95 1321.97 447.42 1321.81 449C1321.69 449.98 1321.81 450.97 1321.68 451.94C1321.55 452.92 1321.11 453.93 1320.23 454.39C1319.41 454.82 1318.4 454.68 1317.53 454.36C1315.74 453.69 1314.31 452.26 1313.67 450.56C1313.51 450.13 1313.41 449.69 1313.36 449.24C1312.25 451.22 1311.55 453.34 1310.64 455.37C1309.87 457.07 1308.99 458.76 1308.41 460.51C1308.96 461.56 1309.69 462.55 1310.55 463.41C1313.69 466.54 1318.3 468.22 1322.9 468.72Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M301.4 394.67C300.82 394.85 300.14 394.62 299.62 394.3C300.06 392.21 300.27 390.07 300.28 387.93C300.91 387.97 301.57 388.13 302.19 388.18C302.12 390.22 301.59 392.64 301.4 394.67Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M295.89 381.44C297.33 380.92 298.46 379.68 298.77 378.2C299.11 376.63 298.53 375.01 298.69 373.42C298.79 372.23 299.31 371.1 299.48 369.9C299.66 368.71 299.39 367.3 298.35 366.68C297.65 366.26 296.73 366.37 296.06 366.8C295.76 367.59 295.57 368.4 295.53 369.09C295.46 370.54 295.82 372 295.63 373.45C295.45 374.79 294.81 376.03 294.22 377.24C293.54 378.62 292.94 380.04 292.59 381.48C293.65 381.81 294.84 381.82 295.89 381.44Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M309.88 383.23C309.76 381.06 308.96 379.01 307.9 377.2C306.78 375.31 305.38 373.6 304.71 371.55C303.86 368.81 304.08 364.09 300.52 363.39C299.56 363.2 298.73 363.47 298.04 363.97L298.4 364.02C299.67 364.25 300.86 365.08 301.39 366.27C302.52 368.78 300.71 371.85 301.81 374.29C302.36 375.53 303.53 376.37 304.29 377.51C305.63 379.54 305.46 382.29 304.75 384.63C304.3 386.13 303.54 387.68 302.17 388.35C301.13 388.86 299.9 388.78 298.76 388.61C297.2 388.38 295.65 387.97 294.3 387.2C293.65 386.82 293.05 386.35 292.55 385.82C292.82 386.87 293.31 387.87 294.08 388.69C295.72 390.46 298.32 391.19 300.81 391.33C303.34 391.49 306.1 391.07 307.93 389.16C309.36 387.66 310 385.39 309.88 383.23Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M298.76 388.61C299.9 388.78 301.13 388.86 302.17 388.35C303.54 387.68 304.3 386.13 304.75 384.63C305.46 382.29 305.63 379.54 304.29 377.51C303.53 376.37 302.36 375.53 301.81 374.29C300.71 371.85 302.52 368.78 301.39 366.27C300.86 365.08 299.67 364.25 298.4 364.02L298.04 363.97C297.14 364.63 296.49 365.7 296.06 366.8C296.73 366.37 297.65 366.26 298.35 366.68C299.39 367.3 299.66 368.71 299.48 369.9C299.31 371.1 298.79 372.23 298.69 373.42C298.53 375.01 299.11 376.63 298.77 378.2C298.46 379.68 297.33 380.92 295.89 381.44C294.84 381.82 293.65 381.81 292.59 381.48C292.46 381.99 292.38 382.5 292.33 383.02C292.24 383.95 292.32 384.9 292.55 385.82C293.05 386.35 293.65 386.82 294.3 387.2C295.65 387.97 297.2 388.38 298.76 388.61Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M321.31 402.92C320.9 403.09 320.39 402.98 319.99 402.79C320.14 401.22 320.18 399.65 320.12 398.08C320.57 398.09 321.06 398.18 321.52 398.19C321.54 399.67 321.28 401.44 321.31 402.92Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M316.81 393.27C317.86 392.88 318.71 391.96 318.97 390.87C319.26 389.72 318.92 388.48 319.12 387.31C319.27 386.43 319.73 385.63 319.95 384.77C320.18 383.9 320.11 382.86 319.43 382.29C318.97 381.91 318.29 381.88 317.76 382.13C317.46 382.7 317.24 383.29 317.16 383.81C316.97 384.91 317.12 386.05 316.9 387.14C316.68 388.16 316.15 389.07 315.68 389.99C315.14 391.04 314.66 392.14 314.4 393.29C315.18 393.55 316.04 393.55 316.81 393.27Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M327.04 394.58C326.94 393.12 326.39 391.71 325.67 390.43C324.92 389.09 323.98 387.83 323.63 386.34C323.17 384.34 323.83 381.08 321.37 380.15C320.7 379.9 320.07 379.98 319.51 380.27C319.59 380.29 319.68 380.31 319.77 380.35C320.66 380.67 321.41 381.4 321.68 382.31C322.23 384.2 320.68 386.27 321.35 388.12C321.69 389.05 322.51 389.7 323.03 390.54C323.94 392.02 323.78 393.92 323.3 395.59C322.99 396.66 322.48 397.78 321.51 398.31C320.76 398.72 319.87 398.72 319.03 398.64C317.88 398.53 316.73 398.29 315.72 397.73C315.23 397.47 314.78 397.12 314.4 396.71C314.62 397.53 315.02 398.3 315.61 398.9C316.89 400.2 318.84 400.57 320.65 400.54C322.51 400.51 324.49 400.05 325.74 398.68C326.73 397.59 327.14 396.05 327.04 394.58Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M319.03 398.64C319.87 398.72 320.76 398.72 321.51 398.31C322.48 397.78 322.99 396.66 323.3 395.59C323.78 393.92 323.94 392.02 323.03 390.54C322.51 389.7 321.69 389.05 321.35 388.12C320.68 386.27 322.23 384.2 321.68 382.31C321.41 381.4 320.66 380.67 319.77 380.35C319.68 380.31 319.59 380.29 319.51 380.27C318.78 380.64 318.18 381.35 317.76 382.13C318.29 381.88 318.97 381.91 319.43 382.29C320.11 382.86 320.18 383.9 319.95 384.77C319.73 385.63 319.27 386.43 319.12 387.31C318.92 388.48 319.26 389.72 318.97 390.87C318.71 391.96 317.86 392.88 316.81 393.27C316.04 393.55 315.18 393.55 314.4 393.29C314.3 393.68 314.24 394.09 314.2 394.5C314.14 395.24 314.21 396 314.4 396.71C314.78 397.12 315.23 397.47 315.72 397.73C316.73 398.29 317.88 398.53 319.03 398.64Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M339.82 395.26C339.43 395.42 338.94 395.3 338.56 395.11C338.74 393.62 338.81 392.11 338.77 390.61C339.2 390.63 339.67 390.72 340.11 390.74C340.1 392.16 339.83 393.84 339.82 395.26Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M335.69 385.95C336.7 385.6 337.52 384.73 337.8 383.7C338.09 382.59 337.79 381.41 338 380.29C338.16 379.45 338.61 378.69 338.84 377.87C339.07 377.05 339.03 376.05 338.38 375.49C337.95 375.12 337.3 375.08 336.79 375.31C336.49 375.84 336.27 376.41 336.18 376.91C335.98 377.95 336.11 379.05 335.87 380.09C335.65 381.06 335.13 381.92 334.66 382.8C334.13 383.79 333.65 384.84 333.38 385.92C334.12 386.19 334.95 386.21 335.69 385.95Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M345.45 387.39C345.39 385.98 344.88 384.63 344.22 383.38C343.52 382.09 342.64 380.87 342.34 379.43C341.93 377.52 342.62 374.41 340.27 373.47C339.64 373.22 339.03 373.29 338.49 373.55C338.58 373.58 338.66 373.6 338.74 373.64C339.58 373.96 340.29 374.67 340.53 375.55C341.03 377.36 339.51 379.32 340.12 381.11C340.43 382 341.21 382.63 341.68 383.44C342.53 384.88 342.35 386.69 341.86 388.28C341.54 389.3 341.04 390.36 340.09 390.86C339.38 391.23 338.52 391.22 337.71 391.13C336.62 391 335.52 390.75 334.57 390.2C334.1 389.94 333.68 389.6 333.32 389.2C333.52 389.99 333.88 390.73 334.44 391.32C335.64 392.58 337.5 392.98 339.24 392.98C341.02 392.98 342.92 392.57 344.14 391.28C345.11 390.26 345.52 388.79 345.45 387.39Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M337.71 391.13C338.52 391.22 339.38 391.23 340.09 390.86C341.04 390.36 341.54 389.3 341.86 388.28C342.35 386.69 342.53 384.88 341.68 383.44C341.21 382.63 340.43 382 340.12 381.11C339.51 379.32 341.03 377.36 340.53 375.55C340.29 374.67 339.58 373.96 338.74 373.64C338.66 373.6 338.58 373.58 338.49 373.55C337.79 373.9 337.2 374.57 336.79 375.31C337.3 375.08 337.95 375.12 338.38 375.49C339.03 376.05 339.07 377.05 338.84 377.87C338.61 378.69 338.16 379.45 338 380.29C337.79 381.41 338.09 382.59 337.8 383.7C337.52 384.73 336.7 385.6 335.69 385.95C334.95 386.21 334.12 386.19 333.38 385.92C333.28 386.31 333.21 386.69 333.17 387.08C333.1 387.79 333.15 388.52 333.32 389.2C333.68 389.6 334.1 389.94 334.57 390.2C335.52 390.75 336.62 391 337.71 391.13Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M373.22 411.22C372.78 411.42 372.22 411.32 371.78 411.12C371.89 409.42 371.89 407.71 371.76 406C372.26 406 372.79 406.08 373.29 406.07C373.36 407.68 373.14 409.61 373.22 411.22Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M368.01 400.89C369.14 400.43 370.02 399.4 370.28 398.21C370.55 396.94 370.14 395.62 370.32 394.33C370.45 393.37 370.92 392.49 371.13 391.54C371.35 390.6 371.25 389.46 370.48 388.87C369.97 388.47 369.23 388.46 368.66 388.75C368.35 389.37 368.14 390.03 368.06 390.6C367.9 391.8 368.1 393.03 367.89 394.22C367.7 395.33 367.15 396.35 366.67 397.37C366.12 398.52 365.63 399.73 365.38 400.98C366.24 401.24 367.18 401.22 368.01 400.89Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M379.17 401.97C379.01 400.38 378.37 398.87 377.54 397.5C376.68 396.07 375.61 394.73 375.19 393.12C374.62 390.97 375.23 387.4 372.52 386.48C371.78 386.22 371.1 386.33 370.5 386.67C370.6 386.69 370.69 386.71 370.78 386.74C371.76 387.07 372.6 387.83 372.93 388.81C373.59 390.85 371.97 393.15 372.77 395.14C373.16 396.13 374.08 396.81 374.67 397.71C375.71 399.28 375.61 401.36 375.14 403.19C374.83 404.36 374.33 405.59 373.28 406.21C372.49 406.67 371.51 406.71 370.59 406.65C369.35 406.57 368.09 406.34 366.97 405.77C366.43 405.5 365.93 405.14 365.51 404.71C365.78 405.59 366.23 406.41 366.9 407.05C368.32 408.41 370.45 408.76 372.43 408.66C374.45 408.56 376.58 408 377.89 406.46C378.94 405.25 379.32 403.56 379.17 401.97Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M370.59 406.65C371.51 406.71 372.49 406.67 373.28 406.21C374.33 405.59 374.83 404.36 375.14 403.19C375.61 401.36 375.71 399.28 374.67 397.71C374.08 396.81 373.16 396.13 372.77 395.14C371.97 393.15 373.59 390.85 372.93 388.81C372.6 387.83 371.76 387.07 370.78 386.74C370.69 386.71 370.6 386.69 370.5 386.67C369.73 387.09 369.1 387.89 368.66 388.75C369.23 388.46 369.97 388.47 370.48 388.87C371.25 389.46 371.35 390.6 371.13 391.54C370.92 392.49 370.45 393.37 370.32 394.33C370.14 395.62 370.55 396.94 370.28 398.21C370.02 399.4 369.14 400.43 368.01 400.89C367.18 401.22 366.24 401.24 365.38 400.98C365.3 401.42 365.24 401.86 365.22 402.31C365.18 403.11 365.27 403.94 365.51 404.71C365.93 405.14 366.43 405.5 366.97 405.77C368.09 406.34 369.35 406.57 370.59 406.65Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M401.03 408.01C400.56 408.13 400.03 407.93 399.63 407.66C400.04 406.01 400.34 404.32 400.52 402.62C401 402.7 401.52 402.88 402 402.96C401.79 404.56 401.24 406.42 401.03 408.01Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M397.73 396.93C398.92 396.68 399.97 395.82 400.43 394.7C400.92 393.5 400.75 392.12 401.15 390.89C401.46 389.97 402.07 389.18 402.45 388.29C402.82 387.39 402.92 386.26 402.28 385.54C401.84 385.06 401.12 384.92 400.51 385.11C400.09 385.66 399.77 386.27 399.59 386.82C399.22 387.97 399.2 389.22 398.79 390.35C398.4 391.41 397.68 392.31 397.03 393.23C396.28 394.27 395.59 395.38 395.13 396.56C395.92 396.97 396.85 397.11 397.73 396.93Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M408.51 399.96C408.64 398.37 408.27 396.77 407.7 395.28C407.11 393.72 406.29 392.21 406.16 390.55C405.98 388.34 407.2 384.93 404.7 383.55C404.02 383.17 403.33 383.16 402.68 383.38C402.77 383.42 402.86 383.46 402.95 383.5C403.85 383.99 404.55 384.9 404.69 385.92C404.99 388.04 402.99 390.02 403.42 392.11C403.63 393.16 404.42 393.99 404.84 394.97C405.58 396.71 405.12 398.73 404.33 400.45C403.83 401.55 403.11 402.67 401.97 403.09C401.11 403.41 400.15 403.27 399.25 403.05C398.04 402.75 396.84 402.31 395.84 401.55C395.36 401.19 394.93 400.75 394.59 400.25C394.7 401.17 395 402.05 395.54 402.8C396.71 404.39 398.74 405.1 400.7 405.36C402.7 405.61 404.9 405.43 406.47 404.16C407.71 403.15 408.38 401.55 408.51 399.96Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M399.25 403.05C400.15 403.27 401.11 403.41 401.97 403.09C403.11 402.67 403.83 401.55 404.33 400.45C405.12 398.73 405.58 396.71 404.84 394.97C404.42 393.99 403.63 393.16 403.42 392.11C402.99 390.02 404.99 388.04 404.69 385.92C404.55 384.9 403.85 383.99 402.95 383.5C402.86 383.46 402.77 383.42 402.68 383.38C401.85 383.66 401.08 384.34 400.51 385.11C401.12 384.92 401.84 385.06 402.28 385.54C402.92 386.26 402.82 387.39 402.45 388.29C402.07 389.18 401.46 389.97 401.15 390.89C400.75 392.12 400.92 393.5 400.43 394.7C399.97 395.82 398.92 396.68 397.73 396.93C396.85 397.11 395.92 396.97 395.13 396.56C394.96 396.98 394.83 397.4 394.73 397.84C394.55 398.62 394.5 399.45 394.59 400.25C394.93 400.75 395.36 401.19 395.84 401.55C396.84 402.31 398.04 402.75 399.25 403.05Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M455.48 465.16C454.74 465.49 453.81 465.3 453.07 464.97C453.3 462.11 453.34 459.23 453.17 456.36C454 456.37 454.9 456.52 455.73 456.52C455.81 459.22 455.4 462.46 455.48 465.16Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M447 447.68C448.91 446.94 450.42 445.23 450.88 443.24C451.37 441.12 450.71 438.88 451.05 436.73C451.3 435.12 452.1 433.64 452.49 432.06C452.87 430.48 452.73 428.57 451.46 427.55C450.61 426.87 449.38 426.84 448.41 427.31C447.87 428.35 447.5 429.44 447.35 430.4C447.05 432.41 447.36 434.48 446.98 436.48C446.62 438.34 445.68 440.03 444.84 441.73C443.88 443.66 443.04 445.68 442.59 447.77C444.02 448.23 445.6 448.22 447 447.68Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M465.71 449.78C465.5 447.11 464.45 444.55 463.1 442.23C461.69 439.82 459.92 437.53 459.26 434.82C458.36 431.18 459.47 425.21 454.94 423.59C453.71 423.14 452.56 423.31 451.55 423.85C451.71 423.89 451.87 423.94 452.02 423.99C453.66 424.56 455.05 425.87 455.57 427.52C456.63 430.96 453.86 434.79 455.14 438.15C455.78 439.83 457.31 440.99 458.27 442.5C459.97 445.18 459.75 448.66 458.91 451.73C458.37 453.69 457.49 455.74 455.71 456.75C454.37 457.51 452.74 457.54 451.19 457.42C449.1 457.25 446.99 456.84 445.13 455.86C444.23 455.39 443.4 454.77 442.7 454.03C443.13 455.52 443.86 456.91 444.97 458C447.34 460.32 450.9 460.96 454.22 460.85C457.61 460.73 461.21 459.84 463.46 457.3C465.24 455.28 465.93 452.46 465.71 449.78Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M451.19 457.42C452.74 457.54 454.37 457.51 455.71 456.75C457.49 455.74 458.37 453.69 458.91 451.73C459.75 448.66 459.97 445.18 458.27 442.5C457.31 440.99 455.78 439.83 455.14 438.15C453.86 434.79 456.63 430.96 455.57 427.52C455.05 425.87 453.66 424.56 452.02 423.99C451.87 423.94 451.71 423.89 451.55 423.85C450.24 424.55 449.16 425.88 448.41 427.31C449.38 426.84 450.61 426.87 451.46 427.55C452.73 428.57 452.87 430.48 452.49 432.06C452.1 433.64 451.3 435.12 451.05 436.73C450.71 438.88 451.37 441.12 450.88 443.24C450.42 445.23 448.91 446.94 447 447.68C445.6 448.22 444.02 448.23 442.59 447.77C442.43 448.5 442.32 449.24 442.27 449.99C442.18 451.34 442.32 452.73 442.7 454.03C443.4 454.77 444.23 455.39 445.13 455.86C446.99 456.84 449.1 457.25 451.19 457.42Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M482.85 456.57C482.42 456.76 481.89 456.65 481.47 456.46C481.6 454.82 481.62 453.18 481.53 451.54C482 451.54 482.51 451.63 482.99 451.63C483.04 453.18 482.8 455.03 482.85 456.57Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M478 446.58C479.09 446.16 479.95 445.18 480.22 444.04C480.5 442.83 480.12 441.55 480.31 440.32C480.46 439.4 480.91 438.56 481.14 437.65C481.36 436.75 481.28 435.66 480.55 435.07C480.06 434.69 479.36 434.67 478.81 434.94C478.5 435.53 478.29 436.16 478.2 436.7C478.03 437.85 478.21 439.04 477.99 440.18C477.78 441.24 477.24 442.21 476.76 443.18C476.22 444.28 475.74 445.43 475.48 446.63C476.3 446.89 477.2 446.89 478 446.58Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M488.69 447.78C488.57 446.25 487.97 444.79 487.2 443.47C486.39 442.09 485.39 440.78 485 439.23C484.49 437.15 485.12 433.74 482.54 432.81C481.83 432.56 481.18 432.66 480.6 432.96C480.69 432.99 480.78 433.01 480.87 433.04C481.8 433.37 482.6 434.12 482.89 435.06C483.5 437.02 481.92 439.21 482.65 441.13C483.02 442.09 483.89 442.76 484.44 443.62C485.41 445.15 485.28 447.14 484.81 448.89C484.5 450.01 483.99 451.19 482.98 451.76C482.21 452.2 481.28 452.21 480.4 452.14C479.2 452.05 477.99 451.81 476.93 451.25C476.42 450.98 475.94 450.63 475.54 450.21C475.79 451.06 476.21 451.85 476.84 452.48C478.19 453.8 480.23 454.16 482.13 454.1C484.06 454.04 486.12 453.53 487.41 452.07C488.42 450.92 488.82 449.31 488.69 447.78Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M480.4 452.14C481.28 452.21 482.21 452.2 482.98 451.76C483.99 451.19 484.5 450.01 484.81 448.89C485.28 447.14 485.41 445.15 484.44 443.62C483.89 442.76 483.02 442.09 482.65 441.13C481.92 439.21 483.5 437.02 482.89 435.06C482.6 434.12 481.8 433.37 480.87 433.04C480.78 433.01 480.69 432.99 480.6 432.96C479.85 433.36 479.23 434.12 478.81 434.94C479.36 434.67 480.06 434.69 480.55 435.07C481.28 435.66 481.36 436.75 481.14 437.65C480.91 438.56 480.46 439.4 480.31 440.32C480.12 441.55 480.5 442.83 480.22 444.04C479.95 445.18 479.09 446.16 478 446.58C477.2 446.89 476.3 446.89 475.48 446.63C475.39 447.05 475.33 447.47 475.3 447.9C475.25 448.67 475.33 449.46 475.54 450.21C475.94 450.63 476.42 450.98 476.93 451.25C477.99 451.81 479.2 452.05 480.4 452.14Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M511.39 446.54C511 446.74 510.49 446.66 510.08 446.49C510.14 444.94 510.09 443.39 509.94 441.84C510.39 441.83 510.87 441.89 511.32 441.87C511.43 443.33 511.28 445.09 511.39 446.54Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M506.4 437.3C507.41 436.86 508.19 435.9 508.39 434.81C508.6 433.65 508.19 432.46 508.32 431.29C508.42 430.41 508.82 429.6 508.99 428.74C509.16 427.87 509.04 426.84 508.33 426.32C507.86 425.98 507.19 425.99 506.68 426.27C506.41 426.84 506.24 427.44 506.18 427.96C506.06 429.05 506.28 430.17 506.12 431.25C505.97 432.27 505.5 433.2 505.09 434.14C504.62 435.2 504.21 436.31 504.02 437.46C504.8 437.67 505.66 437.63 506.4 437.3Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M516.55 437.99C516.37 436.55 515.75 435.2 514.96 433.98C514.15 432.71 513.14 431.52 512.71 430.07C512.14 428.12 512.6 424.87 510.12 424.1C509.44 423.89 508.83 424.01 508.29 424.33C508.38 424.35 508.46 424.36 508.55 424.39C509.45 424.66 510.23 425.33 510.55 426.21C511.2 428.04 509.8 430.18 510.57 431.96C510.95 432.85 511.81 433.44 512.36 434.24C513.35 435.65 513.31 437.53 512.93 439.21C512.68 440.28 512.25 441.41 511.32 441.99C510.61 442.44 509.73 442.49 508.89 442.46C507.76 442.42 506.61 442.25 505.58 441.76C505.09 441.53 504.62 441.21 504.23 440.83C504.49 441.63 504.92 442.36 505.55 442.92C506.88 444.12 508.82 444.38 510.61 444.24C512.44 444.1 514.36 443.53 515.51 442.1C516.43 440.98 516.74 439.44 516.55 437.99Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M508.89 442.46C509.73 442.49 510.61 442.44 511.32 441.99C512.25 441.41 512.68 440.28 512.93 439.21C513.31 437.53 513.35 435.65 512.36 434.24C511.81 433.44 510.95 432.85 510.57 431.96C509.8 430.18 511.2 428.04 510.55 426.21C510.23 425.33 509.45 424.66 508.55 424.39C508.46 424.36 508.38 424.35 508.29 424.33C507.6 424.74 507.05 425.48 506.68 426.27C507.19 425.99 507.86 425.98 508.33 426.32C509.04 426.84 509.16 427.87 508.99 428.74C508.82 429.6 508.42 430.41 508.32 431.29C508.19 432.46 508.6 433.65 508.39 434.81C508.19 435.9 507.41 436.86 506.4 437.3C505.66 437.63 504.8 437.67 504.02 437.46C503.95 437.86 503.91 438.26 503.9 438.66C503.88 439.39 503.99 440.14 504.23 440.83C504.62 441.21 505.09 441.53 505.58 441.76C506.61 442.25 507.76 442.42 508.89 442.46Z" />
                </g>
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M498.73 463.62C498.98 465.23 499.01 466.88 498.81 468.51C499.55 469.06 500.64 469.15 501.43 468.66C501.69 467.04 501.6 465.32 501.19 463.73C500.58 463.73 499.35 463.62 498.73 463.62Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M497 455.94C499.35 455.27 501.14 453.28 502.18 451.06C503.31 448.65 503.68 445.78 502.73 443.29C502.05 441.5 500.6 439.98 498.86 439.26C496.75 439.43 494.69 440.17 492.92 441.35C491.34 442.41 490.03 443.88 489.3 445.65C488.43 447.75 488.39 450.11 488.82 452.33C489.13 452.85 489.48 453.34 489.89 453.79C491.67 455.69 494.49 456.66 497 455.94Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M501.74 460.6C505.59 458.24 508 453.74 507.93 449.22C507.87 445.96 506.55 442.78 504.39 440.34C503.63 439.93 502.79 439.62 501.87 439.43C500.88 439.23 499.86 439.18 498.86 439.26C500.6 439.98 502.05 441.5 502.73 443.29C503.68 445.78 503.31 448.65 502.18 451.06C501.14 453.28 499.35 455.27 497 455.94C494.49 456.66 491.67 455.69 489.89 453.79C489.48 453.34 489.13 452.85 488.82 452.33C488.84 452.41 488.85 452.48 488.86 452.55C489.33 454.84 490.27 457.01 491.26 459.13C491.75 460.18 492.29 461.25 492.93 462.22C495.93 462.75 499.13 462.2 501.74 460.6Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M508.94 445.62C507.95 443.32 506.46 441.45 504.39 440.34C506.55 442.78 507.87 445.96 507.93 449.22C508 453.74 505.59 458.24 501.74 460.6C499.13 462.2 495.93 462.75 492.93 462.22C493.7 463.4 494.63 464.44 495.85 465.13C498.32 466.51 501.54 466.09 503.92 464.54C506.29 462.99 507.89 460.47 508.89 457.82C510.35 453.92 510.58 449.45 508.94 445.62Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M471.82 474.18C472.01 475.4 472.02 476.65 471.87 477.88C472.42 478.3 473.25 478.37 473.84 478C474.04 476.78 473.98 475.48 473.68 474.27C473.22 474.27 472.29 474.18 471.82 474.18Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M470.55 468.37C472.33 467.87 473.69 466.37 474.48 464.7C475.35 462.88 475.64 460.71 474.93 458.82C474.42 457.47 473.34 456.31 472.02 455.76C470.43 455.88 468.86 456.43 467.53 457.32C466.32 458.11 465.33 459.22 464.77 460.55C464.1 462.13 464.06 463.92 464.38 465.6C464.61 466 464.87 466.37 465.18 466.71C466.51 468.15 468.65 468.9 470.55 468.37Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M474.11 471.91C477.03 470.14 478.87 466.75 478.84 463.33C478.81 460.87 477.83 458.45 476.2 456.6C475.63 456.28 475 456.05 474.3 455.9C473.55 455.75 472.78 455.71 472.02 455.76C473.34 456.31 474.42 457.47 474.93 458.82C475.64 460.71 475.35 462.88 474.48 464.7C473.69 466.37 472.33 467.87 470.55 468.37C468.65 468.9 466.51 468.15 465.18 466.71C464.87 466.37 464.61 466 464.38 465.6C464.39 465.66 464.39 465.71 464.41 465.77C464.75 467.5 465.46 469.14 466.19 470.75C466.56 471.55 466.96 472.36 467.44 473.1C469.71 473.51 472.13 473.11 474.11 471.91Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M479.62 460.61C478.88 458.87 477.76 457.44 476.2 456.6C477.83 458.45 478.81 460.87 478.84 463.33C478.87 466.75 477.03 470.14 474.11 471.91C472.13 473.11 469.71 473.51 467.44 473.1C468.02 473.99 468.72 474.78 469.64 475.31C471.5 476.37 473.94 476.06 475.74 474.9C477.54 473.74 478.77 471.84 479.53 469.84C480.65 466.89 480.84 463.51 479.62 460.61Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M387.08 422.78C387.28 424.12 387.3 425.49 387.13 426.84C387.74 427.31 388.64 427.39 389.3 426.98C389.52 425.64 389.46 424.2 389.12 422.88C388.61 422.87 387.59 422.78 387.08 422.78Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M385.67 416.38C387.63 415.84 389.12 414.18 390 412.35C390.95 410.34 391.27 407.96 390.49 405.88C389.93 404.4 388.74 403.12 387.29 402.52C385.54 402.65 383.82 403.25 382.34 404.23C381.02 405.1 379.93 406.32 379.31 407.79C378.58 409.53 378.54 411.49 378.89 413.35C379.14 413.78 379.43 414.19 379.77 414.56C381.24 416.15 383.58 416.97 385.67 416.38Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M389.59 420.28C392.81 418.34 394.83 414.6 394.79 410.84C394.76 408.13 393.67 405.47 391.89 403.43C391.26 403.09 390.56 402.83 389.8 402.67C388.97 402.5 388.12 402.46 387.29 402.52C388.74 403.12 389.93 404.4 390.49 405.88C391.27 407.96 390.95 410.34 390 412.35C389.12 414.18 387.63 415.84 385.67 416.38C383.58 416.97 381.24 416.15 379.77 414.56C379.43 414.19 379.14 413.78 378.89 413.35C378.9 413.41 378.9 413.47 378.92 413.53C379.3 415.43 380.07 417.24 380.89 419.01C381.29 419.88 381.73 420.77 382.26 421.59C384.75 422.04 387.42 421.6 389.59 420.28Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M395.65 407.85C394.84 405.93 393.6 404.37 391.89 403.43C393.67 405.47 394.76 408.13 394.79 410.84C394.83 414.6 392.81 418.34 389.59 420.28C387.42 421.6 384.75 422.04 382.26 421.59C382.89 422.57 383.66 423.44 384.68 424.02C386.73 425.18 389.41 424.85 391.39 423.57C393.37 422.29 394.71 420.2 395.55 418C396.78 414.76 397 411.04 395.65 407.85Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M349.09 448.06C349.36 449.62 349.41 451.22 349.25 452.79C349.97 453.31 351.02 453.38 351.77 452.9C352 451.33 351.89 449.66 351.47 448.13C350.88 448.14 349.69 448.05 349.09 448.06Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M347.3 440.67C349.57 439.99 351.26 438.03 352.23 435.88C353.29 433.52 353.61 430.75 352.65 428.36C351.97 426.64 350.55 425.19 348.85 424.52C346.82 424.71 344.84 425.46 343.15 426.63C341.63 427.67 340.39 429.12 339.71 430.84C338.9 432.87 338.9 435.16 339.35 437.31C339.65 437.8 340 438.27 340.4 438.69C342.15 440.51 344.89 441.4 347.3 440.67Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M351.95 445.11C355.64 442.77 357.9 438.38 357.77 434.01C357.67 430.86 356.34 427.8 354.22 425.48C353.48 425.09 352.66 424.8 351.77 424.64C350.8 424.46 349.82 424.43 348.85 424.52C350.55 425.19 351.97 426.64 352.65 428.36C353.61 430.75 353.29 433.52 352.23 435.88C351.26 438.03 349.57 439.99 347.3 440.67C344.89 441.4 342.15 440.51 340.4 438.69C340 438.27 339.65 437.8 339.35 437.31C339.36 437.37 339.37 437.44 339.39 437.51C339.88 439.72 340.82 441.8 341.81 443.83C342.3 444.84 342.83 445.87 343.46 446.8C346.37 447.27 349.46 446.69 351.95 445.11Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M358.69 430.51C357.7 428.31 356.23 426.52 354.22 425.48C356.34 427.8 357.67 430.86 357.77 434.01C357.9 438.38 355.64 442.77 351.95 445.11C349.46 446.69 346.37 447.27 343.46 446.8C344.23 447.93 345.14 448.92 346.33 449.56C348.74 450.87 351.85 450.41 354.12 448.88C356.39 447.34 357.9 444.89 358.82 442.31C360.18 438.51 360.34 434.19 358.69 430.51Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M319.39 429.43C319.68 430.81 319.78 432.22 319.68 433.62C320.34 434.06 321.28 434.09 321.93 433.64C322.08 432.24 321.93 430.76 321.51 429.41C320.98 429.44 319.92 429.4 319.39 429.43Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M317.55 422.92C319.54 422.24 320.99 420.45 321.78 418.5C322.64 416.37 322.83 413.9 321.9 411.8C321.24 410.3 319.93 409.06 318.4 408.52C316.59 408.76 314.86 409.49 313.4 410.58C312.09 411.56 311.03 412.88 310.48 414.44C309.83 416.27 309.91 418.3 310.37 420.19C310.66 420.63 310.98 421.03 311.36 421.39C312.97 422.95 315.44 423.65 317.55 422.92Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M321.83 426.71C325.03 424.51 326.9 420.53 326.63 416.66C326.44 413.87 325.16 411.19 323.2 409.19C322.53 408.88 321.79 408.65 320.99 408.53C320.13 408.41 319.25 408.41 318.4 408.52C319.93 409.06 321.24 410.3 321.9 411.8C322.83 413.9 322.64 416.37 321.78 418.5C320.99 420.45 319.54 422.24 317.55 422.92C315.44 423.65 312.97 422.95 311.36 421.39C310.98 421.03 310.66 420.63 310.37 420.19C310.39 420.26 310.4 420.32 310.42 420.38C310.93 422.32 311.83 424.14 312.78 425.91C313.24 426.79 313.75 427.69 314.34 428.49C316.94 428.81 319.67 428.2 321.83 426.71Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M327.34 413.52C326.39 411.59 325.02 410.05 323.2 409.19C325.16 411.19 326.44 413.87 326.63 416.66C326.9 420.53 325.03 424.51 321.83 426.71C319.67 428.2 316.94 428.81 314.34 428.49C315.06 429.47 315.9 430.32 316.99 430.86C319.17 431.93 321.91 431.43 323.88 429.99C325.85 428.55 327.11 426.32 327.84 424C328.92 420.58 328.92 416.73 327.34 413.52Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M283.19 393.34C283.34 394.67 283.31 396.01 283.1 397.33C283.69 397.8 284.57 397.91 285.22 397.53C285.48 396.22 285.47 394.82 285.19 393.51C284.69 393.49 283.69 393.36 283.19 393.34Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M282.01 387.04C283.95 386.56 285.47 385 286.38 383.22C287.38 381.29 287.77 378.97 287.08 376.91C286.58 375.44 285.45 374.15 284.05 373.51C282.33 373.58 280.63 374.12 279.15 375.03C277.83 375.84 276.71 377 276.07 378.42C275.3 380.09 275.19 382.02 275.47 383.84C275.7 384.28 275.97 384.69 276.29 385.06C277.68 386.67 279.95 387.54 282.01 387.04Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M285.73 390.98C288.94 389.18 291.04 385.59 291.12 381.91C291.18 379.25 290.2 376.62 288.52 374.56C287.92 374.2 287.24 373.92 286.5 373.75C285.69 373.55 284.87 373.48 284.05 373.51C285.45 374.15 286.58 375.44 287.08 376.91C287.77 378.97 287.38 381.29 286.38 383.22C285.47 385 283.95 386.56 282.01 387.04C279.95 387.54 277.68 386.67 276.29 385.06C275.97 384.69 275.7 384.28 275.47 383.84C275.48 383.9 275.48 383.96 275.49 384.02C275.81 385.9 276.51 387.69 277.24 389.45C277.61 390.32 278.01 391.21 278.5 392.02C280.93 392.55 283.56 392.2 285.73 390.98Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M292.06 379C291.33 377.1 290.17 375.53 288.52 374.56C290.2 376.62 291.18 379.25 291.12 381.91C291.04 385.59 288.94 389.18 285.73 390.98C283.56 392.2 280.93 392.55 278.5 392.02C279.1 393.01 279.82 393.88 280.79 394.48C282.76 395.69 285.4 395.44 287.38 394.26C289.36 393.07 290.74 391.07 291.64 388.94C292.95 385.8 293.28 382.17 292.06 379Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M601.47 488.1C601.69 489.87 601.66 491.67 601.39 493.44C602.18 494.07 603.36 494.2 604.23 493.7C604.58 491.94 604.54 490.05 604.15 488.3C603.48 488.28 602.14 488.12 601.47 488.1Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M599.84 479.66C602.44 479.01 604.45 476.89 605.67 474.51C606.99 471.91 607.49 468.8 606.54 466.05C605.86 464.07 604.33 462.36 602.45 461.52C600.15 461.63 597.87 462.36 595.9 463.59C594.14 464.69 592.65 466.25 591.8 468.16C590.78 470.41 590.65 472.99 591.04 475.44C591.36 476.01 591.73 476.56 592.16 477.06C594.03 479.2 597.08 480.35 599.84 479.66Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M604.86 484.91C609.14 482.47 611.93 477.64 612.01 472.7C612.06 469.14 610.73 465.62 608.46 462.88C607.64 462.41 606.74 462.04 605.74 461.81C604.66 461.56 603.55 461.47 602.45 461.52C604.33 462.36 605.86 464.07 606.54 466.05C607.49 468.8 606.99 471.91 605.67 474.51C604.45 476.89 602.44 479.01 599.84 479.66C597.08 480.35 594.03 479.2 592.16 477.06C591.73 476.56 591.36 476.01 591.04 475.44C591.06 475.52 591.06 475.6 591.08 475.67C591.52 478.19 592.47 480.59 593.47 482.94C593.97 484.1 594.52 485.28 595.18 486.37C598.44 487.05 601.96 486.57 604.86 484.91Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M613.24 468.8C612.24 466.26 610.67 464.16 608.46 462.88C610.73 465.62 612.06 469.14 612.01 472.7C611.93 477.64 609.14 482.47 604.86 484.91C601.96 486.57 598.44 487.05 595.18 486.37C595.98 487.69 596.96 488.86 598.27 489.65C600.92 491.25 604.45 490.9 607.1 489.29C609.74 487.68 611.58 484.99 612.76 482.12C614.49 477.91 614.9 473.04 613.24 468.8Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M631.14 480.76C631.2 482.33 631.05 483.91 630.68 485.44C631.33 486.04 632.36 486.25 633.16 485.87C633.59 484.35 633.69 482.69 633.47 481.13C632.89 481.06 631.72 480.82 631.14 480.76Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M630.31 473.24C632.63 472.85 634.56 471.14 635.79 469.14C637.13 466.95 637.8 464.26 637.16 461.77C636.7 459.99 635.49 458.38 633.9 457.51C631.87 457.44 629.82 457.92 628 458.86C626.38 459.69 624.96 460.96 624.08 462.57C623.02 464.48 622.73 466.73 622.89 468.9C623.13 469.43 623.41 469.94 623.76 470.41C625.25 472.42 627.84 473.65 630.31 473.24Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M634.34 478.2C638.27 476.37 641.06 472.33 641.48 468C641.79 464.89 640.87 461.7 639.07 459.13C638.39 458.66 637.62 458.27 636.76 458C635.83 457.7 634.87 457.54 633.9 457.51C635.49 458.38 636.7 459.99 637.16 461.77C637.8 464.26 637.13 466.95 635.79 469.14C634.56 471.14 632.63 472.85 630.31 473.24C627.84 473.65 625.25 472.42 623.76 470.41C623.41 469.94 623.13 469.43 622.89 468.9C622.9 468.97 622.9 469.04 622.91 469.11C623.11 471.35 623.78 473.52 624.49 475.66C624.84 476.71 625.24 477.79 625.74 478.79C628.55 479.62 631.67 479.45 634.34 478.2Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M642.84 464.67C642.15 462.37 640.92 460.42 639.07 459.13C640.87 461.7 641.79 464.89 641.48 468C641.06 472.33 638.27 476.37 634.34 478.2C631.67 479.45 628.55 479.62 625.74 478.79C626.35 480 627.13 481.1 628.22 481.89C630.43 483.48 633.55 483.42 635.99 482.2C638.42 480.98 640.23 478.75 641.47 476.32C643.28 472.75 643.99 468.51 642.84 464.67Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M625.49 505.79C625.51 507.34 625.31 508.89 624.9 510.38C625.52 511 626.52 511.23 627.32 510.88C627.79 509.4 627.94 507.78 627.78 506.23C627.21 506.15 626.07 505.88 625.49 505.79Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M624.92 498.37C627.22 498.06 629.16 496.44 630.44 494.51C631.83 492.41 632.58 489.78 632.03 487.31C631.63 485.55 630.49 483.92 628.96 483.01C626.96 482.88 624.93 483.29 623.11 484.15C621.49 484.92 620.06 486.12 619.13 487.68C618.03 489.52 617.67 491.72 617.77 493.87C617.98 494.4 618.24 494.9 618.57 495.37C619.97 497.4 622.48 498.69 624.92 498.37Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M628.72 503.38C632.65 501.7 635.53 497.82 636.08 493.58C636.48 490.52 635.68 487.36 633.99 484.78C633.33 484.29 632.59 483.88 631.76 483.58C630.85 483.26 629.91 483.07 628.96 483.01C630.49 483.92 631.63 485.55 632.03 487.31C632.58 489.78 631.83 492.41 630.44 494.51C629.16 496.44 627.22 498.06 624.92 498.37C622.48 498.69 619.97 497.4 618.57 495.37C618.24 494.9 617.98 494.4 617.77 493.87C617.77 493.94 617.77 494.01 617.77 494.07C617.9 496.28 618.48 498.44 619.12 500.56C619.43 501.61 619.79 502.69 620.25 503.69C622.99 504.59 626.06 504.52 628.72 503.38Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M637.52 490.34C636.92 488.06 635.77 486.1 633.99 484.78C635.68 487.36 636.48 490.52 636.08 493.58C635.53 497.82 632.65 501.7 628.72 503.38C626.06 504.52 622.99 504.59 620.25 503.69C620.81 504.9 621.54 506 622.59 506.81C624.71 508.45 627.79 508.49 630.22 507.37C632.65 506.24 634.5 504.11 635.8 501.76C637.7 498.31 638.53 494.16 637.52 490.34Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M217.48 414.71C217.7 416.48 217.67 418.29 217.4 420.05C218.19 420.68 219.37 420.82 220.24 420.31C220.58 418.55 220.55 416.67 220.16 414.92C219.49 414.89 218.15 414.73 217.48 414.71Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M215.85 406.27C218.44 405.62 220.46 403.51 221.67 401.12C222.99 398.53 223.5 395.42 222.55 392.66C221.87 390.69 220.34 388.98 218.46 388.13C216.15 388.24 213.88 388.98 211.91 390.2C210.15 391.3 208.66 392.86 207.8 394.77C206.79 397.03 206.66 399.6 207.05 402.05C207.37 402.63 207.73 403.17 208.17 403.67C210.04 405.81 213.09 406.96 215.85 406.27Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M220.86 411.53C225.15 409.08 227.94 404.25 228.02 399.32C228.07 395.76 226.74 392.23 224.47 389.49C223.65 389.02 222.75 388.65 221.75 388.42C220.67 388.17 219.56 388.08 218.46 388.13C220.34 388.98 221.87 390.69 222.55 392.66C223.5 395.42 222.99 398.53 221.67 401.12C220.46 403.51 218.44 405.62 215.85 406.27C213.09 406.96 210.04 405.81 208.17 403.67C207.73 403.17 207.37 402.63 207.05 402.05C207.06 402.13 207.07 402.21 207.09 402.29C207.52 404.8 208.47 407.2 209.48 409.55C209.98 410.71 210.52 411.9 211.19 412.98C214.45 413.67 217.96 413.18 220.86 411.53Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M229.25 395.42C228.25 392.87 226.68 390.78 224.47 389.49C226.74 392.23 228.07 395.76 228.02 399.32C227.94 404.25 225.15 409.08 220.86 411.53C217.96 413.18 214.45 413.67 211.19 412.98C211.99 414.3 212.97 415.47 214.28 416.26C216.93 417.86 220.46 417.51 223.1 415.9C225.75 414.29 227.59 411.6 228.76 408.74C230.49 404.52 230.91 399.66 229.25 395.42Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M247.14 407.37C247.21 408.94 247.06 410.52 246.69 412.05C247.34 412.66 248.37 412.86 249.17 412.48C249.59 410.96 249.7 409.3 249.48 407.74C248.9 407.67 247.73 407.44 247.14 407.37Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M246.32 399.85C248.64 399.46 250.56 397.75 251.8 395.75C253.14 393.57 253.81 390.87 253.17 388.39C252.71 386.61 251.5 385 249.91 384.12C247.87 384.05 245.83 384.53 244.01 385.47C242.39 386.31 240.97 387.57 240.08 389.18C239.03 391.09 238.73 393.34 238.9 395.52C239.14 396.04 239.42 396.55 239.77 397.02C241.26 399.03 243.85 400.26 246.32 399.85Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M250.34 404.82C254.28 402.98 257.07 398.94 257.49 394.62C257.79 391.5 256.88 388.31 255.08 385.75C254.4 385.27 253.63 384.88 252.77 384.61C251.84 384.31 250.87 384.15 249.91 384.12C251.5 385 252.71 386.61 253.17 388.39C253.81 390.87 253.14 393.57 251.8 395.75C250.56 397.75 248.64 399.46 246.32 399.85C243.85 400.26 241.26 399.03 239.77 397.02C239.42 396.55 239.14 396.04 238.9 395.52C238.91 395.59 238.91 395.66 238.92 395.73C239.12 397.97 239.78 400.14 240.5 402.27C240.85 403.33 241.25 404.4 241.75 405.4C244.56 406.24 247.68 406.06 250.34 404.82Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M258.85 391.28C258.16 388.98 256.93 387.03 255.08 385.75C256.88 388.31 257.79 391.5 257.49 394.62C257.07 398.94 254.28 402.98 250.34 404.82C247.68 406.06 244.56 406.24 241.75 405.4C242.36 406.61 243.14 407.71 244.23 408.5C246.44 410.09 249.56 410.04 252 408.81C254.43 407.59 256.24 405.36 257.47 402.93C259.29 399.36 260 395.12 258.85 391.28Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M241.5 432.4C241.51 433.95 241.31 435.5 240.9 436.99C241.52 437.61 242.53 437.85 243.33 437.5C243.8 436.01 243.95 434.39 243.79 432.85C243.21 432.76 242.07 432.49 241.5 432.4Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M240.93 424.98C243.23 424.68 245.17 423.05 246.45 421.12C247.84 419.02 248.58 416.39 248.04 413.93C247.64 412.16 246.5 410.54 244.96 409.62C242.97 409.49 240.94 409.9 239.12 410.76C237.5 411.53 236.06 412.73 235.14 414.29C234.04 416.13 233.68 418.34 233.77 420.48C233.99 421.01 234.25 421.51 234.57 421.99C235.98 424.01 238.49 425.3 240.93 424.98Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M244.73 430C248.66 428.32 251.53 424.43 252.09 420.19C252.49 417.13 251.68 413.97 250 411.39C249.34 410.9 248.6 410.5 247.77 410.2C246.86 409.87 245.91 409.69 244.96 409.62C246.5 410.54 247.64 412.16 248.04 413.93C248.58 416.39 247.84 419.02 246.45 421.12C245.17 423.05 243.23 424.68 240.93 424.98C238.49 425.3 235.98 424.01 234.57 421.99C234.25 421.51 233.99 421.01 233.77 420.48C233.78 420.55 233.78 420.62 233.78 420.69C233.91 422.9 234.49 425.05 235.12 427.17C235.44 428.23 235.79 429.3 236.26 430.3C238.99 431.21 242.07 431.13 244.73 430Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M253.53 416.96C252.92 414.67 251.78 412.71 250 411.39C251.68 413.97 252.49 417.13 252.09 420.19C251.53 424.43 248.66 428.32 244.73 430C242.07 431.13 238.99 431.21 236.26 430.3C236.82 431.51 237.55 432.61 238.6 433.42C240.72 435.06 243.79 435.11 246.23 433.98C248.66 432.86 250.51 430.72 251.8 428.37C253.71 424.92 254.54 420.77 253.53 416.96Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M705 483.43C705.25 485.25 705.25 487.11 704.99 488.93C705.82 489.57 707.04 489.69 707.93 489.16C708.26 487.34 708.19 485.4 707.76 483.6C707.07 483.59 705.69 483.44 705 483.43Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M703.2 474.75C705.86 474.05 707.91 471.83 709.12 469.36C710.45 466.67 710.92 463.45 709.9 460.63C709.17 458.6 707.57 456.86 705.62 456.02C703.25 456.16 700.91 456.96 698.9 458.25C697.1 459.41 695.59 461.04 694.74 463.02C693.72 465.36 693.63 468.01 694.07 470.53C694.4 471.12 694.79 471.68 695.24 472.19C697.2 474.37 700.37 475.51 703.2 474.75Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M708.44 480.1C712.83 477.52 715.63 472.49 715.63 467.41C715.64 463.74 714.21 460.13 711.83 457.33C710.98 456.86 710.05 456.49 709.02 456.27C707.89 456.03 706.75 455.95 705.62 456.02C707.57 456.86 709.17 458.6 709.9 460.63C710.92 463.45 710.45 466.67 709.12 469.36C707.91 471.83 705.86 474.05 703.2 474.75C700.37 475.51 697.2 474.37 695.24 472.19C694.79 471.68 694.4 471.12 694.07 470.53C694.08 470.61 694.09 470.69 694.11 470.78C694.59 473.36 695.61 475.82 696.68 478.23C697.21 479.42 697.79 480.63 698.49 481.74C701.86 482.4 705.48 481.84 708.44 480.1Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M716.85 463.37C715.78 460.76 714.13 458.62 711.83 457.33C714.21 460.13 715.64 463.74 715.63 467.41C715.63 472.49 712.83 477.52 708.44 480.1C705.48 481.84 701.86 482.4 698.49 481.74C699.34 483.08 700.36 484.27 701.73 485.07C704.48 486.68 708.11 486.27 710.82 484.57C713.52 482.87 715.37 480.07 716.54 477.1C718.27 472.74 718.62 467.71 716.85 463.37Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M720.08 495.82C720.52 497.67 720.7 499.59 720.62 501.49C721.53 502.07 722.8 502.08 723.67 501.43C723.82 499.53 723.56 497.54 722.95 495.73C722.24 495.78 720.79 495.77 720.08 495.82Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M717.37 487.06C720.04 486.07 721.94 483.59 722.94 480.92C724.04 478.01 724.21 474.65 722.88 471.84C721.93 469.83 720.11 468.19 718.02 467.52C715.58 467.9 713.26 468.95 711.32 470.48C709.57 471.85 708.18 473.68 707.49 475.8C706.68 478.31 706.85 481.06 707.55 483.61C707.95 484.19 708.4 484.72 708.92 485.2C711.16 487.25 714.53 488.12 717.37 487.06Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M723.3 492.05C727.56 488.96 729.95 483.51 729.46 478.26C729.1 474.48 727.28 470.9 724.55 468.26C723.63 467.85 722.63 467.57 721.54 467.44C720.36 467.3 719.18 467.33 718.02 467.52C720.11 468.19 721.93 469.83 722.88 471.84C724.21 474.65 724.04 478.01 722.94 480.92C721.94 483.59 720.04 486.07 717.37 487.06C714.53 488.12 711.16 487.25 708.92 485.2C708.4 484.72 707.95 484.19 707.55 483.61C707.57 483.69 707.59 483.78 707.61 483.86C708.37 486.48 709.66 488.91 711 491.28C711.66 492.46 712.38 493.65 713.21 494.72C716.74 495.07 720.42 494.14 723.3 492.05Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M730.31 473.98C728.96 471.4 727.05 469.36 724.55 468.26C727.28 470.9 729.1 474.48 729.46 478.26C729.95 483.51 727.56 488.96 723.3 492.05C720.42 494.14 716.74 495.07 713.21 494.72C714.22 496.02 715.39 497.15 716.87 497.84C719.87 499.22 723.57 498.44 726.19 496.42C728.8 494.41 730.44 491.34 731.35 488.17C732.7 483.5 732.56 478.29 730.31 473.98Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M736.23 473.79C736.48 475.51 736.5 477.26 736.27 478.97C737.05 479.56 738.2 479.67 739.03 479.16C739.33 477.45 739.25 475.62 738.83 473.94C738.18 473.93 736.88 473.8 736.23 473.79Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M734.47 465.65C736.97 464.97 738.88 462.87 740 460.54C741.22 457.99 741.64 454.96 740.66 452.32C739.95 450.42 738.44 448.79 736.6 448.02C734.37 448.17 732.18 448.94 730.3 450.17C728.61 451.27 727.21 452.82 726.42 454.68C725.48 456.89 725.42 459.39 725.85 461.76C726.17 462.31 726.54 462.83 726.97 463.3C728.83 465.34 731.81 466.38 734.47 465.65Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M739.44 470.63C743.54 468.17 746.14 463.43 746.1 458.65C746.08 455.19 744.71 451.81 742.45 449.2C741.65 448.76 740.76 448.43 739.79 448.22C738.74 448 737.66 447.94 736.6 448.02C738.44 448.79 739.95 450.42 740.66 452.32C741.64 454.96 741.22 457.99 740 460.54C738.88 462.87 736.97 464.97 734.47 465.65C731.81 466.38 728.83 465.34 726.97 463.3C726.54 462.83 726.17 462.31 725.85 461.76C725.86 461.83 725.87 461.91 725.89 461.98C726.37 464.41 727.34 466.72 728.37 468.97C728.88 470.09 729.43 471.22 730.1 472.26C733.27 472.85 736.67 472.3 739.44 470.63Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M747.21 454.84C746.19 452.4 744.62 450.4 742.45 449.2C744.71 451.81 746.08 455.19 746.1 458.65C746.14 463.43 743.54 468.17 739.44 470.63C736.67 472.3 733.27 472.85 730.1 472.26C730.91 473.52 731.88 474.63 733.17 475.37C735.77 476.86 739.18 476.44 741.71 474.82C744.24 473.2 745.96 470.56 747.04 467.76C748.62 463.64 748.91 458.91 747.21 454.84Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M752.71 484.27C753.08 485.86 753.23 487.49 753.15 489.12C753.93 489.62 755.01 489.63 755.76 489.08C755.9 487.46 755.68 485.75 755.16 484.2C754.56 484.24 753.32 484.23 752.71 484.27Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M750.43 476.77C752.72 475.93 754.35 473.82 755.22 471.54C756.17 469.06 756.33 466.19 755.21 463.78C754.4 462.05 752.85 460.65 751.07 460.06C748.98 460.38 746.99 461.26 745.32 462.56C743.83 463.73 742.63 465.29 742.03 467.1C741.33 469.25 741.46 471.59 742.04 473.78C742.38 474.27 742.77 474.73 743.21 475.14C745.12 476.91 747.99 477.66 750.43 476.77Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M755.48 481.06C759.14 478.43 761.2 473.78 760.8 469.3C760.52 466.06 758.97 462.99 756.65 460.72C755.86 460.37 755.01 460.12 754.08 460.01C753.07 459.88 752.06 459.91 751.07 460.06C752.85 460.65 754.4 462.05 755.21 463.78C756.33 466.19 756.17 469.06 755.22 471.54C754.35 473.82 752.72 475.93 750.43 476.77C747.99 477.66 745.12 476.91 743.21 475.14C742.77 474.73 742.38 474.27 742.04 473.78C742.06 473.85 742.08 473.92 742.1 473.99C742.74 476.23 743.83 478.31 744.96 480.35C745.53 481.36 746.14 482.38 746.84 483.3C749.86 483.61 753.01 482.83 755.48 481.06Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M761.55 465.64C760.4 463.43 758.78 461.67 756.65 460.72C758.97 462.99 760.52 466.06 760.8 469.3C761.2 473.78 759.14 478.43 755.48 481.06C753.01 482.83 749.86 483.61 746.84 483.3C747.7 484.42 748.7 485.39 749.96 485.98C752.52 487.18 755.69 486.52 757.93 484.81C760.18 483.1 761.59 480.48 762.38 477.77C763.55 473.78 763.46 469.33 761.55 465.64Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M138.52 425.62C138.77 427.44 138.77 429.3 138.51 431.13C139.33 431.76 140.56 431.89 141.45 431.35C141.77 429.53 141.71 427.59 141.28 425.79C140.59 425.78 139.21 425.63 138.52 425.62Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M136.72 416.95C139.38 416.24 141.43 414.03 142.64 411.56C143.97 408.86 144.44 405.65 143.42 402.82C142.69 400.8 141.09 399.05 139.14 398.21C136.76 398.36 134.43 399.15 132.42 400.44C130.62 401.6 129.11 403.23 128.25 405.21C127.24 407.55 127.15 410.21 127.58 412.72C127.92 413.31 128.3 413.87 128.76 414.38C130.72 416.56 133.88 417.7 136.72 416.95Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M141.96 422.29C146.34 419.71 149.14 414.69 149.15 409.6C149.16 405.93 147.73 402.32 145.35 399.53C144.5 399.05 143.56 398.69 142.53 398.46C141.41 398.22 140.27 398.14 139.14 398.21C141.09 399.05 142.69 400.8 143.42 402.82C144.44 405.65 143.97 408.86 142.64 411.56C141.43 414.03 139.38 416.24 136.72 416.95C133.88 417.7 130.72 416.56 128.76 414.38C128.3 413.87 127.92 413.31 127.58 412.72C127.6 412.81 127.61 412.89 127.62 412.97C128.11 415.56 129.13 418.01 130.2 420.42C130.73 421.61 131.31 422.82 132.01 423.93C135.38 424.59 139 424.03 141.96 422.29Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M150.36 405.56C149.3 402.95 147.65 400.82 145.35 399.53C147.73 402.32 149.16 405.93 149.15 409.6C149.14 414.69 146.34 419.71 141.96 422.29C139 424.03 135.38 424.59 132.01 423.93C132.86 425.28 133.88 426.47 135.24 427.26C138 428.87 141.63 428.46 144.33 426.76C147.04 425.07 148.89 422.26 150.06 419.3C151.78 414.93 152.14 409.91 150.36 405.56Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M153.6 438.01C154.04 439.87 154.22 441.78 154.14 443.69C155.05 444.26 156.32 444.27 157.18 443.63C157.34 441.72 157.08 439.73 156.47 437.92C155.75 437.97 154.31 437.96 153.6 438.01Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M150.89 429.25C153.56 428.26 155.45 425.78 156.46 423.12C157.56 420.21 157.73 416.85 156.4 414.04C155.45 412.02 153.63 410.39 151.54 409.71C149.1 410.09 146.78 411.14 144.83 412.67C143.09 414.04 141.7 415.87 141.01 418C140.2 420.51 140.36 423.25 141.06 425.8C141.46 426.38 141.92 426.92 142.44 427.39C144.67 429.45 148.04 430.31 150.89 429.25Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M156.82 434.24C161.08 431.15 163.47 425.7 162.98 420.46C162.62 416.68 160.79 413.1 158.07 410.45C157.14 410.05 156.14 409.76 155.06 409.63C153.88 409.49 152.7 409.53 151.54 409.71C153.63 410.39 155.45 412.02 156.4 414.04C157.73 416.85 157.56 420.21 156.46 423.12C155.45 425.78 153.56 428.26 150.89 429.25C148.04 430.31 144.67 429.45 142.44 427.39C141.92 426.92 141.46 426.38 141.06 425.8C141.09 425.89 141.1 425.97 141.13 426.05C141.89 428.67 143.17 431.1 144.51 433.47C145.18 434.65 145.9 435.84 146.73 436.92C150.26 437.26 153.94 436.33 156.82 434.24Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M163.83 416.18C162.47 413.6 160.56 411.56 158.07 410.45C160.79 413.1 162.62 416.68 162.98 420.46C163.47 425.7 161.08 431.15 156.82 434.24C153.94 436.33 150.26 437.26 146.73 436.92C147.73 438.22 148.91 439.34 150.39 440.03C153.39 441.41 157.09 440.63 159.71 438.62C162.32 436.6 163.96 433.53 164.87 430.36C166.21 425.69 166.08 420.48 163.83 416.18Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M169.75 415.99C170 417.7 170.01 419.45 169.79 421.16C170.57 421.76 171.72 421.86 172.55 421.35C172.84 419.64 172.77 417.81 172.35 416.13C171.7 416.12 170.4 415.99 169.75 415.99Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M167.99 407.85C170.49 407.16 172.39 405.06 173.52 402.73C174.74 400.18 175.16 397.16 174.18 394.51C173.47 392.61 171.96 390.99 170.12 390.21C167.88 390.36 165.7 391.13 163.82 392.36C162.13 393.47 160.73 395.01 159.94 396.88C159 399.09 158.94 401.59 159.37 403.95C159.68 404.5 160.05 405.02 160.49 405.49C162.35 407.53 165.33 408.58 167.99 407.85Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M172.96 412.83C177.06 410.37 179.65 405.62 179.62 400.84C179.6 397.39 178.23 394 175.97 391.4C175.16 390.96 174.28 390.62 173.31 390.42C172.25 390.2 171.18 390.13 170.12 390.21C171.96 390.99 173.47 392.61 174.18 394.51C175.16 397.16 174.74 400.18 173.52 402.73C172.39 405.06 170.49 407.16 167.99 407.85C165.33 408.58 162.35 407.53 160.49 405.49C160.05 405.02 159.68 404.5 159.37 403.95C159.38 404.03 159.39 404.1 159.41 404.18C159.89 406.61 160.86 408.91 161.89 411.16C162.39 412.28 162.95 413.41 163.62 414.45C166.79 415.04 170.19 414.49 172.96 412.83Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M180.73 397.03C179.71 394.59 178.14 392.59 175.97 391.4C178.23 394 179.6 397.39 179.62 400.84C179.65 405.62 177.06 410.37 172.96 412.83C170.19 414.49 166.79 415.04 163.62 414.45C164.43 415.71 165.4 416.82 166.69 417.56C169.29 419.05 172.7 418.63 175.23 417.02C177.76 415.4 179.48 412.75 180.56 409.95C182.14 405.83 182.43 401.1 180.73 397.03Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M186.23 426.46C186.6 428.05 186.75 429.69 186.67 431.32C187.44 431.81 188.53 431.82 189.27 431.28C189.42 429.65 189.2 427.94 188.68 426.39C188.07 426.44 186.84 426.42 186.23 426.46Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M183.95 418.96C186.24 418.12 187.87 416.01 188.74 413.73C189.69 411.25 189.85 408.38 188.73 405.97C187.92 404.25 186.37 402.84 184.58 402.25C182.5 402.57 180.51 403.46 178.84 404.76C177.34 405.92 176.15 407.48 175.55 409.29C174.84 411.44 174.97 413.79 175.56 415.97C175.9 416.46 176.29 416.93 176.73 417.33C178.63 419.1 181.51 419.85 183.95 418.96Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M189 423.25C192.66 420.62 194.72 415.97 194.32 411.49C194.03 408.25 192.49 405.19 190.16 402.92C189.38 402.56 188.52 402.32 187.6 402.2C186.59 402.08 185.58 402.1 184.58 402.25C186.37 402.84 187.92 404.25 188.73 405.97C189.85 408.38 189.69 411.25 188.74 413.73C187.87 416.01 186.24 418.12 183.95 418.96C181.51 419.85 178.63 419.1 176.73 417.33C176.29 416.93 175.9 416.46 175.56 415.97C175.58 416.04 175.6 416.11 175.62 416.18C176.25 418.42 177.35 420.51 178.48 422.54C179.05 423.55 179.65 424.57 180.36 425.5C183.38 425.8 186.53 425.03 189 423.25Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M195.07 407.83C193.92 405.62 192.29 403.87 190.16 402.92C192.49 405.19 194.03 408.25 194.32 411.49C194.72 415.97 192.66 420.62 189 423.25C186.53 425.03 183.38 425.8 180.36 425.5C181.21 426.61 182.21 427.58 183.48 428.17C186.04 429.37 189.2 428.72 191.45 427C193.69 425.29 195.11 422.67 195.9 419.96C197.07 415.98 196.98 411.52 195.07 407.83Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M792.38 431.47C791.98 431.69 791.45 431.64 791.01 431.49C790.99 429.86 790.85 428.24 790.6 426.63C791.07 426.59 791.58 426.63 792.05 426.58C792.24 428.1 792.19 429.95 792.38 431.47Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M786.64 422.08C787.68 421.56 788.44 420.51 788.58 419.36C788.74 418.14 788.25 416.92 788.32 415.69C788.38 414.76 788.75 413.89 788.88 412.98C789.01 412.06 788.82 410.99 788.05 410.49C787.54 410.15 786.84 410.2 786.32 410.53C786.07 411.14 785.92 411.77 785.89 412.32C785.83 413.47 786.12 414.62 786.01 415.77C785.91 416.84 785.48 417.84 785.1 418.85C784.66 419.99 784.3 421.17 784.16 422.38C784.99 422.55 785.88 422.46 786.64 422.08Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M797.3 422.24C797.04 420.74 796.3 419.36 795.41 418.13C794.49 416.84 793.37 415.65 792.84 414.16C792.14 412.16 792.43 408.73 789.8 408.07C789.08 407.88 788.44 408.04 787.9 408.4C787.99 408.42 788.08 408.43 788.17 408.45C789.12 408.69 789.98 409.35 790.36 410.25C791.15 412.13 789.8 414.44 790.71 416.26C791.16 417.17 792.08 417.74 792.71 418.55C793.82 419.96 793.88 421.94 793.58 423.71C793.38 424.84 793 426.05 792.05 426.71C791.34 427.22 790.42 427.32 789.54 427.34C788.35 427.36 787.14 427.24 786.04 426.79C785.51 426.58 785 426.27 784.57 425.9C784.89 426.71 785.38 427.45 786.07 428.01C787.53 429.19 789.57 429.35 791.43 429.1C793.34 428.85 795.32 428.15 796.45 426.6C797.34 425.36 797.57 423.74 797.3 422.24Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M789.54 427.34C790.42 427.32 791.34 427.22 792.05 426.71C793 426.05 793.38 424.84 793.58 423.71C793.88 421.94 793.82 419.96 792.71 418.55C792.08 417.74 791.16 417.17 790.71 416.26C789.8 414.44 791.15 412.13 790.36 410.25C789.98 409.35 789.12 408.69 788.17 408.45C788.08 408.43 787.99 408.42 787.9 408.4C787.2 408.87 786.66 409.68 786.32 410.53C786.84 410.2 787.54 410.15 788.05 410.49C788.82 410.99 789.01 412.06 788.88 412.98C788.75 413.89 788.38 414.76 788.32 415.69C788.25 416.92 788.74 418.14 788.58 419.36C788.44 420.51 787.68 421.56 786.64 422.08C785.88 422.46 784.99 422.55 784.16 422.38C784.11 422.8 784.09 423.22 784.11 423.64C784.13 424.41 784.28 425.18 784.57 425.9C785 426.27 785.51 426.58 786.04 426.79C787.14 427.24 788.35 427.36 789.54 427.34Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M800.48 439.87C800.1 440.06 799.6 439.99 799.2 439.83C799.25 438.3 799.2 436.77 799.04 435.25C799.48 435.23 799.96 435.29 800.41 435.27C800.52 436.71 800.37 438.44 800.48 439.87Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M795.55 430.78C796.55 430.34 797.31 429.4 797.51 428.33C797.72 427.18 797.31 426.01 797.44 424.86C797.54 424 797.93 423.19 798.09 422.34C798.26 421.49 798.14 420.48 797.44 419.97C796.97 419.63 796.31 419.64 795.81 419.92C795.55 420.48 795.38 421.07 795.32 421.58C795.21 422.66 795.43 423.75 795.27 424.82C795.12 425.82 794.66 426.74 794.26 427.67C793.8 428.72 793.4 429.81 793.21 430.94C793.98 431.14 794.82 431.1 795.55 430.78Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M805.55 431.45C805.37 430.03 804.75 428.7 803.98 427.5C803.17 426.24 802.18 425.07 801.76 423.65C801.2 421.74 801.64 418.53 799.19 417.78C798.53 417.57 797.92 417.69 797.4 418C797.48 418.02 797.57 418.04 797.65 418.06C798.53 418.33 799.31 418.99 799.62 419.86C800.27 421.66 798.89 423.76 799.65 425.52C800.03 426.39 800.87 426.97 801.42 427.76C802.39 429.14 802.35 431 801.98 432.64C801.74 433.7 801.32 434.82 800.4 435.39C799.71 435.83 798.84 435.88 798.02 435.86C796.9 435.82 795.77 435.65 794.76 435.17C794.27 434.94 793.81 434.63 793.42 434.26C793.68 435.04 794.11 435.76 794.72 436.32C796.04 437.5 797.95 437.74 799.71 437.61C801.51 437.47 803.4 436.9 804.54 435.5C805.43 434.38 805.73 432.87 805.55 431.45Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M798.02 435.86C798.84 435.88 799.71 435.83 800.4 435.39C801.32 434.82 801.74 433.7 801.98 432.64C802.35 431 802.39 429.14 801.42 427.76C800.87 426.97 800.03 426.39 799.65 425.52C798.89 423.76 800.27 421.66 799.62 419.86C799.31 418.99 798.53 418.33 797.65 418.06C797.57 418.04 797.48 418.02 797.4 418C796.72 418.4 796.17 419.14 795.81 419.92C796.31 419.64 796.97 419.63 797.44 419.97C798.14 420.48 798.26 421.49 798.09 422.34C797.93 423.19 797.54 424 797.44 424.86C797.31 426.01 797.72 427.18 797.51 428.33C797.31 429.4 796.55 430.34 795.55 430.78C794.82 431.1 793.98 431.14 793.21 430.94C793.14 431.33 793.1 431.73 793.09 432.12C793.08 432.84 793.19 433.58 793.42 434.26C793.81 434.63 794.27 434.94 794.76 435.17C795.77 435.65 796.9 435.82 798.02 435.86Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M812.94 429.83C812.5 430.06 811.93 429.99 811.47 429.82C811.48 428.08 811.37 426.34 811.14 424.62C811.64 424.59 812.19 424.64 812.69 424.6C812.86 426.23 812.76 428.2 812.94 429.83Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M807.02 419.66C808.14 419.13 808.97 418.03 809.16 416.81C809.35 415.51 808.85 414.19 808.96 412.87C809.04 411.89 809.45 410.96 809.62 409.99C809.78 409.02 809.6 407.87 808.79 407.32C808.25 406.94 807.5 406.98 806.94 407.31C806.66 407.96 806.49 408.64 806.44 409.22C806.35 410.45 806.63 411.69 806.49 412.91C806.36 414.05 805.87 415.11 805.44 416.17C804.95 417.38 804.54 418.64 804.36 419.92C805.25 420.13 806.2 420.05 807.02 419.66Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M818.41 420.08C818.15 418.47 817.4 416.98 816.48 415.64C815.52 414.25 814.35 412.95 813.83 411.35C813.12 409.19 813.51 405.54 810.71 404.77C809.95 404.56 809.26 404.71 808.68 405.08C808.77 405.1 808.87 405.12 808.97 405.14C809.98 405.41 810.88 406.14 811.27 407.11C812.07 409.14 810.57 411.57 811.49 413.54C811.96 414.53 812.93 415.16 813.58 416.03C814.73 417.57 814.76 419.68 814.39 421.56C814.16 422.77 813.72 424.05 812.69 424.74C811.92 425.26 810.93 425.35 810 425.35C808.72 425.34 807.43 425.19 806.27 424.69C805.7 424.44 805.17 424.11 804.71 423.69C805.04 424.58 805.55 425.38 806.27 425.98C807.8 427.28 809.98 427.5 811.98 427.28C814.02 427.06 816.15 426.35 817.39 424.71C818.37 423.42 818.66 421.68 818.41 420.08Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M810 425.35C810.93 425.35 811.92 425.26 812.69 424.74C813.72 424.05 814.16 422.77 814.39 421.56C814.76 419.68 814.73 417.57 813.58 416.03C812.93 415.16 811.96 414.53 811.49 413.54C810.57 411.57 812.07 409.14 811.27 407.11C810.88 406.14 809.98 405.41 808.97 405.14C808.87 405.12 808.77 405.1 808.68 405.08C807.92 405.56 807.32 406.41 806.94 407.31C807.5 406.98 808.25 406.94 808.79 407.32C809.6 407.87 809.78 409.02 809.62 409.99C809.45 410.96 809.04 411.89 808.96 412.87C808.85 414.19 809.35 415.51 809.16 416.81C808.97 418.03 808.14 419.13 807.02 419.66C806.2 420.05 805.25 420.13 804.36 419.92C804.3 420.37 804.27 420.82 804.27 421.28C804.28 422.1 804.43 422.92 804.71 423.69C805.17 424.11 805.7 424.44 806.27 424.69C807.43 425.19 808.72 425.34 810 425.35Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M33.83 418.69C33.43 418.91 32.9 418.86 32.46 418.71C32.44 417.08 32.3 415.46 32.04 413.85C32.51 413.81 33.03 413.85 33.49 413.8C33.69 415.32 33.64 417.17 33.83 418.69Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M28.09 409.3C29.12 408.78 29.88 407.73 30.03 406.58C30.19 405.36 29.7 404.14 29.77 402.9C29.82 401.98 30.19 401.11 30.32 400.2C30.46 399.28 30.27 398.21 29.5 397.71C28.98 397.37 28.28 397.42 27.77 397.74C27.52 398.36 27.37 398.99 27.34 399.54C27.28 400.69 27.57 401.84 27.46 402.99C27.36 404.06 26.92 405.06 26.54 406.06C26.11 407.2 25.75 408.39 25.61 409.59C26.44 409.77 27.33 409.68 28.09 409.3Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M38.75 409.46C38.48 407.96 37.75 406.58 36.86 405.35C35.94 404.06 34.82 402.87 34.29 401.38C33.59 399.38 33.88 395.95 31.24 395.29C30.52 395.1 29.89 395.26 29.35 395.62C29.44 395.64 29.53 395.65 29.62 395.67C30.57 395.91 31.43 396.57 31.81 397.47C32.6 399.35 31.25 401.66 32.15 403.48C32.61 404.39 33.53 404.96 34.16 405.76C35.26 407.18 35.33 409.15 35.02 410.93C34.83 412.06 34.44 413.27 33.5 413.93C32.78 414.44 31.86 414.54 30.99 414.56C29.8 414.58 28.59 414.46 27.49 414.01C26.95 413.79 26.45 413.49 26.01 413.11C26.34 413.93 26.83 414.67 27.51 415.23C28.97 416.41 31.02 416.57 32.88 416.32C34.79 416.07 36.77 415.37 37.89 413.81C38.79 412.58 39.02 410.95 38.75 409.46Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M30.99 414.56C31.86 414.54 32.78 414.44 33.5 413.93C34.44 413.27 34.83 412.06 35.02 410.93C35.33 409.15 35.26 407.18 34.16 405.76C33.53 404.96 32.61 404.39 32.15 403.48C31.25 401.66 32.6 399.35 31.81 397.47C31.43 396.57 30.57 395.91 29.62 395.67C29.53 395.65 29.44 395.64 29.35 395.62C28.64 396.09 28.11 396.89 27.77 397.74C28.28 397.42 28.98 397.37 29.5 397.71C30.27 398.21 30.46 399.28 30.32 400.2C30.19 401.11 29.82 401.98 29.77 402.9C29.7 404.14 30.19 405.36 30.03 406.58C29.88 407.73 29.12 408.78 28.09 409.3C27.33 409.68 26.44 409.77 25.61 409.59C25.56 410.02 25.54 410.44 25.55 410.86C25.58 411.63 25.73 412.4 26.01 413.11C26.45 413.49 26.95 413.79 27.49 414.01C28.59 414.46 29.8 414.58 30.99 414.56Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M41.93 427.09C41.54 427.28 41.04 427.21 40.64 427.05C40.7 425.52 40.65 423.99 40.49 422.47C40.93 422.45 41.41 422.51 41.85 422.49C41.96 423.93 41.82 425.66 41.93 427.09Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M37 418C38 417.56 38.76 416.62 38.95 415.54C39.16 414.4 38.76 413.23 38.89 412.08C38.98 411.22 39.37 410.41 39.54 409.56C39.71 408.71 39.59 407.7 38.89 407.19C38.42 406.85 37.76 406.86 37.26 407.14C37 407.7 36.83 408.29 36.77 408.8C36.66 409.88 36.87 410.97 36.72 412.04C36.57 413.04 36.11 413.96 35.71 414.89C35.25 415.94 34.85 417.03 34.66 418.16C35.43 418.36 36.27 418.32 37 418Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M47 418.67C46.82 417.25 46.2 415.92 45.43 414.72C44.62 413.46 43.63 412.29 43.21 410.87C42.64 408.95 43.09 405.75 40.64 405C39.98 404.79 39.37 404.91 38.85 405.22C38.93 405.24 39.02 405.26 39.1 405.28C39.98 405.55 40.76 406.21 41.07 407.08C41.72 408.87 40.34 410.98 41.1 412.73C41.48 413.61 42.32 414.19 42.87 414.98C43.84 416.36 43.8 418.22 43.43 419.86C43.19 420.92 42.77 422.03 41.85 422.61C41.15 423.05 40.29 423.1 39.46 423.08C38.35 423.04 37.22 422.87 36.2 422.39C35.71 422.16 35.26 421.85 34.87 421.48C35.13 422.26 35.55 422.98 36.17 423.54C37.48 424.72 39.4 424.96 41.16 424.83C42.96 424.69 44.85 424.12 45.98 422.72C46.88 421.6 47.18 420.09 47 418.67Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M39.46 423.08C40.29 423.1 41.15 423.05 41.85 422.61C42.77 422.03 43.19 420.92 43.43 419.86C43.8 418.22 43.84 416.36 42.87 414.98C42.32 414.19 41.48 413.61 41.1 412.73C40.34 410.98 41.72 408.87 41.07 407.08C40.76 406.21 39.98 405.55 39.1 405.28C39.02 405.26 38.93 405.24 38.85 405.22C38.16 405.62 37.62 406.35 37.26 407.14C37.76 406.86 38.42 406.85 38.89 407.19C39.59 407.7 39.71 408.71 39.54 409.56C39.37 410.41 38.98 411.22 38.89 412.08C38.76 413.23 39.16 414.4 38.95 415.54C38.76 416.62 38 417.56 37 418C36.27 418.32 35.43 418.36 34.66 418.16C34.59 418.55 34.55 418.94 34.54 419.34C34.53 420.06 34.64 420.8 34.87 421.48C35.26 421.85 35.71 422.16 36.2 422.39C37.22 422.87 38.35 423.04 39.46 423.08Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M54.38 417.04C53.95 417.28 53.38 417.2 52.92 417.04C52.93 415.3 52.82 413.56 52.59 411.84C53.09 411.81 53.64 411.86 54.14 411.82C54.31 413.45 54.21 415.42 54.38 417.04Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M48.47 406.88C49.58 406.35 50.42 405.25 50.6 404.03C50.8 402.73 50.3 401.4 50.41 400.09C50.49 399.11 50.9 398.18 51.06 397.21C51.22 396.24 51.05 395.09 50.24 394.54C49.69 394.16 48.95 394.2 48.39 394.53C48.11 395.18 47.94 395.86 47.89 396.44C47.8 397.67 48.08 398.91 47.94 400.13C47.81 401.27 47.32 402.33 46.89 403.39C46.4 404.6 45.99 405.86 45.81 407.14C46.69 407.35 47.65 407.27 48.47 406.88Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M59.85 407.3C59.6 405.69 58.85 404.2 57.93 402.86C56.97 401.47 55.8 400.17 55.27 398.56C54.57 396.41 54.96 392.76 52.16 391.99C51.39 391.78 50.71 391.93 50.12 392.3C50.22 392.32 50.32 392.34 50.41 392.36C51.43 392.63 52.33 393.36 52.71 394.33C53.51 396.35 52.02 398.79 52.94 400.76C53.4 401.75 54.38 402.38 55.03 403.25C56.18 404.78 56.2 406.9 55.84 408.78C55.6 409.99 55.16 411.27 54.14 411.96C53.36 412.48 52.38 412.57 51.44 412.57C50.17 412.56 48.88 412.41 47.71 411.9C47.15 411.66 46.62 411.32 46.16 410.91C46.49 411.79 47 412.6 47.71 413.2C49.25 414.5 51.43 414.72 53.43 414.5C55.47 414.28 57.6 413.57 58.84 411.93C59.82 410.64 60.11 408.9 59.85 407.3Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M51.44 412.57C52.38 412.57 53.36 412.48 54.14 411.96C55.16 411.27 55.6 409.99 55.84 408.78C56.2 406.9 56.18 404.78 55.03 403.25C54.38 402.38 53.4 401.75 52.94 400.76C52.02 398.79 53.51 396.35 52.71 394.33C52.33 393.36 51.43 392.63 50.41 392.36C50.32 392.34 50.22 392.32 50.12 392.3C49.36 392.78 48.77 393.63 48.39 394.53C48.95 394.2 49.69 394.16 50.24 394.54C51.05 395.09 51.22 396.24 51.06 397.21C50.9 398.18 50.49 399.11 50.41 400.09C50.3 401.4 50.8 402.73 50.6 404.03C50.42 405.25 49.58 406.35 48.47 406.88C47.65 407.27 46.69 407.35 45.81 407.14C45.75 407.59 45.72 408.04 45.72 408.5C45.73 409.32 45.88 410.14 46.16 410.91C46.62 411.32 47.15 411.66 47.71 411.9C48.88 412.41 50.17 412.56 51.44 412.57Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M284.8 458.2C284.4 458.42 283.87 458.37 283.43 458.22C283.41 456.59 283.27 454.97 283.02 453.36C283.49 453.32 284 453.36 284.47 453.31C284.66 454.83 284.61 456.68 284.8 458.2Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M279.06 448.81C280.1 448.29 280.86 447.24 281.01 446.09C281.17 444.87 280.67 443.64 280.74 442.41C280.8 441.49 281.17 440.62 281.3 439.7C281.43 438.79 281.24 437.72 280.47 437.22C279.96 436.88 279.26 436.93 278.74 437.25C278.49 437.87 278.35 438.5 278.31 439.05C278.25 440.2 278.54 441.35 278.44 442.5C278.34 443.57 277.9 444.57 277.52 445.57C277.09 446.71 276.72 447.9 276.58 449.1C277.41 449.28 278.31 449.19 279.06 448.81Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M289.73 448.97C289.46 447.47 288.72 446.09 287.84 444.85C286.91 443.57 285.79 442.38 285.26 440.89C284.56 438.89 284.86 435.46 282.22 434.79C281.5 434.61 280.86 434.77 280.32 435.13C280.41 435.15 280.5 435.16 280.59 435.18C281.55 435.41 282.4 436.08 282.78 436.98C283.57 438.86 282.22 441.17 283.13 442.99C283.58 443.9 284.5 444.47 285.13 445.27C286.24 446.69 286.3 448.66 286 450.44C285.8 451.57 285.42 452.78 284.47 453.44C283.76 453.94 282.84 454.05 281.96 454.07C280.77 454.09 279.56 453.97 278.46 453.52C277.93 453.3 277.42 453 276.99 452.62C277.31 453.44 277.8 454.18 278.49 454.74C279.95 455.92 281.99 456.08 283.86 455.83C285.76 455.58 287.74 454.88 288.87 453.32C289.76 452.09 290 450.46 289.73 448.97Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M281.96 454.07C282.84 454.05 283.76 453.94 284.47 453.44C285.42 452.78 285.8 451.57 286 450.44C286.3 448.66 286.24 446.69 285.13 445.27C284.5 444.47 283.58 443.9 283.13 442.99C282.22 441.17 283.57 438.86 282.78 436.98C282.4 436.08 281.55 435.41 280.59 435.18C280.5 435.16 280.41 435.15 280.32 435.13C279.62 435.6 279.08 436.4 278.74 437.25C279.26 436.93 279.96 436.88 280.47 437.22C281.24 437.72 281.43 438.79 281.3 439.7C281.17 440.62 280.8 441.49 280.74 442.41C280.67 443.64 281.17 444.87 281.01 446.09C280.86 447.24 280.1 448.29 279.06 448.81C278.31 449.19 277.41 449.28 276.58 449.1C276.54 449.52 276.51 449.95 276.53 450.37C276.55 451.14 276.71 451.91 276.99 452.62C277.42 453 277.93 453.3 278.46 453.52C279.56 453.97 280.77 454.09 281.96 454.07Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M292.91 466.6C292.52 466.79 292.02 466.71 291.62 466.55C291.67 465.03 291.62 463.5 291.46 461.98C291.91 461.96 292.39 462.02 292.83 462C292.94 463.43 292.8 465.17 292.91 466.6Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M287.97 457.51C288.97 457.07 289.73 456.12 289.93 455.05C290.14 453.91 289.73 452.74 289.86 451.59C289.96 450.72 290.35 449.92 290.52 449.07C290.68 448.22 290.56 447.21 289.86 446.7C289.39 446.36 288.73 446.37 288.23 446.64C287.97 447.21 287.8 447.8 287.75 448.31C287.63 449.39 287.85 450.48 287.69 451.55C287.55 452.55 287.09 453.47 286.68 454.4C286.22 455.45 285.82 456.54 285.63 457.66C286.4 457.87 287.24 457.83 287.97 457.51Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M297.98 458.18C297.8 456.76 297.18 455.43 296.4 454.22C295.6 452.97 294.6 451.8 294.18 450.37C293.62 448.46 294.06 445.26 291.62 444.51C290.95 444.3 290.34 444.42 289.82 444.73C289.9 444.75 289.99 444.77 290.07 444.79C290.96 445.06 291.73 445.72 292.04 446.58C292.69 448.38 291.31 450.49 292.07 452.24C292.45 453.12 293.29 453.7 293.84 454.49C294.81 455.87 294.78 457.72 294.4 459.37C294.17 460.43 293.74 461.54 292.83 462.12C292.13 462.56 291.26 462.61 290.44 462.58C289.32 462.55 288.19 462.38 287.18 461.9C286.69 461.67 286.23 461.36 285.84 460.99C286.1 461.77 286.53 462.49 287.14 463.04C288.46 464.22 290.37 464.47 292.13 464.34C293.93 464.19 295.82 463.63 296.96 462.22C297.86 461.11 298.16 459.59 297.98 458.18Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M290.44 462.58C291.26 462.61 292.13 462.56 292.83 462.12C293.74 461.54 294.17 460.43 294.4 459.37C294.78 457.72 294.81 455.87 293.84 454.49C293.29 453.7 292.45 453.12 292.07 452.24C291.31 450.49 292.69 448.38 292.04 446.58C291.73 445.72 290.96 445.06 290.07 444.79C289.99 444.77 289.9 444.75 289.82 444.73C289.14 445.13 288.6 445.86 288.23 446.64C288.73 446.37 289.39 446.36 289.86 446.7C290.56 447.21 290.68 448.22 290.52 449.07C290.35 449.92 289.96 450.72 289.86 451.59C289.73 452.74 290.14 453.91 289.93 455.05C289.73 456.12 288.97 457.07 287.97 457.51C287.24 457.83 286.4 457.87 285.63 457.66C285.56 458.06 285.52 458.45 285.52 458.85C285.5 459.57 285.61 460.31 285.84 460.99C286.23 461.36 286.69 461.67 287.18 461.9C288.19 462.38 289.32 462.55 290.44 462.58Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M305.36 456.55C304.92 456.79 304.36 456.71 303.89 456.55C303.9 454.81 303.79 453.07 303.56 451.35C304.06 451.31 304.61 451.37 305.11 451.33C305.29 452.96 305.19 454.93 305.36 456.55Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M299.44 446.39C300.56 445.86 301.39 444.76 301.58 443.54C301.78 442.23 301.27 440.91 301.38 439.6C301.46 438.62 301.88 437.69 302.04 436.72C302.2 435.75 302.03 434.6 301.21 434.04C300.67 433.67 299.92 433.71 299.36 434.04C299.08 434.69 298.91 435.37 298.86 435.95C298.77 437.18 299.05 438.41 298.91 439.64C298.78 440.78 298.29 441.84 297.86 442.9C297.38 444.11 296.96 445.37 296.78 446.65C297.67 446.86 298.62 446.78 299.44 446.39Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M310.83 446.81C310.58 445.2 309.82 443.71 308.9 442.37C307.95 440.98 306.77 439.68 306.25 438.07C305.54 435.92 305.94 432.27 303.13 431.49C302.37 431.28 301.68 431.44 301.1 431.81C301.19 431.83 301.29 431.85 301.39 431.87C302.4 432.14 303.3 432.87 303.69 433.84C304.49 435.86 302.99 438.3 303.92 440.27C304.38 441.25 305.35 441.89 306 442.76C307.15 444.29 307.18 446.4 306.81 448.29C306.58 449.5 306.14 450.78 305.11 451.47C304.34 451.99 303.35 452.08 302.42 452.08C301.15 452.07 299.85 451.92 298.69 451.41C298.12 451.17 297.59 450.83 297.14 450.42C297.46 451.3 297.97 452.11 298.69 452.71C300.22 454.01 302.4 454.22 304.4 454.01C306.44 453.78 308.57 453.08 309.81 451.44C310.79 450.15 311.08 448.41 310.83 446.81Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M302.42 452.08C303.35 452.08 304.34 451.99 305.11 451.47C306.14 450.78 306.58 449.5 306.81 448.29C307.18 446.4 307.15 444.29 306 442.76C305.35 441.89 304.38 441.25 303.92 440.27C302.99 438.3 304.49 435.86 303.69 433.84C303.3 432.87 302.4 432.14 301.39 431.87C301.29 431.85 301.19 431.83 301.1 431.81C300.34 432.29 299.75 433.14 299.36 434.04C299.92 433.71 300.67 433.67 301.21 434.04C302.03 434.6 302.2 435.75 302.04 436.72C301.88 437.69 301.46 438.62 301.38 439.6C301.27 440.91 301.78 442.23 301.58 443.54C301.39 444.76 300.56 445.86 299.44 446.39C298.62 446.78 297.67 446.86 296.78 446.65C296.72 447.1 296.69 447.55 296.69 448.01C296.7 448.83 296.85 449.65 297.14 450.42C297.59 450.83 298.12 451.17 298.69 451.41C299.85 451.92 301.15 452.07 302.42 452.08Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M851.86 436.84C851.5 437.04 851.02 436.98 850.63 436.84C850.62 435.38 850.51 433.92 850.3 432.47C850.72 432.44 851.18 432.48 851.61 432.44C851.77 433.81 851.7 435.47 851.86 436.84Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M846.79 428.33C847.72 427.88 848.42 426.94 848.56 425.91C848.72 424.81 848.28 423.71 848.36 422.6C848.42 421.77 848.76 420.99 848.89 420.17C849.01 419.35 848.86 418.38 848.17 417.92C847.71 417.61 847.08 417.65 846.61 417.94C846.38 418.49 846.24 419.06 846.21 419.55C846.14 420.58 846.39 421.62 846.28 422.65C846.18 423.61 845.78 424.51 845.43 425.41C845.03 426.43 844.69 427.49 844.55 428.58C845.3 428.75 846.1 428.67 846.79 428.33Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M856.38 428.58C856.15 427.23 855.51 425.98 854.72 424.87C853.9 423.7 852.9 422.62 852.44 421.27C851.83 419.47 852.13 416.38 849.76 415.76C849.12 415.59 848.54 415.72 848.05 416.04C848.13 416.06 848.22 416.07 848.3 416.09C849.15 416.31 849.92 416.91 850.25 417.73C850.94 419.43 849.7 421.49 850.5 423.14C850.9 423.96 851.73 424.49 852.28 425.22C853.26 426.5 853.3 428.28 853.01 429.87C852.82 430.89 852.46 431.97 851.61 432.56C850.96 433 850.13 433.09 849.34 433.09C848.27 433.1 847.18 432.99 846.2 432.57C845.72 432.37 845.27 432.09 844.88 431.75C845.17 432.49 845.6 433.16 846.21 433.66C847.51 434.74 849.35 434.9 851.03 434.7C852.75 434.5 854.53 433.88 855.56 432.49C856.38 431.4 856.61 429.93 856.38 428.58Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M849.34 433.09C850.13 433.09 850.96 433 851.61 432.56C852.46 431.97 852.82 430.89 853.01 429.87C853.3 428.28 853.26 426.5 852.28 425.22C851.73 424.49 850.9 423.96 850.5 423.14C849.7 421.49 850.94 419.43 850.25 417.73C849.92 416.91 849.15 416.31 848.3 416.09C848.22 416.07 848.13 416.06 848.05 416.04C847.42 416.46 846.93 417.18 846.61 417.94C847.08 417.65 847.71 417.61 848.17 417.92C848.86 418.38 849.01 419.35 848.89 420.17C848.76 420.99 848.42 421.77 848.36 422.6C848.28 423.71 848.72 424.81 848.56 425.91C848.42 426.94 847.72 427.88 846.79 428.33C846.1 428.67 845.3 428.75 844.55 428.58C844.51 428.96 844.48 429.34 844.49 429.72C844.5 430.41 844.64 431.1 844.88 431.75C845.27 432.09 845.72 432.37 846.2 432.57C847.18 432.99 848.27 433.1 849.34 433.09Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M865.88 442.21C865.47 442.4 864.95 442.32 864.53 442.15C864.6 440.55 864.56 438.95 864.41 437.36C864.87 437.35 865.37 437.41 865.83 437.4C865.93 438.9 865.77 440.71 865.88 442.21Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M860.8 432.67C861.84 432.22 862.65 431.24 862.86 430.12C863.09 428.93 862.67 427.7 862.82 426.5C862.93 425.6 863.34 424.76 863.52 423.87C863.7 422.99 863.59 421.93 862.86 421.39C862.37 421.03 861.68 421.04 861.16 421.32C860.88 421.91 860.7 422.52 860.64 423.06C860.51 424.18 860.72 425.33 860.55 426.44C860.39 427.49 859.9 428.44 859.47 429.41C858.98 430.5 858.56 431.64 858.35 432.81C859.15 433.04 860.03 433 860.8 432.67Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M871.24 433.45C871.07 431.96 870.43 430.57 869.63 429.31C868.8 427.99 867.77 426.76 867.34 425.27C866.77 423.27 867.26 419.92 864.71 419.11C864.02 418.89 863.38 419.01 862.83 419.33C862.92 419.35 863.01 419.37 863.1 419.4C864.02 419.68 864.82 420.38 865.14 421.29C865.8 423.17 864.34 425.36 865.12 427.2C865.51 428.12 866.39 428.74 866.95 429.56C867.96 431.01 867.9 432.95 867.5 434.67C867.24 435.77 866.79 436.93 865.83 437.53C865.1 437.98 864.19 438.03 863.33 437.99C862.16 437.94 860.98 437.76 859.93 437.25C859.42 437.01 858.94 436.68 858.54 436.29C858.81 437.11 859.25 437.86 859.89 438.44C861.25 439.69 863.24 439.96 865.08 439.83C866.97 439.7 868.95 439.13 870.14 437.67C871.09 436.51 871.42 434.93 871.24 433.45Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M863.33 437.99C864.19 438.03 865.1 437.98 865.83 437.53C866.79 436.93 867.24 435.77 867.5 434.67C867.9 432.95 867.96 431.01 866.95 429.56C866.39 428.74 865.51 428.12 865.12 427.2C864.34 425.36 865.8 423.17 865.14 421.29C864.82 420.38 864.02 419.68 863.1 419.4C863.01 419.37 862.92 419.35 862.83 419.33C862.12 419.75 861.54 420.51 861.16 421.32C861.68 421.04 862.37 421.03 862.86 421.39C863.59 421.93 863.7 422.99 863.52 423.87C863.34 424.76 862.93 425.6 862.82 426.5C862.67 427.7 863.09 428.93 862.86 430.12C862.65 431.24 861.84 432.22 860.8 432.67C860.03 433 859.15 433.04 858.35 432.81C858.28 433.22 858.23 433.63 858.22 434.05C858.2 434.8 858.31 435.57 858.54 436.29C858.94 436.68 859.42 437.01 859.93 437.25C860.98 437.76 862.16 437.94 863.33 437.99Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M665.7 434.76C665.34 434.99 664.85 434.96 664.44 434.84C664.36 433.35 664.16 431.86 663.87 430.39C664.3 430.34 664.77 430.35 665.2 430.29C665.44 431.68 665.46 433.38 665.7 434.76Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M660.05 426.37C660.98 425.85 661.63 424.86 661.72 423.8C661.82 422.67 661.32 421.56 661.33 420.42C661.35 419.58 661.65 418.76 661.74 417.91C661.82 417.07 661.61 416.09 660.88 415.66C660.39 415.37 659.75 415.45 659.29 415.76C659.08 416.34 658.97 416.93 658.97 417.43C658.96 418.49 659.27 419.54 659.22 420.6C659.17 421.58 658.81 422.52 658.5 423.46C658.15 424.52 657.86 425.63 657.78 426.74C658.55 426.87 659.37 426.75 660.05 426.37Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M669.85 426.08C669.55 424.72 668.82 423.48 667.95 422.38C667.05 421.24 665.97 420.19 665.43 418.84C664.7 417.03 664.83 413.87 662.38 413.36C661.71 413.23 661.14 413.4 660.65 413.75C660.74 413.76 660.82 413.77 660.91 413.79C661.79 413.96 662.61 414.53 662.99 415.35C663.79 417.04 662.64 419.22 663.55 420.86C664 421.68 664.87 422.17 665.48 422.88C666.56 424.13 666.69 425.94 666.49 427.58C666.35 428.64 666.05 429.76 665.21 430.41C664.57 430.9 663.73 431.03 662.92 431.08C661.83 431.15 660.71 431.09 659.68 430.72C659.19 430.54 658.71 430.29 658.3 429.96C658.63 430.7 659.11 431.36 659.76 431.84C661.15 432.86 663.03 432.93 664.74 432.63C666.48 432.32 668.27 431.6 669.24 430.12C670.01 428.96 670.16 427.45 669.85 426.08Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M662.92 431.08C663.73 431.03 664.57 430.9 665.21 430.41C666.05 429.76 666.35 428.64 666.49 427.58C666.69 425.94 666.56 424.13 665.48 422.88C664.87 422.17 664 421.68 663.55 420.86C662.64 419.22 663.79 417.04 662.99 415.35C662.61 414.53 661.79 413.96 660.91 413.79C660.82 413.77 660.74 413.76 660.65 413.75C660.03 414.21 659.57 414.97 659.29 415.76C659.75 415.45 660.39 415.37 660.88 415.66C661.61 416.09 661.82 417.07 661.74 417.91C661.65 418.76 661.35 419.58 661.33 420.42C661.32 421.56 661.82 422.67 661.72 423.8C661.63 424.86 660.98 425.85 660.05 426.37C659.37 426.75 658.55 426.87 657.78 426.74C657.75 427.13 657.75 427.52 657.78 427.91C657.83 428.61 658.01 429.31 658.3 429.96C658.71 430.29 659.19 430.54 659.68 430.72C660.71 431.09 661.83 431.15 662.92 431.08Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M676.75 427.88C676.3 428.09 675.74 427.99 675.29 427.8C675.39 426.07 675.36 424.33 675.22 422.61C675.72 422.59 676.26 422.67 676.76 422.66C676.86 424.29 676.66 426.25 676.75 427.88Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M671.36 417.46C672.5 416.99 673.39 415.93 673.63 414.72C673.9 413.44 673.46 412.1 673.63 410.79C673.76 409.82 674.22 408.92 674.43 407.96C674.64 407 674.52 405.85 673.74 405.25C673.22 404.85 672.47 404.86 671.9 405.16C671.59 405.79 671.38 406.45 671.31 407.03C671.15 408.25 671.38 409.5 671.17 410.71C670.99 411.84 670.45 412.87 669.97 413.91C669.42 415.09 668.94 416.32 668.7 417.59C669.57 417.84 670.53 417.81 671.36 417.46Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M682.68 418.44C682.51 416.83 681.84 415.31 680.99 413.93C680.1 412.49 679 411.14 678.56 409.52C677.96 407.34 678.53 403.72 675.78 402.81C675.03 402.56 674.34 402.68 673.74 403.02C673.83 403.04 673.93 403.07 674.03 403.1C675.02 403.42 675.88 404.18 676.22 405.17C676.91 407.23 675.3 409.58 676.12 411.59C676.54 412.59 677.47 413.27 678.08 414.17C679.15 415.76 679.07 417.86 678.61 419.72C678.31 420.91 677.81 422.17 676.76 422.8C675.96 423.28 674.97 423.32 674.04 423.27C672.78 423.2 671.5 422.99 670.36 422.43C669.81 422.15 669.3 421.79 668.87 421.36C669.15 422.25 669.61 423.08 670.3 423.72C671.76 425.09 673.92 425.41 675.92 425.29C677.97 425.17 680.12 424.58 681.44 423.01C682.48 421.77 682.86 420.05 682.68 418.44Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M674.04 423.27C674.97 423.32 675.96 423.28 676.76 422.8C677.81 422.17 678.31 420.91 678.61 419.72C679.07 417.86 679.15 415.76 678.08 414.17C677.47 413.27 676.54 412.59 676.12 411.59C675.3 409.58 676.91 407.23 676.22 405.17C675.88 404.18 675.02 403.42 674.03 403.1C673.93 403.07 673.83 403.04 673.74 403.02C672.96 403.46 672.33 404.28 671.9 405.16C672.47 404.86 673.22 404.85 673.74 405.25C674.52 405.85 674.64 407 674.43 407.96C674.22 408.92 673.76 409.82 673.63 410.79C673.46 412.1 673.9 413.44 673.63 414.72C673.39 415.93 672.5 416.99 671.36 417.46C670.53 417.81 669.57 417.84 668.7 417.59C668.62 418.03 668.56 418.48 668.55 418.93C668.51 419.75 668.62 420.58 668.87 421.36C669.3 421.79 669.81 422.15 670.36 422.43C671.5 422.99 672.78 423.2 674.04 423.27Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M679.57 443.52C679.21 443.71 678.74 443.65 678.35 443.5C678.38 442.07 678.3 440.63 678.12 439.2C678.53 439.17 678.99 439.22 679.4 439.19C679.53 440.54 679.44 442.17 679.57 443.52Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M674.74 435.06C675.67 434.63 676.37 433.73 676.53 432.72C676.71 431.64 676.3 430.54 676.4 429.45C676.47 428.64 676.82 427.88 676.96 427.07C677.1 426.27 676.97 425.32 676.3 424.85C675.85 424.54 675.23 424.57 674.76 424.84C674.53 425.37 674.38 425.93 674.34 426.41C674.25 427.43 674.48 428.46 674.35 429.47C674.24 430.41 673.82 431.29 673.46 432.16C673.05 433.16 672.69 434.2 672.54 435.26C673.27 435.44 674.06 435.38 674.74 435.06Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M684.17 435.49C683.97 434.16 683.36 432.92 682.61 431.8C681.82 430.64 680.86 429.56 680.44 428.23C679.87 426.44 680.22 423.42 677.9 422.76C677.27 422.58 676.71 422.7 676.22 423C676.3 423.02 676.38 423.03 676.46 423.06C677.29 423.29 678.04 423.89 678.35 424.7C679 426.38 677.74 428.39 678.49 430.03C678.87 430.85 679.67 431.38 680.2 432.1C681.14 433.38 681.15 435.13 680.83 436.69C680.63 437.69 680.25 438.74 679.4 439.31C678.75 439.73 677.94 439.8 677.17 439.79C676.11 439.78 675.04 439.64 674.08 439.22C673.62 439.01 673.18 438.73 672.8 438.38C673.07 439.12 673.48 439.78 674.07 440.29C675.33 441.38 677.14 441.57 678.79 441.4C680.48 441.24 682.25 440.67 683.29 439.32C684.11 438.25 684.37 436.82 684.17 435.49Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M677.17 439.79C677.94 439.8 678.75 439.73 679.4 439.31C680.25 438.74 680.63 437.69 680.83 436.69C681.15 435.13 681.14 433.38 680.2 432.1C679.67 431.38 678.87 430.85 678.49 430.03C677.74 428.39 679 426.38 678.35 424.7C678.04 423.89 677.29 423.29 676.46 423.06C676.38 423.03 676.3 423.02 676.22 423C675.58 423.4 675.09 424.09 674.76 424.84C675.23 424.57 675.85 424.54 676.3 424.85C676.97 425.32 677.1 426.27 676.96 427.07C676.82 427.88 676.47 428.64 676.4 429.45C676.3 430.54 676.71 431.64 676.53 432.72C676.37 433.73 675.67 434.63 674.74 435.06C674.06 435.38 673.27 435.44 672.54 435.26C672.49 435.63 672.46 436 672.46 436.38C672.46 437.06 672.57 437.75 672.8 438.38C673.18 438.73 673.62 439.01 674.08 439.22C675.04 439.64 676.11 439.78 677.17 439.79Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M690.78 430.92C690.36 431.09 689.86 430.98 689.46 430.79C689.61 429.23 689.66 427.65 689.59 426.08C690.05 426.09 690.54 426.18 690.99 426.19C691.01 427.67 690.76 429.44 690.78 430.92Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M686.3 421.26C687.35 420.88 688.19 419.96 688.46 418.87C688.75 417.71 688.41 416.48 688.62 415.31C688.77 414.43 689.22 413.63 689.45 412.76C689.68 411.9 689.62 410.85 688.93 410.28C688.47 409.9 687.8 409.88 687.26 410.13C686.96 410.69 686.74 411.28 686.65 411.8C686.47 412.9 686.62 414.04 686.39 415.13C686.18 416.15 685.64 417.06 685.17 417.99C684.63 419.03 684.15 420.13 683.88 421.28C684.66 421.54 685.53 421.55 686.3 421.26Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M696.52 422.6C696.43 421.13 695.88 419.72 695.16 418.44C694.42 417.1 693.47 415.83 693.13 414.34C692.67 412.34 693.34 409.08 690.87 408.15C690.2 407.89 689.57 407.98 689.01 408.26C689.1 408.29 689.19 408.31 689.27 408.34C690.16 408.67 690.91 409.4 691.18 410.31C691.73 412.2 690.18 414.27 690.84 416.12C691.18 417.05 692 417.7 692.52 418.54C693.43 420.02 693.27 421.93 692.78 423.59C692.47 424.67 691.96 425.78 690.98 426.31C690.24 426.72 689.34 426.72 688.5 426.64C687.36 426.53 686.2 426.28 685.2 425.73C684.71 425.46 684.26 425.11 683.88 424.7C684.1 425.52 684.49 426.29 685.09 426.9C686.36 428.19 688.31 428.57 690.13 428.54C691.98 428.52 693.96 428.06 695.22 426.69C696.21 425.6 696.62 424.06 696.52 422.6Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M688.5 426.64C689.34 426.72 690.24 426.72 690.98 426.31C691.96 425.78 692.47 424.67 692.78 423.59C693.27 421.93 693.43 420.02 692.52 418.54C692 417.7 691.18 417.05 690.84 416.12C690.18 414.27 691.73 412.2 691.18 410.31C690.91 409.4 690.16 408.67 689.27 408.34C689.19 408.31 689.1 408.29 689.01 408.26C688.29 408.63 687.69 409.35 687.26 410.13C687.8 409.88 688.47 409.9 688.93 410.28C689.62 410.85 689.68 411.9 689.45 412.76C689.22 413.63 688.77 414.43 688.62 415.31C688.41 416.48 688.75 417.71 688.46 418.87C688.19 419.96 687.35 420.88 686.3 421.26C685.53 421.55 684.66 421.54 683.88 421.28C683.79 421.67 683.72 422.08 683.69 422.49C683.63 423.23 683.69 423.99 683.88 424.7C684.26 425.11 684.71 425.46 685.2 425.73C686.2 426.28 687.36 426.53 688.5 426.64Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M72.01 391.6C71.65 391.83 71.16 391.8 70.75 391.68C70.66 390.19 70.47 388.7 70.17 387.24C70.6 387.18 71.07 387.19 71.5 387.13C71.74 388.52 71.77 390.22 72.01 391.6Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M66.36 383.21C67.28 382.69 67.94 381.7 68.03 380.64C68.13 379.51 67.62 378.4 67.64 377.27C67.65 376.42 67.96 375.6 68.04 374.76C68.13 373.91 67.91 372.94 67.18 372.5C66.7 372.22 66.06 372.29 65.59 372.61C65.39 373.18 65.28 373.77 65.27 374.27C65.26 375.33 65.58 376.38 65.52 377.44C65.48 378.42 65.11 379.36 64.8 380.3C64.45 381.37 64.17 382.47 64.09 383.58C64.86 383.71 65.67 383.59 66.36 383.21Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M76.16 382.93C75.85 381.56 75.12 380.32 74.26 379.22C73.36 378.08 72.28 377.03 71.73 375.68C71.01 373.87 71.14 370.71 68.69 370.21C68.02 370.07 67.44 370.24 66.96 370.59C67.04 370.6 67.13 370.61 67.21 370.63C68.1 370.8 68.91 371.38 69.3 372.19C70.1 373.88 68.95 376.06 69.85 377.7C70.31 378.52 71.18 379.01 71.79 379.72C72.86 380.97 73 382.79 72.79 384.43C72.66 385.48 72.35 386.6 71.51 387.25C70.87 387.74 70.03 387.87 69.23 387.92C68.14 387.99 67.02 387.93 65.99 387.56C65.49 387.39 65.02 387.13 64.6 386.8C64.93 387.54 65.41 388.2 66.06 388.68C67.45 389.71 69.34 389.77 71.04 389.47C72.78 389.17 74.57 388.44 75.55 386.96C76.32 385.8 76.47 384.29 76.16 382.93Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M69.23 387.92C70.03 387.87 70.87 387.74 71.51 387.25C72.35 386.6 72.66 385.48 72.79 384.43C73 382.79 72.86 380.97 71.79 379.72C71.18 379.01 70.31 378.52 69.85 377.7C68.95 376.06 70.1 373.88 69.3 372.19C68.91 371.38 68.1 370.8 67.21 370.63C67.13 370.61 67.04 370.6 66.96 370.59C66.33 371.05 65.87 371.81 65.59 372.61C66.06 372.29 66.7 372.22 67.18 372.5C67.91 372.94 68.13 373.91 68.04 374.76C67.96 375.6 67.65 376.42 67.64 377.27C67.62 378.4 68.13 379.51 68.03 380.64C67.94 381.7 67.28 382.69 66.36 383.21C65.67 383.59 64.86 383.71 64.09 383.58C64.06 383.97 64.06 384.36 64.09 384.75C64.14 385.45 64.31 386.16 64.6 386.8C65.02 387.13 65.49 387.39 65.99 387.56C67.02 387.93 68.14 387.99 69.23 387.92Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M83.05 384.72C82.61 384.93 82.05 384.83 81.59 384.64C81.69 382.91 81.67 381.17 81.52 379.45C82.03 379.44 82.57 379.51 83.07 379.5C83.16 381.13 82.96 383.09 83.05 384.72Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M77.67 374.3C78.81 373.83 79.69 372.78 79.94 371.57C80.2 370.28 79.77 368.94 79.94 367.64C80.07 366.66 80.53 365.76 80.74 364.8C80.95 363.84 80.83 362.69 80.05 362.09C79.52 361.7 78.78 361.7 78.2 362C77.89 362.63 77.69 363.29 77.61 363.87C77.46 365.09 77.68 366.34 77.48 367.55C77.29 368.68 76.75 369.71 76.27 370.75C75.73 371.93 75.25 373.16 75.01 374.43C75.88 374.68 76.83 374.65 77.67 374.3Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M88.99 375.28C88.82 373.67 88.14 372.15 87.29 370.77C86.41 369.33 85.31 367.98 84.86 366.36C84.27 364.18 84.84 360.56 82.09 359.65C81.34 359.4 80.65 359.52 80.04 359.86C80.14 359.89 80.24 359.91 80.33 359.94C81.32 360.26 82.19 361.03 82.52 362.01C83.22 364.07 81.61 366.42 82.43 368.43C82.84 369.43 83.78 370.11 84.39 371.01C85.46 372.6 85.37 374.7 84.91 376.56C84.62 377.76 84.12 379.01 83.06 379.64C82.26 380.12 81.28 380.16 80.35 380.11C79.08 380.05 77.8 379.83 76.67 379.27C76.12 379 75.6 378.64 75.17 378.2C75.45 379.1 75.92 379.92 76.6 380.56C78.07 381.93 80.23 382.25 82.23 382.14C84.27 382.02 86.43 381.42 87.74 379.85C88.79 378.61 89.16 376.9 88.99 375.28Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M80.35 380.11C81.28 380.16 82.26 380.12 83.06 379.64C84.12 379.01 84.62 377.76 84.91 376.56C85.37 374.7 85.46 372.6 84.39 371.01C83.78 370.11 82.84 369.43 82.43 368.43C81.61 366.42 83.22 364.07 82.52 362.01C82.19 361.03 81.32 360.26 80.33 359.94C80.24 359.91 80.14 359.89 80.04 359.86C79.26 360.3 78.63 361.12 78.2 362C78.78 361.7 79.52 361.7 80.05 362.09C80.83 362.69 80.95 363.84 80.74 364.8C80.53 365.76 80.07 366.66 79.94 367.64C79.77 368.94 80.2 370.28 79.94 371.57C79.69 372.78 78.81 373.83 77.67 374.3C76.83 374.65 75.88 374.68 75.01 374.43C74.93 374.87 74.87 375.32 74.85 375.77C74.82 376.59 74.93 377.42 75.17 378.2C75.6 378.64 76.12 379 76.67 379.27C77.8 379.83 79.08 380.05 80.35 380.11Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M85.87 400.36C85.51 400.55 85.04 400.49 84.66 400.34C84.68 398.91 84.6 397.47 84.42 396.04C84.84 396.01 85.29 396.06 85.71 396.03C85.84 397.38 85.74 399.01 85.87 400.36Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M81.05 391.91C81.98 391.47 82.67 390.57 82.84 389.56C83.01 388.48 82.6 387.38 82.7 386.3C82.78 385.48 83.13 384.72 83.27 383.91C83.41 383.11 83.27 382.16 82.6 381.69C82.15 381.38 81.54 381.41 81.07 381.68C80.83 382.21 80.69 382.77 80.64 383.26C80.56 384.27 80.78 385.3 80.66 386.31C80.54 387.25 80.13 388.13 79.77 389C79.35 390 79 391.04 78.85 392.1C79.58 392.28 80.37 392.22 81.05 391.91Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M90.47 392.33C90.27 391 89.66 389.76 88.91 388.64C88.13 387.48 87.17 386.4 86.74 385.07C86.17 383.28 86.53 380.26 84.21 379.6C83.58 379.42 83.01 379.54 82.52 379.84C82.6 379.86 82.68 379.88 82.76 379.9C83.6 380.13 84.34 380.74 84.65 381.54C85.3 383.22 84.04 385.23 84.8 386.87C85.17 387.69 85.97 388.22 86.51 388.94C87.45 390.22 87.45 391.97 87.14 393.53C86.93 394.53 86.56 395.59 85.71 396.15C85.06 396.57 84.25 396.64 83.47 396.63C82.42 396.62 81.35 396.49 80.39 396.06C79.92 395.85 79.48 395.57 79.11 395.23C79.37 395.96 79.79 396.63 80.38 397.13C81.64 398.22 83.44 398.41 85.1 398.25C86.79 398.08 88.56 397.51 89.6 396.16C90.42 395.1 90.67 393.66 90.47 392.33Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M83.47 396.63C84.25 396.64 85.06 396.57 85.71 396.15C86.56 395.59 86.93 394.53 87.14 393.53C87.45 391.97 87.45 390.22 86.51 388.94C85.97 388.22 85.17 387.69 84.8 386.87C84.04 385.23 85.3 383.22 84.65 381.54C84.34 380.74 83.6 380.13 82.76 379.9C82.68 379.88 82.6 379.86 82.52 379.84C81.89 380.24 81.39 380.94 81.07 381.68C81.54 381.41 82.15 381.38 82.6 381.69C83.27 382.16 83.41 383.11 83.27 383.91C83.13 384.72 82.78 385.48 82.7 386.3C82.6 387.38 83.01 388.48 82.84 389.56C82.67 390.57 81.98 391.47 81.05 391.91C80.37 392.22 79.58 392.28 78.85 392.1C78.79 392.47 78.76 392.85 78.76 393.22C78.76 393.9 78.88 394.59 79.11 395.23C79.48 395.57 79.92 395.85 80.39 396.06C81.35 396.49 82.42 396.62 83.47 396.63Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M97.08 387.76C96.67 387.93 96.16 387.82 95.76 387.63C95.92 386.07 95.96 384.49 95.9 382.92C96.35 382.93 96.84 383.02 97.3 383.03C97.32 384.51 97.06 386.28 97.08 387.76Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M92.6 378.11C93.65 377.72 94.5 376.8 94.77 375.71C95.06 374.55 94.72 373.32 94.92 372.15C95.08 371.27 95.53 370.47 95.76 369.61C95.98 368.74 95.92 367.7 95.24 367.13C94.78 366.75 94.1 366.72 93.57 366.97C93.26 367.53 93.05 368.12 92.96 368.65C92.77 369.74 92.92 370.88 92.7 371.97C92.48 372.99 91.95 373.91 91.47 374.83C90.93 375.88 90.45 376.97 90.19 378.12C90.96 378.38 91.83 378.39 92.6 378.11Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M102.83 379.44C102.74 377.97 102.19 376.56 101.47 375.28C100.72 373.94 99.78 372.67 99.44 371.18C98.98 369.18 99.64 365.92 97.18 364.99C96.51 364.74 95.88 364.82 95.32 365.1C95.41 365.13 95.49 365.15 95.58 365.18C96.47 365.51 97.22 366.24 97.48 367.15C98.04 369.04 96.48 371.11 97.15 372.97C97.48 373.89 98.31 374.54 98.82 375.38C99.73 376.86 99.57 378.77 99.09 380.44C98.77 381.51 98.27 382.62 97.29 383.16C96.54 383.56 95.65 383.56 94.81 383.48C93.66 383.37 92.51 383.12 91.5 382.57C91.01 382.3 90.56 381.95 90.19 381.54C90.41 382.37 90.8 383.13 91.39 383.74C92.67 385.04 94.61 385.41 96.43 385.39C98.29 385.36 100.27 384.9 101.52 383.53C102.52 382.44 102.92 380.91 102.83 379.44Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M94.81 383.48C95.65 383.56 96.54 383.56 97.29 383.16C98.27 382.62 98.77 381.51 99.09 380.44C99.57 378.77 99.73 376.86 98.82 375.38C98.31 374.54 97.48 373.89 97.15 372.97C96.48 371.11 98.04 369.04 97.48 367.15C97.22 366.24 96.47 365.51 95.58 365.18C95.49 365.15 95.41 365.13 95.32 365.1C94.6 365.47 93.99 366.19 93.57 366.97C94.1 366.72 94.78 366.75 95.24 367.13C95.92 367.7 95.98 368.74 95.76 369.61C95.53 370.47 95.08 371.27 94.92 372.15C94.72 373.32 95.06 374.55 94.77 375.71C94.5 376.8 93.65 377.72 92.6 378.11C91.83 378.39 90.96 378.38 90.19 378.12C90.09 378.52 90.03 378.92 89.99 379.33C89.93 380.07 89.99 380.83 90.19 381.54C90.56 381.95 91.01 382.3 91.5 382.57C92.51 383.12 93.66 383.37 94.81 383.48Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M563.15 435.95C562.79 436.09 562.36 435.99 562.02 435.82C562.18 434.48 562.24 433.13 562.2 431.79C562.59 431.8 563.01 431.89 563.4 431.91C563.39 433.17 563.15 434.68 563.15 435.95Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M559.45 427.62C560.36 427.31 561.09 426.53 561.34 425.61C561.6 424.62 561.33 423.56 561.52 422.56C561.66 421.81 562.06 421.13 562.27 420.4C562.48 419.66 562.44 418.77 561.86 418.27C561.47 417.94 560.9 417.9 560.44 418.11C560.17 418.59 559.98 419.09 559.89 419.54C559.72 420.48 559.83 421.45 559.62 422.38C559.42 423.25 558.95 424.02 558.53 424.81C558.06 425.69 557.63 426.63 557.38 427.6C558.05 427.84 558.79 427.86 559.45 427.62Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M568.18 428.91C568.12 427.65 567.67 426.44 567.08 425.33C566.46 424.18 565.66 423.08 565.39 421.8C565.03 420.08 565.65 417.3 563.55 416.47C562.98 416.24 562.44 416.3 561.96 416.54C562.03 416.56 562.11 416.59 562.18 416.61C562.94 416.91 563.57 417.54 563.78 418.32C564.23 419.95 562.87 421.7 563.41 423.29C563.69 424.09 564.38 424.66 564.81 425.38C565.57 426.66 565.41 428.29 564.96 429.71C564.68 430.62 564.23 431.57 563.39 432.01C562.75 432.35 561.98 432.33 561.26 432.25C560.28 432.14 559.3 431.91 558.45 431.43C558.03 431.19 557.65 430.89 557.34 430.53C557.51 431.24 557.84 431.9 558.34 432.43C559.41 433.55 561.07 433.9 562.62 433.91C564.21 433.91 565.91 433.54 567.01 432.39C567.87 431.48 568.24 430.17 568.18 428.91Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M561.26 432.25C561.98 432.33 562.75 432.35 563.39 432.01C564.23 431.57 564.68 430.62 564.96 429.71C565.41 428.29 565.57 426.66 564.81 425.38C564.38 424.66 563.69 424.09 563.41 423.29C562.87 421.7 564.23 419.95 563.78 418.32C563.57 417.54 562.94 416.91 562.18 416.61C562.11 416.59 562.03 416.56 561.96 416.54C561.34 416.85 560.81 417.45 560.44 418.11C560.9 417.9 561.47 417.94 561.86 418.27C562.44 418.77 562.48 419.66 562.27 420.4C562.06 421.13 561.66 421.81 561.52 422.56C561.33 423.56 561.6 424.62 561.34 425.61C561.09 426.53 560.36 427.31 559.45 427.62C558.79 427.86 558.05 427.84 557.38 427.6C557.3 427.94 557.24 428.28 557.2 428.64C557.14 429.27 557.18 429.92 557.34 430.53C557.65 430.89 558.03 431.19 558.45 431.43C559.3 431.91 560.28 432.14 561.26 432.25Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M572.68 445.14C572.24 445.38 571.67 445.31 571.19 445.15C571.18 443.39 571.05 441.63 570.79 439.88C571.3 439.84 571.85 439.89 572.36 439.84C572.56 441.49 572.48 443.49 572.68 445.14Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M566.55 434.91C567.67 434.36 568.51 433.23 568.68 431.99C568.86 430.66 568.34 429.33 568.43 428C568.49 427 568.9 426.06 569.05 425.07C569.21 424.08 569.01 422.92 568.18 422.37C567.63 422 566.87 422.04 566.31 422.39C566.03 423.05 565.87 423.74 565.83 424.33C565.75 425.57 566.05 426.83 565.93 428.07C565.81 429.22 565.32 430.31 564.9 431.39C564.42 432.62 564.02 433.9 563.86 435.21C564.76 435.41 565.72 435.32 566.55 434.91Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M578.1 435.18C577.82 433.56 577.04 432.06 576.09 430.71C575.1 429.31 573.9 428.01 573.34 426.39C572.6 424.21 572.95 420.5 570.1 419.75C569.32 419.55 568.63 419.72 568.04 420.1C568.14 420.12 568.24 420.14 568.33 420.16C569.36 420.42 570.29 421.15 570.69 422.13C571.53 424.17 570.04 426.66 571.01 428.64C571.49 429.64 572.48 430.26 573.16 431.14C574.34 432.68 574.39 434.82 574.05 436.74C573.83 437.97 573.4 439.27 572.37 439.98C571.59 440.52 570.59 440.63 569.64 440.64C568.35 440.65 567.04 440.51 565.85 440.01C565.27 439.77 564.73 439.44 564.26 439.03C564.61 439.92 565.13 440.72 565.87 441.33C567.44 442.62 569.65 442.81 571.68 442.57C573.74 442.32 575.89 441.57 577.13 439.9C578.11 438.57 578.38 436.81 578.1 435.18Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M569.64 440.64C570.59 440.63 571.59 440.52 572.37 439.98C573.4 439.27 573.83 437.97 574.05 436.74C574.39 434.82 574.34 432.68 573.16 431.14C572.48 430.26 571.49 429.64 571.01 428.64C570.04 426.66 571.53 424.17 570.69 422.13C570.29 421.15 569.36 420.42 568.33 420.16C568.24 420.14 568.14 420.12 568.04 420.1C567.27 420.6 566.68 421.47 566.31 422.39C566.87 422.04 567.63 422 568.18 422.37C569.01 422.92 569.21 424.08 569.05 425.07C568.9 426.06 568.49 427 568.43 428C568.34 429.33 568.86 430.66 568.68 431.99C568.51 433.23 567.67 434.36 566.55 434.91C565.72 435.32 564.76 435.41 563.86 435.21C563.8 435.66 563.77 436.12 563.78 436.58C563.8 437.41 563.96 438.25 564.26 439.03C564.73 439.44 565.27 439.77 565.85 440.01C567.04 440.51 568.35 440.65 569.64 440.64Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M582.91 435.92C582.51 436.09 582.02 435.98 581.63 435.8C581.77 434.28 581.81 432.75 581.74 431.23C582.18 431.23 582.66 431.32 583.1 431.33C583.12 432.76 582.88 434.48 582.91 435.92Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M578.52 426.57C579.53 426.19 580.35 425.29 580.61 424.23C580.88 423.11 580.54 421.91 580.74 420.77C580.88 419.91 581.32 419.14 581.54 418.3C581.75 417.46 581.69 416.44 581.02 415.89C580.57 415.52 579.92 415.5 579.4 415.75C579.11 416.29 578.9 416.87 578.82 417.38C578.64 418.45 578.79 419.55 578.58 420.61C578.37 421.6 577.86 422.49 577.4 423.39C576.88 424.41 576.42 425.48 576.17 426.59C576.93 426.84 577.77 426.84 578.52 426.57Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M588.45 427.81C588.36 426.38 587.81 425.02 587.11 423.78C586.38 422.48 585.45 421.26 585.12 419.81C584.67 417.87 585.29 414.7 582.9 413.81C582.25 413.56 581.63 413.65 581.09 413.93C581.18 413.95 581.26 413.97 581.34 414C582.21 414.32 582.94 415.02 583.2 415.91C583.75 417.74 582.25 419.76 582.91 421.55C583.23 422.45 584.04 423.08 584.54 423.89C585.43 425.33 585.29 427.18 584.82 428.8C584.52 429.84 584.04 430.92 583.09 431.45C582.37 431.84 581.5 431.85 580.68 431.77C579.57 431.67 578.45 431.44 577.47 430.9C576.99 430.65 576.55 430.31 576.19 429.92C576.4 430.71 576.79 431.46 577.37 432.04C578.61 433.29 580.5 433.65 582.27 433.62C584.07 433.58 585.99 433.13 587.2 431.79C588.16 430.73 588.55 429.24 588.45 427.81Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M580.68 431.77C581.5 431.85 582.37 431.84 583.09 431.45C584.04 430.92 584.52 429.84 584.82 428.8C585.29 427.18 585.43 425.33 584.54 423.89C584.04 423.08 583.23 422.45 582.91 421.55C582.25 419.76 583.75 417.74 583.2 415.91C582.94 415.02 582.21 414.32 581.34 414C581.26 413.97 581.18 413.95 581.09 413.93C580.39 414.29 579.81 414.99 579.4 415.75C579.92 415.5 580.57 415.52 581.02 415.89C581.69 416.44 581.75 417.46 581.54 418.3C581.32 419.14 580.88 419.91 580.74 420.77C580.54 421.91 580.88 423.11 580.61 424.23C580.35 425.29 579.53 426.19 578.52 426.57C577.77 426.84 576.93 426.84 576.17 426.59C576.08 426.98 576.02 427.37 575.99 427.77C575.93 428.48 576 429.22 576.19 429.92C576.55 430.31 576.99 430.65 577.47 430.9C578.45 431.44 579.57 431.67 580.68 431.77Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M606.33 427.95C606.04 428.07 605.68 427.99 605.39 427.85C605.5 426.74 605.54 425.62 605.49 424.51C605.82 424.51 606.16 424.58 606.49 424.59C606.5 425.64 606.32 426.89 606.33 427.95Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M603.16 421.08C603.9 420.81 604.5 420.16 604.7 419.39C604.9 418.56 604.66 417.69 604.81 416.85C604.92 416.23 605.24 415.66 605.41 415.05C605.57 414.44 605.53 413.69 605.04 413.29C604.71 413.02 604.23 413 603.85 413.17C603.64 413.57 603.49 413.99 603.42 414.37C603.29 415.15 603.39 415.95 603.23 416.73C603.08 417.45 602.7 418.1 602.36 418.75C601.97 419.5 601.63 420.28 601.44 421.09C601.99 421.28 602.61 421.28 603.16 421.08Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M610.42 422.04C610.36 421 609.97 420 609.46 419.08C608.93 418.13 608.26 417.23 608.02 416.17C607.7 414.75 608.17 412.44 606.42 411.77C605.95 411.59 605.5 411.65 605.1 411.85C605.16 411.87 605.22 411.89 605.28 411.91C605.91 412.14 606.45 412.66 606.64 413.31C607.03 414.65 605.92 416.12 606.39 417.44C606.63 418.09 607.21 418.56 607.58 419.15C608.22 420.21 608.11 421.56 607.76 422.75C607.54 423.51 607.18 424.3 606.48 424.68C605.95 424.96 605.32 424.96 604.72 424.9C603.9 424.82 603.09 424.65 602.37 424.25C602.02 424.06 601.7 423.81 601.44 423.52C601.59 424.11 601.87 424.65 602.29 425.08C603.2 426.01 604.58 426.28 605.87 426.26C607.19 426.24 608.6 425.92 609.49 424.94C610.2 424.18 610.48 423.08 610.42 422.04Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M604.72 424.9C605.32 424.96 605.95 424.96 606.48 424.68C607.18 424.3 607.54 423.51 607.76 422.75C608.11 421.56 608.22 420.21 607.58 419.15C607.21 418.56 606.63 418.09 606.39 417.44C605.92 416.12 607.03 414.65 606.64 413.31C606.45 412.66 605.91 412.14 605.28 411.91C605.22 411.89 605.16 411.87 605.1 411.85C604.59 412.11 604.16 412.62 603.85 413.17C604.23 413 604.71 413.02 605.04 413.29C605.53 413.69 605.57 414.44 605.41 415.05C605.24 415.66 604.92 416.23 604.81 416.85C604.66 417.69 604.9 418.56 604.7 419.39C604.5 420.16 603.9 420.81 603.16 421.08C602.61 421.28 601.99 421.28 601.44 421.09C601.37 421.37 601.33 421.66 601.3 421.95C601.26 422.47 601.3 423.01 601.44 423.52C601.7 423.81 602.02 424.06 602.37 424.25C603.09 424.65 603.9 424.82 604.72 424.9Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M624 421.61C624.17 422.53 624.21 423.47 624.13 424.4C624.56 424.71 625.18 424.74 625.62 424.45C625.75 423.52 625.67 422.53 625.41 421.63C625.06 421.64 624.35 421.6 624 421.61Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M622.89 417.24C624.22 416.82 625.21 415.65 625.77 414.37C626.38 412.97 626.55 411.33 625.97 409.92C625.55 408.91 624.7 408.06 623.69 407.67C622.49 407.8 621.32 408.26 620.33 408.96C619.44 409.59 618.71 410.46 618.32 411.48C617.86 412.69 617.88 414.04 618.16 415.31C618.34 415.6 618.55 415.88 618.79 416.12C619.84 417.18 621.47 417.69 622.89 417.24Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M625.67 419.84C627.84 418.43 629.15 415.81 629.03 413.23C628.95 411.36 628.15 409.56 626.87 408.2C626.43 407.98 625.95 407.81 625.42 407.72C624.84 407.63 624.26 407.61 623.69 407.67C624.7 408.06 625.55 408.91 625.97 409.92C626.55 411.33 626.38 412.97 625.77 414.37C625.21 415.65 624.22 416.82 622.89 417.24C621.47 417.69 619.84 417.18 618.79 416.12C618.55 415.88 618.34 415.6 618.16 415.31C618.16 415.35 618.17 415.39 618.18 415.43C618.49 416.74 619.06 417.96 619.66 419.16C619.96 419.75 620.28 420.35 620.66 420.9C622.38 421.16 624.21 420.79 625.67 419.84Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M629.56 411.15C628.96 409.85 628.07 408.8 626.87 408.2C628.15 409.56 628.95 411.36 629.03 413.23C629.15 415.81 627.84 418.43 625.67 419.84C624.21 420.79 622.38 421.16 620.66 420.9C621.12 421.56 621.67 422.14 622.38 422.52C623.81 423.27 625.65 422.98 626.98 422.05C628.32 421.13 629.19 419.66 629.72 418.13C630.49 415.87 630.56 413.32 629.56 411.15Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M525.17 410.66C525.31 411.27 525.38 411.9 525.36 412.52C525.66 412.71 526.08 412.71 526.36 412.5C526.41 411.87 526.32 411.21 526.11 410.62C525.87 410.64 525.4 410.64 525.17 410.66Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M524.25 407.78C525.13 407.45 525.74 406.63 526.07 405.75C526.42 404.79 526.47 403.68 526.02 402.76C525.7 402.1 525.1 401.57 524.41 401.35C523.61 401.48 522.85 401.83 522.21 402.34C521.64 402.8 521.19 403.4 520.97 404.1C520.71 404.93 520.77 405.84 521.01 406.67C521.14 406.86 521.29 407.04 521.46 407.19C522.21 407.86 523.32 408.14 524.25 407.78Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M526.21 409.41C527.61 408.38 528.38 406.58 528.2 404.86C528.08 403.61 527.47 402.44 526.56 401.58C526.26 401.45 525.93 401.35 525.57 401.32C525.18 401.27 524.79 401.29 524.41 401.35C525.1 401.57 525.7 402.1 526.02 402.76C526.47 403.68 526.42 404.79 526.07 405.75C525.74 406.63 525.13 407.45 524.25 407.78C523.32 408.14 522.21 407.86 521.46 407.19C521.29 407.04 521.14 406.86 521.01 406.67C521.02 406.7 521.02 406.73 521.03 406.75C521.29 407.61 521.72 408.41 522.16 409.19C522.39 409.57 522.63 409.96 522.9 410.31C524.07 410.42 525.27 410.1 526.21 409.41Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M528.47 403.44C528.02 402.6 527.39 401.93 526.56 401.58C527.47 402.44 528.08 403.61 528.2 404.86C528.38 406.58 527.61 408.38 526.21 409.41C525.27 410.1 524.07 410.42 522.9 410.31C523.24 410.74 523.62 411.11 524.11 411.33C525.1 411.78 526.32 411.51 527.18 410.84C528.03 410.17 528.56 409.16 528.85 408.11C529.28 406.57 529.23 404.85 528.47 403.44Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M533.14 415.09C533.23 415.86 533.22 416.65 533.1 417.42C533.44 417.69 533.96 417.76 534.34 417.54C534.49 416.77 534.48 415.95 534.31 415.19C534.02 415.18 533.43 415.1 533.14 415.09Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M532.45 411.41C533.58 411.13 534.46 410.21 534.99 409.18C535.58 408.05 535.8 406.69 535.39 405.49C535.1 404.63 534.44 403.88 533.62 403.51C532.61 403.55 531.62 403.87 530.76 404.4C529.99 404.87 529.34 405.55 528.96 406.38C528.51 407.36 528.45 408.49 528.62 409.55C528.76 409.81 528.91 410.05 529.1 410.26C529.92 411.2 531.24 411.71 532.45 411.41Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M534.62 413.71C536.5 412.65 537.72 410.55 537.76 408.4C537.79 406.85 537.22 405.31 536.23 404.11C535.88 403.9 535.48 403.74 535.05 403.64C534.58 403.53 534.1 403.49 533.62 403.51C534.44 403.88 535.1 404.63 535.39 405.49C535.8 406.69 535.58 408.05 534.99 409.18C534.46 410.21 533.58 411.13 532.45 411.41C531.24 411.71 529.92 411.2 529.1 410.26C528.91 410.05 528.76 409.81 528.62 409.55C528.62 409.59 528.63 409.62 528.63 409.66C528.82 410.76 529.23 411.8 529.66 412.83C529.88 413.34 530.12 413.85 530.4 414.33C531.82 414.63 533.36 414.42 534.62 413.71Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M538.31 406.7C537.88 405.59 537.2 404.68 536.23 404.11C537.22 405.31 537.79 406.85 537.76 408.4C537.72 410.55 536.5 412.65 534.62 413.71C533.36 414.42 531.82 414.63 530.4 414.33C530.75 414.9 531.18 415.41 531.75 415.76C532.9 416.46 534.44 416.32 535.59 415.62C536.75 414.92 537.55 413.75 538.07 412.51C538.83 410.67 539.02 408.55 538.31 406.7Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M489.07 409.49C489.17 410.24 489.16 411.01 489.05 411.76C489.39 412.03 489.9 412.08 490.27 411.86C490.41 411.12 490.38 410.31 490.21 409.57C489.93 409.56 489.36 409.5 489.07 409.49Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M488.36 405.9C489.46 405.62 490.31 404.71 490.82 403.7C491.38 402.59 491.58 401.26 491.17 400.09C490.87 399.25 490.22 398.53 489.42 398.17C488.44 398.23 487.47 398.55 486.64 399.07C485.89 399.55 485.26 400.22 484.9 401.03C484.47 401.99 484.43 403.09 484.6 404.13C484.74 404.37 484.89 404.61 485.08 404.82C485.88 405.72 487.19 406.2 488.36 405.9Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M490.51 408.13C492.32 407.07 493.5 405.01 493.52 402.91C493.53 401.39 492.95 399.9 491.98 398.74C491.63 398.54 491.24 398.39 490.82 398.29C490.36 398.18 489.89 398.15 489.42 398.17C490.22 398.53 490.87 399.25 491.17 400.09C491.58 401.26 491.38 402.59 490.82 403.7C490.31 404.71 489.46 405.62 488.36 405.9C487.19 406.2 485.88 405.72 485.08 404.82C484.89 404.61 484.74 404.37 484.6 404.13C484.61 404.16 484.61 404.2 484.62 404.23C484.81 405.3 485.22 406.32 485.66 407.31C485.87 407.81 486.11 408.31 486.39 408.77C487.78 409.05 489.28 408.84 490.51 408.13Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M494.03 401.25C493.6 400.17 492.92 399.28 491.98 398.74C492.95 399.9 493.53 401.39 493.52 402.91C493.5 405.01 492.32 407.07 490.51 408.13C489.28 408.84 487.78 409.05 486.39 408.77C486.74 409.33 487.16 409.82 487.72 410.16C488.85 410.83 490.35 410.67 491.47 409.98C492.59 409.29 493.37 408.14 493.86 406.92C494.59 405.12 494.75 403.05 494.03 401.25Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M450.92 409.11C451.09 410.18 451.1 411.27 450.97 412.35C451.46 412.71 452.18 412.77 452.7 412.45C452.87 411.38 452.82 410.24 452.55 409.19C452.14 409.19 451.33 409.11 450.92 409.11Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M449.79 404.03C451.35 403.6 452.53 402.28 453.22 400.82C453.97 399.22 454.23 397.33 453.6 395.68C453.15 394.5 452.2 393.49 451.05 393.01C449.65 393.12 448.29 393.6 447.12 394.38C446.07 395.08 445.2 396.05 444.72 397.22C444.14 398.6 444.11 400.16 444.39 401.64C444.59 401.98 444.82 402.3 445.09 402.6C446.27 403.86 448.13 404.5 449.79 404.03Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M452.92 407.12C455.47 405.57 457.07 402.6 457.03 399.61C457 397.45 456.13 395.35 454.71 393.73C454.2 393.46 453.65 393.25 453.04 393.13C452.38 392.99 451.71 392.96 451.05 393.01C452.2 393.49 453.15 394.5 453.6 395.68C454.23 397.33 453.97 399.22 453.22 400.82C452.53 402.28 451.35 403.6 449.79 404.03C448.13 404.5 446.27 403.86 445.09 402.6C444.82 402.3 444.59 401.98 444.39 401.64C444.4 401.68 444.41 401.73 444.41 401.78C444.72 403.29 445.34 404.73 445.99 406.13C446.32 406.83 446.67 407.53 447.09 408.18C449.07 408.53 451.19 408.18 452.92 407.12Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M457.7 397.23C457.06 395.71 456.07 394.46 454.71 393.73C456.13 395.35 457 397.45 457.03 399.61C457.07 402.6 455.47 405.57 452.92 407.12C451.19 408.18 449.07 408.53 447.09 408.18C447.6 408.96 448.21 409.65 449.02 410.11C450.65 411.03 452.78 410.75 454.35 409.73C455.92 408.71 456.99 407.05 457.65 405.3C458.62 402.72 458.78 399.76 457.7 397.23Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M430.38 415.89C430.29 417.01 430.05 418.12 429.66 419.17C430.07 419.65 430.78 419.89 431.38 419.68C431.81 418.64 432.02 417.48 432 416.36C431.6 416.26 430.79 415.99 430.38 415.89Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M430.44 410.5C432.11 410.43 433.62 409.38 434.66 408.07C435.8 406.63 436.5 404.78 436.26 402.97C436.08 401.67 435.36 400.43 434.31 399.67C432.88 399.45 431.39 399.62 430.02 400.13C428.8 400.58 427.69 401.36 426.93 402.42C426.02 403.68 425.62 405.25 425.55 406.81C425.67 407.2 425.83 407.58 426.04 407.94C426.92 409.49 428.65 410.58 430.44 410.5Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M432.86 414.36C435.8 413.39 438.12 410.77 438.79 407.75C439.27 405.56 438.89 403.23 437.83 401.26C437.39 400.87 436.88 400.53 436.3 400.26C435.66 399.97 434.99 399.78 434.31 399.67C435.36 400.43 436.08 401.67 436.26 402.97C436.5 404.78 435.8 406.63 434.66 408.07C433.62 409.38 432.11 410.43 430.44 410.5C428.65 410.58 426.92 409.49 426.04 407.94C425.83 407.58 425.67 407.2 425.55 406.81C425.55 406.86 425.55 406.91 425.55 406.96C425.5 408.56 425.79 410.15 426.11 411.72C426.27 412.5 426.46 413.3 426.73 414.05C428.65 414.87 430.87 415.01 432.86 414.36Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M440.03 405.5C439.74 403.81 439.03 402.33 437.83 401.26C438.89 403.23 439.27 405.56 438.79 407.75C438.12 410.77 435.8 413.39 432.86 414.36C430.87 415.01 428.65 414.87 426.73 414.05C427.06 414.96 427.52 415.8 428.22 416.45C429.65 417.76 431.87 417.99 433.69 417.33C435.52 416.67 436.99 415.25 438.07 413.63C439.66 411.26 440.52 408.32 440.03 405.5Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M830.39 457.8C830.62 458.61 830.73 459.46 830.73 460.31C831.15 460.55 831.71 460.53 832.09 460.22C832.12 459.38 831.97 458.49 831.66 457.7C831.34 457.74 830.7 457.76 830.39 457.8Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M829.01 453.96C830.18 453.47 830.97 452.33 831.37 451.13C831.8 449.82 831.81 448.32 831.17 447.1C830.71 446.22 829.87 445.53 828.93 445.27C827.85 445.49 826.84 446 826.01 446.71C825.26 447.36 824.68 448.2 824.41 449.15C824.1 450.28 824.22 451.5 824.58 452.62C824.77 452.87 824.98 453.1 825.22 453.3C826.26 454.17 827.77 454.49 829.01 453.96Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M831.74 456.06C833.58 454.61 834.53 452.14 834.21 449.82C833.98 448.15 833.1 446.6 831.84 445.47C831.42 445.31 830.97 445.2 830.49 445.17C829.96 445.13 829.44 445.17 828.93 445.27C829.87 445.53 830.71 446.22 831.17 447.1C831.81 448.32 831.8 449.82 831.37 451.13C830.97 452.33 830.18 453.47 829.01 453.96C827.77 454.49 826.26 454.17 825.22 453.3C824.98 453.1 824.77 452.87 824.58 452.62C824.6 452.65 824.61 452.69 824.62 452.73C825 453.88 825.62 454.93 826.26 455.96C826.58 456.47 826.92 456.98 827.31 457.44C828.89 457.53 830.5 457.05 831.74 456.06Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M834.51 447.91C833.86 446.79 832.97 445.92 831.84 445.47C833.1 446.6 833.98 448.15 834.21 449.82C834.53 452.14 833.58 454.61 831.74 456.06C830.5 457.05 828.89 457.53 827.31 457.44C827.78 458 828.33 458.48 829 458.76C830.36 459.31 831.99 458.9 833.11 457.95C834.23 457 834.9 455.61 835.24 454.18C835.75 452.08 835.59 449.77 834.51 447.91Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M836.65 464.15C836.78 464.86 836.8 465.58 836.73 466.29C837.06 466.52 837.54 466.55 837.88 466.33C837.98 465.62 837.92 464.87 837.73 464.18C837.46 464.18 836.92 464.15 836.65 464.15Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M835.83 460.81C836.85 460.5 837.62 459.61 838.05 458.64C838.53 457.57 838.67 456.32 838.23 455.23C837.91 454.46 837.27 453.81 836.5 453.51C835.58 453.6 834.69 453.94 833.93 454.47C833.24 454.95 832.68 455.6 832.38 456.38C832.02 457.3 832.02 458.34 832.23 459.31C832.36 459.53 832.52 459.74 832.71 459.93C833.5 460.75 834.74 461.15 835.83 460.81Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M837.94 462.81C839.61 461.75 840.62 459.76 840.55 457.78C840.5 456.36 839.89 454.98 838.93 453.93C838.59 453.76 838.22 453.63 837.82 453.56C837.38 453.48 836.94 453.46 836.5 453.51C837.27 453.81 837.91 454.46 838.23 455.23C838.67 456.32 838.53 457.57 838.05 458.64C837.62 459.61 836.85 460.5 835.83 460.81C834.74 461.15 833.5 460.75 832.71 459.93C832.52 459.74 832.36 459.53 832.23 459.31C832.23 459.34 832.24 459.37 832.25 459.4C832.47 460.4 832.9 461.34 833.35 462.25C833.58 462.71 833.82 463.17 834.11 463.59C835.42 463.8 836.82 463.53 837.94 462.81Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M840.96 456.2C840.51 455.2 839.84 454.4 838.93 453.93C839.89 454.98 840.5 456.36 840.55 457.78C840.62 459.76 839.61 461.75 837.94 462.81C836.82 463.53 835.42 463.8 834.11 463.59C834.45 464.1 834.87 464.55 835.41 464.84C836.5 465.42 837.9 465.21 838.93 464.51C839.95 463.81 840.63 462.7 841.04 461.53C841.65 459.81 841.71 457.86 840.96 456.2Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M895.58 444.09C895.76 444.82 895.83 445.57 895.81 446.31C896.16 446.54 896.66 446.54 897 446.28C897.06 445.54 896.95 444.76 896.71 444.05C896.43 444.07 895.86 444.07 895.58 444.09Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M894.5 440.67C895.55 440.27 896.28 439.3 896.67 438.25C897.09 437.11 897.15 435.79 896.63 434.7C896.25 433.91 895.53 433.27 894.71 433.01C893.76 433.17 892.85 433.58 892.09 434.19C891.41 434.73 890.87 435.45 890.61 436.28C890.29 437.27 890.36 438.34 890.64 439.34C890.8 439.56 890.98 439.77 891.19 439.96C892.07 440.76 893.39 441.09 894.5 440.67Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M896.83 442.61C898.5 441.39 899.42 439.25 899.22 437.2C899.07 435.72 898.34 434.32 897.27 433.29C896.91 433.13 896.52 433.02 896.09 432.97C895.63 432.92 895.16 432.94 894.71 433.01C895.53 433.27 896.25 433.91 896.63 434.7C897.15 435.79 897.09 437.11 896.67 438.25C896.28 439.3 895.55 440.27 894.5 440.67C893.39 441.09 892.07 440.76 891.19 439.96C890.98 439.77 890.8 439.56 890.64 439.34C890.65 439.37 890.66 439.4 890.67 439.44C890.97 440.46 891.48 441.41 892.01 442.34C892.28 442.79 892.56 443.26 892.89 443.68C894.27 443.81 895.71 443.43 896.83 442.61Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M899.54 435.52C899 434.51 898.25 433.71 897.27 433.29C898.34 434.32 899.07 435.72 899.22 437.2C899.42 439.25 898.5 441.39 896.83 442.61C895.71 443.43 894.27 443.81 892.89 443.68C893.29 444.19 893.75 444.62 894.33 444.89C895.51 445.43 896.96 445.11 897.98 444.32C899 443.52 899.63 442.31 899.98 441.07C900.5 439.24 900.43 437.2 899.54 435.52Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M902.3 449.23C902.47 450 902.54 450.79 902.5 451.57C902.87 451.81 903.4 451.82 903.76 451.56C903.83 450.78 903.73 449.95 903.48 449.2C903.19 449.22 902.59 449.21 902.3 449.23Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M901.22 445.6C902.32 445.2 903.12 444.18 903.54 443.08C904.01 441.89 904.09 440.5 903.56 439.33C903.17 438.5 902.43 437.81 901.56 437.53C900.56 437.67 899.59 438.1 898.78 438.72C898.06 439.28 897.47 440.03 897.18 440.9C896.83 441.94 896.89 443.07 897.17 444.13C897.33 444.37 897.52 444.59 897.73 444.79C898.65 445.65 900.04 446.02 901.22 445.6Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M903.64 447.68C905.42 446.42 906.43 444.18 906.25 442.01C906.11 440.45 905.38 438.96 904.26 437.86C903.88 437.69 903.47 437.57 903.02 437.51C902.53 437.45 902.04 437.45 901.56 437.53C902.43 437.81 903.17 438.5 903.56 439.33C904.09 440.5 904.01 441.89 903.54 443.08C903.12 444.18 902.32 445.2 901.22 445.6C900.04 446.02 898.65 445.65 897.73 444.79C897.52 444.59 897.33 444.37 897.17 444.13C897.18 444.17 897.19 444.2 897.2 444.23C897.5 445.32 898.02 446.33 898.56 447.31C898.83 447.8 899.13 448.3 899.47 448.75C900.92 448.9 902.45 448.53 903.64 447.68Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M906.61 440.25C906.07 439.18 905.29 438.33 904.26 437.86C905.38 438.96 906.11 440.45 906.25 442.01C906.43 444.18 905.42 446.42 903.64 447.68C902.45 448.53 900.92 448.9 899.47 448.75C899.87 449.29 900.36 449.76 900.96 450.05C902.2 450.63 903.73 450.32 904.82 449.5C905.91 448.68 906.6 447.42 906.99 446.11C907.56 444.19 907.53 442.03 906.61 440.25Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M911.68 440.72C911.79 441.48 911.79 442.25 911.69 443.02C912.03 443.28 912.55 443.33 912.92 443.1C913.05 442.34 913.02 441.53 912.83 440.78C912.54 440.78 911.97 440.72 911.68 440.72Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M910.9 437.1C912.01 436.79 912.87 435.86 913.37 434.83C913.91 433.7 914.1 432.35 913.67 431.18C913.36 430.33 912.69 429.61 911.87 429.26C910.88 429.33 909.9 429.66 909.07 430.21C908.32 430.7 907.69 431.38 907.34 432.21C906.92 433.19 906.89 434.3 907.08 435.35C907.22 435.6 907.38 435.83 907.57 436.04C908.4 436.95 909.72 437.42 910.9 437.1Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M913.11 439.32C914.93 438.23 916.09 436.12 916.08 434C916.08 432.46 915.47 430.96 914.47 429.79C914.11 429.6 913.72 429.45 913.29 429.36C912.82 429.26 912.34 429.23 911.87 429.26C912.69 429.61 913.36 430.33 913.67 431.18C914.1 432.35 913.91 433.7 913.37 434.83C912.87 435.86 912.01 436.79 910.9 437.1C909.72 437.42 908.4 436.95 907.57 436.04C907.38 435.83 907.22 435.6 907.08 435.35C907.08 435.39 907.09 435.42 907.09 435.46C907.3 436.54 907.73 437.56 908.19 438.56C908.41 439.06 908.66 439.56 908.95 440.03C910.36 440.29 911.87 440.05 913.11 439.32Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M916.58 432.3C916.13 431.22 915.43 430.33 914.47 429.79C915.47 430.96 916.08 432.46 916.08 434C916.09 436.12 914.93 438.23 913.11 439.32C911.87 440.05 910.36 440.29 908.95 440.03C909.31 440.59 909.74 441.08 910.31 441.41C911.47 442.08 912.99 441.9 914.11 441.18C915.24 440.46 916 439.29 916.49 438.05C917.2 436.22 917.33 434.12 916.58 432.3Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M917.91 447.35C917.99 447.92 918 448.5 917.93 449.08C918.19 449.27 918.58 449.31 918.85 449.13C918.95 448.56 918.92 447.95 918.77 447.39C918.56 447.39 918.12 447.35 917.91 447.35Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M917.3 444.64C918.13 444.41 918.76 443.7 919.13 442.92C919.54 442.07 919.67 441.06 919.34 440.18C919.1 439.55 918.59 439.01 917.97 438.76C917.23 438.81 916.5 439.07 915.88 439.49C915.32 439.86 914.85 440.38 914.59 441C914.29 441.74 914.27 442.57 914.42 443.36C914.53 443.54 914.65 443.72 914.79 443.87C915.42 444.55 916.42 444.89 917.3 444.64Z" />
                  <path id="&lt;Path&gt;" class="shp32" d="M918.97 446.29C920.33 445.46 921.19 443.87 921.16 442.28C921.15 441.13 920.69 440 919.93 439.14C919.66 438.99 919.36 438.88 919.04 438.82C918.69 438.75 918.33 438.73 917.97 438.76C918.59 439.01 919.1 439.55 919.34 440.18C919.67 441.06 919.54 442.07 919.13 442.92C918.76 443.7 918.13 444.41 917.3 444.64C916.42 444.89 915.42 444.55 914.79 443.87C914.65 443.72 914.53 443.54 914.42 443.36C914.42 443.39 914.43 443.41 914.43 443.44C914.6 444.25 914.93 445.01 915.27 445.76C915.45 446.13 915.63 446.51 915.86 446.85C916.92 447.04 918.05 446.85 918.97 446.29Z" />
                  <path id="&lt;Path&gt;" class="shp33" d="M921.53 441.01C921.18 440.2 920.65 439.53 919.93 439.14C920.69 440 921.15 441.13 921.16 442.28C921.19 443.87 920.33 445.46 918.97 446.29C918.05 446.85 916.92 447.04 915.86 446.85C916.13 447.27 916.46 447.64 916.89 447.88C917.76 448.37 918.9 448.23 919.73 447.68C920.57 447.14 921.14 446.25 921.5 445.31C922.01 443.94 922.1 442.36 921.53 441.01Z" />
                </g>
              </g>
            </g>
          </g>
          <g id="&lt;Clip Group&gt;" clip-path="url(#cp1)">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M285.64 1017.3C286.85 1028.57 286.44 1040.02 284.44 1051.19C292.51 1057.47 304.88 1058.78 313.24 1052.88C310.04 1042.55 309.94 1030.9 311.52 1020.19C303.95 1019.66 293.21 1017.83 285.64 1017.3Z" />
              <path id="&lt;Path&gt;" class="shp31" d="M257.99 926.13C262.71 928.1 268.27 929 272.83 926.69C277.75 924.2 280.32 918.59 281.23 913.15C282.13 907.72 281.75 902.13 282.63 896.69C284.05 887.87 288.67 879.94 293.36 872.34C298.05 864.74 302.96 857.06 305.09 848.38C307.21 839.71 306.04 829.66 299.77 823.29C295.58 819.03 288.83 817.35 283.21 818.84C274.77 831.52 269.68 846.26 261.78 859.34C255.04 870.49 246.3 880.29 238.99 891.06C237.99 892.54 237.04 894.04 236.14 895.56C236.31 898.2 236.76 900.82 237.52 903.35C240.53 913.46 248.26 922.06 257.99 926.13Z" />
              <path id="&lt;Path&gt;" class="shp33" d="M385.03 928.07C375.17 909.45 363.29 891.72 356.39 871.81C350.22 854.02 348.06 834.58 338.24 818.51C332.89 809.74 324.24 801.96 314.42 800.3C321.79 806.81 327.42 815.3 330.22 824.73C336.47 845.78 329.17 868.3 329.83 890.24C330.67 918.41 344.64 946.26 338.55 973.77C336.1 984.8 330.07 995.44 320.42 1001.32C311.03 1007.04 299.43 1007.68 288.45 1007.01C263.11 1005.48 237.19 997.2 219.5 978.98C214.64 973.97 210.6 968.1 207.49 961.78C206.21 966.13 205.21 970.55 204.7 975.1C202.95 990.6 207.63 1007.79 220.28 1016.92C229.32 1023.45 240.93 1025 252.03 1026.04C287.99 1029.42 324.3 1029.53 360.23 1025.81C369.4 1024.86 378.96 1023.51 386.46 1018.14C395.77 1011.48 400.17 999.69 401.15 988.28C402.95 967.29 394.89 946.7 385.03 928.07Z" />
              <path id="&lt;Path&gt;" class="shp32" d="M288.45 1007.01C299.43 1007.68 311.03 1007.04 320.42 1001.32C330.07 995.44 336.1 984.8 338.55 973.77C344.64 946.26 330.67 918.41 329.83 890.24C329.17 868.3 336.47 845.78 330.22 824.73C327.42 815.3 321.79 806.81 314.42 800.3C312.35 799.95 310.23 799.85 308.07 800.1C298.77 801.15 291.09 807.95 285.51 815.48C284.7 816.57 283.97 817.71 283.21 818.84C288.83 817.35 295.58 819.03 299.77 823.29C306.04 829.66 307.21 839.71 305.09 848.38C302.96 857.06 298.05 864.74 293.36 872.34C288.67 879.94 284.05 887.87 282.63 896.69C281.75 902.13 282.13 907.72 281.23 913.15C280.32 918.59 277.75 924.2 272.83 926.69C268.27 929 262.71 928.1 257.99 926.13C248.26 922.06 240.53 913.46 237.52 903.35C236.76 900.82 236.31 898.2 236.14 895.56C229.48 906.76 225.18 919.17 219.95 931.11C215.55 941.15 210.62 951.23 207.49 961.78C210.6 968.1 214.64 973.97 219.5 978.98C237.19 997.2 263.11 1005.48 288.45 1007.01Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M149.76 1037.16C143.75 1039.81 136.25 1038.27 130.26 1035.57C132.18 1012.46 132.45 989.22 131.1 966.08C137.81 966.11 145.04 967.34 151.76 967.37C152.42 989.19 149.1 1015.34 149.76 1037.16Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp25" d="M81.26 895.93C96.66 889.97 108.87 876.18 112.58 860.09C116.54 842.95 111.19 824.86 113.93 807.49C115.98 794.48 122.45 782.59 125.57 769.8C128.7 757.01 127.55 741.6 117.28 733.36C110.43 727.87 100.44 727.63 92.65 731.47C88.29 739.83 85.31 748.64 84.12 756.36C81.62 772.6 84.16 789.36 81.06 805.49C78.18 820.53 70.57 834.16 63.78 847.89C56.08 863.48 49.27 879.78 45.63 896.71C57.18 900.4 69.95 900.3 81.26 895.93Z" />
                <path id="&lt;Path&gt;" class="shp10" d="M232.4 912.94C230.65 891.32 222.16 870.72 211.26 851.96C199.91 832.44 185.64 814 180.24 792.08C173.01 762.73 181.94 714.48 145.4 701.36C135.45 697.79 126.19 699.16 118.01 703.52C119.29 703.86 120.57 704.19 121.82 704.63C135.01 709.24 146.29 719.82 150.44 733.17C159.06 760.89 136.65 791.84 146.99 818.97C152.16 832.52 164.49 841.92 172.28 854.16C186.04 875.79 184.21 903.91 177.45 928.64C173.11 944.52 165.96 961.08 151.63 969.2C140.77 975.36 127.58 975.58 115.13 974.6C98.21 973.26 81.18 969.92 66.16 962.01C58.91 958.19 52.17 953.19 46.51 947.24C49.97 959.31 55.93 970.5 64.86 979.29C83.97 998.08 112.79 1003.17 139.57 1002.29C166.97 1001.39 196.03 994.14 214.19 973.61C228.58 957.37 234.16 934.57 232.4 912.94Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M115.13 974.6C127.58 975.58 140.77 975.36 151.63 969.2C165.96 961.08 173.11 944.52 177.45 928.64C184.21 903.91 186.04 875.79 172.28 854.16C164.49 841.92 152.16 832.52 146.99 818.97C136.65 791.84 159.06 760.89 150.44 733.17C146.29 719.82 135.01 709.24 121.82 704.63C120.57 704.19 119.29 703.86 118.01 703.52C107.41 709.17 98.69 719.87 92.65 731.47C100.44 727.63 110.43 727.87 117.28 733.36C127.55 741.6 128.7 757.01 125.57 769.8C122.45 782.59 115.98 794.48 113.93 807.49C111.19 824.86 116.54 842.95 112.58 860.09C108.87 876.18 96.66 889.97 81.26 895.93C69.95 900.3 57.18 900.4 45.63 896.71C44.36 902.62 43.47 908.6 43.07 914.65C42.36 925.55 43.5 936.72 46.51 947.24C52.17 953.19 58.91 958.19 66.16 962.01C81.18 969.92 98.21 973.26 115.13 974.6Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div class="text-box">
      <p>Her er nogle eksempler på Trappestige Planer andre børn har <br>brugt til at være modig.</p>
      <p>Tænk på noget du har lyst til at gøre eller skabe - hvad går <br>det ud på?
        Hvad har du brug for til at gøre det til virkelighed?</p>
      <p>Kan du bryde dette ned i nogle mindre, mere overskuelige og nemmere trin?</p>
    </div>
    <audio src="../../assets/sounds/session7/Page142.mp3" ref="voice"/>
    <div class="page-number" id="page-dark">142</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: 'Session7Page4',
  methods: {
    animateSvg() {
      let vw = document.querySelector('.interactive-container').clientWidth;
      let clouds = document.getElementById('cloud').children;
      clouds.forEach(cloud => {
        anime({
          targets: cloud,
          translateX: (Math.round(Math.random() * 0.4 * vw) + 100) * (Math.random() < 0.5 ? 1: -1),
          easing: 'linear',
          loop: true,
          direction: 'alternate',
          duration: Math.round(Math.random() * 500) + 4000,
        })
      })
    },
    animateText() {
      let text = document.querySelector('.text-box').children;
      let animation = anime.timeline({duration: 500, easing: 'linear',})
      animation
        .add({targets: '.text-box', opacity: 1}, 500)
        .add({targets: text[0], opacity: 1}, 500)
        .add({targets: text[1], opacity: 1}, 7300)
        .add({targets: text[2], opacity: 1}, 17500)
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1)
    },
  },
  mounted() {
    this.animateSvg();
    this.animateText();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
.text-box {
  position: absolute;
  left: 11%;
  bottom: 15vh;
  width: 78%;
  padding: 2vh;
  font-size: 3vh;
  background-color: rgba(255,255,255,0.95);
  text-align: center;
  opacity: 0;
}
.text-box p { opacity: 0 }
.text-box p:last-child {
  margin-bottom: 0;
}
.landscape {
  position: absolute;
  height: auto;
  width: 106%;
}
tspan { white-space:pre }
.shp0 { fill: #dafaed }
.shp1 { fill: #8de5ff }
.shp2 { fill: #abedec }
.shp3 { fill: #b3ffff }
.shp4 { fill: #82cbd8 }
.shp5 { fill: #68a8d4 }
.shp6 { fill: #ffffff }
.shp7 { fill: #325677 }
.shp8 { opacity: 0.102;fill: #ffffff }
.shp9 { opacity: 0.2;fill: #ffffff }
.shp10 { fill: #38607c }
.shp11 { fill: #5a8b74 }
.shp12 { fill: #d2f4a1 }
.shp13 { fill: #c3ed98 }
.shp14 { fill: #aed87c }
.shp15 { fill: #eef9ac }
.shp16 { fill: #6a9f67 }
.shp17 { fill: #8fc36f }
.shp18 { fill: #d6e597 }
.shp19 { fill: #b9dc88 }
.shp20 { fill: #a4d27d }
.shp21 { fill: #3f6764 }
.shp22 { fill: #588872 }
.shp23 { fill: #659b83 }
.shp24 { fill: #92785c }
.shp25 { fill: #5c9999 }
.shp26 { fill: #437084 }
.shp27 { fill: #77a673 }
.shp28 { fill: #629171 }
.shp29 { fill: #8dbc73 }
.shp30 { fill: #446b70 }
.shp31 { fill: #a8daba }
.shp32 { fill: #9ace9e }
.shp33 { fill: #87bc77 }
</style>