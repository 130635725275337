<template>
  <div class="interactive-container">
    <img src="../../assets/images/session8/creek_girl_and_boy.jpg" alt="boy-and-girl" class="images" id="left">
    <img src="../../assets/images/session8/creek_exploring_group.jpg" alt="exploring-creek" class="images" id="right">
    <div class="question-container">
      <div class="text">I færd med <br>at hjælpe hinanden <br>over vandløbet</div>
      <div class="choices-container">
        <svg class="thumb thumb-up" id="thumb-up-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1960 1978" width="1960" height="1978">
          <g id="thumb-up">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp0-thumb-up" d="M1959.33 989.26C1959.33 1535.23 1520.91 1977.83 980.1 1977.83C439.28 1977.83 0.87 1535.23 0.87 989.26C0.87 443.29 439.28 0.7 980.1 0.7C1520.91 0.7 1959.33 443.29 1959.33 989.26Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp1-thumb-up" d="M1414.26 994.1C1459.33 983.47 1492.95 942.74 1492.95 893.99C1492.95 838.41 1449.26 793.24 1394.68 791.28L1394.63 790.84L1118.95 790.84C1149.76 572.02 1122.67 446.26 1037.69 413.54C1014.35 404.55 987.91 407.92 968.23 423.48C907.68 471.35 949.21 595.64 914.6 673.42C858.69 787.82 802.79 823.72 781.22 833.9C775.55 836.57 771.94 842.34 771.94 848.66L771.94 1363.04C771.94 1378.16 784.35 1393.29 799.99 1406.34C829.99 1431.4 867.9 1444.78 906.84 1444.78L1275.11 1444.78L1275.12 1444.7C1312.73 1443.87 1342.99 1412.88 1342.99 1374.71C1342.99 1354.9 1334.8 1337.04 1321.7 1324.29C1369.32 1321.95 1407.25 1282.35 1407.25 1233.69C1407.25 1207.81 1396.47 1184.51 1379.26 1167.97C1380.53 1168.03 1381.78 1168.17 1383.07 1168.17C1430.27 1168.17 1468.54 1127.8 1468.54 1078C1468.54 1039.82 1446.02 1007.26 1414.26 994.1Z" />
                    <path id="&lt;Path&gt;" class="shp1-thumb-up" d="M684.03 1444.78L499.62 1444.78C481.74 1444.78 467.25 1430.15 467.25 1412.1L467.25 824.98C467.25 806.94 481.74 792.31 499.62 792.31L684.03 792.31C701.91 792.31 716.4 806.94 716.4 824.98L716.4 1412.1C716.4 1430.15 701.91 1444.78 684.03 1444.78Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <svg class="thumb thumb-down" id="thumb-down-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1959 1978" width="1959" height="1978">
          <g id="thumb-down">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp0-thumb-down" d="M1958.63 989.26C1958.63 1535.23 1520.22 1977.83 979.4 1977.83C438.59 1977.83 0.17 1535.23 0.17 989.26C0.17 443.29 438.59 0.7 979.4 0.7C1520.22 0.7 1958.63 443.29 1958.63 989.26Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp1-thumb-down" d="M545.24 1018.79C500.17 1029.42 466.55 1070.15 466.55 1118.9C466.55 1174.48 510.24 1219.66 564.82 1221.62L564.87 1222.05L840.55 1222.05C809.24 1444.41 837.37 1570.55 924.92 1600.48C945.55 1607.53 968.33 1605.01 986.5 1592.88C1053.96 1547.83 1009.41 1419.21 1044.9 1339.47C1100.81 1225.07 1156.71 1189.17 1178.28 1179C1183.95 1176.32 1187.56 1170.55 1187.56 1164.23L1187.56 649.86C1187.56 634.73 1175.15 619.6 1159.51 606.55C1129.51 581.49 1091.6 568.11 1052.66 568.11L684.39 568.11L684.38 568.19C646.77 569.02 616.51 600.01 616.51 638.18C616.51 657.99 624.7 675.85 637.8 688.6C590.18 690.94 552.25 730.55 552.25 779.2C552.25 805.08 563.03 828.38 580.24 844.92C578.97 844.87 577.72 844.72 576.43 844.72C529.23 844.72 490.96 885.09 490.96 934.9C490.96 973.07 513.48 1005.63 545.24 1018.79Z" />
                    <path id="&lt;Path&gt;" class="shp1-thumb-down" d="M1275.47 568.11L1459.88 568.11C1477.76 568.11 1492.25 582.75 1492.25 600.79L1492.25 1187.91C1492.25 1205.96 1477.76 1220.59 1459.88 1220.59L1275.47 1220.59C1257.59 1220.59 1243.1 1205.96 1243.1 1187.91L1243.1 600.79C1243.1 582.75 1257.59 568.11 1275.47 568.11Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div class="masks-container" id="mask-container-1">
          <div class="mask mask-left" id="mask-left-1" @click="handleClick"></div>
          <div class="mask mask-right" id="mask-right-1" @click="handleClick"></div>
        </div>
        <red-tick class="tick" id="red-tick-1"/>
        <green-tick class="tick" id="green-tick-1"/>
      </div>
    </div>
    <div class="question-container">
      <div class="text">I færd med <br>at udforske <br>vandløbets bund</div>
      <div class="choices-container">
        <svg class="thumb thumb-up" id="thumb-up-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1960 1978" width="1960" height="1978">
          <g id="thumb-up">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp0-thumb-up" d="M1959.33 989.26C1959.33 1535.23 1520.91 1977.83 980.1 1977.83C439.28 1977.83 0.87 1535.23 0.87 989.26C0.87 443.29 439.28 0.7 980.1 0.7C1520.91 0.7 1959.33 443.29 1959.33 989.26Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp1-thumb-up" d="M1414.26 994.1C1459.33 983.47 1492.95 942.74 1492.95 893.99C1492.95 838.41 1449.26 793.24 1394.68 791.28L1394.63 790.84L1118.95 790.84C1149.76 572.02 1122.67 446.26 1037.69 413.54C1014.35 404.55 987.91 407.92 968.23 423.48C907.68 471.35 949.21 595.64 914.6 673.42C858.69 787.82 802.79 823.72 781.22 833.9C775.55 836.57 771.94 842.34 771.94 848.66L771.94 1363.04C771.94 1378.16 784.35 1393.29 799.99 1406.34C829.99 1431.4 867.9 1444.78 906.84 1444.78L1275.11 1444.78L1275.12 1444.7C1312.73 1443.87 1342.99 1412.88 1342.99 1374.71C1342.99 1354.9 1334.8 1337.04 1321.7 1324.29C1369.32 1321.95 1407.25 1282.35 1407.25 1233.69C1407.25 1207.81 1396.47 1184.51 1379.26 1167.97C1380.53 1168.03 1381.78 1168.17 1383.07 1168.17C1430.27 1168.17 1468.54 1127.8 1468.54 1078C1468.54 1039.82 1446.02 1007.26 1414.26 994.1Z" />
                    <path id="&lt;Path&gt;" class="shp1-thumb-up" d="M684.03 1444.78L499.62 1444.78C481.74 1444.78 467.25 1430.15 467.25 1412.1L467.25 824.98C467.25 806.94 481.74 792.31 499.62 792.31L684.03 792.31C701.91 792.31 716.4 806.94 716.4 824.98L716.4 1412.1C716.4 1430.15 701.91 1444.78 684.03 1444.78Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <svg class="thumb thumb-down" id="thumb-down-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1959 1978" width="1959" height="1978">
          <g id="thumb-down">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp0-thumb-down" d="M1958.63 989.26C1958.63 1535.23 1520.22 1977.83 979.4 1977.83C438.59 1977.83 0.17 1535.23 0.17 989.26C0.17 443.29 438.59 0.7 979.4 0.7C1520.22 0.7 1958.63 443.29 1958.63 989.26Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp1-thumb-down" d="M545.24 1018.79C500.17 1029.42 466.55 1070.15 466.55 1118.9C466.55 1174.48 510.24 1219.66 564.82 1221.62L564.87 1222.05L840.55 1222.05C809.24 1444.41 837.37 1570.55 924.92 1600.48C945.55 1607.53 968.33 1605.01 986.5 1592.88C1053.96 1547.83 1009.41 1419.21 1044.9 1339.47C1100.81 1225.07 1156.71 1189.17 1178.28 1179C1183.95 1176.32 1187.56 1170.55 1187.56 1164.23L1187.56 649.86C1187.56 634.73 1175.15 619.6 1159.51 606.55C1129.51 581.49 1091.6 568.11 1052.66 568.11L684.39 568.11L684.38 568.19C646.77 569.02 616.51 600.01 616.51 638.18C616.51 657.99 624.7 675.85 637.8 688.6C590.18 690.94 552.25 730.55 552.25 779.2C552.25 805.08 563.03 828.38 580.24 844.92C578.97 844.87 577.72 844.72 576.43 844.72C529.23 844.72 490.96 885.09 490.96 934.9C490.96 973.07 513.48 1005.63 545.24 1018.79Z" />
                    <path id="&lt;Path&gt;" class="shp1-thumb-down" d="M1275.47 568.11L1459.88 568.11C1477.76 568.11 1492.25 582.75 1492.25 600.79L1492.25 1187.91C1492.25 1205.96 1477.76 1220.59 1459.88 1220.59L1275.47 1220.59C1257.59 1220.59 1243.1 1205.96 1243.1 1187.91L1243.1 600.79C1243.1 582.75 1257.59 568.11 1275.47 568.11Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div class="masks-container" id="mask-container-2">
          <div class="mask mask-left" id="mask-left-2" @click="handleClick"></div>
          <div class="mask mask-right" id="mask-right-2" @click="handleClick"></div>
        </div>
        <red-tick class="tick" id="red-tick-2"/>
        <green-tick class="tick" id="green-tick-2"/>
      </div>
    </div>

    <audio ref="wrong" src="../../assets/sounds/all/wrong-ans.mp3"/>
    <audio ref="correct" src="../../assets/sounds/all/correct-ans.mp3"/>
    <audio ref="celebrate" src="../../assets/sounds/all/kids-cheering.mp3"/>
    <audio src="../../assets/sounds/all/Correct.mp3" ref="correctVoice"/>
    <audio src="../../assets/sounds/all/GreatWork.mp3" ref="wellDone"/>
    <audio src="../../assets/sounds/all/GoodTry.mp3" ref="goodTry"/>
    <audio src="../../assets/sounds/session8/Page168.mp3" ref="voice"/>
    <div class="page-number" id="page-light">168</div>
  </div>
</template>

<script>
import GreenTick from "@/components/feelingsQuestion/ticks/GreenTick";
import RedTick from "@/components/feelingsQuestion/ticks/RedTick";
import anime from "animejs";
export default {
  name: 'Session8Page12',
  data() {
    return {
      correctAns: 0
    }
  },
  components: {RedTick, GreenTick},
  methods: {
    animateElements() {
      let questions = document.querySelectorAll('.text');
      let animation = anime.timeline({duration: 500, easing: 'linear'});
      animation
        .add({targets: questions[0], opacity: 1}, 7100)
        .add({targets: questions[1], opacity: 1}, 10800)
    },
    handleClick(e) {
      let targetThumb = e.target.id;
      switch (targetThumb) {
        case 'mask-left-1':
          this.animateRedTick('#red-tick-1');
          break;
        case 'mask-right-1':
          this.hideElements(1);
          this.checkComplete();
          this.animateGreenTick('#green-tick-1');
          break;
        case 'mask-left-2':
          this.animateRedTick('#red-tick-2');
          break;
        case 'mask-right-2':
          this.animateGreenTick('#green-tick-2');
          this.hideElements(2);
          this.checkComplete();
          break;
      }
    },
    animateRedTick(target) {
      anime({
        targets: target,
        easing: 'linear',
        opacity: [
          {value: 1, duration: 200},
          {value: 0, duration: 100},
          {value: 1, duration: 200},
          {value: 0, duration: 100, delay: 500},
        ]
      })
      this.$refs.wrong.play()
      setTimeout(() => {this.$refs.goodTry.play()}, 500)
    },
    animateGreenTick(target) {
      anime({
        targets: target,
        easing: 'linear',
        opacity: 1,
        duration: 500
      });
      this.$refs.goodTry.pause();
      this.$refs.correct.play();
    },
    hideElements(id) {
      if (id === 1) {
        document.querySelector('#mask-container-1').style.visibility = 'hidden';
        document.querySelector('#thumb-down-1').style.visibility = 'hidden';
      } else {
        document.querySelector('#mask-container-2').style.visibility = 'hidden';
        document.querySelector('#thumb-down-2').style.visibility = 'hidden';
      }
    },
    checkComplete() {
      this.correctAns++;
      if (this.correctAns === 2) {
        this.$refs.celebrate.play();
        setTimeout(() => {this.$refs.wellDone.play()}, 500)
      } else {
        setTimeout(() => {this.$refs.correctVoice.play()}, 500)
      }
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1);
    },
  },
  mounted() {
    this.animateElements();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
.tick {
  position: absolute;
  bottom: 1vh;
  width: 7vh !important;
  left: 6.3vh;
}
.masks-container {
  position: absolute;
  width: 100%;
  height: 8.4vh;
  top: 2vh;
}
.mask {
  position: absolute;
  border-radius: 50%;
  width: 8.4vh;
  height: 8.4vh;
}
.mask-left {
  left: .7vh;
}
.mask-right {
  right: .7vh;
}
.question-container {
  position: absolute;
  bottom: 2vh;
  width: calc(0.35*133vh);
  height: 18vh;
}
.question-container:nth-of-type(1) {
  left: calc(0.1*133vh);
}
.question-container:nth-of-type(2) {
  right: calc(0.1*133vh);
}
.text {
  position: absolute;
  right: 0;
  width: 60%;
  height: 100%;
  background-color: rgba(0, 206, 124, 0.9);
  color: #ffffff;
  font-size: 3vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
}
.choices-container {
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  width: 40%;
  height: 100%;
}
.thumb {
  position: absolute;
  height: auto;
  width: 8.4vh;
  top: 2vh
}
.thumb-up {
  right: .7vh;
}
.thumb-down {
  left: .7vh;
}
.images {
  position: absolute;
  height: 100%;
  width: 49%;
}
#right {
  right: 0;
  transform: scaleX(-1);
}
.shp0-thumb-up { fill: #51bc4a }
.shp1-thumb-up { fill: #ffffff }
.shp0-thumb-down { fill: #ff3f3d }
.shp1-thumb-down { fill: #ffffff }
</style>