<template>
  <div class="interactive-container">
    <img src="../../assets/images/session4/324483-P9A8QQ-419-resized.jpg"
         alt="lying-on-grass" class="session-background" id="adjust">
    <div class="text-box">
      <h1>"Boble"vejrtrækning</h1>
      <p>Begynd med at øve ”boble” vejrtrækningen ved hjælp af et sugerør.
        Pust langsomt ud igennem sugerøret over længere tid.
        Den langsomme dybe vejrtrækning får dig til at falde til ro!</p>
      <p>Derefter øver du ”boble” vejrtrækningen uden sugerør, imens du ligger ned.
        Forestil dig, at du er på dit fredfyldte og stille sted.</p>
      <p>Hvis du har lyst kan du beskrive eller tænke over dit fredfyldte og stille sted.</p>
      <p>At trække vejret dybt og langsomt får os til at falde til ro.</p>
    </div>
    <audio src="../../assets/sounds/session4/Page96.mp3" ref="voice"/>
    <div class="page-number" id="page-light">96</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: "Session4Page10",
  methods: {
    animateElements() {
      let text = document.getElementsByClassName('text-box')[0].children;
      let animation = anime.timeline({easing: 'linear', duration: 500});
      animation
        .add({targets: text[1], opacity: 1}, 2800)
        .add({targets: text[2], opacity: 1}, 17900)
        .add({targets: text[3], opacity: 1}, 27700)
        .add({targets: text[4], opacity: 1}, 33400);
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1)
    },
  },
  mounted() {
    this.animateElements();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
.text-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #ffffff;
  width: 48%;
  height: 100%;
  padding: 1.5vh;
}
.text-box h1 {
  font-size: 4vh;
  font-weight: bold;
  margin-bottom: 1vh;
}
.text-box p {
  font-size: 3vh;
  margin-bottom: 1vh;
  opacity: 0;
}
.text-box p:last-child {
  display: inline-block;
  background-color: #00ce7c;
  width: 96%;
  color: #ffffff;
  font-weight: bold;
  padding: 1vh;
  text-align: center;
  margin-top: 30%;
}
#adjust {
  left: 12%;
}
</style>