<template>
  <svg class="emotes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 171" width="172" height="171">
    <title>Calm</title>
    <g id="Object">
      <g id="&lt;Group&gt;">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp0-calm" d="M0.55 85.2C0.55 38.13 38.71 -0.04 85.79 -0.04C132.86 -0.04 171.02 38.13 171.02 85.2C171.02 132.27 132.86 170.44 85.79 170.44C38.71 170.44 0.55 132.27 0.55 85.2Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp1-calm" d="M130.38 12.45C138.87 25.7 143.79 41.46 143.79 58.36C143.79 105.44 105.63 143.6 58.56 143.6C42.21 143.6 26.94 138.99 13.96 131.01C29.11 154.66 55.62 170.34 85.79 170.34C132.86 170.34 171.02 132.17 171.02 85.1C171.02 54.37 154.76 27.45 130.38 12.45Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp2-calm" d="M12.14 94.19C12.14 50.49 47.57 15.06 91.27 15.06C116.82 15.06 139.53 27.16 154 45.95C140.34 22.23 114.73 6.26 85.38 6.26C41.68 6.26 6.25 41.69 6.25 85.39C6.25 103.55 12.37 120.28 22.65 133.63C15.96 122.02 12.14 108.55 12.14 94.19Z" />
            </g>
          </g>
          <path id="&lt;Path&gt;" class="shp3-calm" d="M50.85 98.1C48.66 95.96 45.14 98.32 46.27 101.16C52.57 116.9 67.96 128.02 85.94 128.02C103.63 128.02 118.8 117.27 125.29 101.95C126.47 99.15 122.92 96.73 120.74 98.85C108.27 110.94 81.79 128.15 50.85 98.1Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp4-calm" d="M63.78 72.98C61.37 66.95 55.62 63.06 49.12 63.06C42.77 63.06 37.06 66.84 34.58 72.69C34.1 73.82 34.48 75.08 35.48 75.77C36.49 76.46 37.81 76.35 38.68 75.5C43.41 70.92 50.89 67.24 59.65 75.75C60.15 76.23 60.79 76.48 61.43 76.48C61.92 76.48 62.41 76.33 62.84 76.04C63.85 75.37 64.24 74.11 63.78 72.98Z" />
            <path id="&lt;Path&gt;" class="shp4-calm" d="M137.01 72.98C134.6 66.95 128.85 63.06 122.35 63.06C116 63.06 110.29 66.84 107.81 72.69C107.33 73.81 107.71 75.08 108.71 75.77C109.72 76.46 111.04 76.35 111.91 75.5C116.64 70.92 124.12 67.24 132.89 75.75C133.38 76.23 134.02 76.48 134.66 76.48C135.15 76.48 135.64 76.33 136.07 76.04C137.08 75.37 137.47 74.11 137.01 72.98Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "EmoteCalm",
}
</script>

<style scoped>
.emotes {
  height: auto;
  width: 14vh;
}
tspan { white-space:pre }
.shp0-calm { fill: #ffd05d }
.shp1-calm { fill: #fdc453 }
.shp2-calm { fill: #ffd77f }
.shp3-calm { fill: #5e4939 }
.shp4-calm { fill: #303030 }
</style>