<template>
  <div class="interactive-container">
    <img class="session-background" src="../../assets/images/session1/129493-ORFK4F-127-resized.jpg" alt="mia">
    <div class="text-box">
      <p class="mb-0">Mia elsker at klatre i træer.</p>
    </div>
    <audio src="../../assets/sounds/session1/Page15.mp3" ref="voice"/>
    <div class="page-number" id="page-light">15</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: "Session1Page9",
  methods: {
    animateText() {
      anime({
        targets: ".text-box",
        opacity: 1,
        delay: 500,
        duration: 500,
        easing: 'linear',
      })
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1)
    }
  },
  mounted() {
    this.animateText();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
.text-box {
  height: auto;
  width: auto;
  background-color: rgba(0, 206, 124, 0.95);
  position: absolute;
  top: 2vh;
  left: 2%;
  opacity: 0;
  padding: 1.5vh;
}
.text-box p {
  color: #ffffff;
  font-weight: bold;
  display: flex;
  font-size: 4vh;
}
</style>