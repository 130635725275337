<template>
  <svg class="emotes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 171" width="172" height="171">
    <title>Sad</title>
    <g id="Object">
      <g id="&lt;Group&gt;">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp0-sad" d="M0.7 85.15C0.7 38.07 38.86 -0.09 85.94 -0.09C133.01 -0.09 171.18 38.07 171.18 85.15C171.18 132.22 133.01 170.38 85.94 170.38C38.86 170.38 0.7 132.22 0.7 85.15Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp1-sad" d="M130.53 12.4C139.02 25.65 143.94 41.4 143.94 58.31C143.94 105.38 105.78 143.54 58.71 143.54C42.36 143.54 27.09 138.94 14.11 130.96C29.26 154.61 55.77 170.28 85.94 170.28C133.01 170.28 171.18 132.12 171.18 85.04C171.18 54.32 154.92 27.39 130.53 12.4Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp2-sad" d="M12.29 94.14C12.29 50.43 47.72 15 91.42 15C116.97 15 139.69 27.11 154.15 45.9C140.49 22.17 114.88 6.2 85.53 6.2C41.83 6.2 6.4 41.63 6.4 85.34C6.4 103.5 12.52 120.23 22.8 133.58C16.11 121.97 12.29 108.5 12.29 94.14Z" />
            </g>
          </g>
        </g>
        <path id="&lt;Path&gt;" class="shp3-sad" d="M103.93 119.41C99.08 115.21 92.51 112.84 85.94 112.97C79.47 112.99 72.96 115.35 68.06 119.45C65.59 121.54 63.52 124.1 62.08 126.85C60.56 129.84 59.77 132.86 59.74 135.81C59.73 136.52 60.18 137.15 60.84 137.38C61.51 137.61 62.25 137.39 62.68 136.83C64.45 134.55 66.12 132.74 67.79 131.3C69.59 129.77 71.51 128.49 73.48 127.5C77.4 125.54 81.74 124.49 86.02 124.47C90.41 124.44 94.57 125.43 98.41 127.42C102.36 129.45 105.99 132.61 109.19 136.82C109.5 137.24 109.99 137.47 110.5 137.47C110.67 137.47 110.86 137.44 111.03 137.38C111.7 137.15 112.15 136.51 112.14 135.8C112.05 129.86 108.98 123.73 103.93 119.41Z" />
        <g id="&lt;Group&gt;">
          <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="shp4-sad" d="M147.29 98.95C147.29 98.95 147.29 98.95 147.29 98.95C146.17 98.63 145.08 98.32 143.97 98.01C141.67 97.37 139.49 96.76 137.38 96.03C134.22 94.96 131.39 93.75 128.7 92.34C125.88 90.84 123.32 89.18 120.86 87.25C118.17 85.16 115.68 82.72 113.39 80.39C113.15 80.15 112.78 80.08 112.47 80.22C112.15 80.36 111.96 80.67 111.97 81.01C112.15 84.63 113.58 88.36 116.1 91.8C118.4 94.91 121.47 97.53 125 99.37C128.3 101.11 132.02 102.18 135.74 102.46L136.17 102.49C136.69 102.51 137.2 102.53 137.71 102.53C141.28 102.53 144.54 101.84 147.41 100.49C147.72 100.34 147.9 100.02 147.88 99.68C147.85 99.34 147.61 99.05 147.29 98.95ZM147.18 99.31L147.18 99.31L147.18 99.31Z" />
          <path id="&lt;Path&gt;" class="shp4-sad" d="M59.41 80.22C59.1 80.08 58.73 80.15 58.49 80.39C56.2 82.72 53.7 85.16 51.01 87.25C48.56 89.18 45.99 90.84 43.17 92.34C40.49 93.75 37.65 94.96 34.49 96.03C32.39 96.76 30.21 97.37 27.86 98.03C26.79 98.32 25.71 98.63 24.59 98.95C24.59 98.95 24.59 98.95 24.59 98.95C24.26 99.05 24.03 99.34 24 99.68C23.97 100.02 24.16 100.34 24.47 100.49C27.34 101.84 30.6 102.53 34.17 102.53C34.68 102.53 35.19 102.51 35.7 102.49L36.14 102.46C39.86 102.18 43.58 101.11 46.88 99.37C50.41 97.53 53.48 94.91 55.78 91.8C58.3 88.36 59.73 84.63 59.9 81.01C59.92 80.67 59.72 80.36 59.41 80.22Z" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "EmoteSad",
}
</script>

<style scoped>
.emotes {
  height: auto;
  width: 14vh;
}
tspan { white-space:pre }
.shp0-sad { fill: #ffd05d }
.shp1-sad { fill: #fdc453 }
.shp2-sad { fill: #ffd77f }
.shp3-sad { fill: #5e4939 }
.shp4-sad { fill: #303030 }
</style>