<template>
  <div class="interactive-container">
    <img src="../../assets/images/introduction/page1-danish.jpg" alt="page-1" class="session-background">
    <audio src="../../assets/sounds/introduction/Page0.mp3" autoplay ref="voice"/>
  </div>
</template>

<script>
export default {
  name: 'IntroductionPage1',
}
</script>

<style scoped></style>