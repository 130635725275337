<template>
  <div class="interactive-container">
    <img src="../../assets/images/appendix/appendix4-danish.jpg" alt="background" class="session-background">
  </div>
</template>

<script>
export default {
  name: 'AppendixPage11'
}
</script>

<style scoped></style>