<template>
  <div class="interactive-container">
    <svg class="landscape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1654 1103" width="1654" height="1103">
      <defs>
        <linearGradient id="grd1" gradientUnits="userSpaceOnUse"  x1="55" y1="1000" x2="55" y2="0">
          <stop offset="0" stop-color="#bbe6cf"  />
          <stop offset="1" stop-color="#69d5e2"  />
        </linearGradient>
        <linearGradient id="grd2" gradientUnits="userSpaceOnUse"  x1="1226.455" y1="403.776" x2="1182.677" y2="293.499">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd3" gradientUnits="userSpaceOnUse"  x1="373.345" y1="316.268" x2="333.852" y2="216.784">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd4" gradientUnits="userSpaceOnUse"  x1="905.641" y1="487.226" x2="881.312" y2="425.942">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd5" gradientUnits="userSpaceOnUse"  x1="136.596" y1="493.194" x2="95.401" y2="389.423">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd6" gradientUnits="userSpaceOnUse"  x1="624.008" y1="390.441" x2="591.662" y2="308.96">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd7" gradientUnits="userSpaceOnUse"  x1="1345.775" y1="65.395" x2="1761.775" y2="446.728">
          <stop offset="0" stop-color="#28c0bc"  />
          <stop offset="1" stop-color="#1c8e9d"  />
        </linearGradient>
        <linearGradient id="grd8" gradientUnits="userSpaceOnUse"  x1="1508.173" y1="442.421" x2="1553.283" y2="500.346">
          <stop offset="0" stop-color="#08998a"  />
          <stop offset="1" stop-color="#05474f"  />
        </linearGradient>
        <linearGradient id="grd9" gradientUnits="userSpaceOnUse"  x1="1419.772" y1="456.008" x2="1461.979" y2="510.205">
          <stop offset="0" stop-color="#08998a"  />
          <stop offset="1" stop-color="#05474f"  />
        </linearGradient>
        <linearGradient id="grd10" gradientUnits="userSpaceOnUse"  x1="1327.92" y1="464.304" x2="1370.127" y2="518.502">
          <stop offset="0" stop-color="#08998a"  />
          <stop offset="1" stop-color="#05474f"  />
        </linearGradient>
        <linearGradient id="grd11" gradientUnits="userSpaceOnUse"  x1="1460.158" y1="483.054" x2="1490.559" y2="522.092">
          <stop offset="0" stop-color="#08998a"  />
          <stop offset="1" stop-color="#05474f"  />
        </linearGradient>
        <linearGradient id="grd12" gradientUnits="userSpaceOnUse"  x1="1367.121" y1="494.314" x2="1397.522" y2="533.351">
          <stop offset="0" stop-color="#08998a"  />
          <stop offset="1" stop-color="#05474f"  />
        </linearGradient>
        <linearGradient id="grd13" gradientUnits="userSpaceOnUse"  x1="1283.566" y1="508.536" x2="1313.966" y2="547.573">
          <stop offset="0" stop-color="#08998a"  />
          <stop offset="1" stop-color="#05474f"  />
        </linearGradient>
        <linearGradient id="grd14" gradientUnits="userSpaceOnUse"  x1="15.435" y1="422.01" x2="1247.435" y2="422.01">
          <stop offset="0" stop-color="#30c0a9"  />
          <stop offset="1" stop-color="#0ca79d"  />
        </linearGradient>
        <linearGradient id="grd15" gradientUnits="userSpaceOnUse"  x1="1243.779" y1="324.797" x2="1464.223" y2="483.612">
          <stop offset="0" stop-color="#1fb256"  />
          <stop offset="1" stop-color="#0b8c4c"  />
        </linearGradient>
        <linearGradient id="grd16" gradientUnits="userSpaceOnUse"  x1="1080.271" y1="562.623" x2="1567.435" y2="562.623">
          <stop offset="0" stop-color="#dfe99c"  />
          <stop offset="1" stop-color="#fab66d"  />
        </linearGradient>
        <linearGradient id="grd17" gradientUnits="userSpaceOnUse"  x1="240.371" y1="178.799" x2="1699.039" y2="738.799">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd18" gradientUnits="userSpaceOnUse"  x1="240.371" y1="178.799" x2="1699.039" y2="738.799">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd19" gradientUnits="userSpaceOnUse"  x1="960.37" y1="614.331" x2="1683.477" y2="701.135">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd20" gradientUnits="userSpaceOnUse"  x1="567.477" y1="542.033" x2="923.253" y2="804.457">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd21" gradientUnits="userSpaceOnUse"  x1="43.992" y1="317.159" x2="653.769" y2="766.936">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd22" gradientUnits="userSpaceOnUse"  x1="509.605" y1="471.364" x2="904.272" y2="823.364">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd23" gradientUnits="userSpaceOnUse"  x1="237.381" y1="303.175" x2="847.159" y2="752.953">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd24" gradientUnits="userSpaceOnUse"  x1="1326.389" y1="297.843" x2="1718.684" y2="944.954">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd25" gradientUnits="userSpaceOnUse"  x1="1308.201" y1="308.869" x2="1700.497" y2="955.979">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd26" gradientUnits="userSpaceOnUse"  x1="1285.956" y1="322.354" x2="1678.252" y2="969.465">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd27" gradientUnits="userSpaceOnUse"  x1="1245.035" y1="347.161" x2="1637.332" y2="994.273">
          <stop offset="0" stop-color="#86cb63"  />
          <stop offset="1" stop-color="#50aa47"  />
        </linearGradient>
        <linearGradient id="grd28" gradientUnits="userSpaceOnUse"  x1="1522.235" y1="637.773" x2="1615.269" y2="689.625">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd29" gradientUnits="userSpaceOnUse"  x1="1004.348" y1="670.913" x2="1058.498" y2="740.447">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd30" gradientUnits="userSpaceOnUse"  x1="1059.111" y1="746.778" x2="1107.919" y2="809.453">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd31" gradientUnits="userSpaceOnUse"  x1="229.738" y1="319.888" x2="348.588" y2="472.502">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd32" gradientUnits="userSpaceOnUse"  x1="418.079" y1="379.811" x2="515.853" y2="505.36">
          <stop offset="0" stop-color="#0ab27e"  />
          <stop offset="1" stop-color="#066a5c"  />
        </linearGradient>
        <linearGradient id="grd33" gradientUnits="userSpaceOnUse"  x1="323.921" y1="433.205" x2="413.115" y2="547.737">
          <stop offset="0" stop-color="#1fb56c"  />
          <stop offset="1" stop-color="#0d9668"  />
        </linearGradient>
        <linearGradient id="grd34" gradientUnits="userSpaceOnUse"  x1="413.949" y1="524.843" x2="480.965" y2="610.898">
          <stop offset="0" stop-color="#1fb56c"  />
          <stop offset="1" stop-color="#0d9668"  />
        </linearGradient>
        <linearGradient id="grd35" gradientUnits="userSpaceOnUse"  x1="231.099" y1="498.221" x2="305.943" y2="594.328">
          <stop offset="0" stop-color="#1fb56c"  />
          <stop offset="0.671" stop-color="#0d9668"  />
        </linearGradient>
        <linearGradient id="grd36" gradientUnits="userSpaceOnUse"  x1="1448.692" y1="641.478" x2="1551.625" y2="773.653">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd37" gradientUnits="userSpaceOnUse"  x1="1523.504" y1="701.557" x2="1628.423" y2="836.282">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd38" gradientUnits="userSpaceOnUse"  x1="1305.725" y1="727.81" x2="1376.822" y2="819.105">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd39" gradientUnits="userSpaceOnUse"  x1="916.285" y1="511.913" x2="1117.174" y2="830.136">
          <stop offset="0" stop-color="#dfe99c"  />
          <stop offset="1" stop-color="#fab66d"  />
        </linearGradient>
        <linearGradient id="grd40" gradientUnits="userSpaceOnUse"  x1="630.546" y1="745.597" x2="817.352" y2="745.597">
          <stop offset="0" stop-color="#dfe99c"  />
          <stop offset="1" stop-color="#fab66d"  />
        </linearGradient>
        <linearGradient id="grd41" gradientUnits="userSpaceOnUse"  x1="829.657" y1="718.009" x2="942.849" y2="718.009">
          <stop offset="0" stop-color="#dfe99c"  />
          <stop offset="1" stop-color="#fab66d"  />
        </linearGradient>
        <linearGradient id="grd42" gradientUnits="userSpaceOnUse"  x1="936.324" y1="764.528" x2="991.978" y2="764.528">
          <stop offset="0" stop-color="#dfe99c"  />
          <stop offset="1" stop-color="#fab66d"  />
        </linearGradient>
        <linearGradient id="grd43" gradientUnits="userSpaceOnUse"  x1="736.928" y1="632.204" x2="803.262" y2="717.382">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd44" gradientUnits="userSpaceOnUse"  x1="810.469" y1="753.547" x2="855.953" y2="811.952">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd45" gradientUnits="userSpaceOnUse"  x1="266.947" y1="846.705" x2="288.141" y2="873.92">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd46" gradientUnits="userSpaceOnUse"  x1="384.457" y1="872.868" x2="408.734" y2="904.042">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd47" gradientUnits="userSpaceOnUse"  x1="364.843" y1="875.665" x2="341.929" y2="797.852">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd48" gradientUnits="userSpaceOnUse"  x1="236.846" y1="847.945" x2="232.959" y2="735.904">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd49" gradientUnits="userSpaceOnUse"  x1="212.762" y1="870.821" x2="232.326" y2="895.943">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd50" gradientUnits="userSpaceOnUse"  x1="218.368" y1="844.059" x2="216.618" y2="863.851">
          <stop offset="0" stop-color="#ffdf64"  />
          <stop offset="1" stop-color="#ff9b5a"  />
        </linearGradient>
        <linearGradient id="grd51" gradientUnits="userSpaceOnUse"  x1="558.684" y1="908.355" x2="574.629" y2="928.831">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd52" gradientUnits="userSpaceOnUse"  x1="540.429" y1="915.202" x2="507.629" y2="803.819">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd53" gradientUnits="userSpaceOnUse"  x1="616.087" y1="913.261" x2="631.762" y2="933.389">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd54" gradientUnits="userSpaceOnUse"  x1="619.035" y1="887.516" x2="623.361" y2="905.843">
          <stop offset="0" stop-color="#ffdf64"  />
          <stop offset="1" stop-color="#ff9b5a"  />
        </linearGradient>
        <linearGradient id="grd55" gradientUnits="userSpaceOnUse"  x1="407.403" y1="926.411" x2="417.793" y2="939.753">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd56" gradientUnits="userSpaceOnUse"  x1="419.257" y1="909.689" x2="417.507" y2="929.48">
          <stop offset="0" stop-color="#ffdf64"  />
          <stop offset="1" stop-color="#ff9b5a"  />
        </linearGradient>
        <linearGradient id="grd57" gradientUnits="userSpaceOnUse"  x1="314.912" y1="867.252" x2="334.436" y2="892.323">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd58" gradientUnits="userSpaceOnUse"  x1="321.538" y1="837.4" x2="327.81" y2="854.685">
          <stop offset="0" stop-color="#ffdf64"  />
          <stop offset="1" stop-color="#ff9b5a"  />
        </linearGradient>
        <linearGradient id="grd59" gradientUnits="userSpaceOnUse"  x1="129.988" y1="851.664" x2="152.813" y2="880.973">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd60" gradientUnits="userSpaceOnUse"  x1="109.598" y1="863.077" x2="78.029" y2="755.873">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd61" gradientUnits="userSpaceOnUse"  x1="90.148" y1="836.087" x2="113.912" y2="866.603">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd62" gradientUnits="userSpaceOnUse"  x1="99.035" y1="806.8" x2="97.284" y2="826.591">
          <stop offset="0" stop-color="#ffdf64"  />
          <stop offset="1" stop-color="#ff9b5a"  />
        </linearGradient>
        <linearGradient id="grd63" gradientUnits="userSpaceOnUse"  x1="1266.709" y1="845.757" x2="1242.432" y2="876.931">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd64" gradientUnits="userSpaceOnUse"  x1="1286.323" y1="848.554" x2="1309.237" y2="770.742">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd65" gradientUnits="userSpaceOnUse"  x1="1523.733" y1="833.999" x2="1500.88" y2="857.063">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd66" gradientUnits="userSpaceOnUse"  x1="1541.484" y1="838.46" x2="1574.477" y2="761.609">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd67" gradientUnits="userSpaceOnUse"  x1="1493.231" y1="868.111" x2="1473.667" y2="893.233">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd68" gradientUnits="userSpaceOnUse"  x1="1487.625" y1="841.348" x2="1489.376" y2="861.14">
          <stop offset="0" stop-color="#ffdf64"  />
          <stop offset="1" stop-color="#ff9b5a"  />
        </linearGradient>
        <linearGradient id="grd69" gradientUnits="userSpaceOnUse"  x1="1147.31" y1="905.645" x2="1131.364" y2="926.121">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd70" gradientUnits="userSpaceOnUse"  x1="1165.565" y1="912.492" x2="1198.365" y2="801.108">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
        <linearGradient id="grd71" gradientUnits="userSpaceOnUse"  x1="1089.907" y1="910.551" x2="1074.232" y2="930.679">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd72" gradientUnits="userSpaceOnUse"  x1="1086.959" y1="884.806" x2="1082.633" y2="903.132">
          <stop offset="0" stop-color="#ffdf64"  />
          <stop offset="1" stop-color="#ff9b5a"  />
        </linearGradient>
        <linearGradient id="grd73" gradientUnits="userSpaceOnUse"  x1="1213.851" y1="912.441" x2="1203.46" y2="925.783">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd74" gradientUnits="userSpaceOnUse"  x1="1201.996" y1="895.719" x2="1203.747" y2="915.51">
          <stop offset="0" stop-color="#ffdf64"  />
          <stop offset="1" stop-color="#ff9b5a"  />
        </linearGradient>
        <linearGradient id="grd75" gradientUnits="userSpaceOnUse"  x1="1387.286" y1="874.2" x2="1354.071" y2="891.494">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd76" gradientUnits="userSpaceOnUse"  x1="1395.771" y1="850.976" x2="1382.606" y2="864.359">
          <stop offset="0" stop-color="#ffdf64"  />
          <stop offset="1" stop-color="#ff9b5a"  />
        </linearGradient>
        <linearGradient id="grd77" gradientUnits="userSpaceOnUse"  x1="1443.858" y1="899.916" x2="1421.033" y2="929.226">
          <stop offset="0" stop-color="#16b370"  />
          <stop offset="1" stop-color="#0b8765"  />
        </linearGradient>
        <linearGradient id="grd78" gradientUnits="userSpaceOnUse"  x1="1464.248" y1="911.33" x2="1495.817" y2="804.126">
          <stop offset="0" stop-color="#88dbd9"  />
          <stop offset="0.528" stop-color="#f7fafb"  />
          <stop offset="1" stop-color="#fcfdfd"  />
          <stop offset="1" stop-color="#fcfdfd"  />
        </linearGradient>
      </defs>
      <g id="BACKGROUND">
        <path id="&lt;Path&gt;" class="shp0" d="M1559 1000L59 1000L59 0L1559 0L1559 1000Z" />
      </g>
      <g id="OBJECTS">
        <g id="&lt;Clip Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp1" d="M1214.55 362.01L1404.77 362.01L1378.99 354.45C1370.99 336.08 1360.77 328.68 1348.32 332.23C1335.88 335.79 1328.47 340.08 1326.1 345.12C1326.1 317.86 1318.99 302.6 1304.77 299.34C1290.55 296.08 1283.88 308.53 1284.77 336.68C1280.03 327.19 1274.25 322.6 1267.43 322.9C1260.62 323.19 1259.88 331.05 1265.21 346.45C1256.62 338.75 1250.55 335.94 1246.99 338.01C1243.43 340.08 1242.25 344.23 1243.43 350.45L1214.55 362.01Z" />
              <path id="&lt;Path&gt;" class="shp2" d="M362.97 278.45L534.57 278.45L511.32 271.64C504.1 255.07 494.88 248.38 483.65 251.59C472.42 254.8 465.74 258.67 463.6 263.22C463.6 238.63 457.19 224.86 444.36 221.92C431.53 218.98 425.51 230.21 426.32 255.6C422.04 247.05 416.83 242.9 410.68 243.17C404.53 243.44 403.86 250.52 408.67 264.42C400.92 257.47 395.44 254.93 392.23 256.8C389.03 258.67 387.96 262.42 389.03 268.03L362.97 278.45Z" />
              <path id="&lt;Path&gt;" class="shp3" d="M1114.1 454.45L896.32 454.45L937.21 446.45C962.1 407.34 989.66 401.42 1019.88 428.68C1025.21 407.34 1032.32 398.75 1041.21 402.9C1050.1 407.05 1056.62 411.19 1060.77 415.34C1083.88 347.79 1101.66 360.82 1114.1 454.45Z" />
              <path id="&lt;Path&gt;" class="shp4" d="M126.84 452.08L392.32 452.08L325.95 436.68C312.52 400.33 300.27 389.27 289.21 403.49C249.71 348.18 223.24 354.11 209.81 421.27C194 406.26 184.13 408.63 180.18 428.38C145.41 400.73 127.63 408.63 126.84 452.08Z" />
              <path id="&lt;Path&gt;" class="shp5" d="M624.32 370.31L773.66 370.31C765.95 353.12 756.18 348.97 744.32 357.86C735.43 334.16 722.69 330.6 706.1 347.19C685.95 292.68 668.47 285.27 653.66 324.97C641.21 320.23 635.29 325.86 635.88 341.86C628.77 332.97 624.92 342.45 624.32 370.31Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp6" d="M730.1 664.23C951.73 595.49 1082.1 553.42 1121.21 538.01C1179.88 514.9 1288.32 420.68 1330.99 411.79C1359.43 405.86 1390.25 400.53 1423.43 395.79C1449.51 354.31 1469.66 328.82 1483.88 319.34C1498.1 309.86 1529.51 298.01 1578.1 283.79L1578.1 701.56L610.99 701.56L730.1 664.23Z" />
              <path id="&lt;Path&gt;" class="shp7" d="M1423.43 395.79C1390.25 400.53 1359.43 405.86 1330.99 411.79C1288.32 420.68 1179.88 514.9 1121.21 538.01C1082.1 553.42 951.73 595.49 730.1 664.23L610.99 701.57L626.33 701.57C715.85 674.68 979.26 595.76 1002.1 591.34C1020.47 587.79 1042.99 581.86 1069.66 573.57C1067.88 584.23 1060.77 592.23 1048.32 597.57C1035.88 602.9 985.81 636.38 898.1 698.01C966.84 655.94 1008.62 631.94 1023.43 626.01C1045.66 617.12 1091.88 597.57 1099.88 593.12C1105.21 590.16 1119.43 575.64 1142.55 549.57C1227.88 496.82 1277.66 467.19 1291.88 460.68C1306.1 454.16 1344.32 443.19 1406.55 427.79C1345.51 476.38 1306.69 507.79 1290.1 522.01C1273.51 536.23 1214.55 587.79 1113.21 676.68C1189.66 626.31 1230.84 597.57 1236.77 590.45C1242.69 583.34 1258.1 571.49 1282.99 554.9C1313.21 527.64 1338.69 509.57 1359.43 500.68C1380.18 491.79 1380.77 499.79 1361.21 524.68L1308.77 607.34C1326.55 588.38 1340.18 572.38 1349.66 559.34C1363.88 539.79 1372.77 525.57 1385.21 511.34C1397.66 497.12 1407.43 469.57 1410.1 465.12C1412.77 460.68 1409.21 453.57 1450.1 406.45C1490.99 359.34 1477.66 352.23 1509.66 340.68C1525.37 335 1553.52 324.18 1578.1 314.64L1578.1 283.79C1529.51 298.01 1498.1 309.86 1483.88 319.34C1469.66 328.82 1449.51 354.31 1423.43 395.79Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp8" d="M987.43 615.05C1099.14 588.38 1187.14 572.08 1251.43 566.16L1251.43 560.82C1132.32 578.01 1043.88 594.75 986.1 611.05L987.43 615.05Z" />
                <path id="&lt;Path&gt;" class="shp8" d="M985.21 603.49C1116.77 572.97 1205.51 556.68 1251.43 554.6L1250.55 547.49C1103.88 572.38 1014.99 590.16 983.88 600.82L985.21 603.49Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp8" d="M994.55 623.68L987.55 595.9L990.55 589.9L995.66 593.12L1000.43 622.68L994.55 623.68Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M1032.55 615.68L1025.1 586.34L1028.1 580.34L1034.1 583.68L1038.32 614.45L1032.55 615.68Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M1072.47 608.23L1064.03 576.97L1067.73 571.34L1072.92 574.6L1077.36 606.75L1072.47 608.23Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M1113.43 601.34L1106.1 568.45L1110.1 560.45L1116.55 564.68L1119.43 599.34L1113.43 601.34Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M1159.21 593.34L1150.99 560.45L1154.77 551.79L1160.99 556.9L1166.32 591.56L1159.21 593.34Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M1205.88 586.9L1197.43 551.79L1200.77 543.79L1207.43 548.68L1212.1 585.79L1205.88 586.9Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M1244.32 582.9L1235.43 544.9L1240.55 537.79L1244.55 542.9L1250.1 583.12L1244.32 582.9Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp9" d="M1449.53 568.86C1446.31 544.11 1447.92 530.49 1454.38 527.98C1452.58 500.36 1456.71 484.58 1466.75 480.64C1469.97 450.88 1476.43 435.63 1486.11 434.92C1495.79 434.2 1501.71 448.54 1503.86 477.95C1511.75 482.25 1515.34 494.45 1514.62 514.53C1522.51 519.55 1524.48 537.12 1520.54 567.24C1504.04 575.13 1480.37 575.67 1449.53 568.86Z" />
            <path id="&lt;Path&gt;" class="shp10" d="M1364.72 574.46C1361.7 551.31 1363.21 538.56 1369.25 536.21C1367.57 510.37 1371.43 495.61 1380.83 491.92C1383.85 464.07 1389.89 449.81 1398.95 449.14C1408.01 448.47 1413.54 461.89 1415.55 489.4C1422.94 493.43 1426.29 504.84 1425.62 523.63C1433 528.32 1434.85 544.76 1431.16 572.95C1415.72 580.33 1393.58 580.83 1364.72 574.46Z" />
            <path id="&lt;Path&gt;" class="shp11" d="M1272.87 582.75C1269.85 559.6 1271.36 546.85 1277.4 544.5C1275.72 518.67 1279.58 503.91 1288.98 500.21C1292 472.37 1298.03 458.11 1307.09 457.43C1316.15 456.76 1321.69 470.19 1323.7 497.7C1331.08 501.72 1334.44 513.13 1333.77 531.92C1341.15 536.62 1343 553.06 1339.3 581.24C1323.87 588.63 1301.73 589.13 1272.87 582.75Z" />
            <path id="&lt;Path&gt;" class="shp12" d="M1419.7 569.03C1417.53 552.35 1418.61 543.17 1422.96 541.48C1421.76 522.87 1424.54 512.23 1431.3 509.58C1433.48 489.52 1437.83 479.25 1444.35 478.76C1450.88 478.28 1454.87 487.95 1456.32 507.76C1461.63 510.66 1464.05 518.88 1463.57 532.41C1468.88 535.8 1470.21 547.64 1467.55 567.94C1456.44 573.26 1440.49 573.62 1419.7 569.03Z" />
            <path id="&lt;Path&gt;" class="shp13" d="M1326.66 580.29C1324.49 563.61 1325.58 554.43 1329.93 552.74C1328.72 534.13 1331.5 523.49 1338.27 520.84C1340.44 500.78 1344.79 490.51 1351.32 490.02C1357.84 489.54 1361.83 499.21 1363.28 519.02C1368.59 521.92 1371.01 530.14 1370.53 543.67C1375.85 547.06 1377.17 558.9 1374.52 579.2C1363.4 584.52 1347.45 584.88 1326.66 580.29Z" />
            <path id="&lt;Path&gt;" class="shp14" d="M1243.11 594.51C1240.93 577.83 1242.02 568.65 1246.37 566.96C1245.16 548.35 1247.94 537.72 1254.71 535.06C1256.88 515 1261.23 504.73 1267.76 504.24C1274.29 503.76 1278.27 513.43 1279.72 533.25C1285.04 536.15 1287.46 544.36 1286.97 557.9C1292.29 561.28 1293.62 573.12 1290.96 593.42C1279.84 598.74 1263.89 599.1 1243.11 594.51Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp15" d="M1247.43 758.45C1217.21 749.56 850.99 646.45 847.43 641.12C843.88 635.79 767.43 587.79 742.55 580.68C725.95 575.94 665.51 561.71 561.21 538.01C514.99 521.42 483.58 510.16 466.99 504.23C450.4 498.31 373.36 492.38 235.88 486.45L138.1 445.56L15.43 426.01L15.43 735.34L1247.43 758.45Z" />
              <path id="&lt;Path&gt;" class="shp16" d="M15.43 426.01L15.43 450.21L149.66 470.45C172.77 483.49 208.03 502.75 255.43 528.23C326.55 566.45 402.1 626.01 413.66 641.12C421.36 651.19 443.29 666.31 479.43 686.45C445.06 663.34 424.92 645.86 418.99 634.01C410.1 616.23 316.77 542.45 300.77 530.9C284.77 519.34 271.43 514.01 276.77 511.34C282.1 508.68 446.55 520.23 453.66 522.9C460.77 525.57 506.1 563.79 522.1 576.23C538.1 588.68 561.21 618.9 583.43 634.01C598.25 644.08 631.14 663.64 682.1 692.68C633.51 658.31 605.95 638.16 599.43 632.23C589.66 623.34 584.32 622.45 570.99 602.9C562.1 589.86 546.99 572.08 525.66 549.57C542.84 550.75 554.99 553.12 562.1 556.68C572.77 562.01 718.55 590.45 734.55 596.68C745.21 600.82 783.14 621.57 848.32 658.9L1066.99 746.01L1018.62 693.03C929.9 667.44 849.1 643.61 847.43 641.12C843.88 635.79 767.43 587.79 742.55 580.68C725.95 575.94 665.51 561.71 561.21 538.01C514.99 521.42 483.58 510.16 466.99 504.23C450.4 498.31 373.36 492.38 235.88 486.45L138.1 445.57L15.43 426.01Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp8" d="M434.4 617.42C543.43 637.17 619.88 654.55 663.73 669.56L664.32 661.86C570.3 635.39 494.25 618.6 436.18 611.49" />
              <path id="&lt;Path&gt;" class="shp8" d="M438.55 601.42C544.42 621.96 620.87 638.55 667.88 651.19L670.25 643.49C538.3 613.47 461.06 597.47 438.55 595.49L438.55 601.42Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M469.66 647.05L472.03 591.34L483.88 581.86L490.4 591.34L477.95 649.42L469.66 647.05Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M522.1 654.45L526.55 596.97L538.4 586.9L544.03 597.86L530.69 657.12L522.1 654.45Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M568.32 662.45L575.14 605.27L590.55 596.97L595.88 610.01L578.1 664.23L568.32 662.45Z" />
              <path id="&lt;Path&gt;" class="shp8" d="M623.14 674.01L632.32 620.38L643.88 613.27L649.51 622.16L634.1 677.27L623.14 674.01Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp17" d="M728.32 698.01C951.14 607.94 1230.84 562.9 1567.43 562.9L1567.43 708.68C1009.21 701.57 729.51 698.01 728.32 698.01Z" />
              <path id="&lt;Path&gt;" class="shp18" d="M1567.43 562.9C1230.84 562.9 951.14 607.94 728.32 698.01C728.9 698.01 816.57 699.12 935.02 700.63C994.94 613.66 1411 559.99 1567.43 580.69L1567.43 562.9Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M1080.27 605.61C1223.07 620.45 1330.76 672.39 1387.96 706.39C1457.82 707.28 1517.65 708.04 1567.43 708.68L1567.43 619.69C1528.56 598.66 1480.59 579.42 1422.47 566.62C1293.07 573.28 1177.85 588.16 1080.27 605.61Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp20" d="M1434.3 706.98C1395.6 672.06 1299.92 603.08 1141.75 595.38C1137.29 596.06 1132.88 596.76 1128.48 597.47C1188.18 603.98 1314.69 626.88 1409.97 706.67C1418.31 706.78 1426.44 706.88 1434.3 706.98Z" />
                <path id="&lt;Path&gt;" class="shp20" d="M1383.7 568.91C1377.73 569.3 1371.79 569.7 1365.88 570.13C1415.34 580.65 1495.46 603.83 1567.43 652.26L1567.43 635.56C1522.77 608.03 1462.12 581.47 1383.7 568.91Z" />
                <path id="&lt;Path&gt;" class="shp20" d="M1235.12 582.59C1229.04 583.32 1222.99 584.05 1217 584.81C1283.36 596.54 1398.46 627.57 1482.6 707.6C1490.75 707.7 1498.41 707.8 1505.55 707.89C1474.38 675.95 1387.25 601.85 1235.12 582.59Z" />
                <path id="&lt;Path&gt;" class="shp20" d="M1567.43 692.27C1529.1 655.2 1450.98 596.28 1325.17 573.33C1318.94 573.87 1312.73 574.42 1306.58 574.99C1373.38 590.44 1483.23 626.91 1561.5 708.6C1564.08 708.63 1566.06 708.66 1567.43 708.68L1567.43 692.27Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1100.08 637.57L1100.08 619.79C1097.18 626.18 1097.18 632.1 1100.08 637.57Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1096.95 636.34L1087.36 623.97C1088.87 630.59 1092.07 634.72 1096.95 636.34Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1102.35 635.12C1107.93 633.15 1110.83 630.36 1111.06 626.76L1102.35 635.12Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1052.08 680.23L1052.08 662.45C1049.18 668.84 1049.18 674.77 1052.08 680.23Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1048.95 679.01L1039.36 666.64C1040.87 673.26 1044.07 677.38 1048.95 679.01Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1054.35 677.79C1059.93 675.82 1062.83 673.03 1063.06 669.43L1054.35 677.79Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1002.01 649.42L1002.01 631.64C999.11 638.03 999.11 643.96 1002.01 649.42Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M998.87 648.2L989.29 635.82C990.8 642.45 993.99 646.57 998.87 648.2Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1004.28 646.98C1009.85 645 1012.76 642.21 1012.99 638.61L1004.28 646.98Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M948.68 677.27L948.68 659.49C945.77 665.88 945.77 671.81 948.68 677.27Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M945.54 676.05L935.95 663.67C937.46 670.3 940.66 674.42 945.54 676.05Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M950.94 674.83C956.52 672.85 959.42 670.06 959.66 666.46L950.94 674.83Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M857.42 687.34L857.42 669.56C854.51 675.96 854.51 681.88 857.42 687.34Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M854.28 686.12L844.69 673.75C846.2 680.37 849.4 684.5 854.28 686.12Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M859.68 684.9C865.26 682.93 868.17 680.14 868.4 676.54L859.68 684.9Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1292.97 697.42L1292.97 679.64C1290.07 686.03 1290.07 691.96 1292.97 697.42Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1289.84 696.2L1280.25 683.82C1281.76 690.45 1284.96 694.57 1289.84 696.2Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1295.24 694.98C1300.82 693 1303.72 690.21 1303.95 686.61L1295.24 694.98Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1547.94 584.38L1547.94 566.6C1545.03 572.99 1545.03 578.92 1547.94 584.38Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1544.8 583.16L1535.21 570.79C1536.72 577.41 1539.92 581.53 1544.8 583.16Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1550.2 581.94C1555.78 579.96 1558.68 577.18 1558.92 573.57L1550.2 581.94Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1489.71 573.57L1489.71 555.79C1486.81 562.18 1486.81 568.1 1489.71 573.57Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1486.58 572.34L1476.99 559.97C1478.5 566.59 1481.7 570.72 1486.58 572.34Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1491.98 571.12C1497.56 569.15 1500.46 566.36 1500.69 562.76L1491.98 571.12Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1134.45 692.38L1134.45 674.6C1131.55 680.99 1131.55 686.92 1134.45 692.38Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1131.32 691.16L1121.73 678.79C1123.24 685.41 1126.44 689.53 1131.32 691.16Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1136.72 689.94C1142.3 687.96 1145.2 685.18 1145.43 681.57L1136.72 689.94Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1233.71 665.12L1233.71 647.34C1230.81 653.73 1230.81 659.66 1233.71 665.12Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1230.58 663.9L1220.99 651.53C1222.5 658.15 1225.7 662.27 1230.58 663.9Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1235.98 662.68C1241.56 660.71 1244.46 657.92 1244.69 654.31L1235.98 662.68Z" />
              </g>
            </g>
            <path id="&lt;Path&gt;" class="shp22" d="M722.63 696.23C679.49 683.01 395.29 600.23 40.32 600.23L36.77 765.57L41 765.38L41 1009.12L1577 1009.12L1577 696.23L722.63 696.23Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp23" d="M722.63 696.23C679.49 683.01 395.29 600.23 40.32 600.23L36.77 765.57L41 765.38L41 1009.12L1577 1009.12L1577 696.23L722.63 696.23Z" />
              <path id="&lt;Path&gt;" class="shp24" d="M1596.11 694.68L1596.11 723.12L596.32 696.45L1596.11 694.68Z" />
              <path id="&lt;Path&gt;" class="shp25" d="M450.32 1009.12L843.35 1009.12C882.94 956.08 909.13 895.95 890.1 840.23C862.15 758.38 806.19 716.89 762.47 696.23L724.27 696.23C827.97 797.84 804.92 966.86 450.32 1009.12Z" />
              <path id="&lt;Path&gt;" class="shp26" d="M304.37 614.83C187.49 620.61 100.6 637.41 39.15 654.99L37.68 723.29C178.67 658.05 347.47 640.78 473.24 638.75C422.56 629.89 365.81 621.5 304.37 614.83Z" />
              <path id="&lt;Path&gt;" class="shp27" d="M254.01 1009.12L562.85 1009.12C662.82 939.68 769.61 902.77 867.18 884.34C879.6 852.36 879.35 820.58 863.18 789.71C597.05 807.63 367.23 934.93 254.01 1009.12Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M644.32 674.8C342.06 687.77 130.02 788.49 41 839.4L41 937.65C365.62 754.86 638.38 721.75 800.26 723.33C787.19 714.15 772.26 705.11 755.43 696.23L722.63 696.23C711.53 692.83 684.44 684.82 644.32 674.8Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M583.43 813.86L557.95 802.31C568.82 802.9 577.31 806.75 583.43 813.86Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M587.58 811.19L584.32 779.49C579.39 796.48 580.47 807.05 587.58 811.19Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M592.92 811.79L606.84 796.97C606.25 805.66 601.61 810.6 592.92 811.79Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M714.99 869.86L689.51 858.31C700.37 858.9 708.87 862.75 714.99 869.86Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M719.14 867.19L715.88 835.49C710.94 852.48 712.03 863.05 719.14 867.19Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M724.47 867.79L738.4 852.97C737.81 861.66 733.16 866.6 724.47 867.79Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M685.66 756.97L660.18 745.42C671.04 746.01 679.53 749.86 685.66 756.97Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M689.81 754.31L686.55 722.6C681.61 739.59 682.69 750.16 689.81 754.31Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M695.14 754.9L709.06 740.08C708.47 748.77 703.83 753.71 695.14 754.9Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M531.71 696.53L510.4 686.87C519.49 687.36 526.59 690.58 531.71 696.53Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M535.18 694.3L532.45 667.79C528.32 681.99 529.23 690.83 535.18 694.3Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M539.64 694.79L551.28 682.41C550.79 689.67 546.91 693.8 539.64 694.79Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M341.45 815.79L320.14 806.13C329.22 806.63 336.33 809.85 341.45 815.79Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M344.92 813.56L342.19 787.05C338.06 801.26 338.97 810.1 344.92 813.56Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M349.38 814.06L361.02 801.67C360.53 808.94 356.64 813.07 349.38 814.06Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M469.36 793.42L448.05 783.75C457.14 784.25 464.24 787.47 469.36 793.42Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M472.83 791.19L470.1 764.68C465.97 778.88 466.88 787.72 472.83 791.19Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M477.29 791.68L488.93 779.29C488.44 786.56 484.56 790.69 477.29 791.68Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M308.54 735.64L288.55 726.57C297.08 727.04 303.74 730.06 308.54 735.64Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M311.8 733.55L309.24 708.68C305.37 722 306.22 730.29 311.8 733.55Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M315.98 734.01L326.91 722.39C326.44 729.21 322.8 733.08 315.98 734.01Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M185.77 790.3L165.78 781.24C174.31 781.7 180.97 784.72 185.77 790.3Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M189.03 788.21L186.47 763.34C182.6 776.67 183.45 784.96 189.03 788.21Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M193.21 788.68L204.14 777.05C203.67 783.87 200.03 787.75 193.21 788.68Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M87.11 752.97L67.12 743.9C75.64 744.37 82.3 747.39 87.11 752.97Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M90.36 750.88L87.81 726.01C83.93 739.33 84.78 747.62 90.36 750.88Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M94.55 751.34L105.47 739.72C105.01 746.54 101.36 750.41 94.55 751.34Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M98.66 686.3L78.67 677.24C87.2 677.7 93.86 680.72 98.66 686.3Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M101.92 684.21L99.36 659.34C95.49 672.67 96.34 680.96 101.92 684.21Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M106.1 684.68L117.03 673.05C116.56 679.87 112.92 683.75 106.1 684.68Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp29" d="M494.22 657.86L474.23 648.79C482.75 649.26 489.42 652.28 494.22 657.86Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M497.47 655.77L494.92 630.9C491.04 644.22 491.89 652.51 497.47 655.77Z" />
                <path id="&lt;Path&gt;" class="shp29" d="M501.66 656.23L512.58 644.61C512.12 651.43 508.48 655.3 501.66 656.23Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp30" d="M1592.32 744.23L1010.1 731.79L1022.55 740.68L1603.88 765.57L1592.32 744.23Z" />
                <path id="&lt;Path&gt;" class="shp31" d="M1040.32 775.34L1043.88 794.9L1602.99 823.34L1600.32 798.45L1040.32 775.34Z" />
                <path id="&lt;Path&gt;" class="shp32" d="M1594.1 904.23L1606.55 858.9L1058.1 841.12L1064.32 867.79L1594.1 904.23Z" />
                <path id="&lt;Path&gt;" class="shp33" d="M1042.1 937.12L1591.43 970.9L1598.55 938.01L1035.88 921.12L1042.1 937.12Z" />
              </g>
            </g>
            <path id="&lt;Path&gt;" class="shp34" d="M1416.76 708.32C1427.31 702.62 1437.03 701.59 1445.93 705.22C1470.23 669.09 1497.77 669.88 1528.54 707.58C1535.32 690.05 1548.64 687 1568.5 698.44C1590.93 670.33 1619.2 683.98 1653.32 739.42C1639.25 766.7 1612.23 773.7 1572.23 760.41C1560.05 779.67 1529.29 777.88 1479.97 755.02C1447.32 760.78 1428.07 753.53 1422.22 733.27C1393.6 717.65 1391.78 709.33 1416.76 708.32Z" />
            <path id="&lt;Path&gt;" class="shp35" d="M907.43 696.23C963.83 713.22 1010 743.64 1045.95 787.49C1042.2 757.47 1038.25 745.52 1034.1 751.64C1027.39 709.96 1018.6 698.6 1007.73 717.57C993.71 678.45 981.56 670.95 971.29 695.05C962.99 675.89 955.88 674.21 949.95 690.01C931.39 671.05 917.21 673.12 907.43 696.23Z" />
            <path id="&lt;Path&gt;" class="shp36" d="M1005.66 750.45C1040.92 779.19 1064.03 814.31 1074.99 855.79C1077.36 809.57 1074.1 793.12 1065.21 806.45C1064.62 763.79 1058.4 750.31 1046.55 766.01C1038.84 722.75 1025.21 717.57 1005.66 750.45Z" />
            <path id="&lt;Path&gt;" class="shp37" d="M79.95 649.17C71.45 583.98 75.7 548.08 92.71 541.46C87.98 468.71 98.85 427.14 125.3 416.75C133.81 338.33 150.81 298.17 176.32 296.28C201.83 294.39 217.42 332.19 223.09 409.66C243.88 421 253.32 453.12 251.43 506.03C272.22 519.26 277.42 565.55 267.02 644.92C223.56 665.7 161.21 667.12 79.95 649.17Z" />
            <path id="&lt;Path&gt;" class="shp38" d="M0.72 655.59C9.69 633.48 22.07 628.13 37.87 639.54C46.35 607.8 55.56 598.28 65.5 610.98C85.4 594.24 98.86 594.26 105.85 611.06C132.88 610.23 145.29 617.88 143.11 634.03C159.49 627.88 164.93 632.95 159.44 649.25C191.69 647.33 206.84 655.81 204.89 674.68C155.02 696.34 86.96 689.98 0.72 655.59Z" />
            <path id="&lt;Path&gt;" class="shp39" d="M294.35 651.11C287.35 597.48 290.85 567.95 304.84 562.51C300.95 502.66 309.89 468.46 331.65 459.91C338.65 395.4 352.64 362.36 373.63 360.81C394.61 359.25 407.44 390.34 412.1 454.08C429.2 463.41 436.97 489.83 435.42 533.36C452.52 544.24 456.79 582.33 448.24 647.62C412.49 664.72 361.19 665.88 294.35 651.11Z" />
            <path id="&lt;Path&gt;" class="shp40" d="M210.79 680.91C204.41 631.98 207.6 605.04 220.36 600.07C216.82 545.48 224.97 514.28 244.83 506.48C251.21 447.63 263.97 417.49 283.12 416.08C302.26 414.66 313.96 443.02 318.21 501.16C333.81 509.67 340.9 533.78 339.49 573.49C355.08 583.41 358.98 618.16 351.18 677.72C318.57 693.31 271.77 694.38 210.79 680.91Z" />
            <path id="&lt;Path&gt;" class="shp41" d="M328.23 711.54C323.44 674.78 325.84 654.54 335.42 650.81C332.76 609.78 338.89 586.34 353.8 580.48C358.6 536.26 368.19 513.62 382.57 512.56C396.96 511.49 405.75 532.8 408.94 576.49C420.66 582.88 425.99 600.99 424.93 630.83C436.65 638.29 439.58 664.39 433.72 709.14C409.21 720.86 374.05 721.66 328.23 711.54Z" />
            <path id="&lt;Path&gt;" class="shp42" d="M145.63 719.79C140.27 678.73 142.95 656.12 153.66 651.96C150.68 606.14 157.53 579.96 174.19 573.42C179.54 524.04 190.25 498.75 206.31 497.56C222.38 496.37 232.2 520.17 235.77 568.96C248.86 576.1 254.81 596.33 253.62 629.65C266.71 637.98 269.98 667.13 263.43 717.11C236.06 730.2 196.8 731.09 145.63 719.79Z" />
            <path id="&lt;Path&gt;" class="shp43" d="M1207.73 745.79L1513.92 891.32C1545.02 781.53 1529.37 738.24 1466.96 761.43C1453.19 693.13 1424.7 678.45 1381.49 717.38C1346.43 681.64 1320.13 681.32 1302.59 716.43C1267.53 702.81 1235.91 712.6 1207.73 745.79Z" />
            <path id="&lt;Path&gt;" class="shp44" d="M1573.1 964.08C1486.27 950.47 1435.76 924.94 1421.57 887.49C1396.52 884.94 1384 878.98 1384 869.62C1319.71 847.49 1289.24 829.62 1292.58 816C1297.59 795.57 1345.17 766.21 1363.96 812.17C1355.61 783.23 1373.56 767.92 1417.81 766.21C1462.06 764.51 1487.94 789.19 1495.45 840.26C1503.8 765.36 1525.93 736.43 1561.83 753.45C1597.73 770.47 1601.48 840.68 1573.1 964.08Z" />
            <path id="&lt;Path&gt;" class="shp45" d="M1173.73 765.91C1185.71 765.53 1194.88 768.53 1201.24 774.89C1239.05 755.8 1263.39 767.22 1274.24 809.14C1288.09 797.91 1301.38 800.72 1314.11 817.57C1346.68 804.09 1365.96 825.99 1371.95 883.26C1347.24 899.36 1319.91 894.31 1289.96 868.1C1270.5 878.58 1243.73 865.11 1209.67 827.67C1177.85 819.44 1163.81 806.15 1167.55 787.81C1148.84 764.22 1150.89 756.92 1173.73 765.91Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp46" d="M426.99 1020.68C936.32 1020.68 903.6 777.12 722.63 696.23L907.43 696.23C1116.32 774.45 1143.73 895.05 989.66 1058.01L426.99 1020.68Z" />
              <path id="&lt;Path&gt;" class="shp47" d="M836.77 749.56C904.32 824.82 900.77 899.19 826.1 972.68C751.43 1046.16 686.25 1081.12 630.55 1077.56L730.99 1080.23C905.81 976.53 941.06 866.31 836.77 749.56Z" />
              <path id="&lt;Path&gt;" class="shp48" d="M847.43 722.01C906.69 749.86 936.32 803.29 936.32 882.31C936.32 961.32 900.77 1033.61 829.66 1099.19L865.21 1102.75C914.99 1054.55 943.43 991.74 950.55 914.31C961.21 798.16 894.84 744.23 847.43 722.01Z" />
              <path id="&lt;Path&gt;" class="shp49" d="M971.88 768.53C1050.89 865.71 1039.04 967.24 936.32 1073.12L970.69 1073.12C1056.03 951.44 1056.42 849.91 971.88 768.53Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp50" d="M599.14 676.08C685.66 686.75 754.4 716.18 805.36 764.38C804.57 739.1 797.06 729.22 782.84 734.75C771.39 682.21 756.18 663.05 737.21 677.27C703.24 631.44 676.97 624.33 658.4 655.94C644.97 643.29 634.69 645.47 627.58 662.45C614.15 643.1 604.67 647.64 599.14 676.08Z" />
            <path id="&lt;Path&gt;" class="shp51" d="M759.73 751.34C804.37 785.71 823.73 826.4 817.81 873.42C837.56 836.28 841.71 818.7 830.25 820.68C833.02 780.38 827.68 768.53 814.25 785.12C799.63 736.13 781.46 724.87 759.73 751.34Z" />
            <path id="&lt;Path&gt;" class="shp52" d="M254.99 829.56C233.66 871.64 234.25 909.42 256.77 942.9L262.55 941.56C238.25 916.38 237.36 879.34 259.88 830.45L254.99 829.56Z" />
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp53" d="M348.62 976.97C341.51 926.01 350 887.69 374.1 862.01L377.06 863.19C354.55 884.13 346.64 922.45 353.36 978.16L348.62 976.97Z" />
                <path id="&lt;Path&gt;" class="shp54" d="M368.18 865.56C359.43 862.9 363.14 838.9 366.69 838.6C369.06 838.4 370.79 839.54 371.88 842.01C373.46 832.92 377.16 828.58 382.99 828.97C388.82 829.37 389.02 835.29 383.58 846.75C388.82 842.01 393.26 841.12 396.92 844.08C402.4 848.53 385.66 877.27 368.18 865.56Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp55" d="M241 828.38C233.43 807.85 245.99 807.18 248.01 811.83C247.78 798.46 251.83 791.63 260.17 791.32C268.51 791.02 270.11 798.16 264.98 812.75C267.85 808.75 270.87 808.61 274.04 812.33C277.21 816.05 274.45 823.83 265.74 835.67C254.89 840.98 246.64 838.56 241 828.38Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp56" d="M199.43 858.45C184.32 899.34 186.25 928.68 205.21 946.45L212.77 946.01C191.14 937.12 187.88 907.79 202.99 858.01L199.43 858.45Z" />
                <path id="&lt;Path&gt;" class="shp57" d="M201.69 863.03C195.33 862.47 190.59 857.36 191.09 851.63C191.6 845.9 197.17 841.7 203.52 842.27C209.88 842.83 214.62 847.93 214.11 853.67C213.61 859.4 208.04 863.59 201.69 863.03Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp58" d="M554.1 898.9C570.1 934.65 566.94 962.9 544.62 983.64L549.66 984.82C571.19 970.6 573.85 941.27 557.66 896.82L554.1 898.9Z" />
                <path id="&lt;Path&gt;" class="shp59" d="M548.27 900.36C534.77 897.98 528.22 875.15 533.38 871.98C536.82 869.86 539.54 869.86 541.52 871.98C539.54 859.93 541.26 853.25 546.68 851.93C552.11 850.6 555.62 856.49 557.2 869.59C558.53 864.17 561.97 862.85 567.52 865.62C575.86 869.79 571.69 908.3 548.27 900.36Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp60" d="M607.43 903.34C596.18 937.12 594.99 964.53 603.88 985.56L607.88 984.23C599.58 959.05 600.47 931.79 610.55 902.45L607.43 903.34Z" />
                <path id="&lt;Path&gt;" class="shp61" d="M609.74 910.05C603.53 911.52 597.42 908.16 596.1 902.56C594.78 896.96 598.74 891.23 604.95 889.76C611.16 888.3 617.27 891.65 618.59 897.25C619.91 902.86 615.95 908.59 609.74 910.05Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp62" d="M401.21 924.23C408.03 937.86 406.55 952.68 396.77 968.68L402.99 969.56C410.4 960.38 411.29 944.82 405.66 922.9L401.21 924.23Z" />
                <path id="&lt;Path&gt;" class="shp63" d="M402.57 928.66C396.22 928.1 391.48 922.99 391.98 917.26C392.49 911.53 398.05 907.33 404.41 907.9C410.77 908.46 415.51 913.56 415 919.3C414.5 925.03 408.93 929.22 402.57 928.66Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp64" d="M310.1 855.79C321.06 889.57 317.06 922.9 298.1 955.79L302.99 957.12C321.95 938.45 325.21 903.94 312.77 853.56L310.1 855.79Z" />
                <path id="&lt;Path&gt;" class="shp65" d="M315.2 860.52C309.2 862.7 302.74 860.07 300.78 854.66C298.82 849.25 302.09 843.1 308.09 840.92C314.09 838.75 320.54 841.37 322.5 846.78C324.47 852.19 321.2 858.34 315.2 860.52Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp66" d="M122.1 838.45C132.47 890.01 130.1 926.9 114.99 949.12L119.43 950.45C133.66 934.45 136.18 896.97 126.99 838.01L122.1 838.45Z" />
                <path id="&lt;Path&gt;" class="shp67" d="M122 843.91C111.51 845.41 98.78 832.18 103.77 826.68C107.1 823.02 110.43 823.02 113.76 826.68C109.77 810.04 111.68 801.38 119.51 800.71C127.33 800.05 131.33 806.46 131.49 819.94C130.49 815.28 133.24 814.53 139.73 817.69C149.47 822.44 138.98 845.66 122 843.91Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp68" d="M80.77 818.01C92.92 867.79 92.47 908.53 79.43 940.23L85.21 942.45C99.14 918.75 98.99 876.82 84.77 816.68L80.77 818.01Z" />
                <path id="&lt;Path&gt;" class="shp69" d="M82.35 825.77C76 825.21 71.25 820.1 71.76 814.37C72.27 808.64 77.83 804.44 84.19 805.01C90.55 805.57 95.29 810.67 94.78 816.41C94.27 822.14 88.71 826.33 82.35 825.77Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp70" d="M1302.55 949.86C1309.66 898.9 1301.16 860.58 1277.06 834.9L1274.1 836.08C1296.62 857.02 1304.52 895.34 1297.81 951.05L1302.55 949.86Z" />
                <path id="&lt;Path&gt;" class="shp71" d="M1282.99 838.45C1291.73 835.79 1288.03 811.79 1284.47 811.49C1282.1 811.29 1280.37 812.43 1279.29 814.9C1277.71 805.81 1274 801.47 1268.18 801.86C1262.35 802.26 1262.15 808.18 1267.58 819.64C1262.35 814.9 1257.9 814.01 1254.25 816.97C1248.77 821.42 1265.51 850.16 1282.99 838.45Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp72" d="M1544.91 939.74C1558.03 889.99 1554.15 850.93 1533.27 822.57L1530.19 823.39C1550.06 846.86 1553.35 885.84 1540.06 940.36L1544.91 939.74Z" />
                <path id="&lt;Path&gt;" class="shp73" d="M1538.73 826.8C1547.73 825.19 1546.9 800.92 1543.41 800.2C1541.08 799.73 1539.23 800.65 1537.86 802.97C1537.37 793.76 1534.21 789.01 1528.37 788.71C1522.54 788.41 1521.64 794.27 1525.67 806.29C1521.04 800.96 1516.73 799.55 1512.75 802.05C1506.78 805.82 1519.99 836.34 1538.73 826.8Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp74" d="M1506.56 855.74C1521.67 896.63 1519.74 925.97 1500.78 943.74L1493.23 943.3C1514.86 934.41 1518.11 905.08 1503 855.3L1506.56 855.74Z" />
                <path id="&lt;Path&gt;" class="shp75" d="M1504.31 860.32C1510.67 859.76 1515.41 854.65 1514.9 848.92C1514.39 843.19 1508.83 838.99 1502.47 839.56C1496.11 840.12 1491.37 845.22 1491.88 850.96C1492.39 856.69 1497.95 860.88 1504.31 860.32Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp76" d="M1151.89 896.19C1135.89 931.94 1139.05 960.19 1161.37 980.93L1156.34 982.11C1134.81 967.89 1132.14 938.56 1148.34 894.11L1151.89 896.19Z" />
                <path id="&lt;Path&gt;" class="shp77" d="M1157.72 897.65C1171.22 895.27 1177.77 872.44 1172.61 869.27C1169.17 867.15 1166.46 867.15 1164.47 869.27C1166.46 857.22 1164.74 850.54 1159.31 849.22C1153.89 847.89 1150.38 853.78 1148.79 866.88C1147.47 861.46 1144.03 860.13 1138.47 862.91C1130.13 867.08 1134.3 905.59 1157.72 897.65Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp78" d="M1098.56 900.63C1109.82 934.41 1111 961.82 1102.11 982.85L1098.11 981.52C1106.41 956.34 1105.52 929.08 1095.45 899.74L1098.56 900.63Z" />
                <path id="&lt;Path&gt;" class="shp79" d="M1096.25 907.34C1102.47 908.81 1108.57 905.45 1109.9 899.85C1111.22 894.25 1107.25 888.52 1101.04 887.05C1094.83 885.59 1088.72 888.94 1087.4 894.54C1086.08 900.14 1090.04 905.88 1096.25 907.34Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp80" d="M1220.04 910.26C1213.23 923.89 1214.71 938.71 1224.48 954.71L1218.26 955.6C1210.86 946.41 1209.97 930.85 1215.6 908.93L1220.04 910.26Z" />
                <path id="&lt;Path&gt;" class="shp81" d="M1218.68 914.69C1225.04 914.13 1229.78 909.02 1229.27 903.29C1228.76 897.56 1223.2 893.36 1216.84 893.93C1210.48 894.49 1205.74 899.59 1206.25 905.33C1206.76 911.06 1212.32 915.25 1218.68 914.69Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp82" d="M1398.43 872.74C1374.41 898.89 1364.18 930.87 1367.74 968.66L1362.74 967.84C1353.26 942.98 1364.66 910.23 1396.93 869.61L1398.43 872.74Z" />
                <path id="&lt;Path&gt;" class="shp83" d="M1391.83 874.92C1396.38 879.4 1403.34 879.7 1407.38 875.59C1411.41 871.49 1411 864.54 1406.45 860.06C1401.9 855.58 1394.94 855.28 1390.9 859.39C1386.86 863.49 1387.28 870.45 1391.83 874.92Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp84" d="M1451.74 886.71C1441.37 938.26 1443.74 975.15 1458.86 997.37L1454.41 998.71C1440.19 982.71 1437.67 945.22 1446.86 886.26L1451.74 886.71Z" />
                <path id="&lt;Path&gt;" class="shp85" d="M1451.84 892.17C1462.33 893.67 1475.07 880.43 1470.07 874.94C1466.74 871.27 1463.41 871.27 1460.08 874.94C1464.08 858.29 1462.16 849.63 1454.34 848.96C1446.52 848.3 1442.52 854.71 1442.35 868.19C1443.35 863.53 1440.61 862.78 1434.11 865.95C1424.37 870.69 1434.86 893.92 1451.84 892.17Z" />
              </g>
            </g>
            <path id="&lt;Path&gt;" class="shp86" d="M29.76 863.68C44.16 883.03 51.98 894.68 53.22 898.64C54.45 902.6 53.22 894.02 49.51 872.92C59.39 891.83 66.18 907.87 69.89 921.07L71.12 851.15C77.71 884.13 82.44 909.63 85.32 927.66L87.79 901.94L87.79 936.89L139.65 849.83C123.6 893.36 114.75 919.09 113.11 927L137.8 897.32C127.92 923.26 123.4 939.31 124.22 945.47C125.04 951.62 131.42 944.59 143.36 924.36L144.59 937.55C159.41 904.58 163.53 874.68 156.94 847.86C165.58 859.29 168.05 882.59 164.35 917.77L174.23 894.02L174.23 926.34C191.1 888.97 214.15 864.12 243.38 851.81C214.98 878.19 198.92 903.92 195.22 928.98C204.69 918.43 211.68 918.21 216.21 928.32C224.03 917.77 230.82 923.48 236.58 945.47C236.58 929.64 241.73 922.82 252.02 925.02L248.32 894.68C257.78 915.79 264.37 934.92 268.07 952.06L257.58 863.68C269.1 902.38 276.51 934.7 279.8 960.64L297.71 871.6L293.39 938.87C320.55 896.22 339.07 872.48 348.95 867.64C330.43 893.14 320.35 916.01 318.7 936.24C328.17 929.64 334.96 930.74 339.07 939.53C356.36 935.14 369.74 942.17 379.2 960.64C373.85 938.65 367.68 917.55 360.68 897.32C375.5 923.26 385.79 945.03 391.55 962.62C403.08 958.22 412.96 962.4 421.19 975.15L424.27 952.72L427.36 973.17L418.72 885.45L439.09 974.49L436.62 926.34L447.74 967.89L464.41 856.43C462.76 910.51 460.7 945.69 458.23 961.96L476.14 911.17L470.58 954.04L524.29 881.49C501.65 918.43 490.34 944.15 490.34 958.66C496.1 947.23 502.07 949.43 508.24 965.26C518.94 959.54 525.53 963.28 528 976.47L536.64 921.07C535.41 947.01 535.2 966.57 536.02 979.77C544.67 972.29 552.49 973.39 559.48 983.06C562.37 953.16 560.93 929.42 555.16 911.83C565.04 931.18 571.01 954.04 573.07 980.43C578.42 973.83 583.97 974.49 589.74 982.4C595.91 973.17 604.14 968.99 614.43 969.87L601.47 903.26C613.4 931.84 621.22 956.9 624.93 978.45L636.04 946.79L636.04 989L673.09 905.24L648.39 989C656.62 982.84 663 981.74 667.53 985.7C682.35 990.1 690.17 998.89 690.99 1012.08L27.29 1012.08L29.76 863.68Z" />
            <path id="&lt;Path&gt;" class="shp86" d="M1002.49 1004.26C1024.59 988.29 1042.4 984.3 1055.91 992.28C1055.91 987.37 1061.13 985.53 1071.56 986.76L1063.27 943.47L1080.77 982.61L1081.23 886.83C1087.68 935.64 1090.6 965.58 1089.98 976.63L1097.35 955.91L1098.27 979.39C1106.25 963.43 1113.78 956.67 1120.84 959.13L1111.63 918.15C1124.21 939.02 1132.35 955.6 1136.03 967.88C1125.9 932.88 1115.62 906.63 1105.18 889.14C1126.67 916.46 1140.79 941.78 1147.54 965.12L1138.33 899.73L1155.37 967.88L1178.86 859.66L1167.35 964.19L1183.46 934.26L1176.09 956.37L1201.88 928.74L1180.24 967.42C1192.21 960.36 1201.73 958.52 1208.79 961.89C1209.1 951.45 1214.47 946.85 1224.91 948.08C1230.13 935.8 1238.26 929.35 1249.31 928.74L1215.7 867.95C1238.11 895.58 1252.54 916.15 1258.98 929.66L1227.21 833.88C1251.15 879.93 1265.43 913.23 1270.03 933.8L1265.89 873.02L1279.24 931.96L1319.77 864.73C1301.96 904.02 1292.6 926.74 1291.68 932.88C1303.96 923.36 1314.86 922.14 1324.37 929.2C1328.67 917.53 1336.34 915.23 1347.4 922.29L1345.09 872.56C1354.61 903.87 1357.68 929.04 1354.3 948.08L1368.12 889.14L1361.67 957.29L1398.97 917.69L1378.25 955.45L1445.94 896.96L1401.27 951.3C1422.76 949.15 1438.42 954.06 1448.24 966.04C1449.16 958.67 1454.54 957.59 1464.36 962.81C1469.58 942.55 1480.48 932.42 1497.06 932.42L1461.6 862.43C1486.46 896.2 1501.35 918.76 1506.27 930.12L1478.64 819.14C1503.81 880.23 1517.16 917.69 1518.7 931.5L1517.32 870.72L1525.61 916.3C1534.82 910.47 1542.18 912.16 1547.71 921.37L1557.84 914.46L1517.32 833.88L1566.59 914.46L1541.72 839.4L1567.51 903.87L1546.79 815L1578.1 911.7L1578.1 1013.47L995.58 1013.47L1002.49 1004.26Z" />
            <path id="&lt;Path&gt;" class="shp38" d="M191.88 730.9C197.81 707.79 209.36 700.82 226.55 710.01C230.69 677.42 238.55 666.75 250.1 678.01C267.58 658.75 280.92 656.97 290.1 672.68C316.77 668.23 330.1 674.16 330.1 690.45C345.51 682.16 351.58 686.45 348.32 703.34C380.03 697.12 396.18 703.49 396.77 722.45C350.25 750.6 281.95 753.42 191.88 730.9Z" />
          </g>
        </g>
      </g>
    </svg>
    <div class="text-box">
      <span>Tal med dine venner om din Trappestige Plan.</span>
      <br><span>Tænk over måder hvorpå du kan belønne dig <br>selv for at
        have gjort dit bedste, såsom at tage <br>ud i naturen, spille bold,
        eller at spise mad i det <br>fri sammen med din familie.</span>
    </div>
    <audio src="../../assets/sounds/session9/Page174.mp3" ref="voice"/>
    <div class="page-number" id="page-dark">174</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: 'Session9Page2',
  methods: {
    animateText() {
      let text = document.querySelector('.text-box').children;
      let animation = anime.timeline({duration: 500, easing: 'linear'})
      animation
        .add({targets: '.text-box', opacity: 0.9}, 500)
        .add({targets: text[0], color: '#000'}, 500)
        .add({targets: text[2], color: '#000'}, 4800)
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 1)
    },
  },
  mounted() {
    this.animateText();
    this.playVoiceOver();
  }
}
</script>

<style scoped>
.text-box {
  position: absolute;
  background-color: #ffffff;
  width: calc(0.66*133vh);
  padding: 2vh;
  left: calc(0.17*133vh);
  top: 30vh;
  font-size: 4vh;
  opacity: 0;
  text-align: center;
}
.text-box span {color: #ffffff}
.landscape {
  position: absolute;
  height: auto;
  width: 116%;
  left: -5%;
}
tspan { white-space:pre }
.shp0 { fill: url(#grd1) }
.shp1 { fill: url(#grd2) }
.shp2 { fill: url(#grd3) }
.shp3 { fill: url(#grd4) }
.shp4 { fill: url(#grd5) }
.shp5 { fill: url(#grd6) }
.shp6 { fill: url(#grd7) }
.shp7 { fill: #55c7c9 }
.shp8 { fill: #ffffff }
.shp9 { fill: url(#grd8) }
.shp10 { fill: url(#grd9) }
.shp11 { fill: url(#grd10) }
.shp12 { fill: url(#grd11) }
.shp13 { fill: url(#grd12) }
.shp14 { fill: url(#grd13) }
.shp15 { fill: url(#grd14) }
.shp16 { fill: #5ad4b9 }
.shp17 { fill: url(#grd15) }
.shp18 { fill: #5ec94f }
.shp19 { fill: url(#grd16) }
.shp20 { fill: #ebbf6e }
.shp21 { fill: #4e8942 }
.shp22 { fill: url(#grd17) }
.shp23 { fill: url(#grd18) }
.shp24 { fill: url(#grd19) }
.shp25 { fill: url(#grd20) }
.shp26 { fill: url(#grd21) }
.shp27 { fill: url(#grd22) }
.shp28 { fill: url(#grd23) }
.shp29 { fill: #c9f953 }
.shp30 { fill: url(#grd24) }
.shp31 { fill: url(#grd25) }
.shp32 { fill: url(#grd26) }
.shp33 { fill: url(#grd27) }
.shp34 { fill: url(#grd28) }
.shp35 { fill: url(#grd29) }
.shp36 { fill: url(#grd30) }
.shp37 { fill: url(#grd31) }
.shp38 { fill: #93bf4c }
.shp39 { fill: url(#grd32) }
.shp40 { fill: url(#grd33) }
.shp41 { fill: url(#grd34) }
.shp42 { fill: url(#grd35) }
.shp43 { fill: url(#grd36) }
.shp44 { fill: url(#grd37) }
.shp45 { fill: url(#grd38) }
.shp46 { fill: url(#grd39) }
.shp47 { fill: url(#grd40) }
.shp48 { fill: url(#grd41) }
.shp49 { fill: url(#grd42) }
.shp50 { fill: url(#grd43) }
.shp51 { fill: url(#grd44) }
.shp52 { fill: url(#grd45) }
.shp53 { fill: url(#grd46) }
.shp54 { fill: url(#grd47) }
.shp55 { fill: url(#grd48) }
.shp56 { fill: url(#grd49) }
.shp57 { fill: url(#grd50) }
.shp58 { fill: url(#grd51) }
.shp59 { fill: url(#grd52) }
.shp60 { fill: url(#grd53) }
.shp61 { fill: url(#grd54) }
.shp62 { fill: url(#grd55) }
.shp63 { fill: url(#grd56) }
.shp64 { fill: url(#grd57) }
.shp65 { fill: url(#grd58) }
.shp66 { fill: url(#grd59) }
.shp67 { fill: url(#grd60) }
.shp68 { fill: url(#grd61) }
.shp69 { fill: url(#grd62) }
.shp70 { fill: url(#grd63) }
.shp71 { fill: url(#grd64) }
.shp72 { fill: url(#grd65) }
.shp73 { fill: url(#grd66) }
.shp74 { fill: url(#grd67) }
.shp75 { fill: url(#grd68) }
.shp76 { fill: url(#grd69) }
.shp77 { fill: url(#grd70) }
.shp78 { fill: url(#grd71) }
.shp79 { fill: url(#grd72) }
.shp80 { fill: url(#grd73) }
.shp81 { fill: url(#grd74) }
.shp82 { fill: url(#grd75) }
.shp83 { fill: url(#grd76) }
.shp84 { fill: url(#grd77) }
.shp85 { fill: url(#grd78) }
.shp86 { fill: #357068 }
</style>